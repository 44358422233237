import fetch from "isomorphic-fetch";
import {stringify} from "../StringKeyConverter";
import {WAITING_LIST_API_ENDPOINT as waitingListEndpoint} from "../../config";

function generatePostPayload(payload) {
  return {
    method: "POST",
    mode: "cors",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: stringify(payload)
  }
}

function makeRequest(url, payload) {
  return fetch(url, payload)
    .then(response => {
      if (!response.ok) {
        throw new Error("An error occured");
      }
      return response.json().then(json => ({json, response}));
    })
    .then(({json, response}) => {
      if (!response.ok) {
        throw new Error("An error occured");
      }
      return json;
    })
    .then(json => ({response: json}))
    .catch(function(e) {
      return {error: e.message}
    })
}

export function subscribeWaitingList(name, email, country_code, kind) {
  var url = `${waitingListEndpoint}`;

  var info = generatePostPayload({
    subscription: {
      name: name,
      email: email,
      country_code: country_code,
      kind: kind
    }
  });

  return makeRequest(url, info);
}