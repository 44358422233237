import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./ProductWaitingListForm";
import SuccessForm from "./SuccessForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelProductWaitingList, completedProductWaitingList} from "../../actions/ShopActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class ProductWaitingListFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(_values) {
    let updatedValues = fromJS({
      shopId: this.props.shopId,
      sku: this.props.item.productSku,
      kind: this.props.item.kind
    })

    return this.props.customerSubscribeToProduct(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerSubscribeToProduct.errors.length <= 0) {
          this.props.completedProductWaitingList();
        } else {
          let errors = createValidationErrors(response.data.customerSubscribeToProduct.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelProductWaitingList();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />    
    if (data.customerProductSubscriptionStatus.subscribed === true) {
      return (
        <SuccessForm
          title="You are on our waiting list"
          message="Thank you, we have received your request already and will notify you when the product becomes available."
          onClose={this.handleCancel.bind(this)}
          />
      )
    } else {
      return (
        <Form
          productTitle={this.props.item.productTitle}
          productSku={this.props.item.productSku}
          kind={this.props.item.kind}
          message={this.props.item.waitingListText}
          errors={this.state.errors}
          onSubmit={this.handleSubmit.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          />
      )
    }
  }
}

const CHECK_SUBSCRIBED = gql`
  query customerProductSubscriptionStatus($shopId: ID!, $sku: String!, $kind: String!) {
    customerProductSubscriptionStatus(shopId: $shopId, sku: $sku, kind: $kind) {
      subscribed
    }
  }
`;

const SUBSCRIBE_TO_PRODUCT = gql`
  mutation customerSubscribeToProduct($data: CustomerSubscribeToProductInput!) {
    customerSubscribeToProduct(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CHECK_SUBSCRIBED, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        shopId: props.shopId,
        sku: props.item.productSku,
        kind: props.item.kind
      }
    })
  }),
  graphql(SUBSCRIBE_TO_PRODUCT, {
    props: ({ mutate }) => ({
      customerSubscribeToProduct: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default connect(null, {cancelProductWaitingList, completedProductWaitingList})(withQueries(ProductWaitingListFormContainer));

