import React, {Component} from "react";
import { cx } from "../../../shared/lib/ClassSet";
import {dateWithTimeFormat} from "../../../lib/DateFormatter";
import {formatStatus} from "../../../lib/DeliveryTools";
import Button from "../../../shared/components/Button";
import Panel from "../../shared/Panel";
import { connect } from "react-redux";
import { showNotification } from "../../../actions/NotificationActions";
import { generateUUID } from "../../../lib/UUID";
import { DeliveryTag } from "./DeliveryTag";
import { useTranslation, withTranslation } from "react-i18next";
import { Notices } from "./DeliveryItem";
import styles from "./NarrowDeliveriesPanel.module.scss";


class NarrowDeliveriesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedId: ""
    }
  }

  render() {
    if (!this.props.show) return null;
    let classes = cx({
      [styles.module]: true,
      [styles.uActive]: true,
      "panel": true
    })

    let subpanels = (
      <div className={classes}>
        <div>
        { this.props.deliveries.size <= 0
          ? this.renderEmpty()
          : this.renderDeliveries() }
        </div>
        <Button label={this.props.t("common:show_all")} primary={false} link={true} visible={this.props.moreAvailable} onClick={this.props.onShowAll} />
      </div>);

    return (
      <Panel title={this.props.t("deliveries.name")}
        subtitle={this.props.t("deliveries.subtitle")}
        subpanels={subpanels}
        subpanelsCount={this.props.deliveries.size}
        loading={this.props.loading} />
    );
  }

  renderEmpty() {
    if (this.props.loading) return null;
    if (this.props.deliveries.size > 0) return null;
    return (
      <p className={styles.helptext}>{this.props.t("deliveries.no_subscription")}</p>
    )
  }

  renderDeliveries() {
    if (this.props.deliveries.size <= 0) return null;
    return this.props.deliveries.map((delivery, index) => {
      return <Item 
              key={delivery.get("orderId")}
              index={index}
              delivery={delivery}
              selected={delivery.get("orderId") === this.state.selectedId}
              onClick={this.handleItemClick.bind(this)}
              showNotification={this.props.showNotification}
              />
    })
  }

  handleItemClick(id) {
    if (this.state.selectedId === id) {
      this.setState({selectedId: ""});
    } else {
      this.setState({selectedId: id});
    }
  }

}

const Item = (props) => {
  const { t } = useTranslation("accounts");
  const { t: statusT } = useTranslation("accounts", { keyPrefix: "deliveries.status" });
  let data = props.delivery;

  let fn = (e) => props.onClick(data.get("orderId"));

  let classes = cx({
    [styles.item]: true,
    [styles.uSelected]: props.selected,
    [styles.index0]: props.index === 0,
    [styles.uTracking]: data.get("courierStarted") === true,
    [styles.uNoTracking]: data.get("courierStarted") === false,
    [styles.uComplimentary]: data.get("type") === "complimentary",
    [styles.uGift]: data.get("gift") === true
  })

  return (
    <div className={classes}>
      <div className={styles.header} onClick={fn}>
        <div className={styles.info}>
          <div className={styles.status}>
            {formatStatus(data.get("fulfillmentStatus"), statusT)}
          </div>
          <DeliveryTag data={data} className={styles.deliveryTag} />
          <div className={styles.name}>
            { data.getIn(["deliveryAddress", "name"]) }
          </div>
          <div className={styles.destination}>
            {data.getIn(["deliveryAddress", "addressAddressLine1"])}, {data.getIn(["deliveryAddress", "addressPostcode"])} {data.getIn(["deliveryAddress", "addressCity"])}
          </div>
          <div className={styles.date}>
            <span className={styles.prefix}>{t("deliveries.table.last_updated")}</span>
            {dateWithTimeFormat(data.get("updatedAt"))}
          </div>
        </div>
        <div className={styles.count}>
          {data.get("nrPackages")}
        </div>
        <div className={styles.expander}>
          <div className={styles.icon} />
        </div>
      </div>
      <div className={styles.body}>
        <Notices data={data} styles={styles} selected={props.selected} />
      </div>
    </div>
  );
}

NarrowDeliveriesPanel.defaultProps = {
  moreAvailable: false,
  loading: false,
  show: true
}

const mapDispatchToProps = dispatch => ({
  showNotification: (title, kind) => {
    dispatch(showNotification(generateUUID(), title, kind));
  },
});

export default withTranslation("accounts")(connect(null, mapDispatchToProps)(NarrowDeliveriesPanel));