module.exports.labelTransition = {
  enter: {
    animation: {
      opacity: [1,0],
      translateY: [0,-25]
    },
    duration: 500,    
    easing: "easeOutQuint"
  },
  leave: {
    animation: {
      opacity: 0,
      translateY: 10
    },
    duration: 200
  }
}

module.exports.labelTransitionReverse = {
  enter: {
    animation: {
      opacity: [1,0],
      translateY: [0,25]
    },
    duration: 500,
    easing: "easeOutQuint"
  },
  leave: {
    animation: {
      opacity: 0,
      translateY: -10
    },
    duration: 200
  }
}

module.exports.notificationReveal = {
  enter: {
    animation: {
      marginBottom: [0, -60],
      opacity: [1, 0]
    },
    easing: "easeOutCubic",
    duration: 700
  },
  leave: {
    animation: {
      marginBottom: -60,
      opacity: 0,
    },
    easing: "easeOutCubic",
    duration: 100
  },
  enterHideStyle: "hidden",
  enterShowStyle: "",
}