import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/ShippingAddressForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelAddShippingAddress} from "../../actions/AddressBookActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validateShippingAddress} from "../../lib/AddressValidation";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {applyStateProvinceRule} from "../../lib/AddressRules";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import Cookies from "js-cookie";

function validateForm(values, props) {
  return validateShippingAddress(values).toJS();
}

class AddShippingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    const setErrors = (errors) => this.setState({errors: errors});
    return handleAddShippingAddress(this.props, values, setErrors);
  }

  handleCancel() {
    this.props.cancelAddShippingAddress();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let countries = fromJS(filterAndSortCountries(data.customerAvailableCountriesForAccount));
    let template = fromJS(data.customerTemplateShippingAddress);
    let initialValues = template.delete("__typename");
    initialValues = initialValues.set("type", "private");
    if (countries.size === 1) {
      initialValues = initialValues.set("addressCountryCode", countries.first().get("code"));
    } else if (countries.size > 1 && !initialValues.get("addressCountryCode")) {
      const assumedCountryCode = Cookies.get("assumed_country") || "";
      initialValues = initialValues.set("addressCountryCode", assumedCountryCode.toUpperCase());
    }

    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        validate={validateForm}
        countries={countries}
        showAttentionName={true}
        showPhoneNr={true}
        showEmail={true}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />
    );
  }
}

// Adds shipping address to the address book instead of just assigning it
const ADDRESS_BOOK_ADD_ADDRESS = gql`
  mutation customerAddShippingAddressToAccount($data: AddShippingAddressToAccountInput!) {
    customerAddShippingAddressToAccount(input: $data) {
      errors { key message }
    }
  }
`;
function handleAddShippingAddress(props, values, setErrors) {
  let updatedValues = applyStateProvinceRule(
    values.set("accountId", props.accountId)
          .set("phoneNr", getCompletePhoneNrOrNothing(values.get("phoneNr")))
          .delete("addressCountryName")
  );

  return props.customerAddShippingAddressToAccount(updatedValues.toJS())
    .then((response) => {
      if (response.data.customerAddShippingAddressToAccount.errors.length <= 0) {
        props.cancelAddShippingAddress();
      } else {
        let errors = createValidationErrors(response.data.customerAddShippingAddressToAccount.errors);
        setErrors(errors);
      }
    })
    .catch((err) => {
      setErrors(createErrors(err));
    });
}

const SHIPPING_COUNTRIES_FOR_ACCOUNT = gql`
  query customerAvailableCountriesForAccount($accountId:ID!) {
    customerAvailableCountriesForAccount(accountId:$accountId) {
      kind
      code
      name
      availability
    },
    customerTemplateShippingAddress(accountId:$accountId) {
      type
      name
      attName
      phoneNr
      email
      addressAddressLine1
      addressAddressLine2
      addressCity
      addressStateProvince
      addressPostcode
      addressCountryCode   
    }
  }
`;

const withAddressQuery = compose(
  graphql(SHIPPING_COUNTRIES_FOR_ACCOUNT, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ADDRESS_BOOK_ADD_ADDRESS, {
    props: ({ mutate }) => ({
      customerAddShippingAddressToAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

const AddShippingAddressFormContainerWithData = withAddressQuery(AddShippingAddressFormContainer);

export default connect(null, {cancelAddShippingAddress})(AddShippingAddressFormContainerWithData);
