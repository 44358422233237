import {fromJS, Map} from "immutable";
import {CHECKOUT_CART} from "../actions/AnonymousCheckoutActions";

const initialState = fromJS({
  completed: {} // {"cartId": "accountId"}
})

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case CHECKOUT_CART.SUCCESS:
      return state.setIn(["completed", action.cartId], action.accountId);

    default:
      return state;
  }
}

export function checkoutStatus(state, cartId) {
  if (state.hasIn(["completed", cartId])) {
    return Map({completed: true, accountId: state.getIn(["completed", cartId])});
  }
  return Map({completed: false, accountId: ""});
}

export default reducer;