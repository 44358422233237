import React, {useEffect} from "react";
import { cx } from "../lib/ClassSet";
import CommandWindow from "./CommandWindow";
import {buildWindowContent} from "./WindowFactory";
import "./WindowContainer.css";

// const WINDOW_ZINDEX_START = 0;
const MODAL_ZINDEX_START = 500;

const WindowContainer = (props) => {

  useEffect(() => {
    if (props.modalActive) {
      try {
        window.scrollTo(0,0);
      } catch(e) {
        return null;
      }
    }
  }, [props.modalActive])

  let windowContainerClasses = cx({
    "WindowContainer": true,
    "s-active": props.modalActive,
    "s-inactive": !props.modalActive,
  })
  // Do we have any modals?
  let modalElements = null;
  let footerComponent = null;
  if (props.modalActive) {
    // Only the last modal can be active.
    const lastModalIndex = props.modals.size - 1;
    modalElements = props.modals.map((modal, index) => {
      return React.createElement(
        CommandWindow, 
        {
          kind: modal.get("kind"),
          key: modal.get("id"),
          title: modal.get("title"),
          index: MODAL_ZINDEX_START + index,
          active: index === lastModalIndex,
          modal: true,
          headless: modal.getIn(["options", "headless"], false),
          invisible: modal.getIn(["options", "invisible"], false),
          centered: modal.getIn(["options", "centered"], false),
        },
        buildWindowContent(modal.get("kind"), modal.get("id"), modal.get("payload"))
        );
    })
    // If the current modal has a footerComponent in it's options, then we need to instantiate.
    const currentModal = props.modals.last();
    if (currentModal.hasIn(["options", "footerComponent"])) {
      const component = React.createElement(currentModal.getIn(["options", "footerComponent"]), {key: `${currentModal.get("id")}-footer`}, []);
      footerComponent = (
        <div className="WindowContainerFooter">
          <div className="WindowContainerSeparator" />
          { component }
        </div>
      );
    }
  }

  return (
    <div className={windowContainerClasses}>
      <div className="top" />
      {modalElements}
      {footerComponent}
      <div className="bottom" />
    </div>
  )

}

export default WindowContainer;