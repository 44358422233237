import React from "react";
import styles from "./LeftRight.module.scss";

// Let's see how this component could evolve...
function LeftRight(props) {
  const {left, right, customStyle} = props;
  
  return (
    <div className={styles.module} style={customStyle}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{right}</div>
    </div>
  )
}

export default LeftRight;
