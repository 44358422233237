import React from "react";
import Icon from "./Icon";

const DIRECTIONS = {
  UP: "up",
  RIGHT: "right",
  DOWN: "down",
  LEFT: "left",
}

const STYLES = {
  DROP: "drop",
  NORMAL: "normal",
  LINE: "line",
}

const allPoints = {
  // arrow-drop-xxx-line
  [STYLES.DROP]: {
    [DIRECTIONS.UP]: "12 11.828 9.172 14.657 7.757 13.243 12 9 16.243 13.243 14.828 14.657",
    [DIRECTIONS.RIGHT]: "12.172 12 9.343 9.172 10.757 7.757 15 12 10.757 16.243 9.343 14.828",
    [DIRECTIONS.DOWN]: "12 15 7.757 10.757 9.172 9.343 12 12.172 14.828 9.343 16.243 10.757",
    [DIRECTIONS.LEFT]: "11.828 12 14.657 14.828 13.243 16.243 9 12 13.243 7.757 14.657 9.172",
  },
  // arrow-xxx-line
  [STYLES.NORMAL]: {
    [DIRECTIONS.UP]: "", // missing from icons
    [DIRECTIONS.RIGHT]: "16.172 11 10.808 5.636 12.222 4.222 20 12 12.222 19.778 10.808 18.364 16.172 13 4 13 4 11",
    [DIRECTIONS.DOWN]: "", // missing from icons
    [DIRECTIONS.LEFT]: "7.828 11 20 11 20 13 7.828 13 13.192 18.364 11.778 19.778 4 12 11.778 4.222 13.192 5.636",
  },
  // arrow-xxx-s-line
  [STYLES.LINE]: {
    [DIRECTIONS.UP]: "12 10.828 7.05 15.778 5.636 14.364 12 8 18.364 14.364 16.95 15.778",
    [DIRECTIONS.RIGHT]: "13.172 12 8.222 7.05 9.636 5.636 16 12 9.636 18.364 8.222 16.95",
    [DIRECTIONS.DOWN]: "12 13.172 16.95 8.222 18.364 9.636 12 16 5.636 9.636 7.05 8.222",
    [DIRECTIONS.LEFT]: "10.828 12 15.778 16.95 14.364 18.364 8 12 14.364 5.636 15.778 7.05",
  },
}

function ArrowButton(props) {
  const {
    arrowStyle = STYLES.NORMAL,
    direction,
  } = props;

  // TODO animate transitions
  let points = allPoints[arrowStyle][direction];

  return (<Icon {...props} svgPointsParameter={points} />);
}

ArrowButton.DIRECTIONS = DIRECTIONS;
ArrowButton.STYLES = STYLES;

export default ArrowButton;
