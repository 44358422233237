export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const SHOW_NOTIFICATION_WITH_PHOTO = "SHOW_NOTIFICATION_WITH_PHOTO";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const CART_ADD_PRODUCT = "CART_ADD_PRODUCT";

export const NOTIFICATION_KINDS = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
}

export function showNotification(id, message, kind, autoClose=true) {
  return {
    type: SHOW_NOTIFICATION,
    id,
    message,
    kind,
    autoClose
  }
}

export function showNotificationWithPhoto(id, photoUrl, message, kind, autoClose=true) {
  return {
    type: SHOW_NOTIFICATION_WITH_PHOTO,
    id,
    message,
    photoUrl,
    kind,
    autoClose,
  }
}

export function hideNotification(id) {
  return {
    type: HIDE_NOTIFICATION,
    id
  }
}