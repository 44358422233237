import React, {Component} from "react";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import JurisdictionFlag from "../../shared/JurisdictionFlag";
import { cx } from "../../shared/lib/ClassSet";
import AccountStatus from "./AccountStatus";
import styles from "./Accounts.module.scss";
import RenameButton from "../shared/icons/RenameButton";
import { useTranslation } from 'react-i18next';

const CONTEXTS = {
  MENU: styles.menu,
  SETTINGS: styles.settings,
}

class Accounts extends Component {

  render() {
    let elements = this.props.accounts.map(x => {
      const accountId = x.get("accountId");

      return <Account
                key={accountId}
                enabled={x.get("cartInitialized")}
                selected={accountId === this.props.selectedAccountId}
                accountId={accountId}
                name={x.get("name")}
                activeSubscriptions={x.get("subsActive")}
                pausedSubscriptions={x.get("subsPaused")}
                notifications={x.get("notifications")}
                shop={x.get("jurisdictionId")}
                onClick={this.props.onSelectAccount} 
                onRenameClick={this.props.onRenameAccount}
                onDeleteClick={this.props.onDeleteAccount}
                context={this.props.context} />
    }).toList();

    const classes = [styles.module, this.props.context];

    return (
      <div className={classes.join(" ")}>
          {elements}
      </div>
    )
  }
}

const Subtitle = (props) => {
  const { t } = useTranslation();

  let inner = null;
  if (!props.enabled) {
    inner = <span className={styles.wait}>{t("common:please_wait")}</span>;
  } else {
    inner = (
      <>
        <span className={styles.label}>{t("products:subscriptions")}</span>
        <AccountStatus
          activeSubscriptions={props.activeSubscriptions}
          pausedSubscriptions={props.pausedSubscriptions} />
      </>
    );
  }

  return (
    <div className={styles.subtitle}>
      {inner}
    </div>
  );
}

const buildFlag = (props) => {
  if (!props.enabled) {
    return <LoadingCircle size={LoadingCircle.SIZES.SMALL} color="var(--grey-200)" />
  }

  const displayWarning = Number.isInteger(props.notifications) && props.notifications > 0;
  const flagSize = props.context === CONTEXTS.SETTINGS
    ? JurisdictionFlag.SIZES.BIG
    : JurisdictionFlag.SIZES.SMALL;

  return (
    <React.Fragment>
      { displayWarning && <img src="/assets/icons/warning-border.svg" alt="" height="20px" /> }
      <JurisdictionFlag jurisdictionId={props.shop} size={flagSize} />
    </React.Fragment>
  );
}

const Account = (props) => {
  const handleClick = (e) => {
    if (props.enabled && props.onClick) {
      props.onClick(props.accountId);
    }
  }
  const handleRename = (e) => {
    if (props.enabled) {
      props.onRenameClick(props.accountId);
    }
  }

  let classes = cx({
    [styles.item]: true,
    [styles.disabled]: !props.enabled,
    [styles.clickable]: props.enabled && props.onClick,
    [styles.selected]: props.selected,
  })

  const flag = (
    <div className={styles.flags}>
      { buildFlag(props) }
    </div>);

  let actions = null;
  if (props.onRenameClick) {
    actions = (<div className={styles.actions}>
      <div>
        <RenameButton onClick={handleRename} />
        {/* <DeleteButton onClick={props.onDeleteClick && handleDelete}  /> */}
      </div>
    </div>);
  }

  return (
    <div className={classes} onClick={props.onClick && handleClick}>
      { props.context === CONTEXTS.SETTINGS && flag }
      <div className={styles.innerWithoutLeftFlag}>
        <div className={styles.texts}>
          <div className={styles.title}>{props.name}</div>
          <Subtitle {...props} />
        </div>
        { props.context === CONTEXTS.MENU && flag }
        { actions }
      </div>
    </div>
  )
}

Accounts.CONTEXTS = CONTEXTS;

export default Accounts;
