import React from "react";
import Button from "../shared/components/Button";
import Navigation from "../lib/Navigation";
import Error from "./shared/icons/Error";
import Modal from "./shared/Modal";
import { useTranslation } from "react-i18next";

const CheckoutExpiredPage = (props) => {
  const { t } = useTranslation("checkout");

  let backToCartFn = () => Navigation.gotoCart(props.accountId);
  return (
    <Modal displayHeader={false} mode={Modal.MODES.FULLPAGE}
      buttons={<Button label={t("back_to_cart")} onClick={backToCartFn} />}>
      <Error side="50px" colorDisabled="var(--secondary-gold-600)" />
      <h1>{t("expired.title")}</h1>
      <p>{t("expired.message")}</p>
    </Modal>
  );
}

export default CheckoutExpiredPage;