import React, {Component} from "react";
import CreditCardWithSelection from "../shared/CreditCardWithSelection";
import "./CreditCardList.css";

class CreditCardList extends Component {

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  render() {
    let {cards} = this.props;
    let elements = cards.map(card => {
      return (
        <CreditCardWithSelection
          key={card.get("paymentMethodId")}
          card={card}
          id={card.get("paymentMethodId")}
          onSelect={this.handleSelect}
          onDelete={this.handleDelete.bind(this)}
          selected={this.props.selectedId === card.get("paymentMethodId")}
          />          
      )
    })

    return (
      <div className="CreditCardList">
        {elements}
      </div>
    )
  }

  handleSelect(id) {
    if (this.props.selectedId !== id) {
      this.props.onChange(id);
    }
  }
  handleDelete(id) {
    this.props.onDelete(id);
  }
}

CreditCardList.defaultProps = {
  selectedId: ""
}


export default CreditCardList;