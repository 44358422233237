import React, {Component} from "react";
import {VelocityTransitionGroup} from "velocity-react";
import {
  labelTransition as transition,
  labelTransitionReverse as reverseTransition,
} from "../../../lib/Transitions";
import NumberStepperButtons from "./NumberStepperButtons";
import styles from "./NumberStepper.module.scss";

class NumberStepper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      transition: transition
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({transition: (prevProps.value < this.props.value) ? transition : reverseTransition});
    }
  }

  render() {
    let key = `nr${this.props.value}`;
    
    return (
      <div className={styles.module + " NumberStepper"}>
        <VelocityTransitionGroup
          enter={this.state.transition.enter}
          leave={this.state.transition.leave}
          component="div" className={styles.number}>
          <div key={key} className={styles.nr}>{this.props.value}</div>
          <div className={styles.additionnalInfo}>{this.props.additionnalInfo}</div>
        </VelocityTransitionGroup>
        <NumberStepperButtons onIncrement={this.handleInc.bind(this)} onDecrement={this.handleDec.bind(this)} />
      </div>
    );
  }

  handleInc(e) {
    e.preventDefault();
    let newVal = this.props.value + 1;
    if (newVal > this.props.max) return;
    this.signalValue(newVal);
  }

  handleDec(e) {
    e.preventDefault();
    let newVal = this.props.value - 1;
    if (newVal < this.props.min) return;
    this.signalValue(newVal);
  }

  signalValue(val) {
    if (this.props.onChange) {
      this.props.onChange(val);    
    }
  }
  
}

NumberStepper.defaultProps = {
  inFocus: false,
  valid: true,
  value: 0,
  max: 1000,
  min: 0,
  allowManualInput: false      
}

export default NumberStepper;