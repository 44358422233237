import React from "react";
import { trackingUrl as genTrackingUrl } from "../../../lib/TrackingUrl";
import DeliveryPackageStatusTag from "./DeliveryPackageStatusTag";
import styles from "./DeliveryPackage.module.scss";

export function DeliveryPackage(props) {
  let { courierId, packageItem, className, trackingStatusLoading, trackingStatus, trackingUrl } = props;

  const classes = [
    className,
    styles.module,
  ]

  return (
    <div className={classes.join(" ")} key={packageItem.get("packageId")}>
      <span>
        {packageItem.get("title")} {trackingLink(courierId, packageItem, trackingUrl)}
      </span>
      <DeliveryPackageStatusTag status={trackingStatus} loading={trackingStatusLoading} />
    </div>
  );
}

const trackingLink = (courierId, packageItem, trackingUrl) => {
  if (packageItem.get("trackingNr") === "n/a") return null;
  
  const url = genTrackingUrl(courierId, packageItem.get("trackingNr"), trackingUrl);
  return url && url.length > 1
    ? <a href={url} className={styles.trackingLink} target="_blank" rel="noopener noreferrer">Track</a>
    : null;
}