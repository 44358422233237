import React, {Component} from "react";
import {connect} from "react-redux";
import {cancelEditProfile, successEditProfile} from "../../actions/LoginActions";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import GraphQLErrors from "../../shared/GraphQLErrors";
import Form from "./EditProfileForm";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validatePresence, validatePhoneNumber} from "../../lib/Validators";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {Map} from "immutable";
import LoadingLine from "../../shared/components/forms/LoadingLine";

function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "email");
  errors = validatePresence(errors, values, "phone");
  errors = validatePhoneNumber(errors, values, "phone");
  return errors.toJS();
}


class EditProfileFormContainer extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = Map({
      name: values.get("name"),
      email: values.get("email"),
      phone: getCompletePhoneNrOrNothing(values.get("phone"))
    });    
    return this.props.customerChangeProfile(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerChangeProfile.errors.length <= 0) {
          this.props.successEditProfile(values.get("name"));
        } else {
          let errors = createValidationErrors(response.data.customerChangeProfile.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelEditProfile();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />    
    let initialValues = {
      name: data.customerProfile.name,
      email: data.customerProfile.email,
      phone: data.customerProfile.phone
    }
    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        validate={validateForm}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />    
    )
  }
}

const PROFILE = gql`
  query customerProfile {
    customerProfile {
      name
      email
      phone
    }
  }
`;

const UPDATE_CUSTOMER_PROFILE = gql`
  mutation customerChangeProfile($data: CustomerProfileInput!) {
    customerChangeProfile(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(PROFILE, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(UPDATE_CUSTOMER_PROFILE, {
    props: ({ mutate }) => ({
      customerChangeProfile: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelEditProfile, successEditProfile})(withQueries(EditProfileFormContainer));