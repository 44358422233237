// A bottle is about 19 liters.
// 1 bottle is approximately 0.6 liters / day.
// We calculate 30 days per month.
// Calculate 1 liters / day for adult.
// Calculate 0.5 liter / day for children.

const ADULT_LITERS_PER_DAY = 1;
const CHILD_LITERS_PER_DAY = 0.5;

export function getEstimationDescription() {
  return `Based on our customer information, an adult will consume on average ${ADULT_LITERS_PER_DAY} liter per day, and a child ${CHILD_LITERS_PER_DAY} liters per day.`
}

export function estimateBottlesPerMonth(adults, children) {
  let adultLitersPerDay = adults * ADULT_LITERS_PER_DAY;
  let childLitersPerDay = children * CHILD_LITERS_PER_DAY;
  let totalLitersPerMonth = (adultLitersPerDay + childLitersPerDay) * 30;
  let bottles = Math.round(totalLitersPerMonth / 19); // There's 19 liters in a bottle.
  return bottles;
}