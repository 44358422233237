import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {connect} from "react-redux";
import gql from "graphql-tag";
import Form from "../forms/ConfirmForm";
import {cancelResumeSubscriptionScheduling} from "../../actions/AccountActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {Map} from "immutable";
import { withTranslation } from "react-i18next";

class ResumeSubscriptionSchedulingFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleCancel() {
    this.props.cancelResumeSubscriptionScheduling();
  }

  handleSubmit(values) {
    let updatedValues = Map({
      accountId: this.props.accountId,
      subscriptionId: this.props.subscriptionId      
    })
    return this.props.customerResumeSubscriptionScheduling(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerResumeSubscriptionScheduling.errors.length <= 0) {
          this.props.cancelResumeSubscriptionScheduling();
        } else {
          let errors = createValidationErrors(response.data.customerResumeSubscriptionScheduling.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  render() {
    return (
      <Form
        errors={this.state.errors}
        message={this.props.t("subscription.reschedule")}
        label={this.props.t("common:confirm")}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}        
        />
    )
  }
}

const RESUME_SUBSCRIPTION_SCHEDULING = gql`
  mutation customerResumeSubscriptionScheduling($data: CustomerResumeSubscriptionSchedulingInput!) {
    customerResumeSubscriptionScheduling(input: $data) {
      errors { key message }
    }
  }
`

const withQueries = compose(
  graphql(RESUME_SUBSCRIPTION_SCHEDULING, {
    props: ({ mutate }) => ({
      customerResumeSubscriptionScheduling: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);


export default withTranslation("accounts")(connect(null, {cancelResumeSubscriptionScheduling})(withQueries(ResumeSubscriptionSchedulingFormContainer)));

