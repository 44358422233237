import React, {Component} from "react"
import Button from "../../shared/components/Button";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import Checked from "../../shop/shared/icons/Checked";
import SmallMessage from "../../shop/shared/SmallMessage";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import SmallErrorMessage from "../../shop/shared/SmallErrorMessage";
import { withTranslation } from "react-i18next";


const ONBOARDING_STATUS = gql`
  query onboardingStatus($onboardingId: String!) {
    onboardingStatus(onboardingId: $onboardingId) {
      onboardingId
      status
      errorCode
    }
  }
`;

const TIMER_WAIT = 2000; // 2 seconds

class MonitorRegistration extends Component {

  constructor(props) {
    super(props);
    this.onTimer = this.onTimer.bind(this);
    this.state = {
      completed: false,
      errorPollLimit: Date.now() + 10000, // 10 seconds
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.completed && this.state.completed) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    this.timeoutId = window.setTimeout(this.onTimer, TIMER_WAIT)
  }

  stopTimer() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  onTimer() {
    this.props.onSignIn();
  }

  render() {
    return (
      <div className="MonitorRegistration view successView">
        <div className="inner">
          {this.renderViews()}
        </div>
      </div>
    )
  }

  renderViews() {
    let variables = {
      onboardingId: this.props.onboardingId
    }
    return (
      <Query query={ONBOARDING_STATUS} variables={variables} pollInterval={2500} fetchPolicy="network-only">
        {({ loading, error, data, stopPolling }) => {
          if (loading) {
            return this.renderLoadingStatus();
          }

          if (error) {
            if (Date.now() < this.state.errorPollLimit) {
              // It's possible that onboardingId has not yet been populated on the server, and as such we may receive an error "not_found".
              // So we give it some more time before we act on the error.
              return this.renderLoadingStatus();
            } else {
              stopPolling();
              return this.renderFailed();
            }
          }

          if (data.onboardingStatus.status === "failed") {
            stopPolling();
            return this.renderFailed();
          }

          if (data.onboardingStatus.status === "completed") {
            stopPolling();
            if (!this.state.completed) {
              this.setState({completed: true});
            }
            return this.renderCompleted();
          }

          return this.renderLoadingStatus();
        }}
      </Query>            
    )
  }

  renderLoadingStatus() {
    return (
      <SmallMessage title={this.props.t("signup.monitor.creating")}
        icon={<LoadingCircle color={this.props.iconColorDisabled} />}
        message={this.props.t("signup.monitor.creating_message")} />
    );
  }

  renderFailed() {
    return (
      <SmallErrorMessage iconColor={this.props.iconColorDisabled}
        message={this.props.t("signup.monitor.errored_message")}
        button={<Button label={this.props.t("signup.monitor.try_again")} onClick={this.props.onBack} />} />
    );
  }
  renderCompleted() {
    return (
      <SmallMessage title={this.props.t("signup.monitor.created")}
        icon={<Checked side="67px" colorDisabled={this.props.iconColorDisabled} />}
        message={this.props.t("signup.monitor.created_message")}>
        <LoadingLine />
      </SmallMessage>
    );
  }
}

MonitorRegistration.defaultProps = {
  iconColorDisabled: "var(--white)"
}

export default withTranslation("auth")(MonitorRegistration);