import React from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import { useTranslation } from 'react-i18next';
import DropDownField from "../../shared/components/forms/DropDownField";


function ChangeLocaleForm(props) {
  const { t } = useTranslation("settings");

  const {errors, handleSubmit, submit, submitting} = props;
  const submitLabel = submitting ? t("common:standby") : t("language.popup.ok_label");

  return (
    <form className="ChangeLocaleForm" onSubmit={handleSubmit}>
      <div className="fields">
        <Field
          label={t("forms:language")}
          name="locale"
          component={DropDownField}
          placeholder={t("forms:language_placeholder")}
          mode=""
          options={props.locales}
          titleKey="name"
          valueKey="code"
          serverError={props.errors.name}
          />
      </div>

      <div className="buttons">
        <Button label={submitLabel} onClick={submit} active={!submitting} />
        <Button label={t("language.popup.cancel_label")} active={!submitting} onClick={props.onCancel}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
      
      <FormError error={errors._error} />
    </form>
  );
}

const ReduxForm = reduxForm({
  form: "ChangeLocaleForm"
})(ChangeLocaleForm);

export default ReduxForm;