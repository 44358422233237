import React, {Component} from "react";
import {fromJS} from "immutable";
import { cx } from "../../../shared/lib/ClassSet";
import Table from "../../../shared/Table";
import DeliveryItem from "./DeliveryItem";
import Button from "../../../shared/components/Button";
import Panel from "../../shared/Panel";
import { withTranslation } from "react-i18next";
import styles from "./DeliveriesPanel.module.scss";


class DeliveriesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedId: ""
    }
  }

  render() {
    if (!this.props.show) return null;
    let classes = cx({
      [styles.module]: true,
      [styles.uActive]: true
    })

    let tableHeader = fromJS([
      {id: "status", label: this.props.t("deliveries.table.status")},
      {id: "packages", label: this.props.t("deliveries.table.packages")},
      {id: "recipient", label: this.props.t("deliveries.table.destination")},
      {id: "type", label: ""},
      {id: "date", label: this.props.t("deliveries.table.last_updated")},
    ]);

    let subpanels = (
      <div className={classes}>
        { this.props.deliveries.size <= 0
          ? this.renderEmpty()
          : this.renderTable(tableHeader) }
        <Button label={this.props.t("common:show_all")} primary={false} link={true} visible={this.props.moreAvailable} onClick={this.props.onShowAll} />
      </div>);

    return (
      <Panel title={this.props.t("deliveries.name")}
        subtitle={this.props.t("deliveries.subtitle")}
        subpanels={subpanels}
        subpanelsCount={this.props.deliveries.size}
        loading={this.props.loading} />
    )
  }

  renderEmpty() {
    if (this.props.loading) return null;
    if (this.props.deliveries.size > 0) return null;
    return (
      <p className="helptext">{this.props.t("deliveries.no_subscription")}</p>
    )
  }

  renderTable(tableHeader) {
    if (this.props.deliveries.size <= 0) return null;
    return (
      <Table
        data={this.props.deliveries}
        renderer={DeliveryItem}
        mode="table"
        moduleSASS={styles}
        tableHeader={tableHeader}
        itemKey="orderId"
        selected={this.state.selectedId}
        onItemClick={this.handleSelect.bind(this)}
        />
    )    
  }

  handleSelect(orderId) {
    if (this.state.selectedId === orderId) {
      this.setState({selectedId: ""});
    } else {
      this.setState({selectedId: orderId});
    }
  }

}

DeliveriesPanel.defaultProps = {
  show: true,
  moreAvailable: false,
  loading: false
}


export default withTranslation("accounts")(DeliveriesPanel);