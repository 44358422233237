var marked = require("marked");

module.exports = {

  convertUrlsToLinks: function(str) {
    // eslint-disable-next-line
    return str.replace(/((\w+:\/\/)[-a-zA-Z0-9:@;?&=\/%\+\.\*!'\(\),\$_\{\}\^~\[\]`#|]+)/g, "<a href=\"$&\" target=\"_blank\">$&</a>");
  },

  fromMarkdown: function(str) {
    marked.setOptions({
      breaks: true,
      gfm: true
    });    
    return marked(str);
  }

}