import { delay } from 'redux-saga'
import { call, put, fork, take } from 'redux-saga/effects'
import { PRODUCT_CMS_DATA } from "../actions/CmsActions";
import {loadProducts} from "../lib/api/CmsApi";

const BASE_RETRY_MS = 2000;

function* loadFromApi(locale) {
  for (let i=0; i < 5; i++) {
    yield put({type: PRODUCT_CMS_DATA.LOADING});
    let {response, error} = yield call(loadProducts, locale, i);
    if (response) return response;
    if (error && i < 4) {
      let retryDelay = BASE_RETRY_MS + (BASE_RETRY_MS * i * 0.75);
      yield put({type: PRODUCT_CMS_DATA.RETRY, message: error, retryDelay: retryDelay, nextRetry: Date.now() + retryDelay});
      yield delay(retryDelay);
    }
  }

  throw new Error("API request failed");
}

function* load(baseAction) {
  try {
    const response = yield call(loadFromApi, baseAction.locale);
    yield put({type: PRODUCT_CMS_DATA.SUCCESS, data: response});
  } catch (error) {
    yield put({type: PRODUCT_CMS_DATA.FAILED, message: error.message});
  }
}

function* takeLeading(patternOrChannel, saga, ...args) {
  yield fork(function*() {
    while (true) {
      const action = yield take(patternOrChannel);
      yield call(saga, ...args.concat(action));
    }    
  })
}

export function* watchLoad() {
  yield* takeLeading(PRODUCT_CMS_DATA.LOAD, load);
}

export default function* saga() {
  yield fork(watchLoad);
}