import React, {Component} from "react";
import {
  requestAdjustSubscriptionInCart,
  requestCheckoutCart,
  requestEditProductInCart, 
  requestRemoveProductFromCart,
  requestEditSubscriptionInCart,
  requestRemoveSubscriptionFromCart
  } from "../actions/ShopActions";
import {connect} from "react-redux";
import EmptyCartPanel from "./views/EmptyCartPanel";
import CartPanel from "./views/CartPanel";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../lib/SubscriptionQueryWrapper";
import {fromJS} from "immutable";
import GraphQLErrors from "../shared/GraphQLErrors";
import Navigation from "../lib/Navigation";
import "./CartPage.scss";
import ShopLayout from "./ShopLayout";
import { recordViewCart } from "../lib/Analytics";
import { generateViewCartSummary } from "../lib/StatsSummary";
import withCmsProductTitles from "../hocs/withCmsProductTitles";

class CartPage extends Component {

  constructor(props) {
    super(props);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleClearCart = this.handleClearCart.bind(this);
    this.handleAdjustProductClick = this.handleAdjustProductClick.bind(this);
    this.handleRemoveProductClick = this.handleRemoveProductClick.bind(this);
    this.handleAdjustSubscriptionClick = this.handleAdjustSubscriptionClick.bind(this);
    this.handleSelectAnotherDispenser = this.handleSelectAnotherDispenser.bind(this);
    this.handleRemoveSubscriptionClick = this.handleRemoveSubscriptionClick.bind(this);
    this.handleContinueShopping = this.handleContinueShopping.bind(this);
    this.state = {
      hasEmittedViewCart: false
    }
  }

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountId && (prevProps.accountId !== this.props.accountId)) {
      this.subscribe();
    }
    if (this.props.data.customerCart &&
      !this.props.data.error && 
      !this.props.data.loading &&
      this.props.cmsDataProductTitles &&
      !this.props.cmsDataProductTitlesError &&
      !this.props.cmsDataProductTitlesLoading &&
      !this.state.hasEmittedViewCart) {

      // We need to replace the product titles with longTitle from cmsDataProductTitles.
      let titles = this.props.cmsDataProductTitles;
      let customerCart = fromJS(this.props.data.customerCart);
      let updatedItems = customerCart.get("items").map((item) => {
        return item.set("productTitle", titles.getIn([item.get("productSku"), "longTitle"], item.get("productTitle")))
      });
      customerCart = customerCart.set("items", updatedItems);

      let summary = generateViewCartSummary(customerCart);
      recordViewCart(summary.toJS());
      this.setState({hasEmittedViewCart: true});
    }
  }

  subscribe() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.unsubscribe = this.props.subscribeToUpdates(this.props, {});
  }  


  handleCheckout() {
    this.props.requestCheckoutCart(this.props.accountId);
  }

  handleContinueShopping() {
    Navigation.gotoShop(this.props.accountId);
  }

  handleClearCart() {
    this.props.customerClearCart({accountId: this.props.accountId});
  }

  handleAdjustSubscriptionClick() {
    this.props.requestAdjustSubscriptionInCart(this.props.accountId);
  }

  handleSelectAnotherDispenser() {
    Navigation.gotoDispenserGuide(this.props.accountId);
  }

  handleAdjustProductClick(itemData) {
    const noDelete = true; // there is a button to allow direct deletion
    this.props.requestEditProductInCart(this.props.accountId, itemData.get("productSku"), itemData.get("productTitle"), noDelete);
  }

  handleRemoveSubscriptionClick() {
    this.props.requestRemoveSubscriptionFromCart(this.props.accountId);
  }

  handleRemoveProductClick(itemData) {
    this.props.requestRemoveProductFromCart(this.props.accountId, itemData.get("productSku"));
  }

  render() {
    let {loading, error, customerCart} = this.props.data;

    const baseClass = "CartPage";

    if (loading || this.props.cmsDataProductTitlesLoading) {
      return (
        <ShopLayout className={baseClass} showFooter={true}
          boundary={this.props.boundary} accountId={this.props.accountId}
          loading={true} />
      );
    }

    if (error || this.props.cmsDataProductTitlesError) {
      const lambdaNoCart = (e) => e.path && e.path[0] === "customerCart" && e.message === "not_found";
      if (error.graphQLErrors && error.graphQLErrors.find(lambdaNoCart)) {
        // Wrong or expired account id: gotoRoot will handle the rest
        Navigation.gotoRoot();
      }
      return <GraphQLErrors error={error} />;
    }

    let cart = fromJS(customerCart);

    return (
      <ShopLayout className={baseClass} showFooter={true} showFooterCheckout={true}
        boundary={this.props.boundary} accountId={this.props.accountId}>
        {this.renderViews(cart, this.props.cmsDataProductTitles)}
        {/* TODO Hack for desktop so the cart footer doesn't overlap */}
        <div className="desktopOnly" style={{height: "64px"}} />
      </ShopLayout>
    );
  }

  renderViews(cart, productTitles) {
    if (cart.get("nrItems") === 0) {
      return <EmptyCartPanel
               accountId={this.props.accountId}
               gotoShop={() => Navigation.gotoShop(this.props.accountId)} />
    }
    return (
      <>
        <CartPanel
          cart={cart}
          productTitles={productTitles}
          onAdjustProductClick={this.handleAdjustProductClick}
          onRemoveProductClick={this.handleRemoveProductClick}
          onAdjustSubscriptionClick={this.handleAdjustSubscriptionClick}
          onRemoveSubscriptionClick={this.handleRemoveSubscriptionClick}
          onCheckout={this.handleCheckout}
          onContinueShopping={this.handleContinueShopping}
          onClearCart={this.handleClearCart}
          />
      </>
    );
  }

}

const CART_INFO = gql` 
  query customerCart($accountId: ID!) {
    customerCart(accountId: $accountId) {
      accountId
      shopId
      nrItems
      currency
      subtotal
      subscription
      subscriptionFrequencyWeeks
      items {
        productSku,
        productTitle,
        quantity,
        subscription,
        unitPrice,
        subtotal,
        currency
      }
    }
  }
`

const ON_UPDATED_CART = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedCart(accountId: $accountId) {
    accountId
  }`
);

const CLEAR_CART = gql`
  mutation customerClearCart($data: ClearCartInput!) {
    customerClearCart(input: $data) {
      errors { key message}
    }
  }
`;

const withQueries = compose(
  graphql(CART_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(CLEAR_CART, {
    props: ({ mutate }) => ({
      customerClearCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
)

const mapDispatchToProps = dispatch => ({
  subscribeToUpdates: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_CART,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestAdjustSubscriptionInCart: (accountId) => dispatch(requestAdjustSubscriptionInCart(accountId)),
  requestEditProductInCart: (accountId, productSku, productTitle, noDelete) => {
    dispatch(requestEditProductInCart(accountId, productSku, productTitle, noDelete));
  },
  requestRemoveProductFromCart: (accountId, productSku) => {
    dispatch(requestRemoveProductFromCart(accountId, productSku));
  },
  requestEditSubscriptionInCart: (accountId) => {
    dispatch(requestEditSubscriptionInCart(accountId));
  },
  requestRemoveSubscriptionFromCart: (accountId) => {
    dispatch(requestRemoveSubscriptionFromCart(accountId));
  },
  requestCheckoutCart: (accountId) => {
    dispatch(requestCheckoutCart(accountId));
  },
})

export default connect(null, mapDispatchToProps)(withQueries(withCmsProductTitles(CartPage)));