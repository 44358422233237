import React, { useState } from "react";
import Button from "./Button";
import Price from "./Price";
import NumberStepper from "./forms/NumberStepper";
import styles from "./PriceAndButtonForSubscribableProduct.module.scss";
import { currencyFormat } from "../../lib/TextFormatter";
import StatusTag from "./StatusTag";
import RadioSelectButton from "../../shop/shared/RadioSelectButton";
import { MAX_NUMBER_WATER_ITEMS } from "../../lib/ProductsInformation";
import { useTranslation } from "react-i18next";

const PriceAndButtonForSubscribableProduct = (props) => {
  const [subscriptionSelected, setSubscriptionSelected] = useState(true);
  const [numberProducts, setNumberProducts] = useState(1);

  const classes = [
    styles.module,
  ];

  const { t } = useTranslation("products");
  
  const handleNumberChange = (value) => {
    if (value > 0 && value <= MAX_NUMBER_WATER_ITEMS) {
      setNumberProducts(value);
    }
  }

  // We need to shorten the title a bit
  const additionnalInfo = props.title;
  const title = props[props.titleKey];

  let clickAction = () => {
    if (subscriptionSelected) {
      props.onSubscriptionClick(props.sku, title, props.tags, props.status, numberProducts);
    }
    else {
      props.onProductClick(props.sku, title, props.tags, props.status, numberProducts);
    }
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.inputs}>
        <div className={styles.numberStepper}>
          <NumberStepper value={numberProducts} onChange={handleNumberChange}
            additionnalInfo={additionnalInfo} />
        </div>

        <div className={styles.options}>
          <Option selected={subscriptionSelected}
            title={t("subscription_label")}
            titleTag={renderDiscountTag(props.price, props.subscriptionPrice, props.currency, t)}
            value={{ price: props.subscriptionPrice, currency: props.currency, surcharge: props.surcharge }}
            subtitle={t("subscription_sublabel")}
            onClick={(e) => setSubscriptionSelected(true)} />
          <Option selected={!subscriptionSelected}
            title={t("one_time_label")}
            titleTag={renderSubscriberTag(props.price, props.subscriptionPrice, t)}
            value={{ price: props.price, currency: props.currency, surcharge: props.surcharge }}
            subtitle={t("one_time_sublabel")}
            onClick={(e) => setSubscriptionSelected(false)} />
        </div>
      </div>

      <Button label={t("add_to_cart_label")} onClick={clickAction}
        active={props.active} theme={props.buttonTheme} size={Button.SIZES.MEDIUM} />
      
      {/* <FormError error={formatErrors(props.errors)} darkBackground={true} /> */}
    </div>
  );
}

const Option = (props) => {
  const classes = [
    styles.option,
    props.selected && styles.selected,
  ]
  return (
    <div className={classes.join(" ")} onClick={props.onClick}>
      <RadioSelectButton selected={props.selected}>
        <div className={styles.optionInner}>
          <div className={styles.description}>
            <div className={styles.title}>{props.title} {props.titleTag}</div>
            <div className={styles.subtitle}>{props.subtitle}</div>
          </div>
          <div className={styles.price}>
            <Price
              price={props.value.price}
              currency={props.value.currency}
              surcharge={props.value.surcharge}
            />
          </div>
        </div>
      </RadioSelectButton>
    </div>
  );
}

function renderDiscountTag(price, discountedPrice, currency, t) {
  if (price <= discountedPrice) {
    return null;
  }

  const tagText = t("save_money", { money: currencyFormat(price - discountedPrice, currency, 2, true) });

  return <StatusTag color={StatusTag.COLORS.GREEN}
    size={StatusTag.SIZES.SMALL} text={tagText}
  />;
}
function renderSubscriberTag(price, discountedPrice, t) {
  if (price !== discountedPrice) {
    return null;
  }

  return <StatusTag color={StatusTag.COLORS.GREEN}
    size={StatusTag.SIZES.SMALL} text={t("subscriber_price")}
  />;
}

PriceAndButtonForSubscribableProduct.defaultProps = {
  inCart: false,
  active: true,
  currency: "USD",
  price: "",
  suffix: "",
  label: "Label",
  titleKey: "title"
}

export default PriceAndButtonForSubscribableProduct;
