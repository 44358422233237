import React from "react";
import { cx } from "../../shared/lib/ClassSet";
import Button from "../../shared/components/Button";
import "./CartItem.css";

const CartItem = (props) => {

  let {data} = props;
  
  let classes = cx({
    "item": true,
    "sales-cart-list-item": true,
    "u-subscription": data.get("subscription") === true,
    "u-selected": props.selected
  })

  const buttonAction = () => props.onClick(data);

  let button = null;
  if (props.showButton) {
    button = <td className="field f-button u-nowrap"><Button label="adjust" onClick={buttonAction} /></td>
  }

  let subtotal = null;
  if (props.showSubtotal) {
    subtotal = <td className="field f-subtotal u-nowrap">{data.get("subtotal")} <span className="currency">{data.get("currency")}</span></td>
  }

  return (
    <tr className={classes}>
      <td className="field f-id">{data.get("productTitle")}</td>
      {button}
      <td className="field f-quantity u-nowrap">{data.get("quantity")}</td>
      <td className="field f-price unit u-nowrap"><span className="currency">{data.get("currency")}</span> {data.get("unitPrice")}</td>
      <td className="field f-price total u-nowrap"><span className="currency">{data.get("currency")}</span> {data.get("total")}</td>
      {subtotal}
    </tr>
  )
}

CartItem.defaultProps = {
  showSubtotal: true,
  showButton: false
}

export default CartItem;