import React, {Component} from "react";
import {reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import AddressList from "../views/AddressList";
import { withTranslation } from 'react-i18next';

// Displays the Address Book and allows to select any of its addresses
// The addresses in the book can be edited, deleted, and a button allows
// to create a new one, but in any case the user then has to select one
// and click ok in order to update the shipping address.
class ChangeShippingAddressForm extends Component {

  constructor(props) {
    super(props);
    this.handleAddressListChange = this.handleAddressListChange.bind(this);
  }

  render() {
    let {errors, handleSubmit, submitting} = this.props;
    let submitLabel = submitting ? this.props.t("standby") : this.props.t("shipping.select_and_continue");

    const errorMessage = errors && errors.addressStateProvince
      ? errors.addressStateProvince
      : errors._error;
    
    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <p>{this.props.t("shipping.select_or_create")}</p>
          <AddressList
            addresses={this.props.usedShippingAddresses}
            originalSelectedId={this.props.originalSelectedId}
            selectedId={this.props.selectedId}
            onChange={this.handleAddressListChange}
            onEdit={(addressId, address) => this.props.onEditAddress(addressId, address)}
            onDelete={(addressId, address) => this.props.onDeleteAddress(addressId, address)}
            />
          
          <Button label={this.props.t("shipping.add_new_button")} active={!submitting} onClick={this.props.onNewAddress}
            theme={Button.THEMES.GREY_OUTLINE} className="expand" />
        </div>
        <div className="buttons">
          <Button onClick={handleSubmit(values => this.props.onSubmit(values))}
            label={submitLabel} active={!submitting && !!this.props.selectedId} />
          <Button label={this.props.t("cancel")} active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
          
        <FormError error={errorMessage} />
      </form>
    )
  }

  handleSelect(id) {
    this.props.onSelect(id);
  }

  handleAddressListChange(id) {
    this.props.onSelect(id);
  }
}

const ReduxForm = reduxForm({
  form: "ChangeShippingAddressForm"
})(ChangeShippingAddressForm);

export default withTranslation("common")(ReduxForm);