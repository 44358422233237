import React, {useEffect, useState} from "react"
import Button from "../../shared/components/Button";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Accounts from "../../shop/views/Accounts";
import {selectedAccount, selectAccountFailed, reportNoApplicableAccounts} from "../../actions/AnonymousCheckoutActions";
import SmallMessage from "../../shop/shared/SmallMessage";
import {fromJS} from "immutable";
import FieldError from "../../shared/components/forms/FieldError";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { useTranslation } from 'react-i18next';

const SelectAccountFormContainer = ({data, selectedAccount, selectAccountFailed, reportNoApplicableAccounts}) => {
  const { t } = useTranslation("checkout");
  const [view, setView] = useState("loading");

  useEffect(() => {
    if (data.loading) {
      // Do nothing.
    } else if (data.error) {
      selectAccountFailed();
    } else {
      if (data.customerAccountsForAnonymousCart.length === 1) {
        // We have just 1 account, we will auto select
        selectedAccount(data.customerAccountsForAnonymousCart[0].accountId);
      } else if (data.customerAccountsForAnonymousCart.length === 0) {
        // We don't have any applicable accounts...
        reportNoApplicableAccounts();
      } else {
        // We have several accounts.
        setView("select");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [selectedAccountId, setSelectedAccountId] = useState();
  const [noAccoundSelectedError, setNoAccoundSelectedError] = useState(false);

  function handleSelectAccount(accountId) {
    setSelectedAccountId(accountId);
    setNoAccoundSelectedError(false);
  }

  function handleContinue() {
    if (selectedAccountId) {
      return selectedAccount(selectedAccountId);
    } else {
      setNoAccoundSelectedError(true);
    }
  }

  if (view === "select") {
    const accounts = fromJS(data.customerAccountsForAnonymousCart);
    const errorMessage = noAccoundSelectedError && t("anonymous_checkout.select_account.errors.none_selected");

    return (
      <div>
        <p className="sg-text-large u-centered sg-mb-tiny sg-primary-blue-700">{t("anonymous_checkout.select_account.title")}</p>
        <p className="u-centered">{t("anonymous_checkout.select_account.text")}</p>
        <Accounts
          context={Accounts.CONTEXTS.MENU}
          accounts={accounts}
          selectedAccountId={selectedAccountId}
          onSelectAccount={handleSelectAccount} />
        <Button onClick={handleContinue} label={t("anonymous_checkout.select_account.ok_label")}
          theme={Button.THEMES.BLUE} size={Button.SIZES.MEDIUM}
          className="expand" />
        <FieldError globalFormError={true}
          serverError={errorMessage} />
      </div>
    );
  }

  return (
    <SmallMessage title={t("anonymous_checkout.select_account.loading_account")}
      icon={<LoadingCircle />}
      message={t("common:standby")} />
  )    
}

const SUITABLE_ACCOUNTS = gql`
  query suitableAccounts($anonymousCartId: ID!) {
    customerAccountsForAnonymousCart(anonymousCartId: $anonymousCartId) {
      accountId
      name
      jurisdictionId
      cartInitialized
      notifications
      subsActive
      subsPaused
      subsCancelled
    }
  }
`;

const withQuery = compose(
  graphql(SUITABLE_ACCOUNTS, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        anonymousCartId: props.cartId
      }
    })
  })
);

export default connect(null, {selectedAccount, selectAccountFailed, reportNoApplicableAccounts})(withQuery(SelectAccountFormContainer));