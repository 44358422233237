import React, { useEffect, useState } from "react";
import CreditCard from "../../shared/CreditCard";
import BoxWithRadioSelect from "./BoxWithRadioSelect";
import DeleteButton from "./icons/DeleteButton";
import StatusTag from "../../shared/components/StatusTag";
import { useTranslation } from "react-i18next";
import styles from "./BoxWithRadioSelect.module.scss";

const CreditCardWithSelection = (props) => {
  const {
    onDelete = () => {},
  } = props;

  const { t } = useTranslation("checkout");
  const [header, setHeader] = useState();

  let fnSelection = () => props.onSelect(props.id);

  const isCurrentCard = props.id === "current";

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(props.id);
  };

  const cardType = props.card?.get("type");
  useEffect(() => {
    if (isCurrentCard) {
      setHeader(
        <StatusTag text={t("common:credit_card.in_use")} color={StatusTag.COLORS.BLUE} alt />
      );
    } else {
      setHeader(
        <div className={styles.headerRight}>
          <DeleteButton onClick={handleDelete} />
        </div>
      );
    }
  }, [cardType, isCurrentCard]);

  return (
    <BoxWithRadioSelect selected={props.selected}
      onSelect={fnSelection}
      headerText={header}>
      <CreditCard card={props.card} />
    </BoxWithRadioSelect>
  );
}

export default CreditCardWithSelection;