import React, {Component} from "react";
import {Field, FieldArray, reduxForm, getFormValues} from "redux-form/immutable";
import {connect} from "react-redux";
import Button from "../../shared/components/Button";
import DropDownField from "../../shared/components/forms/DropDownField";
import NumberStepperField from "../../shared/components/forms/NumberStepperField";
import FormError from "../../shared/components/forms/FormError";
import FieldError from "../../shared/components/forms/FieldError";
import BackgroundImage from "../../shared/BackgroundImage";
import Conditional from "../../shared/Conditional";
import {currencyFormat} from "../../lib/TextFormatter";
import {indexSurchargeProducts, getSubscriptionTotals} from "../../lib/SubscriptionHelper";
import { cx } from "../../shared/lib/ClassSet";
import Price from "../shared/Price";
import HTMLUtils from "../../lib/HTMLUtils";
import HTMLConverter from "../../lib/HTMLConverter";
import Divider from "../shared/Divider";
import {fromJS, Range} from "immutable";
import { MAX_NUMBER_WATER_ITEMS } from "../../lib/ProductsInformation";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import "./MultiSubscriptionForm.scss";


function numerical(value) {
  let parsed = parseInt(value, 10);
  if (isNaN(parsed)) return value;
  return Math.max(parsed, 0);
}

class MultiSubscriptionForm extends Component {

  render() {
    let {errors, handleSubmit, submit, submitting, formValues, showFrequency, valid, surcharges} = this.props;
    let requirementsFulfilled = valid;
    if (formValues === undefined) return null;

    let classes = cx({
      "MultiSubscriptionForm": true,
      "u-frequency-select": showFrequency,
      "fields" : this.props.basicForm,
    })

    const weeksSelectClasses = this.props.basicForm
      ? "frequencyWeeksSelectSimple"
      : "frequencyWeeksSelect";

    // Translate options (title from server is not translated)
    const frequencyOptions = this.props.frequency.map(
      (o) => o.set("title", this.props.t("subscription.actions.frequency", { count: o.get("value") }))
    );

    return (
      <form className={classes} onSubmit={handleSubmit}>
        <div className={weeksSelectClasses}>
          <div className="inner">
            <Field 
              label={this.props.t("subscription.edit.how_often")}
              name="frequencyWeeks"
              component={DropDownField}
              serverError={this.props.errors.frequencyWeeks}
              options={frequencyOptions}
              showBlank={false}
              normalize={numerical}
              mode=""
              valueKey="value"
              titleKey="title"
              />
          </div>
        </div>
        <FieldArray 
          name="products"
          props={{
            serverError: errors.products,
            frequencyWeeks: formValues.get("frequencyWeeks"),
            handleQuantityAssistant: this.props.onQuantityAssistant,
            surcharges: surcharges
          }} 
          component={this.props.basicForm ? this.renderMembersBasic.bind(this) : this.renderMembers.bind(this)}
          />
        <Conditional show={this.props.showSubmitButtons}>
          <div className="buttons">
            <Button primary={true} label={this.props.t("subscription.edit.continue")}
              onClick={submit} active={!submitting && requirementsFulfilled} />
            <Button primary={false} link={true} label={this.props.t("subscription.edit.cancel")}
              onClick={this.props.onCancel} active={!submitting}
              addCancelToTopRight={true} />
          </div>

          <FormError error={errors._error} />
        </Conditional>
      </form>
    )
  }

  renderMembers({fields, meta: {error, submitFailed, dirty}, serverError, frequencyWeeks, handleQuantityAssistant}) {
    let products = fields.map((member, index) => {
      const productTitle = fields.get(index).get("productTitle");
      let imageSrc = `/assets/subscription-${fields.get(index).get("productSku")}-main.png`;
      let pricing = fields.get(index).get("pricing").first();
      let markdown = HTMLConverter.fromMarkdown(fields.get(index).get("description"));
      return (
        <div className="product" key={index}>
          <div className="inner">
            <div className="media">
              <BackgroundImage width="auto" height="276px" src={imageSrc} backgroundSize="contain" />
            </div>
            <Divider spaceAround={Divider.SPACES.NORMAL} />
            <div className="info">
              <div className="assistant">
                <Field
                  label="Qty."
                  name={`${member}.quantity`}
                  mode="div"
                  component={NumberStepperField}
                  min={0}
                  max={20}
                  />
                <Button label={this.props.t("subscription.edit.quantity_assistant")}
                  visible={fields.get(index).get("productSku") === "WAT-HAL-STL-05"}
                  onClick={() => handleQuantityAssistant(index)}
                  theme={Button.THEMES.BLUE_OUTLINE}
                  size={Button.SIZES.MINI} />
                <p>{this.props.t("subscription.edit.instructions", { product: productTitle, count: frequencyWeeks })}</p>
              </div>
              <div className="texts">
                <div className="title">{productTitle}</div>
                <Price currency={pricing.get("currency")} price={pricing.get("value")} suffix={this.props.t("subscription.edit.plus_taxes")} />
                <p className="description"><span dangerouslySetInnerHTML={HTMLUtils.wrapStringInHTML(markdown)} /> <strong>{fields.get(index).get("note")}</strong></p>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="products">
        {products}
      </div>
    )
  }

  renderMembersBasic({fields, meta: {error, dirty}, serverError, frequencyWeeks, surcharges}) {
    let selectedProducts = [];
    let productFields = fields.map((member, index) => {
      const fieldName = `${member}.quantity`;
      const numberItems = Range(0, MAX_NUMBER_WATER_ITEMS + 1)
        .map((e) => fromJS({ value: e, title: e }));

      const product = fields.get(index);
      const quantity = product.get("quantity");

      // Add product to selectedProducts list as we need it to show totals.
      if (quantity > 0) {
        const price = product.get("pricing").first();
        selectedProducts.push({
          productSku: product.get("productSku"),
          quantity: quantity,
          productTitle: product.get("productTitle"),
          currency: price.get("currency"),
          total: price.get("value") * quantity
        });
      }

      return (
        <Field
          key={fieldName}
          label={product.get("productTitle") + this.props.t("subscription.edit.per_delivery")}
          name={fieldName}
          component={DropDownField}
          serverError={this.props.errors[fieldName]}
          options={numberItems}
          showBlank={false}
          normalize={numerical}
          mode=""
          valueKey="value"
          titleKey="title"
          />
      );
    });

    // Transform selected products to Immutable in order to use our SubscriptionHelper(s).
    selectedProducts = fromJS(selectedProducts);
    return (
      <div className="productsBasic">
        {productFields}
        <FieldError serverError={serverError} clientError={error} showClientError={dirty && error} />
        {this.renderSummary(!serverError, selectedProducts, frequencyWeeks, surcharges)}
      </div>
    )
  }

  renderSummary(show, selectedProducts, frequencyWeeks, surcharges) {
    if (!show || selectedProducts.size === 0) return null;

    let totals = getSubscriptionTotals(selectedProducts, indexSurchargeProducts(surcharges));
    let productInfo = selectedProducts.map(product => {
      return (
        <div key={product.get("productSku")}>
          <div>
            <span className="sg-font-bold">{product.get("quantity")}x</span> {product.get("productTitle")}
          </div>
        </div>
      )
    })

    return (
      <div className="productSummary">
        <div className="totalInfo">
          <div className="sg-font-bold">{this.props.t("subscription.delivery_every", { count: frequencyWeeks })}</div>
          {productInfo}
          <Trans i18nKey="accounts:subscription.price_per_delivery"
            components={{
              b: <span className="sg-font-bold" />
            }}
            values={{
              price: currencyFormat(totals.get("total"), totals.get("currency"), 2, false, false)
            }}
          />
          <SubscriptionSurcharges total={totals.get("surcharges")} currency={totals.get("currency")} />
        </div>
        <div className="disclaimer">{this.props.disclaimer}</div>
      </div>
    )
  }

}

function SubscriptionSurcharges({total, currency}) {
  const { t } = useTranslation("accounts");
  if (total === 0) return null;

  const surchargeAmount = currencyFormat(total, currency, 2, false, false);

  return (
    <div className="surcharge">
      {t("subscription.includes_surcharge", { surcharge: surchargeAmount })}
    </div>
  )
}


MultiSubscriptionForm.defaultProps = {
  showFrequency: true,
  showSubmitButtons: true,
  disclaimer: ""
}

const ReduxForm = reduxForm({
  form: "MultiSubscriptionForm"
})(MultiSubscriptionForm);

function mapStateToProps(state) {
  return {
    formValues: getFormValues("MultiSubscriptionForm")(state)
  }
}
export default withTranslation("accounts")(connect(mapStateToProps)(ReduxForm));