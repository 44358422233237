import React from "react";
import Button from "../shared/components/Button";
import Navigation from "../lib/Navigation";
import SignUpLayout from "../onboarding/SignUpLayout";
import Checked from "../shop/shared/icons/Checked";
import Error from "../shop/shared/icons/Error";
import { useTranslation } from "react-i18next";
import useQueryMap from "./useQueryMap";

const handleClick = () => {
  Navigation.gotoRoot();
}

const EmailVerificationPage = (props) => {
  const { t } = useTranslation("settings");
  const query = useQueryMap();

  let icon = null;
  let message = "";
  
  if (query.get("success") === "true") {
    icon = <Checked side="80px" />;
    message = t("profile.modify_profile.callback_page.success");
  } else if (query.get("message")) {
    icon = <Error side="80px" />;
    message = query.get("message");
  } else {
    icon = <Error side="80px" />;
    message = t("profile.modify_profile.callback_page.error")
  }

  const layoutBG = SignUpLayout.BACKGROUNDS.ACCOUNT_CREATION;

  return (
    <SignUpLayout background={layoutBG} noTestimonials={true}>
      { icon }
      <h1 className="sg-text-large u-bottom-margin-short">{t("profile.modify_profile.callback_page.title")}</h1>
      <p className="u-bottom-margin">{message}</p>
      <Button label={t("common:continue")} onClick={handleClick} />
    </SignUpLayout>
  );
}


export default EmailVerificationPage;