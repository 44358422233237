import React from "react";
import FieldError from "../../shared/components/forms/FieldError";
import AddressWithSelection from "../shared/AddressWithSelection";
import Error from "../shared/icons/Error";
import "./AddressList.scss";

const MODES = {
  NORMAL: AddressWithSelection.MODES.NORMAL,
  TIGHT: AddressWithSelection.MODES.TIGHT,
}

const handleSelect = (props, id) => {
  if (props.selectedId !== id) {
    props.onChange(id);
  }
}

const AddressList = (props) => {
  const { selectedId = "", originalSelectedId = "", addresses } = props;
  
  let elements = addresses.map(x => {
    const addressId = x.get("addressId");

    const selected = selectedId === addressId;
    const optionalSubMessage = selected && props.subMessageForSelected;
    const optionalErrorMessage = props.errorForId && props.errorForId.id === x.get("addressId") && (
      <div className="errorMessage">
        <Error />
        <FieldError serverError={props.errorForId.message} />
      </div>
    );

    const canDelete = addressId !== originalSelectedId;

    return (
      <React.Fragment key={addressId}>
        <AddressWithSelection
          address={x.get("address")}
          id={addressId}
          onSelect={(id) => handleSelect(props, id)}
          selected={selected}
          mode={props.mode}
          additionalElemForSelected={props.additionalElemForSelected}
          onEdit={props.onEdit}
          onDelete={canDelete && props.onDelete}
          />
        { optionalSubMessage }
        { optionalErrorMessage }
      </React.Fragment>
    );
  });

  return (
    <div className="AddressList">
      { elements }
    </div>
  );
}

AddressList.MODES = MODES;

export default AddressList;