import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import CalendarField from "../../shared/components/forms/CalendarField";
import FormError from "../../shared/components/forms/FormError";
import {change} from "redux-form";
import { useTranslation } from 'react-i18next';
import "./CalendarForm.css";

const CalendarForm = (props) => {
  const { t } = useTranslation("accounts");
  let {
    errors,
    handleSubmit, submit, submitting,
    leadingText,
    cancelLabel = t("common:cancel"),
    submitLabel = t("common:save"),
  } = props;

  const submitLabelIf = submitting ? t("common:standby") : submitLabel;

  return (
    <div className="CalendarForm">
      <form onSubmit={handleSubmit}>
        <div className="fields u-centered">
          <Field
            label={leadingText}
            name="deliveryDate"
            mode="not-table"
            disableWeekends={true}
            notBeforeDate={props.notBeforeDate}
            notAfterDate={props.notAfterDate}
            showEvents={props.showEvents}
            component={CalendarField}
            unavailableDates={props.unavailableDates}
            serverError={props.errors.deliveryDate}
            />
        </div>
        <div className="buttons">
          <Button label={submitLabelIf} primary={true} onClick={submit} active={!submitting} />
          <Button label={cancelLabel} active={!submitting} onClick={props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
          
        <FormError error={errors._error} />
      </form>
    </div>
  );
}

const ReduxForm = reduxForm({
  form: "CalendarForm"
})(CalendarForm);

export default connect(null, {change})(ReduxForm);