import React from "react";
import styles from "./FormError.module.scss";

const FormError = (props) => {
  if (!props.error && !props.validationError) return null;
  let errors = [];

  if (props.error) {
    errors.push(props.error);
  }

  if (props.validationError) {
    errors.push(props.validationMsg);
  }

  const classes = [
    styles.module,
    props.darkBackground ? styles.darkBackground : null,
    props.className,
  ];

  return (
    <div className={classes.join(" ")}>
      <p>{errors.join(". ")}</p>
    </div>
  )
}

FormError.defaultProps = {
  validationError: false,
  validationMsg: "Contains Invalid values",
  darkBackground: false,
}

export default FormError;