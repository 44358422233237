import React, {Component} from "react";
import {requestShowOrderStatusWithMonitor} from "../../actions/ShopActions";
import {requestChangeCreditCard, requestRetryPayment} from "../../actions/AccountActions";
import {connect} from "react-redux";
import Button from "../../shared/components/Button";
import { cx } from "../../shared/lib/ClassSet";
import styles from "./PaymentError.module.scss";
import Error from "../shared/icons/Error";
import { withTranslation } from 'react-i18next';

class PaymentError extends Component {

  render() {
    let classes = cx({
      [styles.module]: true,
      [styles.withTitle]: this.props.title !== "",
    })
    let retryMessage = null;
    let clickFn = this.handleClick.bind(this);
    let viewDetails = null;
    if (this.props.showDetailsLink) {
      viewDetails = <span className={styles.clickDetails} onClick={clickFn}>{this.props.t("credit_card.view_details")}</span>;
    }
    if (this.props.willRetry) {
      retryMessage = this.props.t("credit_card.retry_message");
    }

    let message = null;
    let actions = null;

    if (this.props.errorCode === "authentication_required") {
      message = <p>{this.props.errorMessage} {this.props.t("credit_card.charging_info")} <strong>{this.props.t("credit_card.delivery_shipping_info")}</strong> {viewDetails}</p>;
      actions = (
        <React.Fragment>
          <Button label={this.props.t("credit_card.auth_button")} onClick={this.handleAuthenticate.bind(this)} />
          <Button label={this.props.t("credit_card.edit")} link={true} onClick={this.handleChangeCard.bind(this)} />
        </React.Fragment>
      );
    }
    else if (this.props.errorCode === "unknown_error") {
      message = <p>{this.props.t("credit_card.unknown_error_while_charging")} {retryMessage} {viewDetails}</p>;
      actions = null;
    }
    else {
      message = <p>{this.props.errorMessage} {retryMessage} {this.props.t("credit_card.charging_info")} <strong>{this.props.t("credit_card.delivery_delays_info")}</strong> {viewDetails}</p>;
      // The charge will be automatically retried again, however you might want to modify your credit card details.
      // Adding a new credit card will automatically retry the charge instantly.
      // Please note that this may cause delays to your delivery if no action is taken. ===== > why not bold in mobile?
      actions = (
        <React.Fragment>
          <Button label={this.props.t("credit_card.retry_charging")} onClick={this.handleRetryPayment.bind(this)} />
          <Button label={this.props.t("credit_card.edit")} onClick={this.handleChangeCard.bind(this)} />
        </React.Fragment>
      );
    }

    const errorIcon = this.props.displayErrorIcon && (
      <Error colorDisabled="var(--accent-red-600)" />
    );

    const actionsElem = actions && (
      <div className={styles.actions}>
        { actions }
      </div>
    );

    return (
      <div className={classes}>
        <div className={styles.message}>
          { errorIcon }
          <div className={styles.title}>{this.props.title}</div>
          { message }
        </div>
        { actionsElem }
      </div>
    );
  }

  handleAuthenticate() {
    this.props.requestShowOrderStatusWithMonitor(this.props.accountId, this.props.salesOrderId);
  }

  handleChangeCard() {
    this.props.requestChangeCreditCard(this.props.accountId);
  }

  handleRetryPayment() {
    this.props.requestRetryPayment(this.props.accountId, this.props.salesOrderId, 10);
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

}

PaymentError.defaultProps = {
  title: "",
  showDetailsLink: true
}

export default withTranslation("common")(connect(null, {requestShowOrderStatusWithMonitor, requestChangeCreditCard, requestRetryPayment})(PaymentError));