import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Form from "../forms/ShippingAddressForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validateShippingAddress} from "../../lib/AddressValidation";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {applyStateProvinceRule} from "../../lib/AddressRules";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { noop } from "lodash";
import Cookies from "js-cookie";
import styles from "./ShippingAddressFormContainer.module.scss";
import { useTranslation } from "react-i18next";


function validateForm(values, props) {
  return validateShippingAddress(values).toJS();
}

function SetupShippingAddressFormContainer(props) {
  const {
    accountId,
    onAddressAdded = noop,
    data,
    prefilledAddress,
    upstreamErrors = [],
  } = props;

  const { t } = useTranslation("checkout");

  const [errors, setErrors] = useState(upstreamErrors);
  const [submitting, setSubmitting] = useState(false);
  const [added, setAdded] = useState(false);

  function handleSubmit(values) {
    let updatedValues = applyStateProvinceRule(
      values.set("accountId", accountId)
            .set("phoneNr", getCompletePhoneNrOrNothing(values.get("phoneNr")))
            .delete("addressCountryName")
    );

    setSubmitting(true);

    return props.customerAddShippingAddressToAccount(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerAddShippingAddressToAccount.errors.length <= 0) {
          setAdded(true);
          onAddressAdded();
        } else {
          const errors = createValidationErrors(response.data.customerAddShippingAddressToAccount.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  // When the address is added, we stop displaying the form
  if (added) {
    return (
      <>
        <div className={styles.addressAdded}><LoadingCircle /></div>
        {props.children}
      </>
    );
  }
  
  const countries = fromJS(filterAndSortCountries(data.customerAvailableCountriesForAccount));
  const template = fromJS(data.customerTemplateShippingAddress);
  let initialValues = template.delete("__typename");
  initialValues = initialValues.set("type", "private");
  if (countries.size === 1) {
    initialValues = initialValues.set("addressCountryCode", countries.first().get("code"));
  } else if (countries.size > 1 && !initialValues.get("addressCountryCode")) {
    const assumedCountryCode = Cookies.get("assumed_country") || "";
    initialValues = initialValues.set("addressCountryCode", assumedCountryCode.toUpperCase());
  }
  initialValues = initialValues.merge(prefilledAddress);

  return (
    <Form
      initialValues={initialValues}
      submitting={submitting}
      submitLabel={t("checkout:shipping.add_address")}
      errors={errors}
      validate={validateForm}
      countries={countries}
      showAttentionName={true}
      showPhoneNr={true}
      showEmail={false}
      showCancel={false}
      showIntroText={false}
      onSubmit={handleSubmit}

      />
  );
}

// Adds shipping address to the address book instead of just assigning it
const ADDRESS_BOOK_ADD_ADDRESS = gql`
  mutation customerAddShippingAddressToAccount($data: AddShippingAddressToAccountInput!) {
    customerAddShippingAddressToAccount(input: $data) {
      errors { key message }
    }
  }
`;

const SHIPPING_COUNTRIES_FOR_ACCOUNT = gql`
  query customerAvailableCountriesForAccount($accountId:ID!) {
    customerAvailableCountriesForAccount(accountId:$accountId) {
      kind
      code
      name
      availability
    },
    customerTemplateShippingAddress(accountId:$accountId) {
      type
      name
      attName
      phoneNr
      email
      addressAddressLine1
      addressAddressLine2
      addressCity
      addressStateProvince
      addressPostcode
      addressCountryCode   
    }
  }
`;

const withAddressQuery = compose(
  graphql(SHIPPING_COUNTRIES_FOR_ACCOUNT, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ADDRESS_BOOK_ADD_ADDRESS, {
    props: ({ mutate }) => ({
      customerAddShippingAddressToAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withAddressQuery(SetupShippingAddressFormContainer);
