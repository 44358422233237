import {useEffect} from "react";
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import {anonymousCheckoutAlreadyCompleted, beginAnonymousCheckoutSaga, showAnonymousCartNotFoundWindow} from "../actions/AnonymousCheckoutActions";
import {checkoutStatus} from "../reducers/AnonymousCheckoutReducer";

const AnonymousCheckoutPage = ({data, anonymousCheckoutAlreadyCompleted, beginAnonymousCheckoutSaga, showAnonymousCartNotFoundWindow, cartId, auth, checkoutCompleted, checkoutAccountId}) => {
  useEffect(() => {
    if (checkoutCompleted) {
      anonymousCheckoutAlreadyCompleted(cartId, checkoutAccountId);
    } else {
      if (data.loading) {
        // Do nothing
      } else if (data.error) {
        // Show error, we don't know the country code unfortunately.
        showAnonymousCartNotFoundWindow(cartId);
      } else {
        beginAnonymousCheckoutSaga(cartId, data.customerAnonymousCart.countryCode, auth.isAuthenticated());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cartId, checkoutCompleted, checkoutAccountId]);

  return null;
}

const ANONYMOUS_CART = gql`
  query customerAnonymousCart($anonymousCartId: ID!) {
    customerAnonymousCart(anonymousCartId: $anonymousCartId) {
      cartId
      countryCode
      shopId
    }
  }
`;

const withQuery = graphql(
  ANONYMOUS_CART, {
    options: (props) => ({
      fetchPolicy: "network-only",
      skip: props.checkoutCompleted,
      variables: {
        anonymousCartId: props.cartId
      }
    })
  }
)

function mapStateToProps(state, ownProps) {
  let status = checkoutStatus(state.get("anonymousCheckout"), ownProps.cartId);
  return {
    checkoutCompleted: status.get("completed"),
    checkoutAccountId: status.get("accountId")
  }
}

export default connect(mapStateToProps, {beginAnonymousCheckoutSaga, showAnonymousCartNotFoundWindow, anonymousCheckoutAlreadyCompleted})(withQuery(AnonymousCheckoutPage));