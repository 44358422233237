import React, {useEffect, useState} from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Button from "../../shared/components/Button";
import styles from "./CartSummary.module.scss";
import Basket from "./icons/Basket";
import { connect } from "react-redux";
import { SubscriptionQueryWrapper } from "../../lib/SubscriptionQueryWrapper";
import { useTranslation } from "react-i18next";


const CartSummary = (props) => {
  const { t } = useTranslation("cart");

  let {
    accountId,
    data, tightDisplay,
    basketSize = "24px", basketClassName,
    showCheckout, showViewCart,
    onCheckout, onViewCart,
  } = props;

  useEffect(() => {
    if (!accountId) return

    return props.subscribeToUpdates(props, accountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const cartSummary = data.customerCartSummary;
  const [text, setText] = useState();

  useEffect(() => {
    if (!cartSummary) return;
    
    setText(t("items", { count: cartSummary.nrItems }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartSummary && cartSummary.nrItems]);

  if (data.error) return null;

  const hasItems = cartSummary ? cartSummary.nrItems > 0 : false;
  let basketElem = null;
  if (!tightDisplay) {
    basketElem = (
      <p className={styles.info}>
        <Basket side={basketSize} colorDisabled="var(--grey-900)"
          className={basketClassName} />
        <span className={styles.label}>{text}</span>
      </p>
    );
  }
  else {
    basketElem = (
      <div className={styles.tightDisplay}>
        <Basket side={basketSize} color="var(--grey-900)" onClick={onViewCart}
          className={basketClassName} />
        <RoundedTag count={cartSummary && cartSummary.nrItems} />
      </div>
    );
  }

  return (
    <React.Fragment>
      { basketElem }

      <Button className={styles.cart} label={t("links:link_checkout")}
        theme={Button.THEMES.BLUE}
        onClick={onCheckout}
        size={Button.SIZES.SMALL}
        visible={hasItems && showCheckout} />
      <Button className={styles.cart} label={t("links:link_cart")}
        theme={Button.THEMES.BLUE}
        onClick={onViewCart}
        size={Button.SIZES.SMALL}
        visible={hasItems && showViewCart} />
    </React.Fragment>
  );
}


function RoundedTag({count = 0}) {
  if (count <= 0) return null;

  const classes = [
    styles.rounded,
    count >= 10 ? styles.bigger : "",
  ];
  
  return (
    <div className={classes.join(" ")}>{count}</div>
  );
}

// We don't need to subscribe to cart updates as it's done in the client
// section wrapper already.
const CART_SUMMARY = gql`
  query customerCartSummary($accountId: ID!) {
    customerCartSummary(accountId: $accountId) {
      nrItems
    }
  }
`;

const ON_UPDATED_CART = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedCart(accountId: $accountId) {
    accountId
  }`
);

const mapDispatchToProps = dispatch => ({
  subscribeToUpdates: (props, accountId) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_CART,
      variables: {
        accountId: accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
});

const withQueries = compose(
  graphql(CART_SUMMARY, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId
      }
    })
  })
);

CartSummary.defaultProps = {
  showViewCart: true,
  showCheckout: false
}

export default connect(null, mapDispatchToProps)(withQueries(CartSummary));