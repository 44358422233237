import { useEffect, useState } from "react";
import { loadProductTitles } from "../lib/api/CmsApi";
import { Map } from "immutable";

function indexData(data) {
  return data.edges.reduce((acc, item) => {
    return acc.set(item.node.sku, Map({
      sku: item.node.sku,
      title: item.node.title,
      longTitle: item.node.long_title
    }));
  }, Map());
}

// Will return immutable Map that is indexed by SKU.
export default function useCmsProductTitles(lang) {
  const [loadStatus, setLoadStatus] = useState("not_loaded");
  const [currentLang, setCurrentLang] = useState(lang);
  const [error, setError] = useState(false);
  const [data, setData] = useState(Map());

  useEffect(() => {
    let shouldLoad = loadStatus === "not_loaded";
    let shouldReload = lang !== currentLang;

    if (lang && (shouldLoad || shouldReload)) {
      setError(false);
      setLoadStatus("loading");
      setCurrentLang(lang);
      loadProductTitles(lang)
        .then(({response, error}) => {
          if (error) {
            setError(error);
          } else {
            setData(indexData(response));
          }
          setLoadStatus("loaded");
        });
    }
  }, [lang, currentLang, loadStatus]);

  return { 
    data, 
    error,
    loaded: loadStatus === "loaded", 
    loading: loadStatus === "loading" || loadStatus === "not_loaded"
  }
}