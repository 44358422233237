import {List, Map, fromJS} from "immutable";
import { getPermanentQueryParams } from "../config";

export function retainSearchParams(currentQuery, permanentParams=List()) {
  if (permanentParams.size === 0) return Map();
  if (!Map.isMap(currentQuery)) return Map();
  // Do we have any
  let keepParams = permanentParams.reduce((acc, key) => {
    if (currentQuery.has(key)) {
      return acc.set(key, currentQuery.get(key));
    } else {
      return acc;
    }
  }, Map());
  return keepParams;
}

export function retainCartIdSearchParam(currentQuery, retainedParams = Map()) {
  if (!Map.isMap(currentQuery)) return retainedParams;
  if (currentQuery.has("cartId")) {
    return retainedParams.set("cartId", currentQuery.get("cartId"));
  }
  return retainedParams;
}

export function applyParamsToPath(path, params=Map()) {
  if (params.size === 0) return path;

  let basePath = path;
  let pathParams = Map();
  if (path.indexOf("?") > -1) {
    basePath = path.substr(0, path.indexOf("?"));
    // extract query from path.
    pathParams = fromJS(path
                    .substring(path.indexOf("?")+1)
                    .split("&"))
                    .reduce((acc, value) => {
                      // Convert to map.
                      if (value === "") return acc;
                      let [k, v] = value.split("=");
                      return acc.set(k, v);
                    }, Map())
  }
  // Let specified params overwrite path params.
  let allParams = pathParams.merge(params);
  // Sort and serialize
  let query = allParams
                .sortBy((v, key) => key)
                .reduce((acc, value, key) => {
                  return acc.push(`${key}=${value}`);
                }, List())
                .join("&");
  return `${basePath}?${query}`;
}

// Retrieves search params we need to keep like gclid, etc.
export function getSearchParams(query) {
  return retainSearchParams(Map(query), getPermanentQueryParams());
}

// Patches a url (or "as") with the params we need to keep like gclid, etc.
export function patchSearchParams(query, hrefOrAs) {
  let filteredQuery = getSearchParams(query);
  let newHrefOrAs = applyParamsToPath(hrefOrAs, filteredQuery);

  return newHrefOrAs;
}
