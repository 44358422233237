import React from "react";
import TextSlice from "./slices/TextSlice";
import NoteSlice from "./slices/NoteSlice";
import DividerSlice from "./slices/DividerSlice";

export function buildSlice(sliceType, slice, index, onChangePage) {
  switch(sliceType) {
    case "text":
      return <TextSlice key={index} data={slice.get("primary")} onChangePage={onChangePage} />
    case "divider":
      return <DividerSlice key={index} data={slice.get("primary")} />
    case "note":
      return <NoteSlice key={index} data={slice.get("primary")} onChangePage={onChangePage} />
    default:
      console.warn(`Slice of type ${sliceType} does not exist in factory.`)
      return null;
  }
}