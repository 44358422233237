import React from "react";
import Icon from "./Icon";

// Icons/Core/close-line
function CloseX(props) {
  const svgPointsParameter = "12 10.586 16.95 5.636 18.364 7.05 13.414 12 18.364 16.95 16.95 18.364 12 13.414 7.05 18.364 5.636 16.95 10.586 12 5.636 7.05 7.05 5.636";
  return (<Icon {...props} svgPointsParameter={svgPointsParameter} />);
}

export default CloseX;
