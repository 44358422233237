import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Navigation from "../../lib/Navigation";
import {cancelCancelCheckout} from "../../actions/ShopActions";
import SmallMessage from "../shared/SmallMessage";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { withTranslation } from 'react-i18next';

const TIMER_WAIT = 2000; // 2 seconds

class CancelCheckoutFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.onTimer = this.onTimer.bind(this);
    this.state = {
      timerCompleted: false,
      receivedResponse: false,
      completed: false,
    }
  }

  componentDidMount() {
    this.startTimer();
    this.props.cancelCheckout({accountId: this.props.accountId, orderId: this.props.orderId})
      .then((response) => {
        this.handleCancelled();
      })
      .catch((err) => {
        // We got an error but we dont want to burder our customers with that.
        this.handleCancelled();
      })
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.completed !== this.state.completed && this.state.completed) {
      this.handleCompleted();
    }
  }

  startTimer() {
    this.timeoutId = window.setTimeout(this.onTimer, TIMER_WAIT)
  }

  stopTimer() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  onTimer() {
    this.setState({
      timerCompleted: true, 
      completed: this.state.receivedResponse
    })
  }

  handleCancelled() {
    this.setState({
      receivedResponse: true,
      completed: this.state.timerCompleted
    });
  }

  handleCompleted() {
    if (this.props.redirect) Navigation.gotoCart(this.props.accountId);
    this.props.cancelCancelCheckout();
  }

  render() {
    return (
      <React.Fragment>
        <SmallMessage icon={<LoadingCircle />}
          title={this.props.t("notifications.cancelling_title")}
          titleAsGuideHeader={true}
          message={this.props.t("notifications.returning_stock")}
        />
      </React.Fragment>
    )
  }
}

const CANCEL_CHECKOUT = gql`
  mutation customerCancelCheckout($data: CancelCheckoutInput!) {
    customerCancelCheckout(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CANCEL_CHECKOUT, {
    props: ({mutate}) => ({
      cancelCheckout: (data) => mutate({
        variables: {
          data: data
        }
      })
    })
  })
)

CancelCheckoutFormContainer.defaultProps = {
  redirect: true
}

export default withTranslation("checkout")(connect(null, {cancelCancelCheckout})(withQueries(CancelCheckoutFormContainer)));