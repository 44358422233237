import React, { createRef, useEffect, useState } from "react";
import ProductPriceAndButton from "./ProductPriceAndButton";
import styles from "./InstashopProduct.module.scss";
import { connect } from "react-redux";
import PrismicImages from "./PrismicImages";
import { productHasTag } from "../../lib/ProductsInformation";
import StatusTag from "./StatusTag";


const MODES = {
  WIDEST: "productWidestMode",
  WIDER: "productWiderMode",
  WIDE: "productWideMode",
  MEDIUM: "productMediumMode",
  THIN: "productThinMode",
}

const InstashopProduct = (props) => {
  const [productMode, setProductMode] = useState(MODES.THIN)
  const productRef = createRef();

  useEffect(() => {
    // Handle product style depending on its available width
    if (productRef.current) {
      const width = productRef.current.offsetWidth;
      if (width > 1350) {
        setProductMode(MODES.WIDEST);
      }
      else if (width > 1200) {
        setProductMode(MODES.WIDER);
      }
      else if (width > 650) {
        setProductMode(MODES.WIDE);
      }
      else if (width > 500) {
        setProductMode(MODES.MEDIUM);
      }
      else {
        setProductMode(MODES.THIN);
      }
    }
  }, [productRef, props.windowWidth]);
  
  const baseClasses = [
    styles.module,
    props.className,
    productMode,
    props.highlighted ? styles.highlighted : styles.listing,
  ];
  
  const imageClasses = [
    styles.images,
    "productImages",
  ];

  // Image width should be kept low when product is highlighted (product page).
  let imageMaxWidth = props.highlighted ? "424px" : null;
  if (productHasTag(props, "water") && !props.highlighted) {
    // In the product listing, water products shouldn't take up all horizontal
    // space, contrarily to dispensers.
    imageMaxWidth = "300px";
  }

  const keywordsTags = props.highlighted && props.keywords && props.keywords.split(",").map(
    (s) => <StatusTag key={s} text={s} color={StatusTag.COLORS.GOLD} />
  );
  
  return (
    <div ref={productRef} className={baseClasses.join(" ")}>
      <div className={imageClasses.join(" ")}>
        <PrismicImages images={props.prismicImages} onClick={props.onClick} maxWidth={imageMaxWidth} />
      </div>

      <main>
        <header className="clickable" onClick={props.onClick}>
          <h2>{ props.longTitle }</h2>
          <span className={styles.subtitle}>{ props.subtitle }</span>
        </header>

        <section>
          <div className={styles.text}>
            { props.children }
          </div>
          <div className={styles.keywords}>{ keywordsTags }</div>
          <ProductPriceAndButton {...props}
            titleKey="longTitle"
            onProductClick={props.onProductClick}
            onSubscriptionClick={props.onSubscriptionClick}
          />
          
          { props.additionnalInfo }
        </section>
      </main>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    windowWidth: state.getIn(["system", "width"]),
  }
}

export default connect(mapStateToProps)(InstashopProduct);
