import React, { Suspense } from "react";
import styles from "./Tip.module.scss";

const STATUS = {
  WARNING: styles.warning,
  ALERT: styles.alert,
  POSITIVE: styles.positive,
  NEUTRAL: styles.neutral,
}

const ICONS = {
  ERROR: React.lazy(() => import("../../shop/shared/icons/Error")),
  ALARM: React.lazy(() => import("../../shop/shared/icons/Alarm")),
  THUMBUP: React.lazy(() => import("../../shop/shared/icons/ThumbUp")),
  BASKET: React.lazy(() => import("../../shop/shared/icons/Basket")),
}

const Tip = (props) => {
  if (props.show === false) return null;

  const classes = [styles.module, props.status, "Tip"];

  const LazyIcon = props.icon;
  const icon = props.icon && (
    <div className={styles.icon}>
      <Suspense fallback={null}>
        <LazyIcon />
      </Suspense>
    </div>
  );

  return (
    <div className={classes.join(" ")}>
      <div className={styles.inner}>
        { icon }
        <div className={styles.text}>{props.children}</div>
      </div>
    </div>
  )
}

Tip.STATUS = STATUS;
Tip.ICONS = ICONS;

Tip.defaultProps = {
  show: true,
  status: Tip.STATUS.NEUTRAL,
}

export default Tip;