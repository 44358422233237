import React from "react";
import styles from "./Bullets.module.scss";

const THEMES = {
  WHITE: styles.themeWhite,
  BLUE: styles.themeBlue,
}
  
const Bullets = ({index, count, theme, onClick}) => {

  let items = [];
  for (var i=0; i < count; i++) {
    items.push(<Bullet key={i} index={i} active={i === index} onClick={(i) => onClick(i)} />);
  }

  const classes = [styles.module]
  classes.push(theme);

  return (
    <div className={classes.join(" ")}>
      {items}
    </div>
  )
}

Bullets.defaultProps = {
  theme: THEMES.BLUE,
}

Bullets.THEMES = THEMES;

export default Bullets;

const Bullet = ({active, index, onClick}) => {

  let classes = [styles.bullet];
  if (active) classes.push(styles.bulletActive);

  let fn = (e) => onClick(index);

  return (
    <div className={classes.join(" ")} onClick={fn}>
      <div className={styles.icon} />
    </div>
  )
}
