import React, { Component } from 'react';
import {Map} from "immutable";
import {retainSearchParams, retainCartIdSearchParam} from "../lib/PermanentUrlParams";
import {getPermanentQueryParams} from "../config";
import {withRouter} from "react-router";
import LoadingCircle from '../shared/components/forms/LoadingCircle';


class CallbackPage extends Component {

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkAuth();
  }

  checkAuth() {
    if (/access_token|id_token|error/.test(this.props.location.hash)) {
      let params = retainSearchParams(Map(this.props.location.query), getPermanentQueryParams());
      params = retainCartIdSearchParam(Map(this.props.location.query), params).toJS();

      if (this.props.route) {
        this.props.route.auth.handleAuthentication(params);
      } else if (window) {
        window.location.href = "/signin"
      }
    }
  }

  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
    }

    return (
      <div style={style}>
        <LoadingCircle className="centeredWithinViewport" />
      </div>
    );
  }
}

export default withRouter(CallbackPage);