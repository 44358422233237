import React from "react";
import {connect} from "react-redux";
import SubPage from "../SubPage";
import {
  cancelAdjustSubscriptionInCart
} from "../../actions/ShopActions";

class AdjustSubscriptionInCartFormContainer extends React.Component {

  handleClose() {
    this.props.cancelAdjustSubscriptionInCart();
  }

  render() {
    return (
      <div className="form">
        <SubPage accountId={this.props.accountId}
          formOnly={true}
          onCancel={this.handleClose.bind(this)}
          onSuccess={this.handleClose.bind(this)} />
      </div>
    );
  }
}

export default connect(null, {
  cancelAdjustSubscriptionInCart,
  })(AdjustSubscriptionInCartFormContainer);
