import React from "react";
import styles from "./Overlay.module.scss";

// Overlays over parent element
export const Overlay = (props) => {
  // Message should not be under the overlay itself as the opacity is applied
  // to descendants as well.
  return (
    <div className={styles.module}>
      <div className={styles.overlay} style={{backgroundColor: props.backgroundColor}} />
      <div className={styles.message}>
        { props.children }
      </div>
    </div>
  );
}