import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {optimalResURL, getUrlDependingOnWindowWidth, isRetina} from "../../lib/MediaTools";

// Load an image in the background, and display it with an animation once it's
// ready.
// Supports two ways to define images:
// * image url + booleans for retina and mobile versions of it
// * prismic image, which comes in various sizes and this componnent will chose
// the appropriate one.
// => in prismic, when creating a type, define resolutions with names 
// "desktop", "desktop2x", "mobile" and "mobile2x".
function LoadBackgroundImage(props) {
  const { className, loadedClassName, url, prismicImage } = props;

  const [{loaded, loadedUrl}, setLoaded] = useState({loaded: false, loadedUrl: ""});
  const resize = props.resize;

  // Handle image loading and transitions
  useEffect(() => {
    // We don't need to update if the image loadedUrl is the same as the one
    // we would get through getImageUrlFrom.
    const src = getImageUrlFrom(props);

    if (loadedUrl === src) {
      return () => {};
    }

    // Reset loaded state
    setLoaded({loaded: false});

    // Load new image
    const img = new Image();
    img.onload = function() {
      setLoaded({loaded: true, loadedUrl: src});
    }
    img.src = src;

    // Don't forget to reset the onload if this comp gets nuked in between
    return () => {
      img.onload = () => {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, prismicImage.url, resize]);

  const classNames = loaded ? `${className} ${loadedClassName}` : className;
  const styles = generateStyles(loaded, loadedUrl);

  return (
    <div className={classNames} style={styles} />
  );
}

const getImageUrlFrom = (props) => {
  const { url, prismicImage, useRetinaSuffix = true, useMobileSuffix = true } = props;

  let src = "";

  if (url) {
    // decide on what image to use.
    const baseUrl = useMobileSuffix ? getUrlDependingOnWindowWidth(window.innerWidth, url) : url;
    // Either get @2x or normal url.
    src = useRetinaSuffix ? optimalResURL(baseUrl) : baseUrl;
  }
  else if (prismicImage) {
    // prismicImage.url is the default url, but there should be more with
    // different names and resolutions
    const isMobile = window.innerWidth < 700;

    if (isMobile) {
      // See if there is a "mobile2x"/"mobile" image available
      if (useRetinaSuffix && isRetina() && prismicImage.mobile2x) {
        src = prismicImage.mobile2x.url;
      }
      if (!src && prismicImage.mobile) {
        src = prismicImage.mobile.url;
      }
    }
    else {
      // See if there is a "desktop2x"/"desktop" image available
      if (useRetinaSuffix && isRetina() && prismicImage.desktop2x) {
        src = prismicImage.desktop2x.url;
      }
      if (!src && prismicImage.desktop) {
        src = prismicImage.desktop.url;
      }
    }

    if (!src) {
      // Default that should always be defined in prismic
      src = prismicImage.url;
    }
  }
  
  // console.log("SOURCE: ", src)
  return src;
}

function generateStyles(loaded, url) {
  if (!loaded) return {};  
  return {
    backgroundImage: `url(${url})`,
  };
}

LoadBackgroundImage.defaultProps = {
  className: "bgImageCentered",
  loadedClassName: "bgImageLoaded",
  url: "",
  prismicImage: {},
}


function mapStateToProps(state, ownProps) {
  return {
    resize: state.getIn(["system", "width"]),
  }
}

export default connect(mapStateToProps)(LoadBackgroundImage);