import React from "react";
import {reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import { useTranslation } from 'react-i18next';
import FormError from "../../shared/components/forms/FormError";


const ConfirmForm = (props) => {
  const { t } = useTranslation("common");

  const {
    errors,
    label = t("confirm"), cancelLabel = t("cancel"),
    message = t("are_you_sure"),
    submit, submitting
  } = props;
  const submitLabel = submitting ? t("standby") : label;

  return (
    <form className="ConfirmForm">
      <p className="u-centered">{message}</p>
      <div className="buttons">
        <Button label={submitLabel} primary={true} active={!submitting} onClick={submit} />
        <Button label={cancelLabel} primary={false} active={!submitting}
          onClick={props.onCancel} theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
        
      <FormError error={errors._error} />
    </form>
  )
}


const ReduxForm = reduxForm({
  form: "ConfirmForm"
})(ConfirmForm);

export default ReduxForm;