import React, {useEffect, useState} from "react";
import {currencyFormat} from "../../lib/TextFormatter";
import Button from "../../shared/components/Button";
import Conditional from "../../shared/Conditional";
import Forbidden from "../shared/icons/Forbidden";
import { getImageUrlForProduct, getImageUrlForSubscription } from "../../lib/MediaTools";
import { Overlay } from "../../shared/components/Overlay";
import AddIcon from "../shared/icons/Add";
import SubstractIcon from "../shared/icons/Substract";
import LoadBackgroundImage from "../../shared/components/LoadBackgroundImage";
import { useTranslation } from 'react-i18next';
import styles from "./CartPanel.module.scss";

const CartPanel = (props) => {
  const { t } = useTranslation("cart");
  const { cart, productTitles } = props;
  
  const classes = [
    styles.module,
  ];

  const [canCheckout, setCanCheckout] = useState(true);

  // Only render this if there is a subscription.
  let frequencyWeeks = cart.get("subscriptionFrequencyWeeks");
  
  const [subscriptionItemsElems, setSubscriptionItemsElem] = useState();
  const [otherItemsElems, setOtherItemsElem] = useState();
  const [hasOtherItems, setHasOtherItems] = useState(false);

  useEffect(() => {
    setCanCheckout(true);

    const subscriptionItems = cart.get("items").filter((item) => item.get("subscription"))
    const normalItems = cart.get("items").filter((item) => item.get("subscription") === false)
  
    setSubscriptionItemsElem(<RenderItems items={subscriptionItems} productTitles={productTitles} isSubscription={true} {...props} setCanCheckout={setCanCheckout} />);
    setOtherItemsElem(<RenderItems items={normalItems} isSubscription={false} {...props} productTitles={productTitles}  setCanCheckout={setCanCheckout} />);
    setHasOtherItems(normalItems.size > 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, productTitles]);

  return (
    <div className={classes}>
      <div className={styles.cartTotal + " mobileOnly"}>
        <div className={styles.title + " u-centered"}>{t("cart_title")}</div>
      </div>

      <div className={styles.info}>
        <Conditional show={cart.get("subscription") === true}>
          <div className={styles.section + " " + styles.subscriptionSection}>
            <div className={styles.header}>
              <div className={styles.sectionTitle}>{t("subscription")} ({t("delivery_frequency", { count: frequencyWeeks})})</div>
            </div>
            <div className={styles.items}>
              { subscriptionItemsElems }
            </div>
            <div className={styles.itemsFooter}>
              <Button label={t("modify_subscription")}
                onClick={props.onAdjustSubscriptionClick}
                theme={Button.THEMES.GREY_OUTLINE} />
            </div>
          </div>
        </Conditional>

        <Conditional show={hasOtherItems}>
          <div className={styles.section + " " + styles.oneTimeOrders}>
            <div className={styles.header}>
              <div className={styles.sectionTitle}>{t("one_time_orders")}</div>
            </div>
            <div className={styles.items}>
              { otherItemsElems }
            </div>
            <div className={styles.itemsFooter}>
              <Button label={t("continue_shopping")}
                onClick={() => props.onContinueShopping()}
                theme={Button.THEMES.GREY_OUTLINE} />
            </div>
          </div>
        </Conditional>
      </div>

      <div className={styles.sideBar}>
        <div className={styles.fixedDesktop}>
          <div className={styles.cartTotal}>
            <div className={styles.title + " desktopOnly"}>{t("cart_title")}</div>
            <div className="mobileOnly">
              {/* just small space */}
              {/* <Divider spaceAround={Divider.SPACES.SMALL} firstOrLast={true} /> */}
            </div>
            <div className={styles.subtitle}>{t("cart_subtotal")}</div>
            <div className={styles.subtotal}>{currencyFormat(cart.get("subtotal"), cart.get("currency"))}</div>
            <div className={styles.freeShipping}>{t("free_shipping")}</div>
            <p className={styles.disclaimer}>
              {t("products:tax_not_included")}<br/><br/>
              {t("products:tax_on_water_included_for_austrians")}<br/><br/>
              {t("products:surcharge_may_apply")}
            </p>
          </div>
          <Button label={t("go_to_checkout")} onClick={props.onCheckout}
            theme={Button.THEMES.DARK_BLUE} active={canCheckout} />
          <Button label={t("continue_shopping")} onClick={props.onContinueShopping}
            theme={Button.THEMES.GREY_OUTLINE} />
          <Button label={t("clear_cart")} onClick={props.onClearCart}
            theme={Button.THEMES.GREY_OUTLINE} />          
        </div>
      </div>
    </div>
  );
}

const RenderItems = (props) => {
  const { items, isSubscription, setCanCheckout, productTitles } = props;
  const { t } = useTranslation("cart");
  const baseClasses = [
    styles.item,
    isSubscription ? styles.subscriptionItem : styles.oneTimeOrderItem,
  ];

  const elements = items.map(x => {
    const mediaClasses = [styles.media];
    const sku = x.get("productSku");
    if (sku.match(/^(DPR-HAL)|(DPR-OAS)/)) {
      mediaClasses.push("useBackground");
    }

    const key = `${ isSubscription ? "subscription" : "oneTime" }-${sku}`;
    const imageURL = isSubscription ? getImageUrlForSubscription(sku) : getImageUrlForProduct(sku);

    const currency = x.get("currency");
    const unitPrice = x.get("unitPrice");
    const formattedUnitPrice = unitPrice ? currencyFormat(unitPrice, currency) : "--";
    const subtotal = x.get("subtotal");
    const formattedSubtotal = subtotal ? currencyFormat(subtotal, currency) : "--";

    // Bandaid when product not available for current country
    const onRemoveClick = isSubscription ? props.onRemoveSubscriptionClick : props.onRemoveProductClick;
    const onRemoveProductButton = onRemoveClick && (
      <Button label={t("remove_from_cart")} size={Button.SIZES.MINI}
        onClick={() => onRemoveClick(x)}
        theme={Button.THEMES.GREY_OUTLINE} />
    );
    const errorOverlay = (isNaN(unitPrice) || isNaN(subtotal)) && (
      <Overlay backgroundColor="var(--grey-600)">
        <div className={styles.errorOverlay}>
          <Forbidden colorDisabled="var(--white)" side="27px" />
          <p>{t("not_available_in_country")}</p>
          { onRemoveProductButton }
        </div>
      </Overlay>
    );
    if (errorOverlay) {
      setCanCheckout(false);
      baseClasses.push(styles.withOverlay);
    }

    const hideWhenInvalid = errorOverlay ? styles.hideWhenInvalid : undefined;
    const buttonsElem = !isSubscription && (
      <RenderOneTimeOrderButtons element={x} onRemoveProductButton={onRemoveProductButton} {...props} />
    );

    // Get the title from `productTitles` if available, otherwise use the product title.
    let title = productTitles.getIn([x.get("productSku"), "longTitle"], x.get("productTitle"));

    return (
      <div className={baseClasses.join(" ")} key={key}>
        <div className={styles.inner}>
          { errorOverlay }
          <div className={mediaClasses.join(" ")}>
            <LoadBackgroundImage
              className="bgImageCentered"
              useMobileSuffix={false}
              useRetinaSuffix={false}
              url={imageURL}
            />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>{title}</div>
            <table>
              <thead>
                <tr>
                  <th>{t("quantity")}</th>
                  <th className={hideWhenInvalid}>{t("unit_price")}</th>
                  <th className={hideWhenInvalid}>{t("subtotal")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.fQuantity}>{x.get("quantity")}</td>
                  <td className={hideWhenInvalid}>{formattedUnitPrice}</td>
                  <td className={hideWhenInvalid}>{formattedSubtotal}</td>
                </tr>
              </tbody>
            </table>
            { !errorOverlay && buttonsElem }
          </div>
        </div>
      </div>
    )
  });

  return (
    <React.Fragment>
      {elements}
    </React.Fragment>
  );
}

const RenderOneTimeOrderButtons = (props) => {
  const { t } = useTranslation("cart");
  const {
    element, onRemoveProductButton,
    onIncrProductClick, onDecrProductClick,
    onAdjustProductClick
  } = props;

  const onIncrOrDecrButtons = onIncrProductClick && onDecrProductClick && (
    <div className={styles.incrDescrButtons}>
      <Button size={Button.SIZES.MINI}
        onClick={() => onIncrProductClick(element)}
        theme={Button.THEMES.GREY_OUTLINE}>
        <AddIcon colorDisabled="var(--grey-400)" />
      </Button>
      <Button size={Button.SIZES.MINI}
        onClick={() => onDecrProductClick(element)}
        theme={Button.THEMES.GREY_OUTLINE}>
        <SubstractIcon colorDisabled="var(--grey-400)" />
      </Button>
    </div>
  );
  const onAdjustProductButton = onAdjustProductClick && (
    <Button label={t("adjust")} size={Button.SIZES.MINI}
      onClick={() => onAdjustProductClick(element)}
      theme={Button.THEMES.GREY_OUTLINE} />
  );
  
  return (
    <div className={styles.itemButtons}>
      { onIncrOrDecrButtons }
      { onAdjustProductButton }
      { onRemoveProductButton }
    </div>
  );
}

export default CartPanel;
