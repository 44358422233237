import { RichText } from "prismic-reactjs";
import React from "react";
import styles from "./SimpleCard.module.scss";
import LoadBackgroundImage from "./LoadBackgroundImage";


// To go along prismic "simple_card" type: a card with rich text and bg color.
export default function SimpleCard(props) {
  const {
    className,
    style,
    text,
    textPosition,
    textMaxWidth,
    footer,
    backgroundImage,
  } = props;

  const outerClasses = [
    styles.module,
    className,
  ];

  const classes = [
    styles.inner,
    textPosition === "top" ? styles.positionTop : styles.positionCenter,
  ];

  const imageElem = backgroundImage && (
    <LoadBackgroundImage
      className="bgImageCentered bgImageCover"
      prismicImage={backgroundImage}
    />
  )

  const textElem = text && (
    <div className="text">
      <RichText render={text} />
    </div>
  );

  const footerElem = footer && (
    <div className="footer">
      <RichText render={footer} />
    </div>
  );
  
  return (
    <div className={outerClasses.join(" ")} style={style}>
      {imageElem}
      <div className={classes.join(" ")} style={{"--text-max-width": textMaxWidth}}>
        {textElem}
        
        {footerElem}
      </div>
    </div>
  );
}

export function simpleCardConfigFromPrismic(prismicConfig) {
  const text = prismicConfig.get("text");
  const footer = prismicConfig.get("footer");
  const bg = prismicConfig.get("background_image");

  return {
    text: text && text.toJS(),
    textPosition: prismicConfig.get("text_position"),
    textMaxWidth: prismicConfig.get("text_max_width"),
    footer: footer && footer.toJS(),
    backgroundImage: bg && bg.toJS(),
    style: {
      backgroundColor: prismicConfig.get("background_color"),
      color: prismicConfig.get("text_color"),
      minHeight: prismicConfig.get("min_height"),
      minWidth: prismicConfig.get("min_width"),
    },
  }
}