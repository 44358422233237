import React from "react"
import styles from "./Testimonial.module.scss"
import FiveStars from "./FiveStars"

/**
 * Testimonial display the photo, stars rating, quote, author and author info
 * The theme, photo, stars rating and author about text are optional
 * 
 * E.g. for an ambassador:
  <Testimonial 
    photo={data.photo}
    quote={data.quote}
    authorName={data.author}
    authorTitle={data.title}
  />
 * For a customer's review:
  <Testimonial
    rating={data.rating}
    quote={data.quote}
    authorName={data.author}
  />
 */

const THEMES = {
  WHITE: styles.themeWhite,
  SLIDING_WHITE: styles.themeWhite + " " + styles.themeSliding,
  BLUE: styles.themeBlue,
  SLIDING_BLUE: styles.themeBlue + " " + styles.themeSliding,
  BLACK: styles.themeBlack,
}
  
const Testimonial = ({
  theme = THEMES.BLACK,
  quote,
  authorName,
  authorTitle,
  photo,
  rating,
}) => {
  const photoBgStyle = photo && {
    backgroundImage: `url(${photo.url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
  }

  return (
    <div className={theme}>
      {photo && <div className={styles.photo} style={photoBgStyle} />}
      {rating && (
        <div className={styles.stars}>
          <FiveStars rating={rating} cssColor="var(--secondary-gold-600)" />
        </div>
      )}
      <p className={styles.quote + " quote"}>{quote}</p>
      <div>
        <span className={styles.name}>{authorName}</span>
        {authorTitle && <span className={styles.title}>{authorTitle}</span>}
      </div>
    </div>
  );
}

Testimonial.THEMES = THEMES;

export default Testimonial
