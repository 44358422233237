import { compareAsc, parse } from "date-fns";
import {Map, List} from "immutable";

export function isScheduled(delivery = Map()) {
  return delivery.get("scheduleStatus") === "scheduled";
}

export function isBusy(delivery = Map()) {
  return delivery.get("requestStatus") !== "ready";
}

export function isDelayable(delivery = Map()) {
  return !isProcessed(delivery) && !isCancelled(delivery) && delivery.get("requestStatus") === "ready";
}

export function isProcessed(delivery = Map()) {
  return List(["started", "billing", "processed", "in_transit", "completed"]).includes(delivery.get("status"));
}

export function isCurrent(delivery = Map()) {
  return List(["started", "billing", "processed", "in_transit", "completed"]).includes(delivery.get("status"));
}

export function isCancelled(delivery = Map()) {
  return delivery.get("status") === "cancelled";
}

export function isToBeIgnored(delivery = Map()) {
  // Ignore cancelled deliveries
  if (isCancelled(delivery)) return true;

  // If delivery has a delivery date that is older than replacement package
  // update, then any problem with that delivery has been handled manually
  // on the admin app and we shouldn't show it to the user anymore.
  //
  // If date is invalid, compareAsc will return NaN. We hide it only if its
  // delivery date exists and is older than the replacement packages deployment
  const replacementOrdersDeployment = parse("2022-12-07", 'yyyy-MM-dd', new Date());
  const date = parse(delivery.get("requestedDeliveryDate"), 'yyyy-MM-dd', new Date());

  return compareAsc(replacementOrdersDeployment, date) === 1;
}

export function isNext(delivery = Map()) {
  return !isProcessed(delivery) && delivery.get("status") !== "cancelled";
}

export function isSubscriptionActive(subscription = Map()) {
  return subscription.get("status") === "active";
}

export function isSubscriptionPaused(subscription = Map()) {
  return subscription.get("status") === "paused";
}

export function isSubscriptionPauseDeferred(subscription = Map()) {
  return subscription.get("status") === "pause_deferred";
}

export function isSubscriptionCancellationDeferred(subscription = Map()) {
  return subscription.get("status") === "cancellation_deferred";
}

export function indexSurchargeProducts(surcharges = List()) {
  return surcharges.reduce((acc, item) => {
    return acc.set(item.get("productSku"), indexSurchargeCurrencies(item.get("surcharge"), List()));
  }, Map());
}

function indexSurchargeCurrencies(surcharge) {
  return surcharge.reduce((acc, item) => {
    return acc.set(item.get("currency"), item.get("value"));
  }, Map());
}

function getProductSurcharge(indexedSurcharges, sku, currency) {
  return indexedSurcharges.getIn([sku, currency], 0);
}

// Returns map of {products: int, surcharges: int, total: int, currency: currency}
export function getSubscriptionTotals(products, indexedSurcharges = Map()) {
  let _surcharge = 0;
  let currency = products.getIn([0, "currency"], "");
  let totals = products.reduce((acc, product) => {
    _surcharge = product.get("quantity") * getProductSurcharge(indexedSurcharges, product.get("productSku"), product.get("currency"));
    return acc.set("surcharges", acc.get("surcharges") + _surcharge)
              .set("products", acc.get("products") + parseInt(product.get("total"), 10))
  }, Map({products: 0, surcharges: 0}));

  return Map({
    products: totals.get("products"),
    surcharges: totals.get("surcharges"),
    total: totals.get("products") + totals.get("surcharges"),
    currency: currency
  });
}