import React, { useState, useEffect } from "react";
import styles from "./SignUpLayout.module.scss";
import Testimonials from "../shared/components/Testimonials";
import {shuffle} from "../lib/ShuffleArray";
import LoadBackgroundImage from "../shared/components/LoadBackgroundImage";
import { connect } from "react-redux";
import { prismicGetByType } from "../lib/PrismicHelper";
import { useTranslation } from 'react-i18next';


const TESTIMONIALS_DELAY_DEFAULT_S = 8;

const BACKGROUNDS = {
  ACCOUNT_CREATION: "/assets/auth/create-account-bg.jpg",
  WAITING_LIST: "/assets/auth/country-list-bg.jpg",
  WAITING_LIST_SINGAPORE: "/assets/auth/country-singapore-bg.jpg",
};

const SignUpLayout = (props) => {
  const { i18n } = useTranslation();

  const baseClasses = [styles.module, "u-flex-center"];
  const classes = [styles.parentContainer];
  if (props.className) {
    classes.push(props.className);
  }

  const [showTestimonials, setShowTestimonials] = useState(false);
  const [testimonials, setTestimonials] = useState({
    data: [],
    delay: TESTIMONIALS_DELAY_DEFAULT_S,
  });
  const [parentContainerClasses, setParentContainerClasses] = useState(classes);

  useEffect(() => {
    setShowTestimonials(!props.noTestimonials && props.windowWidth >= 1440);
  }, [props.noTestimonials, props.windowWidth]);

  useEffect(() => {
    if (showTestimonials) {
      const context = {
        locale: i18n.language,
      }
      fetchTestimonials(context, setTestimonials);
      setParentContainerClasses([...classes, styles.withTestimonials]);
    }
    else {
      setParentContainerClasses(classes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTestimonials, i18n.language]);

  return (
    <div className={baseClasses.join(" ")}>
      <LoadBackgroundImage
        className={styles.bgImage}
        loadedClassName={styles.bgImageLoaded}
        url={props.background}
        />
      <div className={parentContainerClasses.join(" ")}>
        <div className={styles.container}>
          <div className="content u-auto-margins u-centered">
            {/* Hallstein logo as header */}
            <img
              className={styles.logo}
              src="/assets/LogoSimple-White.svg"
              alt="Hallstein - Artesian Water"
              width={210}
              height={87}
            />
            {props.children}
          </div>
        </div>
        {showTestimonials &&
          <div className={styles.testimonials}>
            <div className={styles.testimonialsInner}>
              <Testimonials
                data={testimonials.data}
                delayInSeconds={testimonials.delay}
                width={548} // TODO inner testimonial width
                theme={Testimonials.THEMES.SLIDING_WHITE}
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

SignUpLayout.defaultProps = {
  className: "",
  background: BACKGROUNDS.ACCOUNT_CREATION,
}

SignUpLayout.BACKGROUNDS = BACKGROUNDS;

const fetchTestimonials = async (context, setTestimonials) => {
  const query = await prismicGetByType(context, "testimonials", {
    // Doesn't work??
    // predicates: [
      // prismic.predicate.at('my.testimonials.testimonials.highlighted', true),
    // ],
  });
  const data = query.results[0].data;
  let testimonialsData = data.testimonials.filter((t) => !!t.highlighted);
  if (data.randomize) {
    testimonialsData = shuffle(testimonialsData);
  }

  setTestimonials({
    data: testimonialsData,
    delay: data.delay_in_seconds || 8
  });
}

function mapStateToProps(state, ownProps) {
  return {
    windowWidth: state.getIn(["system", "width"]),
  }
}

export default connect(mapStateToProps)(SignUpLayout);