import React, {useEffect} from "react";
import {retainSearchParams, applyParamsToPath} from "./lib/PermanentUrlParams";
import {getPermanentQueryParams} from "./config";
import useQueryMap from "./hooks/useQueryMap";
import {useHistory} from "react-router-dom";
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "./lib/Locales";

// The landing page has to keep query params (depending on config), and has to
// redirect old urls to their localised equivalent
// "/forgot-password" => "/en/forgot-password"
// "/de" => "/de/signin"
// "/de/" => "/de/signin"
// "/signin" => "/en/signin"
// "/" => "/en/signin"
export default function LandingPage({auth, base}) {
  const query = useQueryMap();
  const history = useHistory();

  useEffect(() => {
    const keepParams = retainSearchParams(query, getPermanentQueryParams());
    const maybeLocale = getLocaleFromBase(base);

    if (auth.isAuthenticated()) {
      const locale = maybeLocale || DEFAULT_LOCALE;
      const path = buildPath("/client-section", locale);

      history.replace(applyParamsToPath(path, keepParams));
    } else {
      // Base could be "/forgot-password" instead of "/en", in which case we
      // default to English and use that base as the desired path.
      const maybeLocale = getLocaleFromBase(base);
      const path = maybeLocale
        ? buildPath("/signin", maybeLocale)
        : buildPath(base, DEFAULT_LOCALE);

      history.replace(applyParamsToPath(path, keepParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="LandingPage" />
  );
}

function buildPath(path, locale) {
  // Path comes up as undefined for "hw.com" and "hw.com/"
  const validPath = path || "";
  return `/${locale}${validPath}`;
}

function getLocaleFromBase(base) {
  const localeMatch = base && base.match(/\/([^/]{2})/);
  const locale = localeMatch && localeMatch[1];

  return SUPPORTED_LOCALES.includes(locale) && locale;
}