var Postal = require('postal');

module.exports = {

  publish: function(channel, topic, data) {
    Postal.publish({
      channel: channel,
      topic: topic,
      data: data || {}
    })    
  },

  subscribe: function(channel, topic, cb) {
    return Postal.subscribe({
      channel: channel,
      topic: topic,
      callback: function(data, envelope) {
        cb(data, envelope);
      }
    })
  },

  unsubscribe: function(subscription) {
    subscription.unsubscribe();
  }

}
