import React, {useEffect} from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import Accounts from "../views/Accounts";
import {requestRenameAccount} from "../../actions/AccountActions";
import styles from "./SelectedAccount.module.scss";
import { connect } from "react-redux";
import LoadingLine from "../../shared/components/forms/LoadingLine";


const AccountsListSettings = (props) => {
  let {data, accountId} = props;

  // Subscribe to account changes on accountId update
  useEffect(() => {
    const unsubscribe = props.subscribeToAccountChanges(props, {});
    return function() {
      unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  let accounts = fromJS(data.customerAccounts);
  
  return (
    <div className={styles.listSettings}>
      <div className={styles.accounts}>
        <Accounts
          context={Accounts.CONTEXTS.SETTINGS}
          accounts={accounts}
          onRenameAccount={props.requestRenameAccount}
          onDeleteAccount={props.requestDeleteAccount} />
      </div>
    </div>
  );
}

// cartInitialized is used to know if an account is setup (and selectable)
const GET_ACCOUNTS = gql`
  query customerAccounts {
    customerAccounts {
      accountId
      name
      jurisdictionId
      cartInitialized
      notifications
      subsActive
      subsPaused
      subsCancelled
    }
  }
`;

const ON_UPDATED_ACCOUNTS = SubscriptionQueryWrapper("", `
  customerUpdatedAccounts {
    customerId
  }
`);

const mapDispatchToProps = dispatch => ({
  subscribeToAccountChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ACCOUNTS,
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestRenameAccount: (id) => dispatch(requestRenameAccount(id)),
  // requestDeleteAccount: (id) => dispatch(requestDeleteAccount(id)),
})

const withQuery = compose(
  graphql(GET_ACCOUNTS)
);

export default connect(null, mapDispatchToProps)(withQuery(AccountsListSettings));
