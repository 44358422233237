import React, {useEffect, useState} from "react"
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {checkoutStarted, checkoutFailed} from "../../actions/AnonymousCheckoutActions";
import {recordStartedCheckout} from "../../lib/Analytics";
import {generateCheckoutSummary} from "../../lib/StatsSummary";
import SmallMessage from "../../shop/shared/SmallMessage";
import {fromJS} from "immutable";
import { useTranslation } from "react-i18next";
import useCmsProductTitles from "../../hooks/useCmsProductTitles";
import i18n from '../../i18n';

const CheckoutCartFormContainer = ({data, cartId, accountId, checkoutCart, checkoutStarted, checkoutFailed}) => {
  const { t } = useTranslation("checkout");
  const [status, setStatus] = useState("idle");
  const { data: productTitlesData, loading: productTitlesLoading } = useCmsProductTitles(i18n.language)

  useEffect(() => {
    if (!data.loading && !productTitlesLoading && !data.error && data.customerCart) {
      function recordStartedCheckoutStats() {
        let myCart = fromJS(data.customerCart);
        // We need to update the productTitles with the longTitle from the productTitlesData.
        let updatedItems = myCart.get("items").map((product) => {
          return product.set("productTitle", productTitlesData.getIn([product.get("productSku"), "longTitle"], product.get("productTitle")));
        });

        let summary = generateCheckoutSummary(myCart.set("items", updatedItems), "instashop");
        recordStartedCheckout(summary.toJS());
      }

      function startCheckout(id) {
        checkoutCart(id)
        .then((response) => {
          if (response.data.customerCheckoutCart.errors.length <= 0) {
            recordStartedCheckoutStats();
            checkoutStarted(cartId, accountId, response.data.customerCheckoutCart.orderId);
          } else {
            checkoutFailed();
          }
        })
        .catch((err) => {
          checkoutFailed();
        })
      }

      if (status === "idle") {
        setStatus("processing");
        startCheckout(accountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, cartId, accountId, status, productTitlesData, productTitlesLoading]);

  return (
    <SmallMessage title={t("preparing.title")}
      icon={<LoadingCircle />}
      message={t("preparing.checking_out")} />
  )
}

const CART_INFO = gql`
  query customerCart($accountId: ID!) {
    customerCart(accountId: $accountId) {
      accountId
      shopId
      nrItems
      currency
      subtotal
      items {
        productSku
        productTitle
        quantity
        subscription
        subtotal
        unitPrice
      }
    }
  }
`;

const CHECKOUT_CART = gql`
  mutation customerCheckoutCart($accountId: ID!) {
    customerCheckoutCart(accountId: $accountId) {
      orderId
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CART_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId
      }
    })     
  }),
  graphql(CHECKOUT_CART, {
    props: ({ mutate }) => ({
      checkoutCart: (accountId) => mutate({
        variables: {
          accountId: accountId
        }
      })
    })
  })
);

export default connect(null, {checkoutStarted, checkoutFailed})(withQueries(CheckoutCartFormContainer));