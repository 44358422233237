import {INVOICES_DOWNLOAD_URL} from "../config";

const InvoiceStorage = {

  getUrl(accountId, invoiceNr) {
    var url = INVOICES_DOWNLOAD_URL.replace(":account_id", accountId);
    url = url.replace(":invoice_nr", invoiceNr);
    return url;
  },

  download(url) {
    let token = localStorage.getItem("access_token");
    let url_with_token = `${url}?token=${token}`;
    window.open(url_with_token, "_self");
  }

}

export default InvoiceStorage;