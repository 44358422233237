import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import ProgressForm from "../forms/CancelSubscriptionProgressForm";
import ConfirmForm from "../forms/ConfirmForm";
import {cancelCancelSubscription} from "../../actions/AccountActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import { withTranslation } from 'react-i18next';
import "./CancelSubscriptionFormContainer.css";

class CancelSubscriptionFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      errors: [],
      requestId: "",
      view: "confirm"
    }
  }

  handleSubmit() {
    const values = {accountId: this.props.accountId, subscriptionId: this.props.subscriptionId}
    return this.props.customerCancelSubscription(values)
      .then((response) => {
        if (response.data.customerCancelSubscription.errors.length <= 0) {
          this.setState({view: "monitor", requestId: response.data.customerCancelSubscription.requestId});
        } else {
          let errors = createValidationErrors(response.data.customerCancelSubscription.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelCancelSubscription();
  }

  handleBack() {
    this.setState({view: "confirm"});
  }

  handleCompleted() {
    this.props.cancelCancelSubscription(); 
  }

  render() {
    if (this.state.view === "monitor") {
      return this.renderMonitorView();
    }
    return this.renderConfirmView();
  }

  renderConfirmView() {
    return (
      <ConfirmForm
        errors={this.state.errors}
        message={this.props.t("deliveries.cancel.info")}
        label={this.props.t("deliveries.cancel.confirm")}
        cancelLabel={this.props.t("deliveries.cancel.cancel")}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}        
        />
    )
  }

  renderMonitorView() {
    return (
      <ProgressForm
        subscriptionId={this.props.subscriptionId}
        requestId={this.state.requestId}
        onBack={this.handleBack.bind(this)}
        onCompleted={this.handleCompleted.bind(this)}
        />
    )
  }
}

const CANCEL_SUBSCRIPTION = gql`
  mutation customerCancelSubscription($data: CustomerCancelSubscriptionInput!) {
    customerCancelSubscription(input: $data) {
      requestId
      errors { key message }
    }
  }
`;


const withQueries = compose(
  graphql(CANCEL_SUBSCRIPTION, {
    props: ({ mutate }) => ({
      customerCancelSubscription: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default withTranslation("accounts")(connect(null, {cancelCancelSubscription})(withQueries(CancelSubscriptionFormContainer)));

