import React from "react";
import ProfilePanel from "./views/ProfilePanel";
import LanguagePanel from "./views/LanguagePanel";
import Panel from "./shared/Panel";
import Divider from "./shared/Divider";
import styles from "./SettingsPage.module.scss";
import AccountsListSettings from "./accounts/AccountsListSettings";
import NotificationsPanelSettings from "./views/NotificationsPanelSettings";
import { useTranslation } from 'react-i18next';
import ShopLayout from "./ShopLayout";

const SettingsPage = (props) => {
  // const deleteAccountButton = (
  //   <React.Fragment>
  //     <Button label="Close Account" active={false} theme={Button.THEMES.GREY_OUTLINE} />
  //   </React.Fragment>
  // );
  const { t } = useTranslation("settings");

  const baseClass = styles.module;
  
  return (
    <ShopLayout className={baseClass}
      boundary={props.boundary} accountId={props.accountId}>

      <div className={styles.panels}>
        <Divider spaceAround={Divider.SPACES.SECTIONS} firstOrLast={true} />

        <Panel title={t("profile.title")} fullWidthSubpanels={true}
          subpanels={<ProfilePanel accountId={props.accountId} />}
        />

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <Panel title={t("language.title")} fullWidthSubpanels={true}
          subpanels={<LanguagePanel accountId={props.accountId} />}
        />

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <Panel title={t("accounts.title")} fullWidthSubpanels={true}
          subpanels={<AccountsListSettings />} />

        <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <Panel title={t("notifications.title")} fullWidthSubpanels={true}
          subpanels={
            <NotificationsPanelSettings accountId={props.accountId} />
          }
        />

        {/* <Divider spaceAround={Divider.SPACES.SECTIONS} />

        <Panel title="Close Hallstein account" fullWidthSubpanels={true}
          subpanels={<Subpanel button={deleteAccountButton}
            content="This will close your global Hallstein account for good and remove your details from our databases. Please note that if you want to close your account, you have to cancel any subscription you may have active." />} noMargin={true} /> */}

        <Divider spaceAround={Divider.SPACES.SECTIONS} firstOrLast={true} />
      </div>
    </ShopLayout>
  );
}

export default SettingsPage;
