import { takeEvery, delay } from 'redux-saga'
import { call, put, fork } from 'redux-saga/effects'
import { SHOW_NOTIFICATION, hideNotification, SHOW_NOTIFICATION_WITH_PHOTO } from "../actions/NotificationActions";

function* notify(baseAction) {
  if (baseAction.autoClose) {
    yield call(delay, 4000);
    // Undo needs to be handled by individual sagas.
    yield put(hideNotification(baseAction.id));  
  }
}

export function* watchNotifications() {
  yield* takeEvery([SHOW_NOTIFICATION, SHOW_NOTIFICATION_WITH_PHOTO], notify);
}

export default function* saga() {
  yield fork(watchNotifications);
}