import React from "react";
import {currencyFormat} from "../../lib/TextFormatter";

const Price = (props) => {

  return (
    <div className="info">
      <span className="price">{currencyFormat(props.price, props.currency)}</span>
      <span className="suffix">{props.suffix}</span>
    </div>
  )

}

Price.defaultProps = {
  currency: "USD",
  price: "",
  suffix: ""
}

export default Price;