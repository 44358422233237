import React from "react"
import {connect} from "react-redux";
import Button from "../../shared/components/Button";
import SmallMessage from "../../shop/shared/SmallMessage";
import CloseXCircle from "../../shop/shared/icons/CloseXCircle";
import {getCountryName} from "../../lib/CountriesAndStates";
import Navigation from "../../lib/Navigation";
import {closeErrorWindow} from "../../actions/AnonymousCheckoutActions";
import {showSupport} from "../../actions/SupportActions";
import { useTranslation } from "react-i18next";
import * as styles from "./ErrorWindow.module.scss";


const ErrorWindow = ({kind, countryCode, cartId, accountId, closeErrorWindow, showSupport}) => {
  const { t } = useTranslation("checkout", { keyPrefix: "anonymous_checkout.errors" });

  function handleGoToAccounts() {
    Navigation.gotoSelectAccount();
    showSupport("creating-multiple-accounts");
    closeErrorWindow();
  }

  function handleGoToAccountShop() {
    Navigation.gotoShop(accountId);
    closeErrorWindow();
  }

  function handleGoToCart() {
    Navigation.gotoCart(accountId);
    closeErrorWindow();
  }

  function handleTryAgain() {
    // Do nothing, the saga will handle it.
    closeErrorWindow();
  }

  function handleGoBackHistory() {
    closeErrorWindow();
    Navigation.goBack();
  }

  let title = t("failed");
  let message, button;
  switch(kind) {
    case "anonymous-cart-not-found":
      title = t("not_available.title");
      message = t("not_available.text");
      button = <Button key="go-back-button" label={t("not_available.button")} onClick={handleGoBackHistory} />
      break;

    case "registration-sign-in-failed":
      message = t("signin_failed.text");
      button = <Button key="try-again-button" label={t("signin_failed.button")} onClick={handleTryAgain} />
      break;

    case "select-account-failed":
      message = t("select_account_failed.text");
      button = <Button key="go-back-button" label={t("select_account_failed.button")} onClick={handleGoBackHistory} />
      break;    

    case "account-creation-failed":
      message = t("account_creation_failed.text", { country: getCountryName(countryCode) });
      button = <Button key="go-to-account" label={t("account_creation_failed.button")} onClick={handleGoToAccounts} />
      break;

    case "account-creation-monitor-timeout":
      message = t("account_creation_timeout.text", { country: getCountryName(countryCode) });
      button = <Button key="goto-account-shop" label={t("account_creation_timeout.button")} onClick={handleGoToAccountShop} />
      break;

    case "populate-cart-failed":
      message = t("populate_cart_failed.text");
      button = <Button key="go-to-cart" label={t("populate_cart_failed.button")} onClick={handleGoToCart} />
      break;

    case "checkout-failed":
      message = t("checkout_failed.text");
      button = <Button key="go-to-cart" label={t("checkout_failed.button")} onClick={handleGoToCart} />
      break;

    default:
      message = t("generic.text");
      button = <Button key="back-to-cart" label={t("generic.button")} onClick={handleGoBackHistory} />
  }
  
  return (
    <div className={styles.container}>
      <SmallMessage title={title}
        icon={<CloseXCircle side="60px" colorDisabled="var(--primary-blue-800)" />}
        message={message}>
        {button}
      </SmallMessage>
    </div>
  )
}

export default connect(null, {closeErrorWindow, showSupport})(ErrorWindow);