import React, {useState, useEffect} from "react";
import * as prismic from '@prismicio/client';
import Button from "../../shared/components/Button";
import {buildSlice} from "../SliceFactory";
import {fromJS, Map} from "immutable";
import ArrowButton from "../../shop/shared/icons/Arrow";
import { useTranslation } from 'react-i18next';
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { prismicGetByType } from "../../lib/PrismicHelper";
import "./SupportDoc.css";


const SupportDoc = (props) => {
  const { t, i18n } = useTranslation("common", { keyPrefix: "support" });

  const [{loaded, error, page}, setPage] = useState({loaded: false, error: false, page: Map()});
  
  useEffect(() => {
    if (props.page !== "") {
      const context = {
        locale: i18n.language,
      }
      
      fetchPage(context, setPage, props.page);
    }
  }, [props.page, i18n.language]);

  if (!loaded) {
    return (
      <div className="SupportDoc">
        <LoadingLine />
      </div>
    )
  }

  const goBack = props.handleBack && (
    <ArrowButton color="var(--grey-700)"
      className="backArrow"
      arrowStyle={ArrowButton.STYLES.DROP}
      direction={ArrowButton.DIRECTIONS.LEFT}
      // visible={this.props.page !== "toc"}
      onClick={props.handleBack} />
  );

  if (error) {
    return (
      <div className="SupportDocError">
        <div className="inner">
          <div className="title">{t("not_found")} { goBack }</div>
          <p>{t("topic_not_found")}</p>
          <Button label={t("common:go_back")} primary={true} onClick={props.onBack} />
        </div>
      </div>
    )
  }

  // render slices
  let slices = page.get("slices").map((slice, index) => {
    return buildSlice(slice.get("slice_type"), slice, index, props.onChangePage);
  });

  return (
    <div className="SupportDoc">
      <div className="title">{page.get("title")} { goBack }</div>
      <div className="separator"/>
      <div className="body">
        {slices}
      </div>
    </div>
  );
}

const fetchPage = async (context, setPage, page) => {
  const query = await prismicGetByType(context, 'shop_support_page', {
    predicates: [
      prismic.predicate.at("my.shop_support_page.uid", page),
    ],
  });
  const data = query.results[0].data;
  setPage({
    loaded: true,
    error: false,
    page: fromJS(data)
  });
}


export default SupportDoc;