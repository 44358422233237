import { fromJS } from 'immutable';
import React from 'react';
import { useTranslation } from "react-i18next";
import Translate from "../../shop/shared/icons/Translate";
import Select from './forms/Select';
import { localeNameFromCode, SUPPORTED_LOCALES } from '../../lib/Locales';
import styles from "./LocaleSwitcher.module.scss";


function LocaleSwitcher(props) {
  const { t, i18n } = useTranslation("common");
  const {
    className, withIcon,
    label=t("language_selector_label"),
    onSwitch,
    darkBackground,
  } = props;

  const localeData = fromJS(SUPPORTED_LOCALES.map(
    (loc) => ({ code: loc, name: withIcon ? loc : localeNameFromCode(loc) })
  ));

  const localesSelect = (
    <Select
      size={withIcon ? Select.SIZES.SMALL : Select.SIZES.SMALLER}
      name="locales"
      noControl={withIcon}
      value={i18n.language}
      valuePrefix={withIcon ? <Translate /> : null}
      data={localeData}
      valueKey="code"
      titleKey="name"
      onChange={onSwitch}
      centerOptions={withIcon}
    />
  );

  const classes = [
    styles.module,
    className,
    withIcon ? styles.withIcon : styles.textual,
    darkBackground ? styles.darkBackground : null,
  ];

  const labelElem = label && (
    <span className={styles.label}>{label}: </span>
  );

  const localeSelector = (
    <div className={classes.join(" ")}>
      { labelElem }
      { localesSelect }
    </div>
  );

  return localeSelector;
}

export default LocaleSwitcher;