/* eslint react/jsx-no-target-blank: 0 */
import React from "react";
import { cx } from "./lib/ClassSet";
import Navigation from "../lib/Navigation";
import Button from "./components/Button";
import { useTranslation } from 'react-i18next';
import styles from "./FooterActions.module.scss";
import LocaleSwitcher from "./components/LocaleSwitcher";
import { useLocation } from "react-router-dom";
import { switchLocale } from "../lib/Locales";


const FooterActions = (props) => {
  const { t, i18n } = useTranslation("common", { keyPrefix: "footer" });
  const { pathname, search, state } = useLocation();

  let supportFn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.showSupport();
  }

  const linkClasses = cx({
    "sg-white": props.darkBackground,
  });

  const handleSwitch = (desiredLocale) => {
    // Update URL and change the locale
    switchLocale(pathname + search, state, i18n, desiredLocale);
  }

  return (
    <div className={styles.module + " actions"}>
      <LocaleSwitcher
        className={styles.localeSwitcher}
        onSwitch={handleSwitch}
        darkBackground={props.darkBackground}
      />
      <a href="/support" className={linkClasses} style={{display: "block"}}
        onClick={supportFn}>{t("need_help")}</a>
      <Button className={linkClasses} link onClick={Navigation.gotoTermsAndConditions}>
        {t("terms_and_conditions")}
      </Button>
    </div>
  )
}

FooterActions.defaultProps = {
  darkBackground: false,
}

export default FooterActions;