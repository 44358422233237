import React from "react";
import styles from "./Modal.module.scss";

const MODES = {
  DEFAULT: styles.default,
  FULLPAGE: styles.fullpage,
}

const Modal = (props) => {
  const {
    mode = MODES.DEFAULT,
    buttons,
    children,
  } = props;

  const baseClasses = [styles.module, mode];

  return (
    <div className={baseClasses.join(" ")}>
      <div className={styles.top} />
      <div className={styles.inner}>
        { children }
      </div>
      <div className={styles.buttons}>
        { buttons }
      </div>
      <div className={styles.bottom} />
    </div>
  );
}

Modal.MODES = MODES;

export default Modal;