import React from "react";
import Icon from "./Icon";

// Icons/Core/forbid-line
function Forbidden(props) {
  const svgPathDParameter = "M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M8.523,7.109 L16.891,15.477 C16.5015061,16.0236249 16.0236249,16.5015061 15.477,16.891 L7.109,8.523 C7.49857991,7.97673198 7.9764565,7.49919335 8.523,7.11 L8.523,7.109 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default Forbidden;
