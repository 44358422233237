import React, {useState} from "react";
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import FooterActions from "../../shared/FooterActions";
import Form from "../forms/CountrySelectForm";
import Button from "../../shared/components/Button";
import {validatePresence} from "../../lib/Validators";
import {Map} from "immutable";
import { useTranslation, Trans } from 'react-i18next';

function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "countryCode");

  return errors.toJS();
}

const CountrySelect = (props) => {
  const { t } = useTranslation("auth", { keyPrefix: "signup.country_select" });
  const [errors] = useState([]);

  const handleSubmit = (values) => {
    props.onSelected(values);
  }
  const handleCancel = () => {
    props.onBack();
  }

  const countries = props.countriesInShops || [];

  return (
    <div className="CountrySelect view">
      <div className="inner">
        <div className="u-shortest u-auto-margins">
          <p className="sg-large u-no-margin u-centered sg-mb-tiny">{t("title")}</p>
          <p className="sg-small u-centered">{t("select_country")}</p>
        </div>
        <Form 
          errors={errors}
          validate={validateForm}
          showWaitingList={true}
          countries={countries}
          onWaitingList={props.onWaitingList}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          darkBackground={true}
          assumedCountryCode={props.assumedCountryCode}
        />
        <div className="sg-text-normal u-no-margin">
          <Trans i18nKey="auth:already_have_account"
            components={{
              a: <Button
                className="sg-font-bold sg-text-normal"
                theme={Button.THEMES.WHITE}
                onClick={props.onSignIn} link/>
            }}
          />
        </div>
        <p className="sg-text-small sg-mb-tiny u-shorter u-auto-margins">{t("country_not_in_list")}</p>
        <Button label={t("join_waiting_list")} onClick={props.onWaitingList}
          active={countries.length}
          theme={Button.THEMES.WHITE}
          className="sg-font-bold sg-text-normal sg-mb-tiny" link/>
      </div>

      <div className={"u-centered sg-font-bold"}>
        <FooterActions
          showSupport={props.showSupport} 
          darkBackground={true}
        />
      </div>
    </div>
  );
}

export default connect(null, {showSupport})(CountrySelect);