import React, { useEffect, useState } from "react";
import { getStripePublishableKey } from "../../../config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import { stripeElementsOptions } from "../../../lib/StripeHelper";
import { useTranslation } from "react-i18next";
import LoadingLine from "../../../shared/components/forms/LoadingLine";


// Handles the stripe loading, must be an ancestor of any stripe element!
export function StripeElements({shopId, clientSecret, noClientSecret = false, children, useLoadingLine = false}) {
  const { i18n } = useTranslation();

  // We need the shop id to get the stripe promise
  // Stripe promise needs to be set before returning any Stripe Elements
  const [stripePromise, setStripePromise] = useState();
  const [immutableClientSecret, setClientSecret] = useState();

  useEffect(() => {
    if (shopId) {
      const apiKey = getStripePublishableKey(shopId);
      setStripePromise(loadStripe(apiKey));
    }
  }, [shopId]);

  // Stripe Elements doesn't allow secret to change and it triggers a warning
  // when rerendering even if it seems to be the same.
  useEffect(() => {
    if (immutableClientSecret && clientSecret !== immutableClientSecret) console.warn("Client secret shouldn't change", clientSecret, immutableClientSecret)

    // We shouldn't set it again if immutableClientSecret is already set, but
    // we create different setupIntent every time and get new secrets.
    if (!immutableClientSecret && clientSecret) {
      setClientSecret(clientSecret);
    }
  }, [clientSecret]);

  const isStripeReady = stripePromise && (clientSecret || noClientSecret);
  if (!isStripeReady) {
    return useLoadingLine ? <LoadingLine /> : null;
  }
  
  return (
    <Elements stripe={stripePromise} options={stripeElementsOptions(noClientSecret ? null : immutableClientSecret, i18n.language)}>
      {children}
    </Elements>
  );
}