import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/AddAccountForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelAddAccount} from "../../actions/AccountActions";
import {fromJS, Map} from "immutable";
import {validatePresence} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import SmallMessage from "../shared/SmallMessage";
import { SubscriptionQueryWrapper } from "../../lib/SubscriptionQueryWrapper";
import Navigation from "../../lib/Navigation";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import Cookies from "js-cookie";
import { withTranslation } from "react-i18next";


function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "countryCode");
  return errors.toJS();
}

class AddAccountFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreatingAccount: false,
      newAccountId: "",
      errors: [],
    }
  }

  componentDidMount() {
    // No need to track props.accountId changes nor unsubscribe as
    // this form will stay until we redirect the user
    this.props.subscribeToAccountChanges(this.props, {});
  }

  handleSubmit(values) {
    return this.props.customerAddAccount(values.toJS())
      .then((response) => {
        if (response.data.customerAddAccount.errors.length <= 0) {
          this.setState({
            isCreatingAccount: true,
            newAccountId: response.data.customerAddAccount.accountId
          });
        } else {
          let errors = createValidationErrors(response.data.customerAddAccount.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelAddAccount();
  }

  render() {
    let {data} = this.props;

    if (!data.countriesInShops) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />

    if (this.state.isCreatingAccount) {
      const newAccount = data.customerAccounts.find((a) => a.accountId === this.state.newAccountId);
      if (newAccount && newAccount.cartInitialized) {
        // New account ready => redirect and close the modal
        // Bug: even though the account should be initialized, the redirect
        // sometimes brings up an error because the SelectedAccount component
        // hasn't received the update yet. "Fix" with "small" timer for now
        window.setTimeout(() => {
          Navigation.gotoAccount(this.state.newAccountId);
          this.props.cancelAddAccount();
        }, 1000);
      }

      return (
        <SmallMessage
          icon={<LoadingCircle />}
          message={this.props.t("add_account.redirection_message")} />
      );
    }
    else {
      const countries = fromJS(filterAndSortCountries(data.countriesInShops));
      let initialValues = fromJS({});
      if (countries.size === 1) {
        initialValues = initialValues.set("countryCode", countries.first().get("code"));
      } else if (countries.size > 1 && !initialValues.get("countryCode")) {
        const assumedCountryCode = Cookies.get("assumed_country") || "";
        initialValues = initialValues.set("countryCode", assumedCountryCode.toUpperCase());
      }
  
      return (
        <Form
          initialValues={initialValues}
          errors={this.state.errors}
          frequency={fromJS(data.subscriptionFrequencyOptions)}
          countries={countries}
          validate={validateForm}
          onSubmit={this.handleSubmit.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          />
      );
    }
  }
}


const COUNTRIES_IN_SHOPS = gql`
  query countriesInShops {
    countriesInShops {
      code
      name
    }
    customerAccounts {
      accountId
      cartInitialized
    }
  }
`;

const ADD_ACCOUNT = gql`
  mutation customerAddAccount($data: CustomerAddAccountInput!) {
    customerAddAccount(input: $data) {
      accountId
      errors { key message }
    }
  }
`;

const ON_UPDATED_ACCOUNTS = SubscriptionQueryWrapper("", `
  customerUpdatedAccounts {
    customerId
  }
`);

const withQueries = compose(
  graphql(COUNTRIES_IN_SHOPS, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(ADD_ACCOUNT, {
    props: ({ mutate }) => ({
      customerAddAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

const mapDispatchToProps = dispatch => ({
  subscribeToAccountChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ACCOUNTS,
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  cancelAddAccount: () => dispatch(cancelAddAccount()),
})

export default withTranslation("auth")(connect(null, mapDispatchToProps)(withQueries(AddAccountFormContainer)));

