import React from "react";
import {connect} from "react-redux";
import { cx } from "../../../shared/lib/ClassSet";
import {fromJS} from "immutable";
import Button from "../../../shared/components/Button";
import { useTranslation } from "react-i18next";
import PaymentError from "../../accounts/PaymentError";
import {invoiceDate} from "../../../lib/DateFormatter";
import {formatInvoiceNr} from "../../../lib/TextFormatter";
import {downloadInvoice} from "../../../actions/AccountActions";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import "./ChargeItem.css";
import { DeliveryTag } from "../Deliveries/DeliveryTag";
import LoadingLine from "../../../shared/components/forms/LoadingLine";

const ChargeItem = (props) => {
  const { t: statusT } = useTranslation("accounts", { keyPrefix: "payment_history.status" });
  const { t } = useTranslation("products");

  let {data} = props;
  
  let classes = cx({
    "item": true,
    "billing-charge-list-item": true,
    "u-success": data.get("status") === "paid",
    "u-error": data.get("status") === "failed",
    "u-expanded": props.selected
  })

  let contentClasses = cx({
    "content": true,
    "u-expanded": props.selected
  })


  let date = invoiceDate(data.get("invoiceDate"));
  let result = formatStatus(data.get("status"), statusT);
  let clickFn = (e) => props.onClick(data.get("salesOrderId"));
  let downloadInvoiceFn = (e) => props.downloadInvoice(data.get("accountId"), data.get("invoiceNr"));
  let errorRow = null;
  if (data.get("status") === "failed") {
    errorRow = (
      <tr className="item-error billing-charge-list-item">
        <td colSpan={5}>
          <PaymentError 
            accountId={data.get("accountId")}
            salesOrderId={data.get("salesOrderId")}
            willRetry={data.get("willRetry")}
            declineCode={data.get("lastDeclineCode")}
            errorCode={data.get("lastErrorCode")} 
            errorMessage={data.get("lastErrorMessage")} 
            onClick={clickFn} />
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <tr className={classes} onClick={clickFn}>
        <td className="field f-result"><div className="expander"><div className="icon" /></div> {result}</td>
        <td className="field f-charge"><span className="money">{data.get("amount")}</span></td>
        <td className="field f-currency">{data.get("currency")}</td>
        <td className="field f-description">{formatInvoiceNr(data.get("invoiceNr", ""))}</td>
        <td className="field f-date">{date}</td>
      </tr>
      {errorRow}
      <tr className={contentClasses}>
        <td colSpan={5}>
          <div className="innerContent">
            <Products accountId={data.get("accountId")} salesOrderId={data.get("salesOrderId")} active={props.selected} />
            <Button primary={true} label={t("download_invoice")} visible={data.get("invoiceAvailable")} onClick={downloadInvoiceFn} />
            {/* <div className="orderId">#{data.get("salesOrderId")}</div> */}
          </div>
        </td>
      </tr>
    </React.Fragment>
  )
}

export function formatStatus(status, t) {
  switch(status) {
    case "paid":
    case "not_paid":
    case "failed":
    case "voided":
    case "cancelled":
      return t(status);
    default:
      return "";
  }
}


const INVOICE_QUERY = gql`
  query customerInvoice($accountId: ID!, $salesOrderId:ID!) {
    customerInvoice(accountId: $accountId, salesOrderId: $salesOrderId) {
      invoiceNr
      totalBeforeTax
      taxes
      currency
      total
      lineItems {
        productSku
        productTitle
        quantity
        unitPrice
        includedUnitSurcharge
        total
        subscription
      }
    }
  }
`

const Products = (props) => {
  const { t } = useTranslation("accounts", { keyPrefix: "payment_history.table" });
  if (!props.active) return null;
  let variables = {accountId: props.accountId, salesOrderId: props.salesOrderId}

  return (
    <Query query={INVOICE_QUERY} variables={variables} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingLine />
        }

        let invoice = fromJS(data.customerInvoice);

        let products = invoice.get("lineItems").map(x => {
          return (
            <tr key={x.get("productSku")}>
              <td className="product">{x.get("productTitle")}<DeliveryTag data={x} className="deliveryTag" /></td>
              <td className="qty">{x.get("quantity")}</td>
              <td>{x.get("unitPrice")} {invoice.get("currency")}</td>
              <td>{x.get("total")} {invoice.get("currency")}</td>
            </tr>
          )
        });

        return (
          <table className="invoiceTable">
            <thead>
              <tr>
                <th>{t("product")}</th>
                <th className="qty">{t("quantity")}</th>
                <th>{t("unit_price")}</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody className="products">
              {products}
            </tbody>
            <tbody className="taxes">
              <tr>
                <td colSpan="3">{t("subtotal")}</td>
                <td>{invoice.get("totalBeforeTax")} {invoice.get("currency")}</td>
              </tr>
              <tr>
                <td colSpan="3">{t("taxes")}</td>
                <td>{invoice.get("taxes")} {invoice.get("currency")}</td>
              </tr>
            </tbody>
            <tbody className="total">
              <tr>
                <td colSpan="3">{t("grand_total")}</td>
                <td>{invoice.get("total")} {invoice.get("currency")}</td>
              </tr>
            </tbody>
          </table>
        )

      }}
    </Query>
  )

}


export default connect(null, {downloadInvoice})(ChargeItem);