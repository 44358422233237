import * as AbsintheSocket from "@absinthe/socket";
import {createAbsintheSocketLink} from "@absinthe/socket-apollo-link";
import {ApolloLink} from "apollo-link";
import {Socket as PhoenixSocket} from "phoenix";
import {SOCKET_ENDPOINT} from "../config";
import MessageBus from "../lib/MessageBus";

function createPhoenixSocket() {
  return new PhoenixSocket(SOCKET_ENDPOINT, {
    heartbeatIntervalMs: 10000, // 61000 will cause timeout.
    params: () => {
      return {
        token: localStorage.getItem("access_token")
      }
    }
  })
}

function createAbsintheLink(socket) {
  let link = AbsintheSocket.create(socket);
  return createAbsintheSocketLink(link);  
}

class SocketLink extends ApolloLink {

  constructor() {
    super();
    this.socket = createPhoenixSocket();
    this.link = createAbsintheLink(this.socket);
    this.timerId = null;
    this.init();
  }

  init() {
    this.socket.onOpen(this.onOpen.bind(this));
    this.socket.onError(this.onError.bind(this));
    this.socket.onClose(this.onClose.bind(this));     
  }

  // Part of interface/behaviour for ApolloLink.
  request(operation: Operation, forward?: NextLink | undefined) {
    return this.link.request(operation, forward)
  }

  reconnect() {
    // console.log(this.currentTime(), "Reconnect");
    this.socket.disconnect();
    this.socket.connect();
  }

  startMonitoring() {
    // console.log(this.currentTime(), "startMonitoring");
    // this.timerId = setInterval(this.onMonitor.bind(this), 5000);
  }

  stopMonitoring() {
    // console.log(this.currentTime(), "stopMonitoring");
    // clearInterval(this.timerId);
  }

  onOpen() {
    // console.debug(this.currentTime(), "The socket was openend");
    MessageBus.publish("socket", "connected", {});
    this.startMonitoring();
  }

  onError(e) {
    // If we get an error with the connection, that means that the socket server is down.
    // console.debug(this.currentTime(), "there was an error with the connection!", e.code);
  }

  onClose(e) {
    // Code 1006 when server is down. Phoenix will automatically retry.
    // Code 1000 when timeout, "The connection successfully completed the purpose for which it was created."
    // console.debug(this.currentTime(), "the connection dropped", e.code);
    this.stopMonitoring();
    if (e.code === 1000) {
      // Server thinks we are lazy and timed us out. 
      this.reconnect();
    } else {
      MessageBus.publish("socket", "disconnected", {});
    }
  }

  onMonitor() {
    console.debug(this.currentTime(), "isConnected", this.socket.isConnected(), this.socket.connectionState());
  }

  currentTime() {
    return new Date(Date.now()).toTimeString().substr(0, 8);
  }

}

export default new SocketLink();