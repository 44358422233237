import React, {useEffect} from "react";
import {connect} from "react-redux";
import { cx } from "../../shared/lib/ClassSet";
import Button from "../../shared/components/Button";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import {
  requestChangeLocale,
} from "../../actions/LoginActions";
import styles from "./ProfilePanel.module.scss";
import Subpanel from "../shared/Subpanel";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { useTranslation, Trans } from 'react-i18next';
import { localeNameFromCode } from "../../lib/Locales";


const ProfilePanel = (props) => {
  const { t } = useTranslation("settings", { keyPrefix: "language" });
  let {data, accountId} = props;

  // Subscribe to account changes
  useEffect(() => {
    const unsubscribe = props.subscribeToProfileChanges(props, {});
    return function() {
      unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (data.loading) {
    return (
      <div className={styles.module}>
        <LoadingLine />
      </div>
    )
  }
  if (data.error) return <GraphQLErrors error={data.error} />

  let classes = cx({
    [styles.module]: true,
    [styles.uActive]: true,
  })

  const accountHolder = fromJS(data.customerAccountHolder);

  return (
    <Subpanel
      content={
        <div className={classes}>
          <div className={styles.section}>
            <p className="sg-text-small">{t("text")}</p>
            <span className="sg-text-small">
              <Trans i18nKey="settings:language.current_language"
                values={{
                  language: localeNameFromCode(accountHolder.get("locale"))
                }}
                components={{
                  b: <span className="sg-font-bold" />
                }}
              />
            </span>
          </div>
        </div>}
      button={
        <React.Fragment>
          <Button label={t("button_label")} onClick={props.requestChangeLocale}
            theme={Button.THEMES.GREY_OUTLINE} className="u-bottom-margin expand" />
        </React.Fragment>}
    />);
}

const SUBSCRIPTIONS = gql`
  query customerSubscriptions($accountId:ID!) {
    customerAccountHolder(accountId:$accountId) {
      customerId
      locale
    }
  }
`;

const ON_UPDATED_PROFILE_NOTIFICATIONS = SubscriptionQueryWrapper("", `
  customerUpdatedProfile {
    customerId
  }`
);

const mapDispatchToProps = dispatch => ({
  subscribeToProfileChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_PROFILE_NOTIFICATIONS,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestChangeLocale: () => dispatch(requestChangeLocale()),
})

const withQuery = compose(
  graphql(SUBSCRIPTIONS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId,
        deliveriesFilter: {
          limit: 7
        }
      }
    })
  })
);

export default connect(null, mapDispatchToProps)(withQuery(ProfilePanel));
