import React, {useState, useEffect} from "react";
import {fromJS, Map} from "immutable";
import {buildSlice} from "../SliceFactory";
import { useTranslation } from 'react-i18next';
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { prismicGetByType } from "../../lib/PrismicHelper";
import "./TableOfContents.css";


const TableOfContents = (props) => {
  const { t, i18n } = useTranslation("common", { keyPrefix: "support" });

  const [{loaded, error, toc}, setToc] = useState({loaded: false, error: false, toc: Map()});
  
  useEffect(() => {
    const context = {
      locale: i18n.language,
    }
    
    fetchToc(context, setToc)
  }, [i18n.language])

  if (!loaded) {
    return (
      <div className="TableOfContents">
        <LoadingLine />
      </div>
    )
  }

  if (error) {
    return (
      <div className="TableOfContents">
        <div className="intro">
          <p>{t("problem_loading")}</p>
        </div>
      </div>
    )
  }

  let items = toc.get("sections").map(x => {
    return <Item
            key={x.getIn(["page", "id"])}
            slug={x.getIn(["page", "uid"])}
            title={x.getIn(["page", "data", "title"])} 
            onClick={props.onClick} />
  })  

  // render slices
  let slices = toc.get("slices").map((slice, index) => {
    return buildSlice(slice.get("slice_type"), slice, index, props.onChangePage);
  })

  return (
    <div className="TableOfContents">
      <div className="intro">
        {slices}
      </div>
      <div className="items">
        {items}
      </div>
    </div>
  )

}

const Item = (props) => {
  let clickFn = (e) => props.onClick(props.slug);
  return (
    <div className="item" onClick={clickFn}>
      <div className="icon" />
      <div className="title">{props.title}</div>
    </div>
  )
}

const fetchToc = async (context, setToc) => {
  const query = await prismicGetByType(context, "shop_support_index", {
    'fetchLinks': ['shop_support_page.title']
  });
  const data = query.results[0].data;
  setToc({
    loaded: true,
    error: false,
    toc: fromJS(data)
  });
}

export default TableOfContents;