import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {connect} from "react-redux";
import gql from "graphql-tag";
import Form from "../forms/CalendarForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelResumeSubscription, requestConfirmResumeSubscription} from "../../actions/AccountActions";
import {fromJS, Map} from "immutable";
import {validateDate} from "../../lib/Validators";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../lib/DateFormatter";

function validateForm(values, t) {
  let errors = Map();
  errors = validateDate(errors, values, "deliveryDate", t("validation.date_required"));
  return errors.toJS();
}

function ResumeSubscriptionFormContainer(props) {
  const { data } = props;
  const { t } = useTranslation("forms");
  const [errors] = useState([]);

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  const handleCancel = () => {
    props.cancelResumeSubscription();
  }

  const handleSubmit = (values) => {
    handleCancel();

    const dateOnly = formatDate(values.get("deliveryDate"), "yyyy-MM-dd");
    props.requestConfirmResumeSubscription(props.accountId, props.subscriptionId, dateOnly);
  }

  const info = fromJS(data.customerSubscriptionRescheduleInfo);
  const unavailableDates = info.get("unavailableDates").reduce((acc, date) => {
    return acc.set(date, "");
  }, Map());

  return (
    <Form
      errors={errors}
      notBeforeDate={info.get("notBeforeDate")}
      notAfterDate={info.get("notAfterDate")}
      unavailableDates={unavailableDates}
      validate={(values) => validateForm(values, t)}
      showWarning={true}
      leadingText={t("accounts:deliveries.resume.text")}
      submitLabel={t("accounts:deliveries.resume.continue")}
      onSubmit={handleSubmit}
      cancelLabel={t("accounts:deliveries.resume.cancel")}
      onCancel={handleCancel}
      />
  )
}

const GET_RESCHEDULE_INFO = gql`
  query customerSubscriptionRescheduleInfo($accountId: ID!, $subscriptionId: ID!) {
    customerSubscriptionRescheduleInfo(accountId: $accountId, subscriptionId: $subscriptionId) {
      notBeforeDate
      notAfterDate
      unavailableDates
    }
  }
`


const withQueries = compose(
  graphql(GET_RESCHEDULE_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
);

export default connect(null, {
  cancelResumeSubscription,
  requestConfirmResumeSubscription,
})(withQueries(ResumeSubscriptionFormContainer));

