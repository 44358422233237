import React from "react";
import {reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import { useTranslation, Trans } from 'react-i18next';


function ChangePasswordForm(props) {
  const { t } = useTranslation("settings");

  const {errors, handleSubmit, submit, submitting, email} = props;
  const submitLabel = submitting ? t("common:standby") : t("profile.modify_password.popup.ok_label");

  return (
    <form className="ChangePasswordForm" onSubmit={handleSubmit}>
      <p className="fields u-centered">
        <Trans
          i18nKey="settings:profile.modify_password.popup.text"
          components={{
            b: <span className="sg-font-bold"/>
          }}
          values={{
            email: email,
          }}
        />
      </p>

      <div className="buttons">
        <Button label={submitLabel} onClick={submit} active={!submitting} />
        <Button label={t("profile.modify_password.popup.cancel_label")} active={!submitting} onClick={props.onCancel}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
      
      <FormError error={errors._error} />
    </form>
  );
}

const ReduxForm = reduxForm({
  form: "ChangePasswordForm"
})(ChangePasswordForm);

export default ReduxForm;