import {useEffect, useState} from "react";
import MessageBus from "../lib/MessageBus";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

function CustomerProfileSync({data, auth}) {
  const [customerName, setCustomerName] = useState("");
  const {loading, error, customerProfile} = data;

  useEffect(() => {
    if (!loading && !error && customerProfile && auth.isAuthenticated()) {
      if (customerProfile.name !== customerName) {
        setCustomerName(customerProfile.name);
        MessageBus.publish("auth", "profileUpdated", {name: customerProfile.name});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, customerProfile, auth]);

  return null;
}

const CUSTOMER_PROFILE = gql`
  query customerProfile {
    customerProfile {
      customerId
      name
    }
  }
`;

const withQuery = graphql(
  CUSTOMER_PROFILE, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }
);

export default withQuery(CustomerProfileSync);