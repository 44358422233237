import React from "react";
import Icon from "./Icon";

// Icons/Core/substract-line
function Substract(props) {
  const svgPointsParameter = "5 11 19 11 19 13 5 13";
  return (<Icon {...props} svgPointsParameter={svgPointsParameter} />);
}

export default Substract;
