import React, { useState } from "react";
import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { handleStripeConfirmSetup } from "../../../lib/StripeHelper";
import { useTranslation } from "react-i18next";
import FormError from "../../../shared/components/forms/FormError";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import CheckoutPanel from "./CheckoutPanel";
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import styles from "./FastCheckoutButtons.module.scss";


export function FastCheckoutButtons({accountId, clientSecret, onUse, onDataRetrieval}) {
  const { t } = useTranslation("checkout");

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState(null);

  // We don't use it (for now?) because some customers don't have their
  // shipping addresses in their wallet and it's a pain for them to add it.
  // const {data, loading, error} = useQuery(SHIPPING_COUNTRIES_FOR_ACCOUNT, {
  //   variables: { accountId: accountId }
  // });

  // Mutation to register PM into the system for later use
  const [customerAddPaymentMethod, {error: mutationError}] = useMutation(ADD_PAYMENT_METHOD);

  const handleReady = ({availablePaymentMethods}) => {
    setLoading(false)

    if (!availablePaymentMethods) {
      console.log("No payment methods available for express checkout")
      // No buttons will show so we don't show our text around the buttons
    } else {
      setVisible(true);
    }
  };

  const handleClick = ({resolve}) => {
    // Options are available to retrieve the email, the phone number or the
    // shipping address. We could fall back to the customer name, email and
    // phone number that we have already.
    const allowedCountries = []; // Don't ask for shipping address for now
    // const allowedCountries = !loading && !error
    //   && filterCountryCodes(data.customerAvailableCountriesForAccount)

    const options = {
      emailRequired: true,
      // phoneNumberRequired: true, // customers often don't have it set in their wallet
    }

    if (allowedCountries.length > 0) {
      // In order to get the shipping address, we need to give a list of
      // allowed countries along with shipping rates.
      options.shippingAddressRequired = true;
      options.allowedShippingCountries = allowedCountries;
      options.shippingRates = [
        {
          // Always free, though we do have surcharges
          id: 'free-shipping',
          displayName: 'Free shipping',
          amount: 0,
        },
      ];
    };

    // Notify checkout page that the user clicked on an express checkout button
    onUse();

    // Continue with stripe
    resolve(options);
  };

  async function handleSubmit(stripeInfos) {
    onDataRetrieval(stripeInfos);

    const addPaymentMethod = (stripePM) => {
      // save stripe payment method id
      customerAddPaymentMethod({
        variables: {
          data: {
            accountId: accountId,
            paymentMethodId: stripePM,
          }
        }
      });
    }

    handleStripeConfirmSetup(clientSecret, {stripe, elements}, {t, setStripeErrorMessage, addPaymentMethod});
  }

  const expressElementOptions = {
    buttonHeight: 55, // [40-55], 44 is default.
  }

  const classes = [
    styles.module,
    visible ? styles.visible : styles.invisible,
  ]

  return (
    <div>
      {loading && <LoadingLine />}
      <div className={classes.join(" ")}>
        <CheckoutPanel title={t("fast_checkout.title")}>
          <ExpressCheckoutElement onReady={handleReady} onClick={handleClick}
            onConfirm={handleSubmit} options={expressElementOptions}
          />

          <FormError error={stripeErrorMessage} />
          <FormError error={mutationError} />

          <p className={styles.info}>{t("fast_checkout.info")}</p>
        </CheckoutPanel>
      </div>
    </div>
  );
}

// function filterCountryCodes(countries) {
//   return countries.filter((e) => e.kind === "country" && e.availability === "available")
//     .map(c => c.code.toUpperCase());
// }

// const SHIPPING_COUNTRIES_FOR_ACCOUNT = gql`
//   query customerAvailableCountriesForAccount($accountId:ID!) {
//     customerAvailableCountriesForAccount(accountId:$accountId) {
//       kind
//       code
//       availability
//     },
//   }
// `;


const ADD_PAYMENT_METHOD = gql`
  mutation customerAddPaymentMethod($data: PaymentMethodIdInput!) {
    customerAddPaymentMethod(input: $data) {
      errors { key message }
    }
  }
`;