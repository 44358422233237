import {createSubmitRequestTypes} from "./helpers";

export const REQUEST_ADJUST_SUBSCRIPTION_IN_CART = "SHOP:REQUEST_ADJUST_SUBSCRIPTION_IN_CART";
export const ADJUST_SUBSCRIPTION = createSubmitRequestTypes("SHOP:ADJUST_SUBSCRIPTION");
export const requestAdjustSubscriptionInCart = (accountId) => ({ type: REQUEST_ADJUST_SUBSCRIPTION_IN_CART, accountId });
export const cancelAdjustSubscriptionInCart = () => ({ type: ADJUST_SUBSCRIPTION.CANCEL });

export const SET_CHECKOUT_INFO = "SHOP:SET_CHECKOUT_INFO";
export const CLEAR_CHECKOUT_INFO = "SHOP:CLEAR_CHECKOUT_INFO";
export const setCheckoutInfo = (accountId, orderId) => ({ type: SET_CHECKOUT_INFO, accountId, orderId});
export const clearCheckoutInfo = () => ({ type: CLEAR_CHECKOUT_INFO});

export const REQUEST_ADD_WATER_SUBSCRIPTION_TO_CART = "SHOP:REQUEST_ADD_WATER_SUBSCRIPTION_TO_CART";
export const ADD_WATER_SUBSCRIPTION_TO_CART = createSubmitRequestTypes("SHOP:ADD_WATER_SUBSCRIPTION_TO_CART");
export const requestAddWaterSubscriptionToCart = (accountId, quantity = 1, frequencyWeeks = 4) => ({ type: REQUEST_ADD_WATER_SUBSCRIPTION_TO_CART, accountId, quantity, frequencyWeeks });
export const cancelAddWaterSubscriptionToCart = () => ({ type: ADD_WATER_SUBSCRIPTION_TO_CART.CANCEL });

export const REQUEST_EDIT_SUBSCRIPTION_IN_CART = "SHOP:REQUEST_EDIT_SUBSCRIPTION_IN_CART";
export const EDIT_SUBSCRIPTION_IN_CART = createSubmitRequestTypes("SHOP:EDIT_SUBSCRIPTION_IN_CART");
export const requestEditSubscriptionInCart = (accountId) => ({ type: REQUEST_EDIT_SUBSCRIPTION_IN_CART, accountId });
export const cancelEditSubscriptionInCart = () => ({ type: EDIT_SUBSCRIPTION_IN_CART.CANCEL });

export const REQUEST_REMOVE_SUBSCRIPTION_FROM_CART = "SHOP:REQUEST_REMOVE_SUBSCRIPTION_FROM_CART";
export const REMOVE_SUBSCRIPTION_FROM_CART = createSubmitRequestTypes("SHOP:REMOVE_SUBSCRIPTION_FROM_CART");
export const requestRemoveSubscriptionFromCart = (accountId) => ({ type: REQUEST_REMOVE_SUBSCRIPTION_FROM_CART, accountId });
export const cancelRemoveSubscriptionFromCart = () => ({ type: REMOVE_SUBSCRIPTION_FROM_CART.CANCEL });

export const REQUEST_ADD_PRODUCT_TO_CART = "SHOP:REQUEST_ADD_PRODUCT_TO_CART";
export const ADD_PRODUCT_TO_CART = createSubmitRequestTypes("SHOP:ADD_PRODUCT_TO_CART");
export const requestAddProductToCart = (accountId, productSku, productTitle) => ({ type: REQUEST_ADD_PRODUCT_TO_CART, accountId, productSku, productTitle });
export const cancelAddProductToCart = () => ({ type: ADD_PRODUCT_TO_CART.CANCEL });

export const REQUEST_EDIT_PRODUCT_IN_CART = "SHOP:REQUEST_EDIT_PRODUCT_IN_CART";
export const EDIT_PRODUCT_IN_CART = createSubmitRequestTypes("SHOP:EDIT_PRODUCT_IN_CART");
export const requestEditProductInCart = (accountId, productSku, productTitle, noDelete) => ({ type: REQUEST_EDIT_PRODUCT_IN_CART, accountId, productSku, productTitle, noDelete });
export const cancelEditProductInCart = () => ({ type: EDIT_PRODUCT_IN_CART.CANCEL });

export const REQUEST_REMOVE_PRODUCT_FROM_CART = "SHOP:REQUEST_REMOVE_PRODUCT_FROM_CART";
export const REMOVE_PRODUCT_FROM_CART = createSubmitRequestTypes("SHOP_REMOVE_PRODUCT_FROM_CART");
export const requestRemoveProductFromCart = (accountId, productSku) => ({ type: REQUEST_REMOVE_PRODUCT_FROM_CART, accountId, productSku });
export const cancelRemoveProductFromCart = () => ({ type: REMOVE_PRODUCT_FROM_CART.CANCEL });

export const REQUEST_PRODUCT_WAITING_LIST = "SHOP:REQUEST_PRODUCT_WAITING_LIST";
export const PRODUCT_WAITING_LIST = createSubmitRequestTypes("SHOP:PRODUCT_WAITING_LIST");
export const requestProductWaitingList = (shopId, accountId, item) => ({ type: REQUEST_PRODUCT_WAITING_LIST, shopId, accountId, item });
export const cancelProductWaitingList = () => ({ type: PRODUCT_WAITING_LIST.CANCEL });
export const completedProductWaitingList = () => ({ type: PRODUCT_WAITING_LIST.SUCCESS });

export const REQUEST_CHECKOUT_CART = "SHOP:REQUEST_CHECKOUT_CART";
export const CHECKOUT_CART = createSubmitRequestTypes("SHOP:CHECKOUT_CART");
export const requestCheckoutCart = (accountId) => ({ type: REQUEST_CHECKOUT_CART, accountId });
export const cancelCheckoutCart = () => ({ type: CHECKOUT_CART.CANCEL });

export const REQUEST_CANCEL_CHECKOUT = "SHOP:REQUEST_CANCEL_CHECKOUT";
export const CANCEL_CHECKOUT = createSubmitRequestTypes("SHOP:CANCEL_CHECKOUT");
export const requestCancelCheckout = (accountId, orderId, redirect = true) => ({ type: REQUEST_CANCEL_CHECKOUT, accountId, orderId, redirect });
export const cancelCancelCheckout = () => ({ type: CANCEL_CHECKOUT.CANCEL });

export const REQUEST_ADD_GIFTING_OPTION = "SHOP:REQUEST_ADD_GIFTING_OPTION";
export const ADD_GIFTING_OPTION = createSubmitRequestTypes("SHOP:ADD_GIFTING_OPTION");
export const requestAddGiftingOption = (accountId, orderId) => ({ type: REQUEST_ADD_GIFTING_OPTION, accountId, orderId });
export const cancelAddGiftingOption = () => ({ type: ADD_GIFTING_OPTION.CANCEL });

export const REQUEST_REMOVE_GIFTING_OPTION = "SHOP:REQUEST_REMOVE_GIFTING_OPTION";
export const REMOVE_GIFTING_OPTION = createSubmitRequestTypes("SHOP:REMOVE_GIFTING_OPTION");
export const requestRemoveGiftingOption = (accountId, orderId) => ({ type: REQUEST_REMOVE_GIFTING_OPTION, accountId, orderId });
export const cancelRemoveGiftingOption = () => ({ type: REMOVE_GIFTING_OPTION.CANCEL });

// Modify an order's shipping address: uses the address book forms
export const REQUEST_CHANGE_SHIPPING_ADDRESS = "SHOP:REQUEST_CHANGE_SHIPPING_ADDRESS";
export const CHANGE_SHIPPING_ADDRESS = createSubmitRequestTypes("SHOP:CHANGE_SHIPPING_ADDRESS");
export const requestChangeShippingAddress = (accountId, orderId, addressId) => ({ type: REQUEST_CHANGE_SHIPPING_ADDRESS, accountId, orderId, addressId });
export const cancelChangeShippingAddress = () => ({ type: CHANGE_SHIPPING_ADDRESS.CANCEL });

export const REQUEST_SHOW_ORDER_STATUS_WITH_MONITOR = "SHOP:REQUEST_SHOW_ORDER_STATUS_WITH_MONITOR";
export const SHOW_ORDER_STATUS_WITH_MONITOR = createSubmitRequestTypes("SHOP:SHOW_ORDER_STATUS_WITH_MONITOR");
export const requestShowOrderStatusWithMonitor = (accountId, orderId) => ({ type: REQUEST_SHOW_ORDER_STATUS_WITH_MONITOR, accountId, orderId});

export const REQUEST_SHOW_ORDER_STATUS = "SHOP:REQUEST_SHOW_ORDER_STATUS";
export const SHOW_ORDER_STATUS = createSubmitRequestTypes("SHOP:SHOW_ORDER_STATUS");
export const requestShowOrderStatus = (accountId, orderId, status, errorMsg = "", invoiceNr = "") => ({ type: REQUEST_SHOW_ORDER_STATUS, accountId, orderId, status, errorMsg, invoiceNr });
export const cancelShowOrderStatus = () => ({ type: SHOW_ORDER_STATUS.CANCEL });
export const reportShowOrderStatusSuccess = () => ({ type: SHOW_ORDER_STATUS.SUCCESS });

export const REQUEST_QUANTITY_ASSISTANT_FOR_GUIDE = "SHOP:REQUEST_QUANTITY_ASSISTANT_FOR_GUIDE";
export const REQUEST_QUANTITY_ASSISTANT_FOR_FORM = "SHOP:REQUEST_QUANTITY_ASSISTANT_FOR_FORM";
export const REQUEST_QUANTITY_ASSISTANT = "SHOP:REQUEST_QUANTITY_ASSISTANT";
export const QUANTITY_ASSISTANT = createSubmitRequestTypes("SHOP:QUANTITY_ASSISTANT");
export const requestQuantityAssistant = () => ({ type: REQUEST_QUANTITY_ASSISTANT});
export const requestQuantityAssistantForGuide = () => ({ type: REQUEST_QUANTITY_ASSISTANT_FOR_GUIDE});
export const requestQuantityAssistantForForm = (form, quantityField, frequencyWeeksField) => ({ type: REQUEST_QUANTITY_ASSISTANT_FOR_FORM, form, quantityField, frequencyWeeksField});
export const cancelQuantityAssistant = () => ({ type: QUANTITY_ASSISTANT.CANCEL });
export const confirmQuantityAssistant = (quantity, frequencyWeeks) => ({ type: QUANTITY_ASSISTANT.SUCCESS, quantity, frequencyWeeks });

export const REQUEST_AUTO_ADD_WATER_SUBSCRIPTION_TO_CART = "SHOP:REQUEST_AUTO_ADD_WATER_SUBSCRIPTION_TO_CART";
export const AUTO_ADD_WATER_SUBSCRIPTION_TO_CART = createSubmitRequestTypes("SHOP:AUTO_ADD_WATER_SUBSCRIPTION_TO_CART");
export const requestAutoAddWaterSubscriptionToCart = (accountId, quantity = 1, frequencyWeeks = 4) => ({ type: REQUEST_AUTO_ADD_WATER_SUBSCRIPTION_TO_CART, accountId, quantity, frequencyWeeks });
export const cancelAutoAddWaterSubscriptionToCart = () => ({ type: AUTO_ADD_WATER_SUBSCRIPTION_TO_CART.CANCEL });

export const UPDATE_GUIDE_WATER_SUBSCRIPTION_QUANTITY = "SHOP:UPDATE_GUIDE_WATER_SUBSCRIPTION_QUANTITY";
export const updateGuideWaterSubscriptionQuantity = (quantity) => ({ type: UPDATE_GUIDE_WATER_SUBSCRIPTION_QUANTITY, quantity });

export const REQUEST_SHOW_GIFTING_DISCREPANCY = "SHOP:REQUEST_GIFTING_DISCREPANCY";
export const SHOW_GIFTING_DISCREPANCY = createSubmitRequestTypes("SHOP:SHOW_GIFTING_DISCREPANCY");
export const requestShowGiftingDiscrepancy = (message, savedMessage) => ({ type: REQUEST_SHOW_GIFTING_DISCREPANCY, message, savedMessage});
export const cancelShowGiftingDiscrepancy = () => ({ type: SHOW_GIFTING_DISCREPANCY.CANCEL });
