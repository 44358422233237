import {fromJS, Record, Map} from "immutable";

import {
  SHOW_MODAL_WINDOW,
  UPDATE_MODAL_WINDOW,
  REMOVE_MODAL_WINDOW
} from "../actions/WindowActions";

const WindowRecord = Record({
  id: "",
  kind: "",
  title: "",
  payload: Map(),
  options: Map()
})

const initialState = fromJS({
  windows: {},
  modalIds: [],
  modals: {},
  modalActive: false
})

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case SHOW_MODAL_WINDOW:
      return addModalWindow(state, action.windowId, action.kind, action.payload, action.title, action.options);

    case REMOVE_MODAL_WINDOW:
      return removeModalWindow(state, action.windowId);

    case UPDATE_MODAL_WINDOW:
      return updateModalWindow(state, action.windowId, action.payload, action.title, action.options);

    default:
      return state;
  }
}

function addModalWindow(state, id, kind, payload, title, options) {
  let updatedState = state.setIn(["modals", id], new WindowRecord({id: id, kind: kind, payload: payload, title: title, options: options}));
  return updatedState.set("modalIds", updatedState.get("modalIds").push(id))
                     .set("modalActive", true);
}

function updateModalWindow(state, id, payload, title, options) {
  let oldState = state.getIn(["modals", id]);
  return state.setIn(["modals", id], new WindowRecord({id: id, kind: oldState.kind, payload: payload, title: title, options: options}));
}

function removeModalWindow(state, id) {
  let filteredModalIds = state.get("modalIds").filter(windowId => windowId !== id);
  return state.set("modalIds", filteredModalIds)
              .deleteIn(["modals", id])
              .set("modalActive", filteredModalIds.size > 0);
}

export function getModals(state) {
  return state.get("modalIds").map(id => {
    return state.getIn(["modals", id]);
  })
}

export default reducer;