import React, {Component} from "react";
import {Field, reduxForm, formValueSelector} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import DropDownField from "../../shared/components/forms/DropDownField";
import TelephoneInputField from "../../shared/components/forms/TelephoneInputField";
import {List,fromJS} from "immutable";
import normalizePhoneNr from "../../lib/TelephoneNormalizer";
import FormError from "../../shared/components/forms/FormError";
import {COUNTRY_CODE_KEY, COUNTRY_LABEL_KEY, getStatesOrProvinces, preferredCountries} from '../../lib/CountriesAndStates';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Conditional from "../../shared/Conditional";

function toUpperCase(value) {
  return value && value.toUpperCase();
}

function toLowerCase(value) {
  return value && value.toLowerCase();
}


class ShippingAddressForm extends Component {
  componentDidUpdate(prevProps, prevState) {
    const countryWasChanged = prevProps.addressCountryCode !== this.props.addressCountryCode
    const isInitialRender = prevProps.addressCountryCode === undefined
    if (countryWasChanged && !isInitialRender) {
      this.props.change("addressStateProvince", "");
    }
  }

  render() {
    let {
      errors, handleSubmit, submitting, submitFailed, invalid,
      submitLabel = this.props.t("shipping.save_address"),
    } = this.props;

    const buttonSubmitLabel = submitting ? this.props.t("standby") : submitLabel;
    const addressTypes = fromJS([
      {id: "private", label: this.props.t("forms:address_residential")},
      {id: "business", label: this.props.t("forms:address_commercial")},
    ]);

    let stateProvinceInput = null;
    if (this.props.statesOrProvinces.size > 0) {
      stateProvinceInput = <Field
        label={this.props.t("forms:state_province")}
        name="addressStateProvince"
        mode=""
        component={DropDownField}
        options={this.props.statesOrProvinces}
        autoComplete="address-level1"
        valueKey="code"  
        titleKey="name"
        serverError={this.props.errors.addressStateProvince}
        />
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <Conditional show={this.props.showIntroText}>
            <p className="u-centered">{this.props.t("shipping.enter_address_info")}</p>
          </Conditional>
          <Field
            label={this.props.t("forms:address_residential_or_commercial")}
            name="type"
            component={DropDownField}
            options={addressTypes}
            mode=""
            valueKey="id"  
            titleKey="label" 
            serverError={this.props.errors.type}
            />
          <Field
            label={this.props.t("shipping.recipient_name")}
            name="name"
            placeholder={this.props.t("forms:full_name_placeholder")}
            mode=""
            autoComplete="name"
            component={TextInputField}
            serverError={this.props.errors.name}
            />
          <Field
            label={this.props.t("shipping.recipient_attn")}
            name="attName"
            mode=""
            placeholder=""
            component={TextInputField}
            serverError={this.props.errors.attName}
            />
          <Field
            label={this.props.t("shipping.recipient_phone")}
            name="phoneNr"
            mode=""
            normalize={normalizePhoneNr}
            autoComplete="tel"
            component={TelephoneInputField}
            serverError={this.props.errors.phoneNr}
            />
          <Field
            label={this.props.t("shipping.recipient_email")}
            name="email"
            placeholder={this.props.t("forms:email_placeholder")}
            mode=""
            autoComplete="email"
            component={TextInputField}
            serverError={this.props.errors.email}
            normalize={toLowerCase}
            />
          <Field
            label={this.props.t("forms:country")}
            name="addressCountryCode"
            preferredValues={preferredCountries}
            placeholder={this.props.t("forms:country_placeholder")}
            component={DropDownField}
            options={this.props.countries}
            withSpritePath="/assets/flags/sprites-vertical.png"
            mode=""
            autoComplete="country"
            valueKey={COUNTRY_CODE_KEY}
            titleKey={COUNTRY_LABEL_KEY}
            serverError={this.props.errors.addressCountryCode}
            />
          <Field
            label={this.props.t("forms:address_line_1")}
            name="addressAddressLine1"
            placeholder={this.props.t("forms:address_line_1_placeholder")}
            mode=""
            autoComplete="address-line1"
            component={TextInputField}
            serverError={this.props.errors.addressAddressLine1}
            />
          <Field
            label={this.props.t("forms:address_line_2")}
            name="addressAddressLine2"
            placeholder={this.props.t("forms:address_line_2_placeholder")}
            mode=""
            autoComplete="address-line2"
            component={TextInputField}
            serverError={this.props.errors.addressAddressLine2}
            />
          <Field
            label={this.props.t("forms:city")}
            name="addressCity"
            placeholder={this.props.t("forms:city_placeholder")}
            mode=""
            autoComplete="address-level2"
            component={TextInputField}
            serverError={this.props.errors.addressCity}
            />
          <Field
            label={this.props.t("forms:post_code")}
            name="addressPostcode"
            placeholder={this.props.t("forms:post_code_placeholder")}
            mode=""
            autoComplete="postal-code"
            normalize={toUpperCase}
            component={TextInputField}
            serverError={this.props.errors.addressPostcode}
            />      

            {stateProvinceInput}
        </div>
        <div className="buttons">
          <Button onClick={handleSubmit(values => this.props.onSubmit(values))} primary={true} label={buttonSubmitLabel} active={!submitting} />
          <Button label={this.props.t("cancel")} active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true}
            visible={this.props.showCancel} />
          <Button label={this.props.t("delete")} active={!submitting} onClick={this.props.onDelete}
            visible={this.props.showDelete} />
        </div>
        
        <FormError error={errors._error} validationError={submitFailed && invalid} />
      </form>
    )
  }
}

ShippingAddressForm.defaultProps = {
  statesOrProvinces: List([]),
  showDelete: false,
  showCancel: true,
  showAttentionName: false,
  showPhoneNr: false,
  showEmail: false,
  showIntroText: true,
}

const ReduxForm = reduxForm({
  form: "ShippingAddressForm",
  // Allow update of fields through changes to initialValues
  enableReinitialize: true,
  // Prevent such updates from impacting fiels that were edited by the user
  keepDirtyOnReinitialize: true,
})(ShippingAddressForm);

// Some state or province can not be selected for delivery
const filterStatesOrProvinces = (addressCountryCode) => {
  let states = getStatesOrProvinces(addressCountryCode) || List([]);

  const code = String(addressCountryCode).substr(0,2).toLowerCase();
  if (code === "us") {
    states = states.filter((s) => s.get("code") !== "VI");
  }

  return states;
}

const selector = formValueSelector("ShippingAddressForm");
function mapStateToProps(state, ownProps) {
  const addressCountryCode = selector(state, "addressCountryCode")
  return {
    addressCountryCode: addressCountryCode,
    statesOrProvinces: filterStatesOrProvinces(addressCountryCode),
  }
}

export default withTranslation("common")(connect(mapStateToProps)(ReduxForm));
