export const SET_WINDOW_SIZE = "SYS:SET_WINDOW_SIZE";
export const SOCKET_CONNECTED = "SYS:SOCKET_CONNECTED";
export const SOCKET_DISCONNECTED = "SYS:SOCKET_DISCONNECTED";

export function setWindowSize(width, height) {
  return {
    type: SET_WINDOW_SIZE,
    width, height
  }
}

export function setSocketConnected() {
  return {
    type: SOCKET_CONNECTED
  }
}
export function setSocketDisconnected() {
  return {
    type: SOCKET_DISCONNECTED
  }
}