import React, {Component} from "react"
import Button from "../../shared/components/Button";
import gql from "graphql-tag";
import {fromJS} from "immutable";
import {graphql} from "react-apollo";
import Checked from "../../shop/shared/icons/Checked";
import SmallMessage from "../../shop/shared/SmallMessage";
import Error from "../../shop/shared/icons/Error";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import SmallErrorMessage from "../../shop/shared/SmallErrorMessage";
import { withTranslation } from "react-i18next";

const INVITATION = gql`
  query customerAuthInvite($inviteId: String!) {
    customerAuthInvite(inviteId: $inviteId) {
      inviteId
      name
      email
      phone
      status
    }
  }
`;

class VerifyInvitation extends Component {

  render() {
    return (
      <div className="VerifyInvitation view successView">
        <div className="inner">
          {this.renderViews()}
        </div>
      </div>
    );
  }

  renderViews() {
    let {data} = this.props;
    if (data.loading) return this.renderLoadingStatus();
    if (data.error) return this.renderError();

    const invitation = fromJS(data.customerAuthInvite);
    if (invitation.get("status") === "accepted") {
      return this.renderCompleted();
    }
    return this.renderFound(invitation);
  }

  renderLoadingStatus() {
    return (
      <SmallMessage title={this.props.t("common:standby")}
        icon={<LoadingCircle color="var(--white)" />}
        message={this.props.t("invitation.wait.text")}
      />
    ); 
  }

  renderError() {
    return (
      <SmallErrorMessage iconColor="var(--white)"
        message={this.props.t("invitation.not_found.text")}
        button={<Button label={this.props.t("common:try_again")} onClick={this.props.onBack} />}
      />
    );
  }

  renderFound(invitation) {
    return (
      <SmallMessage title={this.props.t("invitation.normal.title")}
        icon={<Checked side="80px" colorDisabled="var(--white)" />}
        message={this.props.t("invitation.normal.text", { name: invitation.get("name")})}
        button={<Button label={this.props.t("common:continue")} onClick={() => this.props.onStart(invitation)} />}
      />
    );
  }

  renderCompleted(name) {
    return (
      <SmallMessage title={this.props.t("invitation.used.title")}
        icon={<Error side="80px" colorDisabled="var(--white)" />}
        message={this.props.t("invitation.used.text")}
        button={<Button label={this.props.t("links:sign_in")} onClick={this.props.onSignIn} />}
      />
    );
  }
}

const withQuery = graphql(
  INVITATION, {
    options: (props) => ({
      variables: {
        inviteId: props.inviteId
      }
    })
  }
)

export default withTranslation("accounts")(withQuery(VerifyInvitation));