import React from "react";
import {Route, Switch} from "react-router";
import RedirectToAccountPage from "./RedirectToAccountPage";
import AccountsPage from "./AccountsPage";
import ShopPage from "./ShopPage";
import EditSubPage from "./EditSubPageWrapper";
import CheckoutPage from "./CheckoutPage";
import CartPage from "./CartPage";
import SettingsPage from "./SettingsPage";
import NotFoundPage from "../NotFoundPage";
import CustomerProfileSync from "../shared/CustomerProfileSync";
import {BOUNDARIES} from "../routes";

const ClientSectionContainer = (props) => {
  const { base, auth } = props;
  
  return (
    <>
      {/* Syncing of profile name with cookies: */}
      <CustomerProfileSync auth={auth} />
      <Switch>
        <Route exact path={base + "/client-section"}>
          <RedirectToAccountPage auth={auth} autoSelect={true} />
        </Route>
        
        <Route exact path={base + "/client-section/accounts"}>
          <RedirectToAccountPage auth={auth} />
        </Route>

        {/* For links coming from the website app, which ignores the current account id */}
        <Route exact path={base + "/client-section/current-account/"}><RedirectToAccountPage auth={auth} autoSelect={true} /></Route>
        <Route exact path={base + "/client-section/current-account/shop"}><RedirectToAccountPage auth={auth} autoSelect={true} page="shop" /></Route>
        <Route exact path={base + "/client-section/current-account/shop/:productId"} render={({match}) => <RedirectToAccountPage auth={auth} autoSelect={true} page="shop" productId={match.params.productId} />} />
        <Route exact path={base + "/client-section/current-account/settings"}><RedirectToAccountPage auth={auth} autoSelect={true} page="settings" /></Route>

        <Route exact path={base + "/client-section/accounts/:accountId"} render={({match}) => <AccountsPage boundary={BOUNDARIES.ACCOUNT} accountId={match.params.accountId} />} />
        <Route path={base + "/client-section/accounts/:accountId/settings"} render={({match}) => <SettingsPage boundary={BOUNDARIES.SETTINGS} accountId={match.params.accountId} />} />
        <Route path={base + "/client-section/accounts/:accountId/subscription/:subscriptionId"} render={({match}) => <EditSubPage accountId={match.params.accountId} subscriptionId={match.params.subscriptionId} />} />
        <Route exact path={base + "/client-section/accounts/:accountId/shop"} render={({match}) => <ShopPage boundary={BOUNDARIES.SHOP} accountId={match.params.accountId} />} />
        <Route path={base + "/client-section/accounts/:accountId/shop/product/:productId"} render={({match}) => <ShopPage boundary={BOUNDARIES.SHOP} accountId={match.params.accountId} productId={match.params.productId} />} />
        <Route path={base + "/client-section/accounts/:accountId/shop/products/by-tag/:productsTagName"} render={({match}) => <ShopPage boundary={BOUNDARIES.SHOP} accountId={match.params.accountId} productsTagName={match.params.productsTagName} />} />
        <Route path={base + "/client-section/accounts/:accountId/cart"} render={({match}) => <CartPage boundary={BOUNDARIES.CART} accountId={match.params.accountId} />} />
        <Route path={base + "/client-section/accounts/:accountId/checkout/:orderId"} render={({match, location}) => <CheckoutPage accountId={match.params.accountId} orderId={match.params.orderId} location={location} />} />

        <Route path={base + "/client-section/*"}>
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  );
}

export default ClientSectionContainer;
