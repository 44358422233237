import {applyParamsToPath} from "./lib/PermanentUrlParams";
import auth0 from 'auth0-js';
import {Map} from "immutable";
import Navigation from "./lib/Navigation";
import { AUTH_CONFIG, LOGOUT_RETURN_URL, COOKIE_DOMAIN, getUseSecureCookieDomain } from './config';
import Cookies from 'js-cookie';
import i18n from "./i18n";

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.audience,
    responseType: 'token id_token'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);    
  }

  changePassword(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword({
        email: email, 
        connection: "Username-Password-Authentication"
      }, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(resp);
        }
      })
    })
  }  

  loginWithCredentials(email, password, params={}, callbacks={}) {
    let self = this;
    this.auth0.client.login({
      realm: 'Username-Password-Authentication', //connection name or HRD domain
      username: email,
      password: password,
      audience: AUTH_CONFIG.audience,
      // scope: 'read:order write:order',
      }, function(err, authResult) {
        // Auth tokens in the result or an error
        if (authResult && authResult.accessToken) { 
          self.setSession(authResult);
          if (callbacks.onSuccess) {
            callbacks.onSuccess(params);
          } else if (params.rTo) {
            // We don't want to keep this in params.
            let rTo = params.rTo;
            delete params["rTo"];
            self.redirectAfterAuthentication(params, rTo);
          } else if (params.cartId) {
            self.redirectAfterAuthentication(params, `/checkout/${params.cartId}`);
          } else {
            self.redirectAfterAuthentication(params);
          }
        } else if (err && callbacks.onError) {
          callbacks.onError(err.description);
        }
    });    
  }

  login(params={}) {
    let redirectUri = applyParamsToPath(AUTH_CONFIG.callbackUrl, Map(params));
    this.auth0.authorize({
      redirectUri: redirectUri
    });
  }

  handleAuthentication(params={}) {
    // params is either an empty object {} or contains search params that we should include.
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        if (params.rTo) {
          // We don't want to keep this in params.
          let rTo = params.rTo;
          delete params["rTo"];
          this.setSession(authResult);
          this.redirectAfterAuthentication(params, params.rTo);
        } else if (params.cartId) {
          this.setSession(authResult);
          this.redirectAfterAuthentication(params, `/checkout/${params.cartId}/signed-in`);
        } else {
          this.setSession(authResult);
          this.redirectAfterAuthentication(params);
        }
      } else if (err) {
        this.logout();
      }
    });
  }

  // private
  redirectAfterAuthentication(params, path = "/client-section") {
    // navigate to the home route
    let finalPath = applyParamsToPath(path, params);
    Navigation.replaceHistory(finalPath);
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  logout() {
    console.info("Logout");
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('cname');
    // navigate to the home route
    Cookies.remove("cname", {domain: COOKIE_DOMAIN});
    this.auth0.logout({
      returnTo: LOGOUT_RETURN_URL + "/" + i18n.language,
      clientID: AUTH_CONFIG.clientId 
    })
  }

  changeCustomerName(name) {
    // localStorage.setItem('cname', name);
    // Expires in 7 days as a default.
    // This is only used to remember the customer name for the hallstein website.
    Cookies.set("cname", name, {domain: COOKIE_DOMAIN, secure: getUseSecureCookieDomain(), expires: 7});
  }

  isAuthenticated() {
    // Check whether the current time is past the 
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

}
