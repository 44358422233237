import React, { useState } from "react";
import Tip from "./Tip";
import styles from "./Banner.module.scss";
import CloseXCircle from "../../shop/shared/icons/CloseXCircle";
import {getSetting, saveSetting} from "../../lib/SettingsStore";

// Simple Banner with status icon and text.
const Banner = (props) => {
  const { type, bannerKey } = props;

  const [hide, setHide] = useState(shouldBeHidden(props));

  if (hide) {
    return null;
  }

  const closeBanner = () => {
    saveSetting(bannerKey, "closed");
    setHide(true);
    if (props.onHide) {
      props.onHide();
    }
  }
  const closeBannerIcon = bannerKey && (
    <CloseXCircle className={styles.closeIcon}
      onClick={closeBanner} />
  );

  const classes = [
    styles.module,
    bannerKey ? styles.cancellable : null,
  ];
  let status = null;
  let icon = null;

  switch (type) {
    case "HEADING":
      classes.push(styles.golden);
      break;
    case "WARNING":
      status = Tip.STATUS.WARNING;
      icon = Tip.ICONS.ERROR;
      break;
    case "POSITIVE":
      status = Tip.STATUS.POSITIVE;
      icon = Tip.ICONS.THUMBUP;
      break;
    case "ALERT":
      status = Tip.STATUS.ALERT;
      icon = Tip.ICONS.ALARM;
      break;
    case "NEUTRAL":
    default:
      status = Tip.STATUS.NEUTRAL;
      icon = Tip.ICONS.THUMBUP;
      break;
  }

  return (
    <div className={classes.join(" ")}>
      <Tip status={status} icon={icon}>
        { props.children }
      </Tip>
      { closeBannerIcon }
    </div>
  );
}

const shouldBeHidden = (props) => {
  const erroneousBanner = !props.children;
  const userClosedIt = props.bannerKey && getSetting(props.bannerKey);
  return erroneousBanner || userClosedIt;
}

export default Banner;