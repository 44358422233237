export const SHOW_MODAL_WINDOW = "WINDOW_SHOW_MODAL_WINDOW";
export const REMOVE_MODAL_WINDOW = "WINDOW_REMOVE_MODAL_WINDOW";
export const UPDATE_MODAL_WINDOW = "WINDOW_UPDATE_MODAL_WINDOW";

export function showModalWindow(windowId, kind, payload, title="", options={}) {
  return {
    type: SHOW_MODAL_WINDOW,
    windowId, kind, payload, title, options
  }
}

export function updateModalWindow(windowId, payload, title="", options={}) {
  return {
    type: UPDATE_MODAL_WINDOW,
    windowId, payload, title, options
  }
}

export function removeModalWindow(windowId) {
  return {
    type: REMOVE_MODAL_WINDOW,
    windowId
  }
}