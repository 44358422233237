import React from "react";
import Button from "../../shared/components/Button";
import MobileMenuTrigger from "../../shared/components/MobileMenuTrigger";
import { cx } from "../../shared/lib/ClassSet";
import styles from "./HeaderMobile.module.scss";
import HeaderMenu from "./HeaderMenu";
import { useTranslation } from "react-i18next";

const buttonClasses = (boundary, currentBoundary) => {
  const classes = [styles.button];
  if (boundary === currentBoundary) {
    classes.push(styles.selected);
  }

  return classes.join(" ");
}

const HeaderMobile = (props) => {
  const { t } = useTranslation("links");
  const { expanded } = props;

  if (!props.show) return null;

  let classes = cx({
    [styles.module]: true,
    [styles.expanded]: expanded,
  });

  const loggedInAs = props.customerName && (
    <>
      <div className={styles.loggedInAs}>
        <span className={styles.title}>{t("common:logged_in_as")}</span>
        <span className={styles.name}>{ props.customerName }</span>
      </div>
      <div className={styles.divider} />
    </>
  );

  const selectedAccount = props.selectedAccount && (
    <>
      <div className={styles.selectAccount}>
        { props.selectedAccount }
      </div>
      <div className={styles.divider} />
    </>
  );

  const bottomButtons = [];

  if (props.handleSignOut) {
    bottomButtons.push(
      <Button key="signOut" label={t("sign_out")} theme={Button.THEMES.GREY_OUTLINE}
        onClick={props.handleSignOut} />
    );
  }
  if (props.handleSignIn) {
    bottomButtons.push(
      <Button key="signIn" label={t("auth.sign_in")} theme={Button.THEMES.BLUE}
        onClick={props.handleSignIn} />
    );
    bottomButtons.push(
      <Button key="orderNow" label={t("order_now")} theme={Button.THEMES.GOLD}
        href="/shop" />
    );
  }

  return (
    <div>
      { !expanded && props.banner }
      <div className={classes}>
        <a className={styles.logo} href={props.websiteRootSrc}
          aria-label={t("goto_home")}>
          <img
            src={props.logoSrc}
            alt={t("hallstein:artesian_water")}
            width={89}
            height={37}
          />
        </a>

        <div className={styles.trigger}>
          <div className={styles.cartSummary}>
            { props.cartSummary }
          </div>
          <MobileMenuTrigger 
            show={props.showMenuTrigger}
            menuShowing={expanded}
            onClick={props.toggleMenu}
            />
        </div>

        <div className={styles.navigation}>
          { loggedInAs }
          
          { selectedAccount }

          <HeaderMenu
            authenticated={props.authenticated}
            theme={Button.THEMES.WHITE}
            size={Button.SIZES.SMALL}
            buttonClassCallback={(boundary) => buttonClasses(boundary, props.boundary)}
            accountId={props.accountId}
            dividerClass={styles.divider}
            onAfterclick={props.toggleMenu} />

          <div className={styles.divider} />

          { bottomButtons }

          <div className={styles.bottomTexts}>
            <div className={styles.slogan}>
              {t("hallstein:motto")}
            </div>

            <div className={styles.socialButtons}>
              <a href="https://www.facebook.com/hallsteinwater" className={styles.facebook} title="Facebook"><span>warn</span></a>
              <a href="https://instagram.com/hallsteinwater" className={styles.instagram} title="Instagram"><span>warn</span></a>
              <a href="https://www.linkedin.com/company/alpinewatergmbh/" className={styles.linkedin} title="LinkedIn"><span>warn</span></a>
            </div>

            <div className={styles.copyright}>
              {t("hallstein:copyright")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HeaderMobile.defaultProps = {
  show: true,
  showMenuTrigger: true,
}

export default HeaderMobile;