import React, {useState} from "react";
import {connect} from "react-redux";
import ForgotPassword from "./views/ForgotPassword";
import FooterActions from "../shared/FooterActions";
import Navigation from "../lib/Navigation";
import {showSupport} from "../actions/SupportActions";
import {validateEmail} from "../lib/Validators";
import {Map} from "immutable";
import LoadBackgroundImage from "../shared/components/LoadBackgroundImage";
import Auth from "../Auth";
import styles from "./ForgotPasswordPage.module.scss";

const ForgotPasswordPage = (props) => {
  const [errors, setErrors] = useState({});
  const [succeededFor, setSucceededFor] = useState("");

  return (
    <div className={styles.module + " u-flex-center u-centered"}>
      <LoadBackgroundImage
        className={styles.bgImage}
        loadedClassName={styles.bgImageLoaded}
        url="/assets/auth/forgot-password-bg.jpg"
        />
      <div className={styles.container}>
        <ForgotPassword
          onSubmit={(values) => handleSubmit(values, setErrors, setSucceededFor)}
          onSignUp={Navigation.signUp}
          errors={errors}
          validate={validateForm}
          succeededFor={succeededFor}
        />
        <div className={styles.footer + " u-centered sg-font-bold"}>
          <FooterActions
            showSupport={props.showSupport}
            darkBackground={true}
          />
        </div>
      </div>
    </div>
  );
}

function handleSubmit(values, setErrors, setSucceededFor) {
  const data = values.toJS();

  new Auth().changePassword(data.email).then(
    () => setSucceededFor(data.email),
    (res) => setErrors({ email: res })
  );
}

function validateForm(values) {
  let errors = Map();
  errors = validateEmail(errors, values, "email");

  return errors.toJS();
}

function mapStateToProps(state) {
  return {
    windowHeight: state.getIn(["system", "height"])
  }
}

export default connect(mapStateToProps, {showSupport})(ForgotPasswordPage);
