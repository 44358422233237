import React, {Component} from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import DropDownField from "../../shared/components/forms/DropDownField";
import FormError from "../../shared/components/forms/FormError";

function numerical(value) {
  let parsed = parseInt(value, 10);
  if (isNaN(parsed)) return value;
  return parsed;
}

class SubscriptionForm extends Component {

  render() {
    let {errors, handleSubmit, submit, submitting} = this.props;
    let submitLabel = submitting ? "Standby..." : "Ok"; 
    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <Field 
            label="How many bottles would you like per delivery?"
            name="quantity"
            component={TextInputField}
            normalize={numerical}
            type="number"
            mode=""
            serverError={this.props.errors.quantity}
            />
          <Field 
            label="How often would you like deliveries?"
            name="frequencyWeeks"
            component={DropDownField}
            serverError={this.props.errors.frequencyWeeks}
            options={this.props.frequency}
            normalize={numerical}
            mode=""
            valueKey="value"
            titleKey="title"
            />
        </div>
        <div className="buttons">
          <Button label={submitLabel} onClick={submit} active={!submitting} />
          <Button label="Remove" onClick={this.props.onRemove} active={!submitting} 
            visible={this.props.showRemove} />
          <Button label="Quantity Assistant" visible={this.props.showAssistant}
            active={!submitting} onClick={this.props.onAssist} />
          <Button label="Cancel" active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
          <Button label="" active={!submitting} onClick={this.props.onCancel} />
        </div>
          
        <FormError error={errors._error} />
      </form>
    )
  }
}

const ReduxForm = reduxForm({
  form: "SubscriptionForm"
})(SubscriptionForm);

SubscriptionForm.defaultProps = {
  showRemove: false,
  showAssistant: false
}

export default ReduxForm;