import React from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextAreaInputField from "../../shared/components/forms/TextAreaInputField";
import FormError from "../../shared/components/forms/FormError";
import Conditional from "../../shared/Conditional";
import Checked from "../shared/icons/Checked";
import { useTranslation } from "react-i18next";

const GiftingMessageForm = (props) => {
  const { t } = useTranslation("checkout");
  let {errors, handleSubmit, submit, submitting} = props;
  let submitLabel = submitting ? t("common:standby") : t("shipping.gift.save_button");

  return (
    <form className="giftMessageForm" onSubmit={handleSubmit}>
      <div className="fields">
        <Field 
          label={t("shipping.gift.label")}
          name="message"
          placeholder={t("shipping.gift.placeholder")}
          component={TextAreaInputField}
          mode=""
          rows={10}
          serverError={props.errors.message}
          />
      </div>
      <p className="giftMessageInfo">{t("shipping.gift.text")}</p>
      <div className="buttons">
        <Button label={submitLabel} size={Button.SIZES.MINI}
          onClick={submit} active={!submitting && !props.messageSaved}
        />
        <Conditional show={props.messageSaved}>
          <span className="notif">
            <Checked outline={true} />
            {t("shipping.gift.saved")}
          </span>
        </Conditional>
      </div>
      
      <FormError error={errors._error} />
    </form>
  );
}

GiftingMessageForm.defaultProps = {
  showRemoveButton: false
}

const ReduxForm = reduxForm({
  form: "GiftingMessageForm"
})(GiftingMessageForm);

export default ReduxForm;