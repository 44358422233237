import React from "react";
import AddWaterSubscriptionForm from "../../shop/forms/AddWaterSubscriptionFormContainer";
import EditSubscriptionForm from "../../shop/forms/EditSubscriptionFormContainer";
import RemoveSubscriptionFromCartForm from "../../shop/forms/RemoveSubscriptionFromCartFormContainer";
import AdjustSubscriptionInCartForm from "../../shop/forms/AdjustSubscriptionInCartFormContainer";
import AddProductForm from "../../shop/forms/AddProductFormContainer";
import EditProductForm from "../../shop/forms/EditProductFormContainer";
import RemoveProductFromCartForm from "../../shop/forms/RemoveProductFromCartFormContainer";
import ChangeShippingAddressForm from "../../shop/forms/ChangeShippingAddressFormContainer";
import AddShippingAddressForm from "../../shop/addressBook/AddShippingAddressFormContainer";
import EditShippingAddressForm from "../../shop/addressBook/EditShippingAddressFormContainer";
import DeleteShippingAddressForm from "../../shop/addressBook/DeleteShippingAddressFormContainer";
import ConfirmShippingAddressForm from "../../shop/addressBook/ConfirmShippingAddressFormContainer";
import CheckoutCartForm from "../../shop/checkout/CheckoutCartFormContainer";
import CancelCheckoutForm from "../../shop/checkout/CancelCheckoutFormContainer";
import PlaceOrderForm from "../../shop/forms/PlaceOrderFormContainer";
import OrderStatusMonitor from "../../shop/OrderStatusMonitor";
import ProductWaitingListForm from "../../shop/forms/ProductWaitingListFormContainer";
import GiftingDiscrepancyForm from "../../shop/forms/GiftingDiscrepancyFormContainer";
import RenameSubscriptionForm from "../../shop/accounts/RenameSubscriptionFormContainer";
import PauseSubscriptionForm from "../../shop/accounts/PauseSubscriptionFormContainer";
import AdjustTransitionPreferenceForm from "../../public_keyed_pages/product_8_pack_eol/AdjustTransitionPreferenceFormContainer";
import ResumeSubscriptionForm from "../../shop/accounts/ResumeSubscriptionFormContainer";
import ConfirmResumeSubscriptionForm from "../../shop/accounts/ConfirmResumeSubscriptionFormContainer";
import ResumeSubscriptionSchedulingForm from "../../shop/accounts/ResumeSubscriptionSchedulingFormContainer";
import AdjustSubscriptionForm from "../../shop/accounts/AdjustSubscriptionFormContainer";
import AddAccountForm from "../../shop/accounts/AddAccountFormContainer";
import RenameAccountForm from "../../shop/accounts/RenameAccountFormContainer";
import ChangeSubscriptionShippingAddressForm from "../../shop/accounts/ChangeSubscriptionShippingAddressFormContainer";
import DelayDeliveryForm from "../../shop/accounts/DelayDeliveryFormContainer";
import ConfirmDelayDeliveryForm from "../../shop/accounts/ConfirmDelayDeliveryFormContainer";
import CancelSubscriptionForm from "../../shop/accounts/CancelSubscriptionFormContainer";

import ModifyAccountForm from "../../shop/accounts/ModifyAccountFormContainer";
import AddCreditCardForm from "../../shop/forms/AddCreditCardFormContainer";
import ChangePaymentTermsToCreditCardForm from "../../shop/forms/ChangePaymentTermsToCreditCardFormContainer";
import ChangeCreditCardForm from "../../shop/forms/ChangeCreditCardFormContainer";
import RetryPaymentForm from "../../shop/forms/RetryPaymentFormContainer";
import ChangeBillingAddressForm from "../../shop/accounts/ChangeBillingAddressFormContainer";
import SetBillingAddressForm from "../../shop/accounts/SetBillingAddressFormContainer";
import SetTaxInfoForm from "../../shop/accounts/SetTaxInfoFormContainer";
import ModifyBillingForm from "../../shop/accounts/ModifyBillingFormContainer";

import QuantityAssistantForm from "../../shop/forms/QuantityAssistantFormContainer";
import ChangeShippingAddressNotification from "../../shop/accounts/ChangeShippingAddressNotification";

import ChangePasswordForm from "../../login/forms/ChangePasswordFormContainer";
import ChangeLocaleForm from "../../login/forms/ChangeLocaleFormContainer";
import EditProfileForm from "../../login/forms/EditProfileFormContainer";

import RegisterForm from "../../anonymous_checkout/views/RegisterFormContainer";
import MonitorRegistrationMediator from "../../anonymous_checkout/views/MonitorRegistrationMediator";
import SignInForm from "../../anonymous_checkout/views/SignInFormContainer";
import SelectAccountForm from "../../anonymous_checkout/views/SelectAccountFormContainer";
import MonitorAccountCreation from "../../anonymous_checkout/views/MonitorAccountCreation";
import PopulateCartForm from "../../anonymous_checkout/views/PopulateCartFormContainer";
import CheckoutCartAnonymousCheckoutForm from "../../anonymous_checkout/views/CheckoutCartFormContainer";
import CreateAccountForm from "../../anonymous_checkout/views/CreateAccountFormContainer";
import ErrorWindow from "../../anonymous_checkout/views/ErrorWindow";

export function buildWindowContent(kind, id, payload) {
  switch(kind) {

    // Auth
    
    case "ChangePasswordWindow":
      return build(ChangePasswordForm, id, payload);

    case "ChangeLocaleWindow":
      return build(ChangeLocaleForm, id, payload);

    case "EditProfileWindow":
      return build(EditProfileForm, id, payload);

    // Onboarding

    case "AnonymousCheckout.Register":
      return build(RegisterForm, id, payload);

    case "AnonymousCheckout.MonitorRegistration":
      return build(MonitorRegistrationMediator, id, payload);

    case "AnonymousCheckout.SignIn":
      return build(SignInForm, id, payload);

    case "AnonymousCheckout.SelectAccount":
      return build(SelectAccountForm, id, payload);

    case "AnonymousCheckout.CreateAccount":
      return build(CreateAccountForm, id, payload);

    case "AnonymousCheckout.MonitorAccountCreation":
      return build(MonitorAccountCreation, id, payload);

    case "AnonymousCheckout.PopulateCart":
      return build(PopulateCartForm, id, payload);

    case "AnonymousCheckout.Checkout":
      return build(CheckoutCartAnonymousCheckoutForm, id, payload);

    case "AnonymousCheckout.ErrorWindow":
      return build(ErrorWindow, id, payload);

    // Shop

    case "AddSubscriptionToCartWindow":
      return build(AddWaterSubscriptionForm, id, payload);

    case "EditSubscriptionInCartWindow":
      return build(EditSubscriptionForm, id, payload);

    case "RemoveSubscriptionFromCartWindow":
      return build(RemoveSubscriptionFromCartForm, id, payload);

    case "AdjustSubscriptionInCartWindow":
      return build(AdjustSubscriptionInCartForm, id, payload);
    
    case "AddProductToCartWindow":
      return build(AddProductForm, id, payload);

    case "EditProductInCartWindow":
      return build(EditProductForm, id, payload);

    case "RemoveProductFromCartWindow":
      return build(RemoveProductFromCartForm, id, payload);

    case "GiftingDiscrepancy":
      return build(GiftingDiscrepancyForm, id, payload);

    case "ProductWaitingListWindow":
      return build(ProductWaitingListForm, id, payload);

    case "ChangeShippingAddressWindow":
      return build(ChangeShippingAddressForm, id, payload);

    case "AddShippingAddressWindow":
      return build(AddShippingAddressForm, id, payload);
    
    case "EditShippingAddressWindow":
      return build(EditShippingAddressForm, id, payload);

    case "DeleteShippingAddressWindow":
      return build(DeleteShippingAddressForm, id, payload);

    case "ConfirmShippingAddressWindow":
      return build(ConfirmShippingAddressForm, id, payload);
    
    case "CheckoutCartWindow":
      return build(CheckoutCartForm, id, payload);

    case "CancelCheckoutWindow":
      return build(CancelCheckoutForm, id, payload);

    case "PlaceOrderWindow":
      return build(PlaceOrderForm, id, payload);

    case "OrderStatusWithMonitorWindow":
      return build(OrderStatusMonitor, id, payload);

    case "QuantityAssistantWindow":
      return build(QuantityAssistantForm, id, payload);

    // Account.

    case "AddAccountWindow":
      return build(AddAccountForm, id, payload);

    case "RenameAccountWindow":
      return build(RenameAccountForm, id, payload);

    case "CancelSubscriptionWindow":
      return build(CancelSubscriptionForm, id, payload);

    case "RenameSubscriptionWindow":
      return build(RenameSubscriptionForm, id, payload);

    case "PauseSubscriptionWindow":
      return build(PauseSubscriptionForm, id, payload);

    case "ResumeSubscriptionWindow":
      return build(ResumeSubscriptionForm, id, payload);

    case "ConfirmResumeSubscriptionWindow":
      return build(ConfirmResumeSubscriptionForm, id, payload);

    case "ResumeSubscriptionSchedulingWindow":
      return build(ResumeSubscriptionSchedulingForm, id, payload);

    case "AdjustSubscriptionWindow":
      return build(AdjustSubscriptionForm, id, payload);

    case "ChangeSubscriptionShippingAddressWindow":
      return build(ChangeSubscriptionShippingAddressForm, id, payload);

    case "ChangeShippingAddressNotification":
      return build(ChangeShippingAddressNotification, id, payload);

    case "AddCreditCardWindow":
      return build(AddCreditCardForm, id, payload);
    
    case "ChangePaymentTermsToCreditCardWindow":
      return build(ChangePaymentTermsToCreditCardForm, id, payload);

    case "ChangeCreditCardWindow":
      return build(ChangeCreditCardForm, id, payload);

    case "RetryPaymentWindow":
      return build(RetryPaymentForm, id, payload);

    case "ChangeBillingAddressWindow":
      return build(ChangeBillingAddressForm, id, payload);

    case "SetBillingAddressWindow":
      return build(SetBillingAddressForm, id, payload);

    case "SetTaxInfoWindow":
      return build(SetTaxInfoForm, id, payload);

    case "ModifyBillingWindow":
      return build(ModifyBillingForm, id, payload);

    case "DelayDeliveryWindow":
      return build(DelayDeliveryForm, id, payload);
    
    case "ConfirmDelayDeliveryWindow":
      return build(ConfirmDelayDeliveryForm, id, payload);

    case "ModifyAccountWindow":
      return build(ModifyAccountForm, id, payload);

    case "AdjustTransitionPreferenceWindow":
      return build(AdjustTransitionPreferenceForm, id, payload);

    // Default

    default:
      console.warn(`No builder found for window type "${kind}".`);
      return null;
  }
}

function build(cls, id, payload) {
  return React.createElement(cls, {key: id, id: id, ...payload});
}