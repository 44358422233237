import React from "react";
import Icon from "./Icon";

// Icons/Core/checkbox-circle-fill and checkbox-circle-line
function Checked(props) {
  const { outline = false } = props;
  const svgPathDParameter = outline
    ? "M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M11.003,16 L6.76,11.757 L8.174,10.343 L11.003,13.172 L16.659,7.515 L18.074,8.929 L11.003,16 Z" // line
    : "M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M11.003,16 L18.073,8.929 L16.659,7.515 L11.003,13.172 L8.174,10.343 L6.76,11.757 L11.003,16 Z"; // fill
  
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default Checked;
