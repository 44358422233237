import React, {Component} from "react";
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import FooterActions from "../../shared/FooterActions";
import Form from "../forms/RegistrationForm";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validatePresence, validateAcceptance, validatePhoneNumber, validatePassword} from "../../lib/Validators";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {Map} from "immutable";
import {withRouter} from "react-router";
import {queryToMap} from "../../lib/QueryParser";
import { withTranslation } from 'react-i18next';


function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "email");
  errors = validatePassword(errors, values, "password");
  errors = validatePresence(errors, values, "phone");
  errors = validatePhoneNumber(errors, values, "phone");
  errors = validateAcceptance(errors, values, "tacAccepted", "yes");

  return errors.toJS();
}

class Registration extends Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  render() {
    return (
      <div className="Registration view">
        <div className="inner">
          <div className="u-shorter u-auto-margins">
            <p className="sg-large u-no-margin u-centered sg-mb-tiny">{this.props.t("signup.registration.title")}</p>
            <p className="sg-small u-centered">{this.props.t("signup.registration.subtitle")}</p>
          </div>
          <Form 
            errors={this.state.errors}
            validate={validateForm}
            onSignIn={this.props.onSignIn}
            onSubmit={this.handleSubmit.bind(this)}
            onCancel={this.handleCancel.bind(this)}
            darkBackground={true}
            countryCode={this.props.countryCode}
            showReferralCode={this.props.showReferralCode}
            />
        </div>

        <div className={"u-centered sg-font-bold"}>
          <FooterActions
            showSupport={this.props.showSupport} 
            darkBackground={true}
          />
        </div>
      </div>
    )
  }

  handleSubmit(values) {
    let query = queryToMap(this.props.location.search);
    let updatedValues = Map({
      locale: this.props.i18n.language,
      name: values.get("name"),
      email: values.get("email"),
      phone: getCompletePhoneNrOrNothing(values.get("phone")),
      password: values.get("password"),
      tacAccepted: values.get("tacAccepted") === "yes",
      marketingAccepted: values.get("marketingAccepted") === "yes",
      countryCode: this.props.countryCode,
      referralCode: getReferralCodeFromUrl(query), // Can only come from query (field isn't used)
      gclid: query.get("gclid", ""),
      fbclid: query.get("fbclid", "")
    });
    
    return this.props.startOnboarding(updatedValues.toJS())
      .then((response) => {
        if (response.data.startOnboarding.errors.length <= 0) {
          this.props.onRegistrationStarted(response.data.startOnboarding.onboardingId, updatedValues.get("email"), updatedValues.get("password"));
        } else {
          let errors = createValidationErrors(response.data.startOnboarding.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.onBack();
  }
}

Registration.defaultProps = {
  countryCode: "US",
  showReferralCode: false
}

export function getReferralCodeFromUrl(query) {
  if (query.get("ref", "").length > 0) return query.get("ref").toUpperCase();
  return "";
}

  
const START_ONBOARDING = gql`
  mutation startOnboarding($data: StartOnboardingInput!) {
    startOnboarding(input: $data) {
      onboardingId
      errors { key message }
    }
  }
`;


const withMutation = compose(
  graphql(START_ONBOARDING, {
    props: ({ mutate }) => ({
      startOnboarding: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);


export default withTranslation("auth")(connect(null, {showSupport})(withMutation(withRouter(Registration))));
