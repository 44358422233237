import React from "react";
import { RichText } from 'prismic-reactjs'
import {linkWithChangePage} from "./Utils";

export default function NoteSlice({data, onChangePage}) {
  return (
    <div className="NoteSlice">
      <RichText
        render={data.get("note_body").toJS()}
        serializeHyperlink={linkWithChangePage(onChangePage)}
        />
    </div>
  )
}