import React, { useState } from "react";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {showSupport} from "../../actions/SupportActions";
import gql from "graphql-tag";
import BaseLayout from "../BaseLayout";
import Button from "../../shared/components/Button";
import { useTranslation } from "react-i18next";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import GraphQLErrors from "../../shared/GraphQLErrors";
import useQueryMap from "../../hooks/useQueryMap";
import Navigation from "../../lib/Navigation";
import { createErrors, createValidationErrors } from "../../lib/ErrorFormatter";
import { generateUUID } from "../../lib/UUID";
import { showNotification } from "../../actions/NotificationActions";
import { ChoiceBox } from "./Box";
import { computeQuantityAndPrice6Cases } from "./Subscription8PackEOL";

const EMAIL_QUERY_KEY = "match_bottles";

// Public page which, using the emailKey, can retrieve the necessary info about
// the subscription and the user preference for the 8 pack EOL.
function Product8PackLinkedPage(props) {
  const {
    data,
  } = props;

  let inner = <LoadingLine />
  
  if (data.error) {
    inner = <GraphQLErrors error={data.error} />;
  } else if (!data.loading) {
    const info = props.data.customerPackTransitionSubscriptionInfoFromEmailKey;

    inner = (
      <Product8PackLinkedPageWithData {...info}
        customerSetPackTransitionPreference={props.customerSetPackTransitionPreference}
        showNotification={props.showNotification}
      />
    );
  }

  return (
    <BaseLayout {...props}>
      <div className="CommandWindow">
        {inner}
      </div>
    </BaseLayout>
  );
}

function Product8PackLinkedPageWithData(props) {
  const {
    accountId,
    matchBottles,
    products,
    subscriptionId,
  } = props;

  const { t } = useTranslation("accounts");
  const [initialMutationSent, setInitialMutationSent] = useState(false);
  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  // We'll need the query to handle email links redefining the matching strategy
  // /en/8-bottles-cases-transition-preference/{{table_key}}?match_bottles=true
  const query = useQueryMap();
  const urlDesiredStrategy = query.get(EMAIL_QUERY_KEY) !== undefined
    ? query.get(EMAIL_QUERY_KEY) === "true"
    : null;

  function handleMutationSuccess() {
    props.showNotification(generateUUID(), t("eol.public_page.notification_success"));
  }

  function handleSubmit(matchBottles) {
    if (matchBottles === null) return;

    const values = {
      accountId: accountId,
      subscriptionId: subscriptionId,
      matchBottles: matchBottles,
    }

    setSubmitting(true);

    return props.customerSetPackTransitionPreference(values)
      .then((response) => {
        if (response.data.customerSetPackTransitionPreference.errors.length <= 0) {
          handleMutationSuccess();
        } else {
          let errors = createValidationErrors(response.data.customerSetPackTransitionPreference.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      })
      .finally(() => setSubmitting(false));
  }

  const currentStrategy = matchBottles;
  if (!initialMutationSent && currentStrategy !== urlDesiredStrategy) {
    // URL strategy should trigger a mutation so the strategy gets set as
    // desired as soon as the user arrives on this page
    handleSubmit(urlDesiredStrategy);
    setInitialMutationSent(true);
  }

  const product8CasesInfo = products.find((p) => p.productSku === "WAT-HAL-STL-8PC-750");
  const number8Cases = product8CasesInfo && product8CasesInfo.quantity;

  const { quantity: newQuantity } = computeQuantityAndPrice6Cases(number8Cases, urlDesiredStrategy);

  return (
    <div>
      <p className="u-centered">
        {t("eol.public_page.intro")}
      </p>
      { errors.length > 0 ? <div className="u-centered">{t("eol.public_page.error")}</div> :
        <div>
          <p>{t("eol.public_page.success")}</p>
          <ChoiceBox
            numberLeft={number8Cases}
            numberRight={newQuantity}
          />
        </div>
      }
      <Button
        className="expand u-whitespace-small"
        onClick={() => Navigation.gotoAccountSubscription(accountId, subscriptionId)}
        label={t("eol.public_page.back_to_account")} active={!submitting}
      />
    </div>
  );
}

// Needs to retrieve customerPackTransitionSubscriptionInfoFromEmailKey basic info
const CUSTOMER_SUBSCRIPTION_INFO = gql`
  query customerPackTransitionSubscriptionInfoFromEmailKey($emailKey: String!) {
    customerPackTransitionSubscriptionInfoFromEmailKey(emailKey: $emailKey) {
      accountId
      subscriptionId
      products {
        productSku
        quantity
      }
      matchBottles
    }
  }
`;

const CUSTOMER_SET_PACK_TRANSITION_PREFERENCE = gql`
  mutation customerSetPackTransitionPreference($data: CustomerSetPackTransitionPreferenceInput!) {
    customerSetPackTransitionPreference(input: $data) {
      errors { key message}
    }
  }
`;

const withQueries = compose(
  graphql(CUSTOMER_SUBSCRIPTION_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(CUSTOMER_SET_PACK_TRANSITION_PREFERENCE, {
    props: ({ mutate }) => ({
      customerSetPackTransitionPreference: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

const mapDispatchToProps = dispatch => ({
  showNotification: (id, message, kind) => {
    dispatch(showNotification(id, message, kind));
  },
  showSupport: (page) => {
    dispatch(showSupport(page));
  }
})

export default connect(null, mapDispatchToProps)(withQueries(Product8PackLinkedPage));
