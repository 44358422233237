import Cookies from "js-cookie";
import React from "react";
import { currencyFormat } from "../../../lib/TextFormatter";
import { useTranslation } from 'react-i18next';
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import Conditional from "../../../shared/Conditional";
import Products from "../Products";
import "./OrderSummary.scss";

const OrderSummary = (props) => {
  const { t } = useTranslation("checkout")
  const { data, productTitles } = props;

  const currency = data.get("currency");
  const showDiscount = (data.get("promoCodeApplied") === true);
  let discount = `${currency} -${data.get("discount")}`;
  let taxes = `${currency} ${data.get("subtotalTaxes")}`;
  let total = `${currency} ${data.get("total")}`;
  if (data.get("isPreparing")) {
    discount = <LoadingLine key="discount-loading" />
    taxes = <LoadingLine key="taxes-loading" />
    total = <LoadingLine key="total-loading" />
  }

  // We update `products` with titles from `productTitels` if available.
  let products = data.get("products").map((product) => {
    return product.set("productTitle", productTitles.getIn([product.get("productSku"), "longTitle"], product.get("productTitle")));
  });

  return (
    <div className="OrderSummary">
      <div className="title">{t("summary.title")}</div>
      <Products
        products={products}
        subscriptionFrequencyWeeks={data.get("subscriptionFrequencyWeeks")}
      />
      <div className="content">
        <table>
          <tbody>
            <Conditional show={showDiscount}>
              <tr>
                <td>{t("summary.code_discount")}</td>
                <td>{discount}</td>
              </tr>
            </Conditional>
            <tr>
              <td>{t("summary.taxes")}</td>
              <td>{taxes}</td>
            </tr>
            <tr className="large-separator">
              <td colSpan="2"><hr/></td>
            </tr>
            <tr className="order-total">
              <td>{t("summary.total")}</td>
              <td>{total}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrderSummary;
