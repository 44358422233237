import {useEffect, useState} from "react"

export default function useResizeListener(node, onResize = null) {
  const [offsets, setOffsets] = useState({ height: 0, width: 0});

  useEffect(() => {
    const handleResize = () => {
      const newOffsets = {
        height: node.offsetHeight,
        width: node.offsetWidth,
      }

      setOffsets(newOffsets);
      if (onResize) {
        onResize(newOffsets);
      }
    }

    if (node) {
      handleResize();

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(node);
      
      return () => resizeObserver.disconnect();
    }
  }, [node, onResize]);
  
  return offsets;
}
