import React from "react";
import Notification from "./Notification";
import {VelocityTransitionGroup} from "velocity-react";
import {notificationReveal} from "../lib/Transitions";
import styles from "./NotificationsContainer.module.scss";

const NotificationsContainer = (props) => {

  var notifications = props.getNotifications();

  let elements = null;
  if (!notifications.isEmpty()) {
    elements = notifications.map((x) => {
      return (
        <Notification key={x.get("id")}
          id={x.get("id")}
          message={x.get("message")}
          photo={x.get("photo", false)}
          photoUrl={x.get("photoUrl", "")}
          hasUndo={x.get("undo", false)}
          kind={x.get("kind")} />
      )
    }).toArray();
  }

  return (
    <VelocityTransitionGroup className={styles.module} enter={notificationReveal.enter} leave={notificationReveal.leave} component="div">
      {elements}
    </VelocityTransitionGroup>
  );
}

export default NotificationsContainer;