import React, {useState} from "react";
import {connect} from "react-redux";
import SignIn from "./views/SignIn";
import FooterActions from "../shared/FooterActions";
import LoadBackgroundImage from "../shared/components/LoadBackgroundImage";
import Navigation, { buildWebsiteLink } from "../lib/Navigation";
import {showSupport} from "../actions/SupportActions";
import {validateEmail, validatePassword} from "../lib/Validators";
import {Map} from "immutable";
import Button from "../shared/components/Button";
import useQueryMap from "../hooks/useQueryMap";
import { useTranslation } from 'react-i18next';
import styles from "./SignInPage.module.scss";

const SignInPage = ({auth, showSupport}) => {
  const { t } = useTranslation("auth", { keyPrefix: "signin" });
  const [errors, setErrors] = useState([]);
  const query = useQueryMap();

  return (
    <div className={styles.module + " u-flex-center u-centered"}>
      <LoadBackgroundImage
        className={styles.bgImage}
        loadedClassName={styles.bgImageLoaded}
        url="/assets/auth/login-bg.jpg"
        />
      <div className={styles.container}>
        <img
          className={styles.logo}
          src="/assets/LogoSimple-White.svg"
          alt="Hallstein - Artesian Water"
          width={210}
          height={87}
        />

        <SignIn
          darkBackground
          subtitle={t("login_to_continue")}
          onSubmit={(values) => handleSubmit(values, setErrors, auth, query)}
          onSignUp={Navigation.signUp}
          errors={errors}
          validate={(values) => validateForm(values, setErrors)}
        />
        <Button label={t("back_to_hallstein")} link
          theme={Button.THEMES.WHITE} className="u-whitespace-small sg-font-bold"
          href={buildWebsiteLink(query)} />

        <div className={styles.footer + " u-centered sg-font-bold"}>
          <FooterActions
            showSupport={showSupport}
            darkBackground={true}
          />
        </div>
      </div>
    </div>
  );
}

function handleSubmit(values, setErrors, auth, query) {
  const data = values.toJS();
  const onError = (descr) => setErrors({"loginForm": descr});
  if (query.has("rTo")) {
    auth.loginWithCredentials(data.username, data.password, {rTo: query.get("rTo")}, {onError: onError});
  } else {
    auth.loginWithCredentials(data.username, data.password, {}, {onError: onError});
  }
}

function validateForm(values, setErrors) {
  setErrors([]); // Reset error state as we want to stop showing the "Wrong
  // username or password" message when the user modifies the fields.
  
  let errors = Map();
  errors = validateEmail(errors, values, "username");
  errors = validatePassword(errors, values, "password");
  return errors.toJS();
}

function mapStateToProps(state) {
  return {
    windowHeight: state.getIn(["system", "height"])
  }
}

export default connect(mapStateToProps, {showSupport})(SignInPage);
