import React, {Component} from "react";
import {reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import BackgroundImage from "../../shared/BackgroundImage";
import FormError from "../../shared/components/forms/FormError";
import "./ProductWaitingListForm.css";
import { getImageUrlForProduct, getImageUrlForSubscription } from "../../lib/MediaTools";

class ProductWaitingListForm extends Component {

  render() {
    let {errors, handleSubmit, submit, submitting, productTitle, productSku, kind, message} = this.props;
    let submitLabel = submitting ? "Standby..." : "Notify me when available"; 
    const imageSrc = kind === "subscription"
      ? getImageUrlForSubscription(productSku)
      : getImageUrlForProduct(productSku);

    return (
      <form className="ProductWaitingListForm" onSubmit={handleSubmit}>
        <div className="media">
          <BackgroundImage width="auto" height="276px" src={imageSrc} backgroundSize="contain" />
        </div>      
        <h5 className="productTitle">{productTitle}</h5>
        <p>{message}</p>
        <div className="buttons">
          <Button label={submitLabel} primary={true} onClick={submit} active={!submitting} />
          <Button label="Cancel" active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
          
        <FormError error={errors._error} />
      </form>
    )
  }
}

const ReduxForm = reduxForm({
  form: "ProductWaitingListForm"
})(ProductWaitingListForm);

export default ReduxForm;