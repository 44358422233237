import React from "react"
import {connect} from "react-redux";
import MonitorRegistration from "../../onboarding/views/MonitorRegistration";
import {signedIn, signInFailed} from "../../actions/AnonymousCheckoutActions";
import Auth from "../../Auth";

const MonitorRegistrationMediator = ({onboardingId, email, password, signedIn, signInFailed}) => {

  function handleBack() {
    signInFailed();
  }

  function handleSignIn() {
    const onSuccess = (params) => signedIn(params);
    new Auth().loginWithCredentials(email, password, {}, {onSuccess: onSuccess});
  }

  return (
    <MonitorRegistration
      onboardingId={onboardingId} 
      onBack={handleBack} 
      onSignIn={handleSignIn} 
      iconColorDisabled="var(--primary-blue-800)" />
  )
}

export default connect(null, {signedIn, signInFailed})(MonitorRegistrationMediator);