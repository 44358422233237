import React, { Component } from "react";
import { cx, addClassName } from "../../lib/ClassSet";
import { Map } from "immutable";
import "./Calendar.scss";
import ArrowButton from "../../../shop/shared/icons/Arrow";
import { addDays, addMonths, endOfMonth, endOfWeek, isAfter, isBefore, isSameDay, isSameMonth, startOfMonth, startOfWeek, subMonths } from "date-fns";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../../lib/DateFormatter";


class Calendar extends Component {
  renderHeader() {
    const dateFormat = "MMMM yyyy";

    let currentMonth = formatDate(this.props.currentMonth, dateFormat, this.props.i18n.language);

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <ArrowButton className="icon left"
            onClick={this.prevMonth} color="var(--grey-900)"
            arrowStyle={ArrowButton.STYLES.LINE}
            direction={ArrowButton.DIRECTIONS.LEFT} />
        </div>
        <div className="col col-center">
          <span>{currentMonth}</span>
        </div>
        <div className="col col-end">
          <ArrowButton className="icon right"
              onClick={this.nextMonth} color="var(--grey-900)"
              arrowStyle={ArrowButton.STYLES.LINE}
              direction={ArrowButton.DIRECTIONS.RIGHT} />
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "EEE";
    const days = [];

    let startDate = startOfWeek(this.props.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {formatDate(addDays(startDate, i), dateFormat, this.props.i18n.language)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderCells() {
    const {
      currentMonth,
      selectedDate,
      unavailableDates,
      notBeforeDate,
      notAfterDate,
      showEvents,
    } = this.props;
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const isoDateFormat = "yyyy-MM-dd";
    const rows = [];

    const useNotBeforeDate = notBeforeDate != null;
    const useNotAfterDate = notAfterDate != null;

    let days = [];
    let day = startDate;
    let formattedDate = "";
    let formattedIsoDate = "";
    // console.log("NOT BEFORE", notBeforeDate, "NEITHER AFTER", notAfterDate)
    // console.log("month", currentMonth, monthStart, monthEnd)
    // console.log("date selected", selectedDate, "start:", startDate, "end:", endDate)
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = formatDate(day, dateFormat, this.props.i18n.language);
        formattedIsoDate = formatDate(day, isoDateFormat, this.props.i18n.language);
        let isUnavailable = unavailableDates.has(formattedIsoDate);
        if (
          !isUnavailable &&
          useNotBeforeDate &&
          isBefore(day, notBeforeDate)
        )
          isUnavailable = true;
        if (
          !isUnavailable &&
          useNotAfterDate &&
          isAfter(day, notAfterDate)
        )
          isUnavailable = true;
        const cloneDay = new Date(day);
        let event = null;
        if (showEvents && unavailableDates.has(formattedIsoDate)) {
          event = (
            <span className="event">
              {unavailableDates.get(formattedIsoDate)}
            </span>
          );
        }
        let classes = `col cell weekday-${i + 1}`;
        classes = addClassName(isUnavailable, classes, "unavailable");
        classes = addClassName(
          !isSameMonth(day, monthStart),
          classes,
          "differentMonth"
        );
        classes = addClassName(
          isSameDay(day, selectedDate || null),
          classes,
          "selected"
        );
        
        days.push(
          <div
            className={classes}
            key={day}
            onClick={() => this.onDateClick(cloneDay)}
            onDoubleClick={() =>
              this.onDateDoubleClick(cloneDay)
            }
          >
            <span className="number">{formattedDate}</span>
            {event}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    if (this.props.onSelect) {
      this.props.onSelect(day);
    }
  };

  onDateDoubleClick = (day) => {
    if (this.props.onConfirm) {
      this.props.onConfirm(day);
    }
  };

  nextMonth = () => {
    this.props.onSetCurrentMonth(addMonths(this.props.currentMonth, 1));
  };

  prevMonth = () => {
    this.props.onSetCurrentMonth(subMonths(this.props.currentMonth, 1));
  };

  render() {
    let classes = cx({
      Calendar: true,
      "u-weekends-disabled": this.props.disableWeekends,
    });
    return (
      <div className={classes}>
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

Calendar.defaultProps = {
  unavailableDates: Map(),
  disableWeekends: false,
  selectedDate: new Date(),
  currentMonth: new Date(),
  notBeforeDate: null,
  notAfterDate: null,
  showEvents: true,
};

export default withTranslation("common")(Calendar);
