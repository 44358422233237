import {takeLatest} from "redux-saga";
import {fork, put, take, race} from "redux-saga/effects";
import {generateUUID} from "../lib/UUID";
import * as AccountActions from "../actions/AccountActions";
import * as AddressBookActions from "../actions/AddressBookActions";
import {showModalWindow, removeModalWindow} from "../actions/WindowActions";
import InvoiceStorage from "../lib/InvoiceStorage";
import { Map } from "immutable";
import i18n from '../i18n';

// Add account.
function* requestAddAccount(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AddAccountWindow", {}, i18n.t("accounts:create_new_account")));
  yield take([AccountActions.ADD_ACCOUNT.CANCEL, AccountActions.ADD_ACCOUNT.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAddAccount() {
  yield takeLatest(AccountActions.REQUEST_ADD_ACCOUNT, requestAddAccount);
}

// Rename the account.
function* requestRenameAccount(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "RenameAccountWindow", {accountId: baseAction.accountId}, i18n.t("settings:accounts.rename_account.popup.title")));
  yield take([AccountActions.RENAME_ACCOUNT.CANCEL, AccountActions.RENAME_ACCOUNT.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestRenameAccount() {
  yield takeLatest(AccountActions.REQUEST_RENAME_ACCOUNT, requestRenameAccount);
}


// Request cancel subscription.
function* requestCancelSubscription(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "CancelSubscriptionWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.cancel")));
  yield take([AccountActions.CANCEL_SUBSCRIPTION.CANCEL, AccountActions.CANCEL_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestCancelSubscription() {
  yield takeLatest(AccountActions.REQUEST_CANCEL_SUBSCRIPTION, requestCancelSubscription);
}

// Rename the subscription.
function* requestRenameSubscription(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "RenameSubscriptionWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.rename")));
  yield take([AccountActions.RENAME_SUBSCRIPTION.CANCEL, AccountActions.RENAME_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestRenameSubscription() {
  yield takeLatest(AccountActions.REQUEST_RENAME_SUBSCRIPTION, requestRenameSubscription);
}

// Request pause subscription.
function* requestPauseSubscription(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "PauseSubscriptionWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.pause")));
  yield take([AccountActions.PAUSE_SUBSCRIPTION.CANCEL, AccountActions.PAUSE_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestPauseSubscription() {
  yield takeLatest(AccountActions.REQUEST_PAUSE_SUBSCRIPTION, requestPauseSubscription);
}

// Request resume subscription.
function* requestResumeSubscription(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ResumeSubscriptionWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.resume")));
  yield take([AccountActions.RESUME_SUBSCRIPTION.CANCEL, AccountActions.RESUME_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestResumeSubscription() {
  yield takeLatest(AccountActions.REQUEST_RESUME_SUBSCRIPTION, requestResumeSubscription);
}

// Request confirm resume subscription.
function* requestConfirmResumeSubscription(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ConfirmResumeSubscriptionWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId, desiredDeliveryDate: baseAction.desiredDeliveryDate}, i18n.t("accounts:subscription.actions.resume_confirm")));
  yield take([AccountActions.CONFIRM_RESUME_SUBSCRIPTION.CANCEL, AccountActions.CONFIRM_RESUME_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestConfirmResumeSubscription() {
  yield takeLatest(AccountActions.REQUEST_CONFIRM_RESUME_SUBSCRIPTION, requestConfirmResumeSubscription);
}

// Request resume subscription scheduling.
function* requestResumeSubscriptionScheduling(baseAction) {
  let windowId = generateUUID();
  let title = baseAction.state === "cancelled" ? i18n.t("accounts:subscription.actions.undo_cancel") : i18n.t("accounts:subscription.actions.undo_pause")
  yield put(showModalWindow(windowId, "ResumeSubscriptionSchedulingWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId, state: baseAction.state}, title));
  yield take([AccountActions.RESUME_SUBSCRIPTION_SCHEDULING.CANCEL, AccountActions.RESUME_SUBSCRIPTION_SCHEDULING.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestResumeSubscriptionScheduling() {
  yield takeLatest(AccountActions.REQUEST_RESUME_SUBSCRIPTION_SCHEDULING, requestResumeSubscriptionScheduling);
}

// Request adjust subscription.
function* requestAdjustSubscription(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AdjustSubscriptionWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.adjust")));
  yield take([AccountActions.ADJUST_SUBSCRIPTION.CANCEL, AccountActions.ADJUST_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAdjustSubscription() {
  yield takeLatest(AccountActions.REQUEST_ADJUST_SUBSCRIPTION, requestAdjustSubscription);
}

// Request editting shipping address for a subscription.
function* requestChangeSubscriptionShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangeShippingAddressNotification", {}, i18n.t("accounts:subscription.actions.edit_delivery_address")));
  // eslint-disable-next-line
  const {yes, no} = yield race({
    yes: take((action) => action.type === AccountActions.YES_NOTIFICATION),
    no:  take((action) => action.type === AccountActions.NO_NOTIFICATION)
  });
  yield put(removeModalWindow(windowId));
  if (yes) {
    yield showChangeSubscriptionShippingAddress(baseAction)
  }
}
function* showChangeSubscriptionShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangeSubscriptionShippingAddressWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.edit_delivery_address")));
  // eslint-disable-next-line
  const {success, cancel, addAddress, editAddress, deleteAddress} = yield race({
    success: take((action) => action.type === AccountActions.CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS.SUCCESS),
    cancel:  take((action) => action.type === AccountActions.CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS.CANCEL),
    addAddress: take((action) => action.type === AddressBookActions.REQUEST_ADD_SHIPPING_ADDRESS),
    editAddress: take((action) => action.type === AddressBookActions.REQUEST_EDIT_SHIPPING_ADDRESS),
    deleteAddress: take((action) => action.type === AddressBookActions.REQUEST_DELETE_SHIPPING_ADDRESS),
  });
  yield put(removeModalWindow(windowId));

  if (addAddress) {
    yield take([AddressBookActions.ADD_SHIPPING_ADDRESS.CANCEL, AddressBookActions.ADD_SHIPPING_ADDRESS.SUCCESS]);
    yield showChangeSubscriptionShippingAddress(baseAction);
  }
  if (editAddress) {
    yield take([AddressBookActions.EDIT_SHIPPING_ADDRESS.CANCEL, AddressBookActions.EDIT_SHIPPING_ADDRESS.SUCCESS]);
    yield showChangeSubscriptionShippingAddress(baseAction);
  }
  if (deleteAddress) {
    yield take([AddressBookActions.DELETE_SHIPPING_ADDRESS.CANCEL, AddressBookActions.DELETE_SHIPPING_ADDRESS.SUCCESS]);
    yield showChangeSubscriptionShippingAddress(baseAction);
  }
}
function* watchRequestChangeSubscriptionShippingAddress() {
  yield takeLatest(AccountActions.REQUEST_CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS, requestChangeSubscriptionShippingAddress);
}
// Request set tax info.
function* requestSetTaxInfo(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "SetTaxInfoWindow", {accountId: baseAction.accountId}, i18n.t("common:billing.edit_tax_id_number")));
  yield take([AccountActions.SET_TAX_INFO.CANCEL, AccountActions.SET_TAX_INFO.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestSetTaxInfo() {
  yield takeLatest(AccountActions.REQUEST_SET_TAX_INFO, requestSetTaxInfo);
}


// Request modify billing.
function* requestModifyBilling(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ModifyBillingWindow", {accountId: baseAction.accountId}, i18n.t("accounts:billing.edit")));
  yield take([AccountActions.MODIFY_BILLING.CANCEL, AccountActions.MODIFY_BILLING.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestModifyBilling() {
  yield takeLatest(AccountActions.REQUEST_MODIFY_BILLING, requestModifyBilling);
}

// Request addition of credit card.
function* requestAddCreditCard(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AddCreditCardWindow", {accountId: baseAction.accountId}, i18n.t("common:credit_card.add")));
  yield take([AccountActions.ADD_CREDIT_CARD.CANCEL, AccountActions.ADD_CREDIT_CARD.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAddCreditCard() {
  yield takeLatest(AccountActions.REQUEST_ADD_CREDIT_CARD, requestAddCreditCard);
}

function* requestChangePaymentTermsToCreditCard(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangePaymentTermsToCreditCardWindow", {accountId: baseAction.accountId}, i18n.t("common:credit_card.switch_to_cc")));
  yield take([AccountActions.CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD.CANCEL, AccountActions.CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestChangePaymentTermsToCreditCard() {
  yield takeLatest(AccountActions.REQUEST_CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD, requestChangePaymentTermsToCreditCard);
}

// Request changing credit card.
function* requestChangeCreditCard(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangeCreditCardWindow", {accountId: baseAction.accountId}, i18n.t("common:credit_card.edit")));
  yield take([AccountActions.CHANGE_CREDIT_CARD.CANCEL, AccountActions.CHANGE_CREDIT_CARD.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestChangeCreditCard() {
  yield takeLatest(AccountActions.REQUEST_CHANGE_CREDIT_CARD, requestChangeCreditCard);
}

// Request retry credit card.
function* requestRetryPayment(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "RetryPaymentWindow", {accountId: baseAction.accountId, salesOrderId: baseAction.salesOrderId, timeoutSeconds: baseAction.timeoutSeconds}, i18n.t("common:credit_card.retry_payment"), Map({headless: true, centered: true})));
  yield take([AccountActions.RETRY_PAYMENT.CANCEL, AccountActions.RETRY_PAYMENT.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestRetryPayment() {
  yield takeLatest(AccountActions.REQUEST_RETRY_PAYMENT, requestRetryPayment);
}

// Request changing billing address.
function* requestChangeBillingAddress(baseAction) {
  let windowId = generateUUID();
  const title = baseAction.isCreation ? i18n.t("common:billing.add_address") : i18n.t("common:billing.edit_address");
  yield put(showModalWindow(windowId, "ChangeBillingAddressWindow", {accountId: baseAction.accountId, isCreation: baseAction.isCreation}, title));
  yield take([AccountActions.CHANGE_BILLING_ADDRESS.CANCEL, AccountActions.CHANGE_BILLING_ADDRESS.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestChangeBillingAddress() {
  yield takeLatest(AccountActions.REQUEST_CHANGE_BILLING_ADDRESS, requestChangeBillingAddress);
}

// Request set billing address.
function* requestSetBillingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "SetBillingAddressWindow", {accountId: baseAction.accountId, address: baseAction.address}, i18n.t("common:billing.updating_address")));
  yield take([AccountActions.SET_BILLING_ADDRESS.CANCEL, AccountActions.SET_BILLING_ADDRESS.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestSetBillingAddress() {
  yield takeLatest(AccountActions.REQUEST_SET_BILLING_ADDRESS, requestSetBillingAddress);
}

// Request delay delivery.
function* requestDelayDelivery(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "DelayDeliveryWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId}, i18n.t("accounts:subscription.actions.change_delivery_date")));
  yield take([AccountActions.DELAY_DELIVERY.CANCEL, AccountActions.DELAY_DELIVERY.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestDelayDelivery() {
  yield takeLatest(AccountActions.REQUEST_DELAY_DELIVERY, requestDelayDelivery);
}

// Request confirm delay delivery.
function* requestConfirmDelayDelivery(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ConfirmDelayDeliveryWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId, desiredDeliveryDate: baseAction.desiredDeliveryDate}, i18n.t("accounts:subscription.actions.change_delivery_date_confirm")));
  yield take([AccountActions.CONFIRM_DELAY_DELIVERY.CANCEL, AccountActions.CONFIRM_DELAY_DELIVERY.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestConfirmDelayDelivery() {
  yield takeLatest(AccountActions.REQUEST_CONFIRM_DELAY_DELIVERY, requestConfirmDelayDelivery);
}

// Request modify account.
function* requestModifyAccount(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ModifyAccountWindow", {accountId: baseAction.accountId}, i18n.t("accounts:edit_account")));
  yield take([AccountActions.MODIFY_ACCOUNT.CANCEL, AccountActions.MODIFY_ACCOUNT.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestModifyAccount() {
  yield takeLatest(AccountActions.REQUEST_MODIFY_ACCOUNT, requestModifyAccount);
}


// Request download invoice.
/* eslint-disable require-yield  */
function* downloadInvoice(baseAction) {
  let downloadUrl = InvoiceStorage.getUrl(baseAction.accountId, baseAction.invoiceNr);
  InvoiceStorage.download(downloadUrl);
}
/* eslint-enable require-yield  */
function* watchDownloadInvoice() {
  yield takeLatest(AccountActions.DOWNLOAD_INVOICE.SUBMIT, downloadInvoice);
}

// 8 bottle packs EOL for Europe.
function* requestAdjustTransitionPreference(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AdjustTransitionPreferenceWindow", {accountId: baseAction.accountId, subscriptionId: baseAction.subscriptionId, number8Cases: baseAction.number8Cases}, i18n.t("accounts:eol.popup.title")));
  yield take([AccountActions.ADJUST_TRANSITION_PREFERENCE.CANCEL, AccountActions.ADJUST_TRANSITION_PREFERENCE.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAdjustTransitionPreference() {
  yield takeLatest(AccountActions.REQUEST_ADJUST_TRANSITION_PREFERENCE, requestAdjustTransitionPreference);
}


export default function* saga() {
  yield fork(watchRequestAddAccount);
  yield fork(watchRequestRenameAccount);
  yield fork(watchRequestModifyAccount);
  
  yield fork(watchRequestRenameSubscription);
  yield fork(watchRequestPauseSubscription);
  yield fork(watchRequestAdjustTransitionPreference);
  yield fork(watchRequestResumeSubscription);
  yield fork(watchRequestConfirmResumeSubscription);
  yield fork(watchRequestResumeSubscriptionScheduling);
  yield fork(watchRequestAdjustSubscription);
  yield fork(watchRequestChangeSubscriptionShippingAddress);
  yield fork(watchRequestCancelSubscription);
  
  yield fork(watchRequestModifyBilling);
  yield fork(watchRequestSetTaxInfo);
  yield fork(watchRequestAddCreditCard);
  yield fork(watchRequestChangePaymentTermsToCreditCard);
  yield fork(watchRequestChangeCreditCard);
  yield fork(watchRequestRetryPayment);
  yield fork(watchRequestChangeBillingAddress);
  yield fork(watchRequestSetBillingAddress);
  yield fork(watchRequestDelayDelivery);
  yield fork(watchRequestConfirmDelayDelivery);

  yield fork(watchDownloadInvoice);
}
