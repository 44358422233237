import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/ShippingAddressForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelEditShippingAddress} from "../../actions/AddressBookActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validateShippingAddress} from "../../lib/AddressValidation";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {applyStateProvinceRule} from "../../lib/AddressRules";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import LoadingLine from "../../shared/components/forms/LoadingLine";

function validateForm(values, props) {
  return validateShippingAddress(values).toJS();
}

class EditShippingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    const setErrors = (errors) => this.setState({errors: errors});
    return handleEditShippingAddress(this.props, values, setErrors);
  }

  handleCancel() {
    this.props.cancelEditShippingAddress();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let countries = fromJS(filterAndSortCountries(data.customerAvailableCountriesForAccount));
    let initialValues = this.props.address.delete("__typename").delete("addressCountryName");

    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        validate={validateForm}
        countries={countries}
        showAttentionName={true}
        showPhoneNr={true}
        showEmail={true}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />
    )
  }
}

// Edit shipping address in the address book itself not just for this shipping
const ADDRESS_BOOK_EDIT_ADDRESS = gql`
  mutation customerUpdateShippingAddressForAccount($data: UpdateShippingAddressForAccountInput!) {
    customerUpdateShippingAddressForAccount(input: $data) {
      errors { key message }
    }
  }
`;

function handleEditShippingAddress(props, values, setErrors) {
  let updatedValues = applyStateProvinceRule(
    values.set("addressId", props.addressId)
          .set("accountId", props.accountId)
          .set("phoneNr", getCompletePhoneNrOrNothing(values.get("phoneNr")))
          .delete("addressCountryName")
  );

  return props.customerUpdateShippingAddressForAccount(updatedValues.toJS())
    .then((response) => {
      if (response.data.customerUpdateShippingAddressForAccount.errors.length <= 0) {
        props.cancelEditShippingAddress();
      } else {
        let errors = createValidationErrors(response.data.customerUpdateShippingAddressForAccount.errors);
        setErrors(errors);
      }
    })
    .catch((err) => {
      setErrors(createErrors(err));
    });
}

const SHIPPING_COUNTRIES_FOR_ACCOUNT = gql`
  query customerAvailableCountriesForAccount($accountId:ID!) {
    customerAvailableCountriesForAccount(accountId:$accountId) {
      kind
      code
      name
      availability
    },
  }
`;

const withQuery = compose(
  graphql(SHIPPING_COUNTRIES_FOR_ACCOUNT, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId,
        orderId: props.orderId
      }
    })
  }),
  graphql(ADDRESS_BOOK_EDIT_ADDRESS, {
    props: ({ mutate }) => ({
      customerUpdateShippingAddressForAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

export default connect(null, {cancelEditShippingAddress})(withQuery(EditShippingAddressFormContainer));
