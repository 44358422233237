import React, {Component} from "react";
import CustomerInvitationDetails from "./views/CustomerInvitationDetails";
import MonitorInvitation from "./views/MonitorInvitation";
import VerifyInvitation from "./views/VerifyInvitation";
import MessageBus from "../lib/MessageBus";
import Navigation from "../lib/Navigation";
import "./OnboardingPage.css";
import SignUpLayout from "./SignUpLayout";
import {withRouter} from "react-router";

class InvitationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view: "initial",
      inviteId: "",
      name: "",
      email: "",
      phone: ""
    }
  }

  render() {
    return (
      <SignUpLayout className="InvitationPage OnboardingPage"
        background={SignUpLayout.BACKGROUNDS.ACCOUNT_CREATION}
        noTestimonials={true}>
        {this.renderView(this.state.view)}
        <div className="preloadGraphics">
        </div>
      </SignUpLayout>
    )
  }

  renderView(view) {
    const { match } = this.props; 
    switch(view) {

      case "monitor":
        return <MonitorInvitation
                  inviteId={match.params.inviteId} 
                  onBack={this.changeView.bind(this, "customerDetails")} 
                  onSignIn={this.handleSignIn.bind(this)} />

      case "customerDetails":
        return <CustomerInvitationDetails
                  inviteId={match.params.inviteId}
                  name={this.state.name}
                  email={this.state.email}
                  phone={this.state.phone}
                  onBack={this.changeView.bind(this, "initial")}
                  onStarted={this.handleInviteStarted.bind(this)}
                  />
                                
      default:
        return <VerifyInvitation 
                  inviteId={match.params.inviteId} 
                  onBack={this.handleCancel} 
                  onSignIn={this.handleSignInManual.bind(this)} 
                  onStart={this.handleStart.bind(this)} />
    }
  }

  changeView(view) {
    this.setState({view: view});
  }

  handleCancel() {
    MessageBus.publish("navigation", "goto", {url: "/signin"});
  }

  handleInviteStarted(email, password) {
    this.setState({
      view: "monitor",
      email: email,
      password: password
    })
  }

  handleStart(invitation) {
    this.setState({
      view: "customerDetails",
      invitation_id: invitation.get("inviteId"),
      name: invitation.get("name"),
      email: invitation.get("email"),
      phone: invitation.get("phone")
    })
  }

  handleSignIn() {
    this.props.auth.loginWithCredentials(this.state.email, this.state.password, {});
  }

  handleSignInManual() {
    Navigation.signIn();
  }
}

export default withRouter(InvitationPage);