var tmpStorage = {};

function storageAvailable(type) {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return false;
  }
}

function localStorageAvailable() {
  return storageAvailable("localStorage");
}

export function getSetting(key, defaultValue) {
  if (localStorageAvailable()) {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  } else {
    return tmpStorage[key] || defaultValue;
  }
}

export function saveSetting(key, value) {
  if (localStorageAvailable()) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    tmpStorage[key] = value;
  }
}

export function clearAllSettings() {
  tmpStorage = {}
  if (localStorageAvailable()) {
    localStorage.clear();
  }
  if (sessionStorageAvailable()) {
    sessionStorage.clear();
  }
}

// SESSION STORAGE

function sessionStorageAvailable() {
  return storageAvailable("sessionStorage");
}

export function getSessionSetting(key, defaultValue) {
  if (sessionStorageAvailable()) {
    return JSON.parse(sessionStorage.getItem(key)) || defaultValue;
  } else {
    return tmpStorage[key] || defaultValue;
  }
}

export function saveSessionSetting(key, value) {
  if (sessionStorageAvailable()) {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    tmpStorage[key] = value;
  }
}
