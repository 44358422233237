import React, {Component} from "react";
import ProductTable from "../shared/ProductTable";
import "./Products.css";

class Products extends Component {

  render() {

    let {products, subscriptionFrequencyWeeks} = this.props;

    return (
      <ProductTable
        products={products}
        subscriptionFrequencyWeeks={subscriptionFrequencyWeeks}
        showSubtotal={false}
        />
    )
  }

}

export default Products;