import React from "react";
import AddressInline from "../../shared/AddressInline";
import AddressNameAndType from "../../shared/AddressNameAndType";
import BoxWithRadioSelect from "./BoxWithRadioSelect";
import DeleteButton from "./icons/DeleteButton";
import RenameButton from "./icons/RenameButton";
import styles from "./BoxWithRadioSelect.module.scss";

const MODES = {
  NORMAL: AddressInline.MODES.NORMAL,
  TIGHT: AddressInline.MODES.TIGHT,
}

const AddressWithSelection = (props) => {
  const { id, mode, onEdit, onDelete, address, selected, additionalElemForSelected } = props;

  const fn = () => props.onSelect(id);
  let headerText = <AddressNameAndType address={address} />;

  if (onEdit || onDelete) {
    const handleEdit = (e) => {
      e.stopPropagation();
      onEdit(id, address);
    }
    const handleDelete = (e) => {
      e.stopPropagation();
      onDelete(id, address);
    }

    headerText = <React.Fragment>
      { headerText }
      <div className={styles.headerRight}>
        { onEdit && <RenameButton onClick={handleEdit} /> }
        { onDelete && <DeleteButton onClick={handleDelete} /> }
      </div>
    </React.Fragment>
  }
  
  return (
    <BoxWithRadioSelect selected={selected} onSelect={fn}
      headerText={headerText}
      displayTight={mode === MODES.TIGHT}>
      <AddressInline address={address} mode={mode} />
      { selected && additionalElemForSelected }
    </BoxWithRadioSelect>
  );
}

AddressWithSelection.MODES = MODES;

export default AddressWithSelection;