import React, {Component} from "react";
import { cx } from "../../lib/ClassSet";

import styles from "./ToggleButton.module.scss";

class ToggleButton extends Component {

  render() {
    let classes = cx({
      [styles.module]: true,
      [this.props.className]: !!this.props.className,
      [styles.active]: this.props.active,
      [styles.on]: this.props.state === "on",
      [styles.off]: this.props.state === "off",
      [styles.undecided]: this.props.state === "undecided" || this.props.state === ""
    })

    return (
      <div className={classes} onClick={this.handleClick.bind(this)}>
        {this.renderState(this.props.submitting)}
      </div>
    );
  }

  renderState(submitting) {
    const classes = [styles.bg];
    if (submitting) {
      classes.push(styles.submitting);
    }

    return (
      <div className={classes.join(" ")}>
        <div className={styles.toggle} />
      </div>
    )
  }

  handleClick(e) {
    e.preventDefault();
    if (this.props.submitting) {
      return
    }

    if (this.props.state === "on") {
      this.props.onToggle("off")
    } else {
      this.props.onToggle("on")
    }
  }

}


ToggleButton.defaultProps = {
  state: "undecided",
  active: true,
  submitting: false
}

export default ToggleButton;