import React, {Component} from "react";
import {connect} from "react-redux";
import {requestChangeBillingAddress, requestSetBillingAddress, requestSetTaxInfo} from "../../../actions/AccountActions";
import AddressInline from "../../../shared/AddressInline";
import Button from "../../../shared/components/Button";
import CheckoutPanel from "./CheckoutPanel";
import { isSameAddress } from "../../../lib/AddressRules";
import BoxWithRadioSelect from "../../shared/BoxWithRadioSelect";
import AddressList from "../AddressList";
import AddressNameAndType from "../../../shared/AddressNameAndType";
import StatusTag from "../../../shared/components/StatusTag";
import Checked from "../../shared/icons/Checked";
import { useTranslation, withTranslation } from 'react-i18next';
import SetupBillingAddressFormContainer from "../../accounts/SetupBillingAddressFormContainer";

class SelectBillingAddress extends Component {

  handleChangeBillingAddress(isCreation = false) {
    this.props.requestChangeBillingAddress(this.props.accountId, isCreation);
  }

  handleUseShippingAddress() {
    this.props.requestSetBillingAddress(this.props.accountId, this.props.shippingAddress.toJS());
  }

  handleChangeTaxId() {
    // put back tax id
    this.props.requestSetTaxInfo(this.props.accountId);
  }

  render() {
    return (
      <CheckoutPanel nbTasks={2} title={this.props.t("billing.title")}
        completed={this.props.billingAddressStatus === "provided"}
        help={this.props.t("billing.info")}
        instructions={this.renderInstructions()}
      >
        {this.renderBillingAddress()}
      </CheckoutPanel>
    )
  }

  renderInstructions() {
    if (this.props.billingAddressStatus === "provided") return null;

    return this.props.t("billing.provide_address");
  }  

  renderBillingAddress() {
    let shippingAddressProvided = this.props.shippingAddressStatus === "provided";

    return (
      <div className="BillingAddress">
        <ValidAddressBoxesOrInlineForm
          accountId={this.props.accountId}
          billingAddress={this.props.billingAddress}
          prefilledAddress={this.props.prefilledAddress}
          shippingAddress={this.props.shippingAddress}
          shippingAddressProvided={shippingAddressProvided}
          onUseShippingAddress={this.handleUseShippingAddress.bind(this)}
          onChangeBillingAddress={this.handleChangeBillingAddress.bind(this)}
          onChangeTaxId={this.handleChangeTaxId.bind(this)}
          billingTaxId={this.props.billingTaxId}
          billingValidTaxId={this.props.billingValidTaxId}
          billingTaxExempt={this.props.billingTaxExempt}
        />
      </div>
    )
  }
}



function ValidAddressBoxesOrInlineForm(props) {
  const {
    accountId,
    billingAddress,
    prefilledAddress,
    shippingAddress,
    shippingAddressProvided,
    onUseShippingAddress,
    onChangeBillingAddress,
    onChangeTaxId,
    billingTaxId,
    billingValidTaxId,
    billingTaxExempt,
  } = props;

  const { t } = useTranslation("checkout");

  const isValidBillingAddress = !!billingAddress.get("addressPostcode", null)
  // While we don't have an address book, there are only 2 cases here:
  // - use the same as the shipping address
  // - define a different address
  const isSameAddressAsShipping = isSameAddress(billingAddress, shippingAddress);
  
  if (!isValidBillingAddress) {
    // Return inline form optionaly preceded by a "Same as shipping" box
    return (
      <>
        <SameAsShippingBox label={t("billing.use_same_as_shipping")}
          isSameAddressAsShipping={isSameAddressAsShipping}
          shippingAddressProvided={shippingAddressProvided}
          onSelect={() => !isSameAddressAsShipping && onUseShippingAddress()}
        />
        <SetupBillingAddressFormContainer accountId={accountId} address={billingAddress}
          prefilledAddress={prefilledAddress} />
      </>
    );
  }
  
  let taxId = billingTaxId;
  let taxIdVerified = null;
  if (billingValidTaxId) {
    taxIdVerified = (
      <StatusTag color={StatusTag.COLORS.GREEN} text={t("common:billing.verified")}>
        <Checked outline />
      </StatusTag>
    );
  }
  let taxButtonLabel = t("common:billing.add_tax_id");
  let taxIdElement = null;
  if (taxId && taxId !== "") {
    taxButtonLabel = t("common:billing.change_tax_id");
    taxIdElement = <div key="tax_id">{t("common:billing.tax_id")}: <strong>{taxId}</strong> {taxIdVerified}</div>
  }

  let taxExemptionElement = null;
  if (billingTaxExempt) {
    taxExemptionElement = <div key="tax_exempt">{t("common:billing.tax_exempt")}</div>
  }

  const buttonLabel = !isValidBillingAddress
    ? t("billing.add_address")
    : t("billing.edit_address");

  return (
    <>
      <SameAsShippingBox label={isSameAddressAsShipping ? billingAddress.get("name") : t("billing.use_same_as_shipping")}
        isSameAddressAsShipping={isSameAddressAsShipping}
        shippingAddressProvided={shippingAddressProvided}
        onSelect={() => !isSameAddressAsShipping && onUseShippingAddress()}>
        <AddressInline
          showType={false}
          address={shippingAddress.delete("email").delete("phoneNr")}
          mode={AddressList.MODES.TIGHT}
        />
        { taxIdElement }
        { taxExemptionElement }
      </SameAsShippingBox>

      <DifferentThanShippingBox 
        label={<AddressNameAndType address={billingAddress} />}
        isSameAddressAsShipping={isSameAddressAsShipping}>
        <AddressInline
          showType={false}
          address={billingAddress}
          mode={AddressList.MODES.TIGHT}
        />
        { taxIdElement }
        { taxExemptionElement }
      </DifferentThanShippingBox>

      <div className="buttons">
        <Button label={buttonLabel}
          onClick={() => onChangeBillingAddress(isSameAddressAsShipping)}
          theme={!isValidBillingAddress ? Button.THEMES.BLUE : Button.THEMES.BLUE_OUTLINE}
          size={Button.SIZES.SMALL} />
        <Button label={taxButtonLabel} visible={!billingTaxExempt}
          theme={Button.THEMES.BLUE_OUTLINE}
          onClick={onChangeTaxId} active={isValidBillingAddress} />
      </div>
    </>
  );
}

function SameAsShippingBox({label, isSameAddressAsShipping, shippingAddressProvided, onSelect, children}) {
  if (!shippingAddressProvided) return null;

  return (
    <BoxWithRadioSelect selected={isSameAddressAsShipping}
      headerText={label}
      enabled={shippingAddressProvided}
      onSelect={onSelect}>
      {children}
    </BoxWithRadioSelect>
  );
}

function DifferentThanShippingBox({label, isSameAddressAsShipping, children}) {
  if (isSameAddressAsShipping) return null;

  return (
    <BoxWithRadioSelect selected={!isSameAddressAsShipping}
      displayTight={true}
      headerText={label}
      onSelect={() => {}}>
      {children}
    </BoxWithRadioSelect>
  );
}

SelectBillingAddress.defaultProps = {
  active: true
}


export default withTranslation("checkout")(connect(null, {
  requestChangeBillingAddress,
  requestSetBillingAddress,
  requestSetTaxInfo
})(SelectBillingAddress));