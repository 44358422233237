import React, { useCallback, useEffect, useState } from "react";
import useResizeListener from "../../hooks/useResizeListener";
import useScrollListener from "../../hooks/useScrollListener";
import Button from "./Button";
import styles from "./Submenu.module.scss";

const Submenu = (props) => {
  const {
    items,
    spaceEvenly = false,
    parentRef,
  } = props;

  const classes = [
    styles.module,
    props.className,
    spaceEvenly ? styles.spaceEvenly : null,
  ];

  const [hashPositions, setHashPositions] = useState([]);

  const handleResize = useCallback((offsets) => {
    let useHashPositions = true;
    const hashPos = items.map((i) => {
      const elem = document.getElementById(i.hash.replace("#", ""));

      if (!elem) {
        // Some submenu use hash as a filter and don't need that
        useHashPositions = false;
        return null;
      }

      const rect = elem.getBoundingClientRect();
      
      return {
        hash: i.hash,
        positionTop: window.scrollY + rect.top,
      }
    })

    // Make sure the hashes are sorted according to their position Y from last to first
    hashPos.reverse();
    setHashPositions(useHashPositions ? hashPos : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useResizeListener(parentRef ? parentRef.current : document.body, handleResize);
  
  return (
    <div className={classes.join(" ")}>
      <div className={styles.submenuInner}>
        <SubmenuItems {...props} hashPositions={hashPositions} />
      </div>
    </div>
  );
}

// Buttons to be used in a submenu: they get highlighted as the page hash
// gets updated.
function SubmenuItems({items, pageHash, defaultHash, hashPositions, noSeparators = false}) {
  const scrollY = useScrollListener();

  const [currentHash, setCurrentHash] = useState(null);
  const [menuItems, setMenuItems] = useState(null);

  useEffect(() => {
    // Keep it in sync with the page hash
    const desiredHash = pageHash || defaultHash;
    if (currentHash !== desiredHash) {
      setCurrentHash(desiredHash);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageHash, defaultHash])

  useEffect(() => {
    // Some submenu don't use hashPositions as hash are used as filters there
    if (hashPositions.length > 0) {
      // Hashes are ordered from last to first
      const scrollYMinusHalf = scrollY + (window.innerHeight / 2);
      hashPositions.every(({hash, positionTop}) => {
        if (positionTop < scrollYMinusHalf) {
          setCurrentHash(hash);
          return false;
        }

        return true;
      });
    }
  }, [hashPositions, scrollY])

  useEffect(() => {
    // When not using hash, we want to scroll to the top on any item click as
    // it's going to change the filtering and thus the items displayed.
    const noHash = hashPositions.length === 0;
    const handleClick = noHash ? (() => window.scrollTo(0, 0)) : null;

    const menuItems = items.map((i, index) => (
      <React.Fragment key={index}>
        { !noSeparators && index !== 0 && <div className={styles.divider} /> }
        <Button
          href={i.hash} label={i.label}
          onClick={handleClick}
          link={true}
          className={i.hash === currentHash ? "selected" : ""} />
      </React.Fragment>
    ));

    setMenuItems(menuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, currentHash, noSeparators])

  return menuItems;
}

export default Submenu;