import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./SubscriptionForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelAddWaterSubscriptionToCart, requestQuantityAssistant} from "../../actions/ShopActions";
import {fromJS, Map} from "immutable";
import {validateGreaterThan} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

function validateForm(values) {
  let errors = Map();
  errors = validateGreaterThan(errors, values, "quantity", 0);
  errors = validateGreaterThan(errors, values, "frequencyWeeks", 0);
  return errors.toJS();
}

class AddWaterSubscriptionFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = values.set("accountId", this.props.accountId);
    return this.props.customerAddWaterSubscriptionToCart(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerAddWaterSubscriptionToCart.errors.length <= 0) {
          this.props.cancelAddWaterSubscriptionToCart();
        } else {
          let errors = createValidationErrors(response.data.customerAddWaterSubscriptionToCart.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelAddWaterSubscriptionToCart();
  }

  handleAssist() {
    this.props.requestQuantityAssistant();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let initialValues = {
      quantity: this.props.quantity,
      frequencyWeeks: this.props.frequencyWeeks
    }
    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        frequency={fromJS(data.subscriptionFrequencyOptions)}
        validate={validateForm}
        showAssistant={true}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onAssist={this.handleAssist.bind(this)}
        />
    )
  }

}


const ADD_SUBSCRIPTION_TO_CART = gql`
  mutation customerAddWaterSubscriptionToCart($data: CustomerAddWaterSubscriptionToCartInput!) {
    customerAddWaterSubscriptionToCart(input: $data) {
      errors { key message }
    }
  }
`;

const SUBSCRIPTION_FREQUENCY_OPTIONS = gql`
  query subscriptionFrequencyOptions {
    subscriptionFrequencyOptions {
      title
      value
    }
  }
`;


const withQueries = compose(
  graphql(SUBSCRIPTION_FREQUENCY_OPTIONS),
  graphql(ADD_SUBSCRIPTION_TO_CART, {
    props: ({ mutate }) => ({
      customerAddWaterSubscriptionToCart: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelAddWaterSubscriptionToCart, requestQuantityAssistant})(withQueries(AddWaterSubscriptionFormContainer));

