import React from "react";
import Button from "../../shared/components/Button";
import {longDateFormat} from "../../lib/DateFormatter";
import { cx } from "../../shared/lib/ClassSet";
import {isScheduled, isBusy, isDelayable, isProcessed, isCancelled, isNext} from "../../lib/SubscriptionHelper";
import StatusTag from "../../shared/components/StatusTag";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { useTranslation, Trans } from "react-i18next";
import Subpanel from "./Subpanel";
import styles from "../views/SubscriptionDeliveries.module.scss";


export default function DeliveryItem(props) {
  const {
    withoutPanel = false,
  } = props;

  const { t } = useTranslation("accounts", { keyPrefix: "subscription" });
  // otherwise bugs because of keyPrefix even though the keyPrefix should apply only on "accounts"
  const { t: tCommon } = useTranslation("common");

  let element = null;
  if (isScheduled(props.delivery)) {
    element = (
      <div className={styles.nextDelivery + " " + styles.scheduled}>
        <Trans i18nKey="accounts:subscription.estimated_delivery"
          components={{
            span: <span className={styles.date} />
          }}
          values={{
            date: longDateFormat(props.delivery.get("deliveryDate"))
          }}
        />
      </div>
    )
  } else {
    element = (
      <div className={styles.nextDelivery + " " + styles.requested}>
        <Trans i18nKey="accounts:subscription.requested_delivery"
          components={{
            span: <span className={styles.date} />
          }}
          values={{
            date: longDateFormat(props.delivery.get("requestedDeliveryDate"))
          }}
        />
      </div>
    )
  }

  let classNames = cx({
    [styles.item]: true,
    [styles.uBusy]: isBusy(props.delivery),
    [styles.uNext]: isNext(props.delivery),
    [styles.uCancelled]: isCancelled(props.delivery),
    [styles.uProcessed]: isProcessed(props.delivery),
    [styles.hasButtons]: isDelayable(props.delivery),
    [styles.withMargin]: withoutPanel,
  })

  let delayFn = () => props.onDelay(props.delivery.get("deliveryId"));

  const item = (
    <div className={classNames}>
      <div className={styles.icons}>
        <StatusTag className={styles.next} color={StatusTag.COLORS.GREY} text={t("status.pending")} />
        <StatusTag className={styles.cancelled} color={StatusTag.COLORS.RED} text={t("status.cancelled")} />
        <StatusTag className={styles.processed} color={StatusTag.COLORS.GREEN} text={t("status.in_progress")} />
        <span className={styles.updating + " sg-text-small u-no-margin"}>{tCommon("updating")}</span>
        <div className={styles.busy}>
          <LoadingLine show={isBusy(props.delivery)} />
        </div>
      </div>
      {renderProducts(props.delivery.get("products"))}
      {element}
    </div>
  );

  return withoutPanel ? item : (
    <Subpanel
      content={item}
      button={
        <Button
          label={t("actions.change_delivery_date")}
          theme={Button.THEMES.BLUE_OUTLINE}
          active={isDelayable(props.delivery)}
          onClick={delayFn} />
      }
    />
  );
}


function renderProducts(products) {
  return products.map((product) => {
    return (
      <div key={product.get("productSku")} className={styles.product}>
        <div className={styles.description}>
          <span className={styles.quantity}>{product.get("quantity")}x</span> {product.get("productTitle")}
        </div>
      </div>
    )
  })
}
