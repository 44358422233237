import React, {Component} from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import Checkbox from "../../shared/components/forms/Checkbox";
import FormError from "../../shared/components/forms/FormError";
import { withTranslation, Trans } from 'react-i18next';


function toBoolean(val) {
  return val === true || val === "true" || val === "yes";
}


class TaxInfoForm extends Component {

  render() {
    let {errors, handleSubmit, submitting} = this.props;
    let submitLabel = submitting ? this.props.t("standby") : this.props.t("billing.add_tax_id_number");

    let description = this.props.t("billing.enter_tax_id");
    let validateField = null;
    if (this.props.showValidateTaxId) {
      validateField = (
        <Field
          label={this.props.t("billing.select_vies")}
          name="validateTaxId"
          normalize={toBoolean}
          component={Checkbox}
          onValue={true}
          offValue={false}
          serverError={this.props.errors.validateTaxId}
          />
      );
      description = (
        <React.Fragment>
          {description} <Trans i18nKey="common:billing.make_sure_validate" components={{ b: <strong /> }} />
        </React.Fragment>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <p>{description}</p>

          <Field
            label={this.props.t("billing.tax_id_number")}
            name="taxId"
            placeholder={this.props.t("billing.tax_id_number_placeholder")}
            normalize={upcase}
            mode=""
            component={TextInputField}
            serverError={this.props.errors.taxId}
            />

            {validateField}
        </div>
        <div className="buttons">
          <Button onClick={handleSubmit(values => this.props.onSubmit(values))}
            primary={true} label={submitLabel} active={!submitting} />
          <Button label={this.props.t("cancel")} active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
          
        <FormError error={errors._error} />
      </form>
    )
  }
}

function upcase(val) {
  return String(val).toUpperCase();
}

TaxInfoForm.defaultProps = {
  showValidateTaxId: false
}

const ReduxForm = reduxForm({
  form: "TaxInfoForm"
})(TaxInfoForm);

export default withTranslation("common")(ReduxForm);