import React from "react";
import Button from "../../shared/components/Button";
import { buildWebsiteLink } from "../../lib/Navigation";
import useQueryMap from "../../hooks/useQueryMap";
import { Trans } from 'react-i18next';

export default function CancelCheckoutFooter(props) {
  const query = useQueryMap();

  function handleCancel() {
    window.location.href = buildWebsiteLink(query, "/shop");
  }

  return (
    <p className="u-centered sg-text-small u-no-margin">
      <Trans
        i18nKey="checkout:anonymous_checkout.back_to_shop"
        components={{
          a: <Button className="sg-font-bold" theme={Button.THEMES.BLACK} onClick={handleCancel} link />
        }}
      />
    </p>
  );
}