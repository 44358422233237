import React, {Component} from "react";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import ToggleButton from "../../shared/components/forms/ToggleButton";
import { withTranslation } from 'react-i18next';
import LeftRight from "../shared/LeftRight";
import GraphQLErrors from "../../shared/GraphQLErrors";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class NotificationsPanelSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      receiveMarketingEmailsSubmitting: false,
    }
  }

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountId && (prevProps.accountId !== this.props.accountId)) {
      this.subscribe();
    }
  }

  subscribe() {
    if (this.unsubscribeNotificationChanges) {
      this.unsubscribeNotificationChanges();
    }
    this.unsubscribeNotificationChanges = this.props.subscribeToProfileChanges(this.props, {});
  }

  render() {
    let {data} = this.props;
    if (data.loading && !data.customerProfile) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />

    let receiveMarketingEmails = "off";
    if (data.customerProfile.marketingAccepted) {
      receiveMarketingEmails = "on";
    }

    return (
      <div className="sg-text-normal">
        <LeftRight customStyle={{"marginBottom": "18px"}}
          left={this.props.t("notifications.receive_marketing_emails")}
          right={
            <div style={{marginLeft: "auto"}}>
              <ToggleButton state={receiveMarketingEmails}
                submitting={this.state.receiveMarketingEmailsSubmitting}
                onToggle={this.handleToggleMarketing.bind(this)} />
            </div>
        } />
      </div>
    );
  }

  toggleNotification(notification, updatedState) {
    return this.props.customerToggleMarketing({accountId: this.props.accountId, notification: notification})
      .then((response) => {
        if (response.data.customerToggleMarketing.errors.length <= 0) {
          this.setState(updatedState);
        }
      })
      .catch((err) => {
        console.warn("Error", err);
        this.setState(updatedState);
      })    
  }

  handleToggleMarketing(value) {
    this.setState({receiveMarketingEmailsSubmitting: true});
    this.toggleNotification("customer_toggle_marketing", {receiveMarketingEmailsSubmitting: false});
  }
}

const TOGGLE_NOTIFICATION = gql`
  mutation customerToggleMarketing {
    customerToggleMarketing {
      errors { key message }
    }
  }
`;

const PROFILE_NOTIFICATIONS = gql`
  query customerProfile {
    customerProfile {
      marketingAccepted
    }
  }
`;

const withQueries = compose(
  graphql(PROFILE_NOTIFICATIONS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(TOGGLE_NOTIFICATION, {
    props: ({ mutate }) => ({
      customerToggleMarketing: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

const ON_UPDATED_PROFILE_NOTIFICATIONS = SubscriptionQueryWrapper("", `
  customerUpdatedProfile {
    customerId
  }`
);

const mapDispatchToProps = dispatch => ({
  subscribeToProfileChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_PROFILE_NOTIFICATIONS,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  }
})


export default withTranslation("settings")(connect(null, mapDispatchToProps)(withQueries(NotificationsPanelSettings)));
