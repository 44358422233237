import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Form from "./NewPaymentElementForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {Map} from "immutable";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import styles from "./SetupCreditCardFormContainer.module.scss";
import { StripeElements } from "../views/Checkout/StripeElements";


function validateForm(_values) {
  let errors = Map();
  return errors.toJS();
}

// Used when an account doesn't have a credit card set up yet and is not
// displayed inside a popin, so the user cannot "cancel" this form.
function SetupCreditCardFormContainer(props) {
  const {
    accountId,
    clientSecret, // Mandatory, so we can reuse the same for all checkout payment forms
    onCardAdded = noop,
    data,
  } = props;
  const { t } = useTranslation("common");

  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [added, setAdded] = useState(false);

  function handleSubmit(paymentMethodId) {
    let values = {accountId: accountId, paymentMethodId: paymentMethodId};
    setSubmitting(true);

    props.customerAddPaymentMethod(values)
      .then((response) => {
        if (response.data.customerAddPaymentMethod.errors.length <= 0) {
          setAdded(true);
          onCardAdded();
        } else {
          let better_errors = {_error: t("credit_card.add_failure")}
          setErrors(better_errors);
        }
      })
      .catch((_err) => {
        let better_errors = {_error: t("credit_card.add_failure")}
        setErrors(better_errors);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  // When the card is added, we stop displaying the form
  if (added) {
    return (
      <>
        <div className={styles.cardAdded}><LoadingCircle /></div>
        {props.children}
      </>
    );
  }

  return (
    <StripeElements useLoadingLine={true}
      shopId={data.customerBillingShop.id}
      clientSecret={clientSecret}>
      <Form
        clientSecret={clientSecret}
        shopName={data.customerBillingShop.name}
        submitting={submitting}
        errors={errors}
        validate={validateForm}
        onSubmit={handleSubmit}
        textClass="" // Prevent default
        buttonsClass={styles.buttons} // Custom css for this case
        />
    </StripeElements>
  );
}

const ADD_PAYMENT_METHOD = gql`
  mutation customerAddPaymentMethod($data: PaymentMethodIdInput!) {
    customerAddPaymentMethod(input: $data) {
      errors { key message }
    }
  }
`;

const QUERY = gql`
  query customerBillingShop($accountId: ID!) {
    customerBillingShop(accountId: $accountId) {
      id
      name
    }
  }
`;


const withQueries = compose(
  graphql(QUERY, {
    options: (props) => ({
      variables: {
        accountId: props.accountId
      },
      fetchPolicy: "network-only"
    })
  }),
  graphql(ADD_PAYMENT_METHOD, {
    props: ({ mutate }) => ({
      customerAddPaymentMethod: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default withQueries(SetupCreditCardFormContainer);

