import React from "react";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import gql from "graphql-tag";
import GraphQLErrors from "../../shared/GraphQLErrors";
import AuthenticateStripePayment from "./AuthenticateStripePayment";
import {requestShowOrderStatus} from "../../actions/ShopActions";
import { StripeElements } from "../views/Checkout/StripeElements";
import LoadingLine from "../../shared/components/forms/LoadingLine";


function AuthenticatePayment(props) {
  function handleSuccess() {
    props.requestShowOrderStatus(props.accountId, props.orderId, "verifying");
  }

  function handleError(msg) {
    props.requestShowOrderStatus(props.accountId, props.orderId, "error", msg);    
  }

  const { data } = props;
  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  return (
    <StripeElements useLoadingLine={true}
      shopId={data.customerBillingPaymentIntentInfo.shopId}
      noClientSecret={true}>
      <AuthenticateStripePayment
        clientSecret={data.customerBillingPaymentIntentInfo.clientSecret}
        onSuccess={handleSuccess}
        onError={handleError}
        />
    </StripeElements>
  );
}

const QUERY = gql`
  query customerBillingPaymentIntentInfo($accountId:ID!, $salesOrderId:ID!) {
    customerBillingPaymentIntentInfo(accountId: $accountId, salesOrderId: $salesOrderId) {
      clientSecret
      shopId
    }
  }
`;

const withQueries = graphql(QUERY, {
  options: (props) => ({
    fetchPolicy: "network-only",
    variables: {
      salesOrderId: props.orderId,
      accountId: props.accountId
    }    
  })
});

export default connect(null, {requestShowOrderStatus})(withQueries(AuthenticatePayment));