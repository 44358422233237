import React from "react"
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import FooterActions from "../../shared/FooterActions";
import Button from "../../shared/components/Button";
import Checked from "../../shop/shared/icons/Checked";
import { useTranslation } from "react-i18next";

const AddedToWaitingList = (props) => {
  const { t } = useTranslation("auth", { keyPrefix: "waiting_list" });
  const iconSize = "80px";

  return (
    <div className="AddedToWaitingList view successView">
      <div className="inner">
        <Checked side={iconSize} className="centralIcon" colorDisabled="var(--white)" />

        <h4 className="sg-text-large u-bottom-margin-short">{t("added_form.title")}</h4>
        <p className="sg-text-small">{t("added_form.subtitle")}</p>

        <div>
          <p className="u-no-margin">{t("want_create_account")}</p>
          <Button
            label={t("create_account")}
            href="/signup" link={true}
            theme={Button.THEMES.WHITE}
            onClick={props.onSignUp}
            className="sg-font-bold sg-text-normal"
          />
        </div>
        
        <div className={"u-centered sg-font-bold"}>
          <FooterActions
            showSupport={props.showSupport} 
            darkBackground={true}
          />
        </div>
      </div>
    </div>
  )

}

export default connect(null, {showSupport})(AddedToWaitingList);