import {useEffect} from "react";
import { recordViewItem } from "../../lib/Analytics";
import { generateViewItemSummary } from "../../lib/StatsSummary";
import { fromJS } from "immutable";

// Just a helper component to record a view item event.
export default function ProductRecordView({product, prices, shopId}) {
  
  // We will get data from Prismic and prices from the Public API.
  // We will need to merge both data sources.

  // Product data (only relevant fields).
  // {  
  //   long_title: "Hallstein Water 8-Bottle Case"  
  //   sku: "WAT-HAL-STL-8PC-750"
  // }

  // Pricing
  // {
  //   currency: "EUR",
  //   price: 62,
  //   subscriptionPrice: 62,
  //   surcharge: undefined
  // }

  useEffect(() => {
    if (product) {
      let summary = generateViewItemSummary(product.get("sku"), product.get("long_title"), fromJS(prices), shopId);
      recordViewItem(summary.toJS());
    }
  }, []);

  return null;
}
