import {createSubmitRequestTypes} from "./helpers";

export const REQUEST_PROFILE = "Auth:REQUEST_PROFILE";
export const requestProfile = () => ({ type: REQUEST_PROFILE});

export const REQUEST_EDIT_PROFILE = "Auth:REQUEST_EDIT_PROFILE";
export const EDIT_PROFILE = createSubmitRequestTypes("Auth:EDIT_PROFILE");
export const requestEditProfile = () => ({ type: REQUEST_EDIT_PROFILE});
export const cancelEditProfile = () => ({ type: EDIT_PROFILE.CANCEL });
export const successEditProfile = (name) => ({ type: EDIT_PROFILE.SUCCESS, name: name });

export const REQUEST_CHANGE_PASSWORD = "Auth:REQUEST_CHANGE_PASSWORD";
export const CHANGE_PASSWORD = createSubmitRequestTypes("Auth:CHANGE_PASSWORD");
export const requestChangePassword = () => ({ type: REQUEST_CHANGE_PASSWORD});
export const cancelChangePassword = () => ({ type: CHANGE_PASSWORD.CANCEL });
export const successChangePassword = (msg) => ({ type: CHANGE_PASSWORD.SUCCESS, msg });

export const REQUEST_CHANGE_LOCALE = "Auth:REQUEST_CHANGE_LOCALE";
export const CHANGE_LOCALE = createSubmitRequestTypes("Auth:CHANGE_LOCALE");
export const requestChangeLocale = () => ({ type: REQUEST_CHANGE_LOCALE});
export const cancelChangeLocale = () => ({ type: CHANGE_LOCALE.CANCEL });
export const successChangeLocale = (msg) => ({ type: CHANGE_LOCALE.SUCCESS, msg });