import React, {useState} from "react";
import {connect} from "react-redux";
import {cancelChangeLocale, successChangeLocale} from "../../actions/LoginActions";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import GraphQLErrors from "../../shared/GraphQLErrors";
import Form from "./ChangeLocaleForm";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { fromJS } from "immutable";
import { createErrors, createValidationErrors } from "../../lib/ErrorFormatter";
import { localeNameFromCode, SUPPORTED_LOCALES, switchLocale } from "../../lib/Locales";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const ChangeLocaleFormContainer = (props) => {
  const [errors, setErrors] = useState([]);
  const { i18n } = useTranslation();
  const { pathname, search, state } = useLocation();

  const handleSubmit = (values) => {
    const locale = values.get("locale");
    if (!locale) {
      return;
    }
  
    return props.customerChangeLocale(values.toJS())
      .then((response) => {
        if (response.data.customerChangeLocale.errors.length <= 0) {
          switchLocale(pathname + search, state, i18n, locale);
          props.successChangeLocale();
        } else {
          let errors = createValidationErrors(response.data.customerChangeLocale.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });
  }
  const handleCancel = props.cancelChangeLocale;

  const {data} = props;
  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  const locales = fromJS(SUPPORTED_LOCALES.map((l) => ({
    code: l,
    name: localeNameFromCode(l)
  })))

  return (
    <Form
      locale={data.customerProfile.locale}
      locales={locales}
      errors={errors}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      />    
  )
}


const PROFILE = gql`
  query customerProfile {
    customerProfile {
      locale
    }
  }
`;

const CHANGE_LOCALE = gql`
  mutation customerChangeLocale($data: CustomerChangeLocaleInput!) {
    customerChangeLocale(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(PROFILE, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(CHANGE_LOCALE, {
    props: ({ mutate }) => ({
      customerChangeLocale: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

export default connect(null, {cancelChangeLocale, successChangeLocale})(withQueries(ChangeLocaleFormContainer));