import React, { useState } from "react";
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import Form from "./ConfirmForm";
import {cancelRemoveProductFromCart} from "../../actions/ShopActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import { useTranslation } from 'react-i18next';


const RemoveProductFromCartFormContainer = (props) => {
  const { t } = useTranslation("cart");
  const [errors, setErrors] = useState([]);

  const handleSubmit = () => {
    return props.customerRemoveProductFromCart({accountId: props.accountId, productSku: props.productSku})
      .then((response) => {
        if (response.data.customerRemoveProductFromCart.errors.length <= 0) {
          props.cancelRemoveProductFromCart();
        } else {
          const errors = createValidationErrors(response.data.customerRemoveProductFromCart.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });          
  }

  const handleCancel = () => {
    props.cancelRemoveProductFromCart();
  }

  return (
    <Form
      errors={errors}
      message={t("remove_from_cart_warning")}
      onSubmit={handleSubmit.bind(this)}
      onCancel={handleCancel.bind(this)}
      />
  )
}  


const REMOVE_PRODUCT_FROM_CART = gql`
  mutation customerRemoveProductFromCart($data: CustomerAccountInput!) {
    customerRemoveProductFromCart(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = graphql(REMOVE_PRODUCT_FROM_CART, {
    props: ({ mutate }) => ({
      customerRemoveProductFromCart: (data) => mutate({
        variables: { data: data }
      })
    })
  });



export default connect(null, {cancelRemoveProductFromCart})(withQueries(RemoveProductFromCartFormContainer));

