import React from "react";
import EditSubPage from "./EditSubPage";

// Converts the route params into props
const EditSubPageWrapper = ({accountId, subscriptionId}) => {

  return <EditSubPage
    accountId={accountId}
    subscriptionId={subscriptionId}
  />
}

export default EditSubPageWrapper;