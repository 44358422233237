import React from "react";
import { RichText } from 'prismic-reactjs'
import {linkWithChangePage} from "./Utils";

export default function TextSlice({data, onChangePage}) {
  return (
    <div className="TextSlice">
      <RichText
        render={data.get("text_body").toJS()}
        serializeHyperlink={linkWithChangePage(onChangePage)}
        />
    </div>
  )
}