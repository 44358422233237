import React, { useState, setState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {connect} from "react-redux";
import gql from "graphql-tag";
import {cancelConfirmResumeSubscription, requestResumeSubscription} from "../../actions/AccountActions";
import {Map} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import { useTranslation } from "react-i18next";
import Button from "../../shared/components/Button";
import ConfirmDeliveryDate from "./ConfirmDeliveryDate";


function ConfirmResumeSubscriptionFormContainer(props) {
  const {
    desiredDeliveryDate,
  } = props;
  
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  const handleCancel = () => {
    props.cancelConfirmResumeSubscription();
  }

  const handleSubmit = () => {
    const updatedValues = Map({
      accountId: props.accountId,
      subscriptionId: props.subscriptionId,
      deliveryDate: desiredDeliveryDate, // Will be adjusted server side if necessary
    });

    return props.customerRestartSubscription(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerRestartSubscription.errors.length <= 0) {
          handleCancel();
        } else {
          let errors = createValidationErrors(response.data.customerRestartSubscription.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setState(createErrors(err));
      });
  }

  const handleTryAnotherDate = () => {
    // Switch back to date selection window
    handleCancel();
    props.requestResumeSubscription(props.accountId, props.subscriptionId);
  }

  return (
    <div className="form">
      <ConfirmDeliveryDate {...props} errors={errors}
        i18nPrefix="accounts:deliveries.resume.confirm."
        onTryAnotherDate={handleTryAnotherDate}
        desiredDeliveryDateStr={desiredDeliveryDate}
      />
      <div className="buttons">
        <Button
          onClick={handleSubmit}
          label={t("accounts:deliveries.resume.confirm.continue")} />
        <Button
          onClick={handleCancel}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true}
          label={t("accounts:deliveries.resume.confirm.cancel")} />
      </div>
    </div>
  )
}


const RESTART_SUBSCRIPTION = gql`
  mutation customerRestartSubscription($data: CustomerRestartSubscriptionInput!) {
    customerRestartSubscription(input: $data) {
      errors { key message }
    }
  }
`


const withQueries = compose(
  graphql(RESTART_SUBSCRIPTION, {
    props: ({ mutate }) => ({
      customerRestartSubscription: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default connect(null, {
  cancelConfirmResumeSubscription,
  requestResumeSubscription,
  })(withQueries(ConfirmResumeSubscriptionFormContainer));

