import React, {Component} from "react";
import OrderSummary from "./views/Checkout/OrderSummary";
import {clearCheckoutInfo, requestCancelCheckout, requestShowGiftingDiscrepancy, requestShowOrderStatus} from "../actions/ShopActions";
import {showNotification} from "../actions/NotificationActions";
import {showSupport} from "../actions/SupportActions";
import {connect} from "react-redux";
import SelectShippingAddress from "./views/Checkout/SelectShippingAddress";
import SelectPaymentMethod from "./views/Checkout/SelectPaymentMethod";
import SelectBillingAddress from "./views/Checkout/SelectBillingAddress";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../lib/SubscriptionQueryWrapper";
import {Map, fromJS} from "immutable";
import Button from "../shared/components/Button";
import PromoCode from "./shared/PromoCode";
import PromoCodeFormContainer from "./forms/PromoCodeFormContainer";
import Conditional from "../shared/Conditional";
import Navigation, {getShopOriginFromLocationState} from "../lib/Navigation";
import InsufficientStockPage from "./InsufficientStockPage";
import CheckoutExpiredPage from "./CheckoutExpiredPage";
import CheckoutFailedPage from "./CheckoutFailedPage";
import {generateUUID} from "../lib/UUID";
import {recordPurchaseCompleted} from "../lib/Analytics";
import {generatePurchaseSummary} from "../lib/StatsSummary";
import OrderVersionMonitor from "./OrderVersionMonitor";
import styles from "./CheckoutPage.module.scss";
import { createErrors, createValidationErrors } from "../lib/ErrorFormatter";
import ShopLayout from "./ShopLayout";
import { withTranslation, Trans } from 'react-i18next';
import withCmsProductTitles from "../hocs/withCmsProductTitles";
import { FastCheckoutButtons } from "./views/Checkout/FastCheckoutButtons";
import { StripeElements } from "./views/Checkout/StripeElements";
import { extractAddressesFromExpressCheckoutData } from "../lib/StripeHelper";
import { requestConfirmShippingAddress } from "../actions/AddressBookActions";
import { validateBillingAddress, validateShippingAddress } from "../lib/AddressValidation";


// The account holder is allowed to send gifts to himself, we just display a
// warning in such cases.
function isGiftingEmailValid(checkoutData) {
  let email = checkoutData.getIn(["shippingAddress", "email"], "");
  return email !== "";
}
function isEmailSameAsAccountHolders(checkoutData, accountHolder) {
  let email = checkoutData.getIn(["shippingAddress", "email"], "");
  return email === accountHolder.get("email");
}

class CheckoutPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      paid: false,
      expired: false,
      failed: false,
      invoiceNr: "",
      status: "waiting",
      usedFastCheckout: false,
      retrievedInfoFromStripe: { address: { shippingAddress: Map(), billingAddress: Map() }},
      errorMsg: "",
      lastAttempt: 0,
      showConfirmation: false,
      giftingExpanded: false,
      firstLoad: true,
      // must store the orderId in state as we use requestCancelCheckout in componentWillUnmount
      orderId: props.orderId,
      // GIFTING: moved state here in order to make sure that the user didn't
      // forget to save his gifting message before placing the order.
      isGiftChecked: false,
      giftMessage: "", // as written right now in the gifting message form
      savedGiftMessage: "", // as saved through the customerMarkAsGift command
    }
    this.timeoutId = window.setTimeout(() => this.handleCheckTimeout(), 5000);
    this.handleReload = this.handleReload.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
  }

  handleCheckTimeout() {
    window.clearTimeout(this.timeoutId);
    // We use this function to refetch data in case there was some mis-timing with subscriptiona and data retrieval.
    if (this.props.data && typeof this.props.data.customerCheckoutInfo === 'undefined') {
      this.props.data.refetch();
    }
  }

  componentDidMount() {
    this.unsubscribeToBillingUpdates = this.props.subscribeToBillingUpdates(this.props, {});
    this.unsubscribeToAddressBookChanges = this.props.subscribeToAddressBookChanges(this.props, this.props.accountId);
  }

  componentWillUnmount() {
    this.unsubscribeToBillingUpdates && this.unsubscribeToBillingUpdates();
    this.unsubscribeToAddressBookChanges && this.unsubscribeToAddressBookChanges();

    if (!this.state.paid && !this.state.expired && !this.state.failed) {
      // Must use the orderId stored in state as the router params have already changed at this point.
      this.props.requestCancelCheckout(this.props.accountId, this.state.orderId, false);
    }
    this.props.clearCheckoutInfo();
    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      switch(this.state.status) {
        case "started":
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "submitting");
          this.makePayment();
          break;
        case "paid":
        case "billed":
          this.trackPurchaseCompleted();
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "success", "", this.state.invoiceNr);
          break;
        case "failed":
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "error", this.state.errorMsg);
          break;
        case "authenticate":
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "authenticate");
          break;          
        default:
          // do nothing
      }
    }
    if (!this.props.data.loading && this.props.data && this.props.data.customerCheckoutInfo) {
      // When refreshing the page, make sure the client state is synced to checkout data

      if (this.state.firstLoad) {
        if (this.props.data.customerCheckoutInfo.gift && this.state.isGiftChecked === false) {
          this.setState({ firstLoad: false, isGiftChecked: true, savedGiftMessage: this.props.data.customerCheckoutInfo.giftMessage });
        } else {
          this.setState({ firstLoad: false });
        }
      }

      if (prevProps.data.customerCheckoutInfo && this.props.data.customerCheckoutInfo.total !== prevProps.data.customerCheckoutInfo.total) {
        this.notifyTotalChange();
      }
      // Ok billing status changed.
      if (this.props.data.customerCheckoutInfo.expired && !this.state.expired) {
        this.setState({expired: true});
      } else if (this.props.data.customerCheckoutInfo.status === "checkout_failed" && !this.state.failed) {
        this.setState({failed: true});
      } else if (this.props.data.customerCheckoutInfo.billingStatus === "authentication_required" && this.state.status !== "authenticate") {
        this.setState({status: "authenticate"})
      } else if (this.state.lastAttempt !== this.props.data.customerCheckoutInfo.billingAttempt) {
        switch(this.props.data.customerCheckoutInfo.billingStatus) {
          case "paid":
          case "billed":
            this.setState({status: "paid", paid: true, invoiceNr: this.props.data.customerCheckoutInfo.invoiceNr, lastAttempt: this.props.data.customerCheckoutInfo.billingAttempt});
            break;
          case "failed":
            this.setState({status: "failed", errorMsg: this.props.data.customerCheckoutInfo.billingError, lastAttempt: this.props.data.customerCheckoutInfo.billingAttempt});
            break;
          default:
            // do nothing.
        }
      }
    }
  }

  trackPurchaseCompleted() {
    let checkoutData = fromJS(this.props.data.customerCheckoutInfo);
    // We need to update the productTitles with the longTitle from the cmsDataProductTitles.
    let productTitles = this.props.cmsDataProductTitles;
    let updatedProducts = checkoutData.get("products").map((product) => {
      return product.set("productTitle", productTitles.getIn([product.get("productSku"), "longTitle"], product.get("productTitle")));
    });
    
    let summary = generatePurchaseSummary(
      checkoutData.set("products", updatedProducts), 
      this.state.invoiceNr, 
      getShopOriginFromLocationState(this.props.location.state));

    recordPurchaseCompleted(summary.toJS());
  }

  notifyTotalChange(newTotal) {
    this.props.showNotification(generateUUID(), this.props.t("notifications.total_changed"));
  }

  handleCancelCheckout() {
    Navigation.gotoShop(this.props.accountId);
  }

  handlePlaceOrder(checkoutData) {
    // Before starting the payment, we need to make sure the gifting is good:
    // Is the checkout data up to date with the current gifting state?
    const checkoutIsGift = checkoutData.get("gift");
    const checkoutGiftMessage = checkoutData.get("giftMessage");

    if (this.state.isGiftChecked) {
      const savedMessageNotSynced = checkoutGiftMessage !== this.state.savedGiftMessage;
      const savedMessageDifferentCurrent = this.state.giftMessage !== this.state.savedGiftMessage;

      if (!checkoutIsGift || savedMessageNotSynced || savedMessageDifferentCurrent) {
        return this.props.requestShowGiftingDiscrepancy(this.state.giftMessage, this.state.savedGiftMessage);
      }
    } else {
      if (checkoutIsGift || checkoutGiftMessage) {
        // checkout data wasn't updated yet!
        return this.props.requestShowGiftingDiscrepancy(this.state.giftMessage, this.state.savedGiftMessage);
      }
    }
    
    this.handleStartPayment();
  }

  handleStartPayment() {
    this.setState({status: "started"});
  }

  makePayment() {
    let values = {
      orderId: this.props.orderId
    }
    this.props.customerPlaceOrder(values)
      .then((response) => {
        if (response.data.customerPlaceOrder.errors.length <= 0) {
          this.setState({status: "submitted"});
        } else {
          console.warn("ORDER ERROR", response.data.customerPlaceOrder.errors);
          this.setState({status: "failed", errorMsg: "unknown"});
        }
      })
      .catch((err) => {
        console.warn("ORDER ERROR!!", err);
        this.setState({status: "failed", errorMsg: "unknown"})
      }); 
  }

  isGifting(data) {
    // NB: isGiftChecked is only client side info, so if the user never clicked
    // the save the message, the checkout data won't be able to know.
    return data.get("gift") === true || this.state.isGiftChecked;
  }

  render() {
    let {
      loading, error, customerCheckoutInfo, customerShippingAddresses, customerBillingSetupPaymentCredentials,
      customerDefaultPaymentMethod, customerBillingAccount, customerAccountHolder
    } = this.props.data;
    let {cmsDataProductTitles, cmsDataProductTitlesLoading} = this.props;
    // If there is an error we just keep showing the Loading, because 99% of the time it will be because the
    // order has not yet fully populated.
    if (loading || cmsDataProductTitlesLoading) {
      return (
        <ShopLayout showHeader={false} showFooter={false} loading={true} />
      );
    }
    // Note, we ignore errors on cmsDataProductTitles, because we can still show the page without it.
    if (error) {
      // const lambdaNoInfo = (e) => e.path && e.path[0] === "customerCheckoutInfo" && e.message === "not_found";
      // if (error.graphQLErrors && error.graphQLErrors.find(lambdaNoInfo)) {
      //   // Wrong or expired checkout info: gotoRoot will handle the rest
      //   Navigation.gotoRoot();
      //   return null;
      // }
      // TODO: better handle cases other than "not_found" checkout?
      return (
        <ShopLayout showHeader={false} showFooter={false} loading={true} />
      );
    }

    let checkoutData = fromJS(customerCheckoutInfo);
    let setupPaymentCredentials = fromJS(customerBillingSetupPaymentCredentials);
    let shippingAddress = checkoutData.get("shippingAddress");
    let usedShippingAddresses = fromJS(customerShippingAddresses);
    let billingAccount = fromJS(customerBillingAccount);
    let accountHolder = fromJS(customerAccountHolder);

    // error and keep state for shipping is bad
    if (checkoutData.get("status") === "checkout_failed_insufficient_stock") {
      return <InsufficientStockPage 
              orderId={this.props.orderId}
              accountId={checkoutData.get("accountId")} />
    }
    if (checkoutData.get("status") === "checkout_failed") {
      return <CheckoutFailedPage 
              accountId={checkoutData.get("accountId")} />
    }

    if (checkoutData.get("expired") === true) {
      return <CheckoutExpiredPage
              accountId={checkoutData.get("accountId")} />
    }
    const defaultPaymentMethod = fromJS(customerDefaultPaymentMethod);
    const paymentTerms = billingAccount.get("paymentMethod") === "payment_terms";
    const paymentIsReady = !!(defaultPaymentMethod || paymentTerms)

    let giftingValid = true;
    const giftingEmailValid = isGiftingEmailValid(checkoutData);
    if (this.isGifting(checkoutData) && !giftingEmailValid) {
      giftingValid = false;
    }

    const stripeClientSecret = setupPaymentCredentials.get("clientSecret");

    // usedShippingAddresses is really the address book, so if the user deletes
    // all addresses from there, even if he already selected one for the
    // checkout, we shouldn't let him checkout as the selected one is not
    // visible anymore.
    const canPay = checkoutData.get("canPay") === true // System has everything needed
      && paymentIsReady // User has selected a payment method
      && !this.state.submitting
      && giftingValid // No discrepency between local text and text saved in system
      && usedShippingAddresses.size > 0; // user didn't delete the selected address

    const that = this
    function handleDataRetrieval(stripeInfos) {
      const {shippingAddress, billingAddress, cardBrand} = extractAddressesFromExpressCheckoutData(stripeInfos, accountHolder, )

      that.setState({retrievedInfoFromStripe: {
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        cardBrand: cardBrand,
      }});

      // Show up a popup if the address seems valid to confirm its usage
      if (validateShippingAddress(shippingAddress).size === 0) {
        that.props.requestConfirmShippingAddress(checkoutData.get("accountId"), cardBrand, shippingAddress)
      }

      // Express checkout doesn't trigger the stripe payment method's attach
      // callback, so we should register the billing address here too.
      if (validateBillingAddress(billingAddress).size === 0) {
        // Ignore response as if there are errors the user will have to fix
        // them in the billing address inline form
        that.props.customerChangeBillingAddress(billingAddress.set("accountId", checkoutData.get("accountId")))
      }
    }

    return (
      <ShopLayout className={styles.module}
        showHeader={false} showFooter={false} loading={loading}
        noWhiteSpaceAround={true}>
        <OrderVersionMonitor 
          orderId={checkoutData.get("orderId")}
          onChange={this.handleVersionChange} 
          version={checkoutData.get("version", 0)}
          />
        <div className={styles.inner}>
          <div className={styles.content}>
            <div className={styles.sections}>
              <div className={styles.sectionsInner}>
                <div>
                  <h1>{this.props.t("title")}</h1>
                  <p>{this.props.t("fill_sections")}</p>
                </div>
                <Conditional show={!!stripeClientSecret && !paymentIsReady}>
                  <StripeElements
                    shopId={checkoutData.get("shopId")}
                    clientSecret={stripeClientSecret}>
                    <FastCheckoutButtons
                      accountId={checkoutData.get("accountId")}
                      clientSecret={stripeClientSecret}
                      onUse={() => this.setState({usedFastCheckout: true})}
                      onDataRetrieval={handleDataRetrieval}
                    />
                  </StripeElements>
                </Conditional>

                <SelectShippingAddress
                  accountId={checkoutData.get("accountId")}
                  orderId={checkoutData.get("orderId")}
                  status={checkoutData.get("shippingAddressStatus")}
                  shippingAddress={shippingAddress}
                  prefilledAddress={this.state.retrievedInfoFromStripe.billingAddress}
                  usedShippingAddresses={usedShippingAddresses}
                  canChangeAddress={checkoutData.get("canChangeAddress")}
                  accountHolderEmail={accountHolder.get("email")}
                  displaySameEmailWarning={isEmailSameAsAccountHolders(checkoutData, accountHolder)}
                  isGiftChecked={this.state.isGiftChecked}
                  setIsGiftChecked={(isGiftChecked) => this.setState({ isGiftChecked: isGiftChecked })}
                  giftMessage={this.state.giftMessage}
                  setGiftMessage={(message) => this.setState({ giftMessage: message })}
                  setSavedGiftMessage={(message) => this.setState({ savedGiftMessage: message })}
                  onForceRefreshAddresses={() => this.handleForceRefresh()}
                  />
                <SelectBillingAddress
                  accountId={checkoutData.get("accountId")}
                  billingAddress={checkoutData.get("billingAddress")}
                  prefilledAddress={this.state.retrievedInfoFromStripe.billingAddress}
                  billingAddressStatus={checkoutData.get("billingDetailsStatus")}
                  shippingAddressStatus={checkoutData.get("shippingAddressStatus")}
                  shippingAddress={shippingAddress}
                  billingTaxId={checkoutData.get("billingTaxId")}
                  billingValidTaxId={checkoutData.get("billingValidTaxId")}
                  billingTaxExempt={checkoutData.get("taxExempt")}
                  active={checkoutData.get("shippingAddressStatus") === "provided" && (defaultPaymentMethod || paymentTerms)}
                  />
                <SelectPaymentMethod
                  accountId={checkoutData.get("accountId")}
                  orderId={checkoutData.get("orderId")}
                  clientSecret={stripeClientSecret}
                  paymentMethod={billingAccount.get("paymentMethod")}
                  paymentTermsDays={billingAccount.get("paymentTermsDays")}
                  defaultPaymentMethod={defaultPaymentMethod}
                  active={checkoutData.get("shippingAddressStatus") === "provided"}
                  handleDeleteCard={(id) => this.handleDeleteCardId(id)}
                  onForceRefreshPaymentMethods={() => this.handleForceRefresh()}
                  />
              </div>
            </div>
            <div className={styles.summary}>
              <div className={styles.fixedDesktop}>
                <img
                  className={styles.logo}
                  src="/assets/LogoSimple-Black.svg"
                  alt="Hallstein - Artesian Water"
                  width={159}
                  height={66}
                />
                <OrderSummary
                  data={checkoutData} 
                  productTitles={cmsDataProductTitles}
                  />
                <div className={styles.divider} />
                <Conditional show={checkoutData.get("promoCodeApplied") === true && checkoutData.get("discount") === "--"}>
                  <p>{this.props.t("summary.promo_code.discount_reminder")}</p>
                </Conditional>
                <div className={styles.PromoComponents}>
                  <PromoCodeFormContainer
                    orderId={checkoutData.get("orderId")}
                    accountId={checkoutData.get("accountId")}
                    />

                  <PromoCode
                    active={checkoutData.get("promoCodeApplied") === true}
                    code={checkoutData.get("promoCode")}
                    />
                </div>
                <div className={styles.divider} />
                { !canPay && <div className={styles.notice}>{this.props.t("summary.fill_all_reminder")}</div> }
                <div className={styles.buttons}>
                  <Button label={this.props.t("summary.ok_label")}
                    size={Button.SIZES.MEDIUM}
                    onClick={() => this.handlePlaceOrder(checkoutData)} active={canPay} />
                  <Button label={this.props.t("summary.cancel_label")}
                    onClick={this.handleCancelCheckout.bind(this)}
                    theme={Button.THEMES.WHITE} />
                </div>
                <div className={styles.more}>
                  <p className={styles.stripeElement}>
                    <span className={styles.text}>{this.props.t("summary.safe_and_secure")}</span>
                    <span className={styles.icon} />
                  </p>
                  <p>
                    <Trans
                      i18nKey="checkout:summary.having_problems"
                      components={{
                        a: <Button link={true} onClick={this.handleReload} />
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShopLayout>
    );
  }

  handleReload() {
    window.location.reload();
  }

  handleVersionChange(version) {
    this.props.data.refetch();
  }

  handleForceRefresh() {
    // If the user clicked the link to force refresh, he could be missing more
    // than addresses in his address book. Let's try refetching it all.
    if (this.props.data) {
      this.props.data.refetch();
    }
  }

  handleDeleteCardId(id) {
    return this.props.customerRemovePaymentMethod({accountId: this.props.accountId, paymentMethodId: id})
      .then((response) => {
        if (response.data.customerRemovePaymentMethod.errors.length <= 0) {
          // this.props.cancelChangeCreditCard();
          // ^ we don't want to close the window after a card gets deleted..
        } else {
          let errors = createValidationErrors(response.data.customerRemovePaymentMethod.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });
  }
}


const CHECKOUT_INFO = gql`
  query customerCheckoutInfo($accountId: ID!, $orderId: ID!) {
    customerCheckoutInfo(accountId: $accountId, orderId: $orderId) {
      version
      orderId
      accountId
      shopId
      currency
      shippingAddress {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
      invoiceNr
      billingStatus
      billingError
      billingAttempt
      billingDetailsStatus
      billingAddress {
        name
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
      billingTaxId
      billingValidTaxId
      billingTaxExempt
      shippingAddressStatus
      subtotalProducts
      subtotalBeforeTaxes
      subtotalTaxes
      subscriptionFrequencyWeeks
      total
      canPay
      canChangeAddress
      isPreparing
      expired
      status
      discount
      promoCode
      promoCodeApplied      
      gift
      giftMessage
      products {
        productSku,
        productTitle,
        quantity,
        subscription,
        unitPrice,
        unitDiscount,
        total,
        includesSurcharge,
        currency
      }
    }
    customerBillingSetupPaymentCredentials(accountId: $accountId) {
      clientSecret
    }
    customerShippingAddresses(accountId: $accountId) {
      addressId 
      address {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
    }    
    customerDefaultPaymentMethod(accountId:$accountId) {
      paymentMethodId
      type
      cardBrand
      cardExpYear
      cardExpMonth
      cardLast4
      default
      addedAt
    }
    customerBillingAccount(accountId:$accountId) {
      paymentMethod
      paymentTermsDays
    }
    customerAccountHolder(accountId:$accountId) {
      email
      name
      phone
    }
  }
`;

const REMOVE_CARD = gql`
  mutation customerRemovePaymentMethod($data: CustomerRemovePaymentMethodInput!) {
    customerRemovePaymentMethod(input: $data) {
      errors { key message }
    }
  }
`

const ON_UPDATED_BILLING = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedBilling(accountId: $accountId) {
    accountId
  }`
);

// We use an address book for shipping addresses so we need to be able to
// refresh that list when we add or edit any.
const ON_UPDATED_ADDRESS_BOOK = SubscriptionQueryWrapper("$accountId: ID!", `
customerUpdatedShippingAddresses(accountId: $accountId) {
    accountId
  }`
);

const CHANGE_BILLING_ADDRESS = gql`
  mutation customerChangeBillingAddress($data: BillingAddressInput!) {
    customerChangeBillingAddress(input: $data) {
      errors { key message }
    }
  }
`;

const PLACE_ORDER = gql`
  mutation customerPlaceOrder($data: PlaceOrderInput!) {
    customerPlaceOrder(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CHECKOUT_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId,
        orderId: props.orderId
      }
    })
  }),
  graphql(REMOVE_CARD, {
    props: ({ mutate }) => ({
      customerRemovePaymentMethod: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(PLACE_ORDER, {
    props: ({mutate}) => ({
      customerPlaceOrder: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(CHANGE_BILLING_ADDRESS, {
    props: ({mutate}) => ({
      customerChangeBillingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
)

const mapDispatchToProps = dispatch => ({
  subscribeToBillingUpdates: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_BILLING,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  subscribeToAddressBookChanges: (props, accountId) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ADDRESS_BOOK,
      variables: {
        accountId: accountId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  clearCheckoutInfo: clearCheckoutInfo,
  requestConfirmShippingAddress: (accountId, walletType, address) => {
    dispatch(requestConfirmShippingAddress(accountId, walletType, address));
  },
  requestShowOrderStatus: (accountId, orderId, status, errorMsg, invoiceNr) => {
    dispatch(requestShowOrderStatus(accountId, orderId, status, errorMsg, invoiceNr));
  },
  requestCancelCheckout: (accountId, orderId) => {
    dispatch(requestCancelCheckout(accountId, orderId));
  },
  requestShowGiftingDiscrepancy: (message, savedMessage) => {
    dispatch(requestShowGiftingDiscrepancy(message, savedMessage));
  },
  showNotification: (id, message, kind) => {
    dispatch(showNotification(id, message, kind))
  },
  showSupport: (page) => {
    dispatch(showSupport(page))
  }
})

const mapStateToProps = (state, ownProps) => {
  return {
    accountId: ownProps.accountId
  }
}

export default withTranslation("checkout")(connect(mapStateToProps, mapDispatchToProps)(withQueries(withCmsProductTitles(CheckoutPage))));