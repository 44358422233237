import React from "react";
import Icon from "./Icon";

// Icons/Core/emotion-unhappy-line
function Unhappy(props) {
  const svgPathDParameter = "M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M7,17 C7,14.2385763 9.23857625,12 12,12 C14.7614237,12 17,14.2385763 17,17 L15,17 C15,15.3431458 13.6568542,14 12,14 C10.3431458,14 9,15.3431458 9,17 L7,17 Z M8,11 C7.17157288,11 6.5,10.3284271 6.5,9.5 C6.5,8.67157288 7.17157288,8 8,8 C8.82842712,8 9.5,8.67157288 9.5,9.5 C9.5,10.3284271 8.82842712,11 8,11 Z M16,11 C15.1715729,11 14.5,10.3284271 14.5,9.5 C14.5,8.67157288 15.1715729,8 16,8 C16.8284271,8 17.5,8.67157288 17.5,9.5 C17.5,10.3284271 16.8284271,11 16,11 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default Unhappy;
