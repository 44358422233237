import React from "react";
import PaymentError from "../accounts/PaymentError";
import styles from "./BillingIssues.module.scss";

const BillingIssues = (props) => {
  if (props.issues.size === 0) return null;
  
  let issues = props.issues.map((issue) => (
    <PaymentError 
      title="There is a billing problem with your subscription"
      showDetailsLink={false}
      key={issue.get("salesOrderId")}
      accountId={issue.get("accountId")}
      salesOrderId={issue.get("salesOrderId")}
      willRetry={issue.get("willRetry")}
      declineCode={issue.get("declineCode")}
      errorCode={issue.get("errorCode")} 
      errorMessage={issue.get("description")}
      displayErrorIcon={true}
      />
  ))

  return (
    <div className={styles.module}>
      <div className={styles.inner}>
        {issues}
      </div>
    </div>
  )
}

export default BillingIssues;