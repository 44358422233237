import React, {Component} from "react";
import {connect} from "react-redux";
import {cancelChangePassword, successChangePassword} from "../../actions/LoginActions";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import GraphQLErrors from "../../shared/GraphQLErrors";
import Form from "./ChangePasswordForm";
import Auth from "../../Auth";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { withTranslation } from "react-i18next";

class ChangePasswordFormContainer extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    return new Auth().changePassword(this.props.data.customerProfile.email)
      .then((response) => {
        this.props.successChangePassword(response);
      })
      .catch(err => {
        this.setState({ errors: {_error: this.props.t("error_try_again")}});
      });
  }

  handleCancel() {
    this.props.cancelChangePassword();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />    
    return (
      <Form
        email={data.customerProfile.email}
        errors={this.state.errors}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />    
    )
  }
}

const PROFILE = gql`
  query customerProfile {
    customerProfile {
      email
    }
  }
`;

const withQueries = compose(
  graphql(PROFILE, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  })
);

export default withTranslation("common")(connect(null, {cancelChangePassword, successChangePassword})(withQueries(ChangePasswordFormContainer)));