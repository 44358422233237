import React, { useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelAdjustTransitionPreference} from "../../actions/AccountActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import AdjustTransitionPreferenceForm from "./AdjustTransitionPreferenceForm";


function AdjustTransitionPreferenceFormContainer(props) {
  const {
    accountId,
    subscriptionId,
    number8Cases,
    data,
  } = props;

  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  function handleCancel() {
    props.cancelAdjustTransitionPreference();
  }

  function handleSubmit(matchBottles) {
    if (matchBottles === null) return;

    const values = {
      accountId: accountId,
      subscriptionId: subscriptionId,
      matchBottles: matchBottles,
    }

    setSubmitting(true);

    return props.customerSetPackTransitionPreference(values)
      .then((response) => {
        if (response.data.customerSetPackTransitionPreference.errors.length <= 0) {
          handleCancel();
        } else {
          let errors = createValidationErrors(response.data.customerSetPackTransitionPreference.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      })
      .finally(() => setSubmitting(false));
  }

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  return (
    <AdjustTransitionPreferenceForm
      number8Cases={number8Cases}
      defaultMatchBottles={data.customerPackTransitionPreference.matchBottles}
      onSubmit={handleSubmit}
      submitting={submitting}
      errors={errors}
      onCancel={handleCancel}
    />
  );
}

const CUSTOMER_PACK_TRANSITION_PREFERENCE = gql`
  query customerPackTransitionPreference($accountId: ID!, $subscriptionId: ID!) {
    customerPackTransitionPreference(accountId: $accountId, subscriptionId: $subscriptionId) {
      matchBottles
    }
  }
`

const CUSTOMER_SET_PACK_TRANSITION_PREFERENCE = gql`
  mutation customerSetPackTransitionPreference($data: CustomerSetPackTransitionPreferenceInput!) {
    customerSetPackTransitionPreference(input: $data) {
      errors { key message}
    }
  }
`

const withQueries = compose(
  graphql(CUSTOMER_PACK_TRANSITION_PREFERENCE, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(CUSTOMER_SET_PACK_TRANSITION_PREFERENCE, {
    props: ({ mutate }) => ({
      customerSetPackTransitionPreference: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelAdjustTransitionPreference})(withQueries(AdjustTransitionPreferenceFormContainer));

