import { useTranslation } from "react-i18next";
import React from "react";
import Button from "./Button";
import Price from "./Price";
import styles from "./PriceAndButtonForOneTimeProduct.module.scss";

const PriceAndButtonForOneTimeProduct = (props) => {
  const { t } = useTranslation("products");
  const title = props[props.titleKey];
  let clickAction = () => props.onProductClick(props.sku, title, props.tags, props.status);

  return (
    <div className={styles.module}>
      <Price price={props.price} currency={props.currency}
        surcharge={props.surcharge} suffix={props.suffix} />
      <Button label={t("add_to_cart_ot_label")} onClick={clickAction}
        active={props.active} theme={props.buttonTheme} />
    </div>
  );
}

PriceAndButtonForOneTimeProduct.defaultProps = {
  titleKey: "title"
}

export default PriceAndButtonForOneTimeProduct;
