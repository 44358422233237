import React from "react";
import RadioSelectButton from "./RadioSelectButton";
import styles from "./BoxWithRadioSelect.module.scss";

function BoxWithRadioSelect(props) {
  const { className, enabled = true, selected,
    headerText, onSelect, children, displayTight,
  } = props;

  const classes = [
    styles.module,
    className,
    displayTight && styles.displayTight,
    !enabled && styles.disabled,
    selected && styles.uSelected,
    onSelect && enabled && styles.clickable,
    children && styles.hasChildren,
  ];
  
  return (
    <div className={classes.join(" ")}
      onClick={(e) => enabled && onSelect(e)}>
      <div className={styles.header}>
        <RadioSelectButton selected={selected}>
          { headerText }
        </RadioSelectButton>
      </div>
      { children }
    </div>
  );
}

export default BoxWithRadioSelect;
