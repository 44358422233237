import React, {useEffect} from "react";
import {retainSearchParams, applyParamsToPath} from "../lib/PermanentUrlParams";
import {getPermanentQueryParams} from "../config";
import useQueryMap from "../hooks/useQueryMap";
import {useHistory} from "react-router-dom";

export default function GoogleNewPage({auth}) {
  const query = useQueryMap();
  const history = useHistory();

  useEffect(() => {
    let keepParams = retainSearchParams(query, getPermanentQueryParams());
    if (auth.isAuthenticated()) {
      history.replace(applyParamsToPath("/client-section?flow=new", keepParams));
    } else {
      history.replace(applyParamsToPath("/signup?flow=new", keepParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="GoogleNewPage" />
  )

}