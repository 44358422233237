import React from "react";
import { cx } from "../../../shared/lib/ClassSet";
import {dateWithTimeFormat} from "../../../lib/DateFormatter";
import {formatStatus} from "../../../lib/DeliveryTools";
import deliveriesStyles from "./DeliveriesPanel.module.scss";
import DeliveryPackagesList from "./DeliveryPackagesList";
import { NOTIFICATION_KINDS, showNotification } from "../../../actions/NotificationActions";
import Copy from "../../shared/icons/Copy";
import { copyToClipboard } from "../../../lib/CopyToClipboard";
import { useDispatch } from "react-redux";
import { generateUUID } from "../../../lib/UUID";
import { DeliveryTag } from "./DeliveryTag";
import { useTranslation } from "react-i18next";
import styles from "./DeliveryItem.module.scss";


const DeliveryItem = (props) => {
  const { t: statusT } = useTranslation("accounts", { keyPrefix: "deliveries.status" });
  let {data} = props;
  
  let classes = cx({
    [deliveriesStyles.main]: true,
    "item": true,
    "shipping-delivery-list-item": true,
    [deliveriesStyles.uExpanded]: props.selected,
  })

  let contentClasses = cx({
    [styles.module]: true,
    [deliveriesStyles.content]: true,
    [deliveriesStyles.uExpanded]: props.selected,
    [styles.uTracking]: data.get("courierStarted") === true,
    [styles.uNoTracking]: data.get("courierStarted") === false,
    [styles.uComplimentary]: data.get("type") === "complimentary",
    [styles.uGift]: data.get("gift") === true
  })

  const date = dateWithTimeFormat(props.data.get("updatedAt"));

  const orderId = data.get("orderId", "n/a");

  const clickFn = (e) => props.onClick(orderId);


  return (
    <React.Fragment>
      <tr className={classes} onClick={clickFn}>
        <td className={"field " + deliveriesStyles.fStatus}>
          <div className={deliveriesStyles.expander}><div className={deliveriesStyles.icon} /></div>
          {formatStatus(data.get("fulfillmentStatus"), statusT)}
        </td>
        <td className={"field " + deliveriesStyles.fPackages}>{data.get("nrPackages")}</td>
        <td className={"field " + deliveriesStyles.fRecipient}>{formatAddress(data.get("deliveryAddress"))}</td>
        <td className={"field " + deliveriesStyles.fType}>
          <DeliveryTag data={data} />
        </td>
        <td className={"field " + deliveriesStyles.fDate}>{date}</td>
      </tr>
      <tr className={contentClasses}>
        <td colSpan={5}>
          <div className={deliveriesStyles.innerContent}>
            <Notices data={data} styles={styles} selected={props.selected} />
          </div>
        </td>
      </tr>
    </React.Fragment>
  )
}

export const Notices = (props) => {
  const { t } = useTranslation("accounts", { keyPrefix: "deliveries.notices" });
  const dispatch = useDispatch();
  const { data, styles } = props;

  const orderId = data.get("orderId");
  const refNumber = orderId && orderId.substr(0, 12);

  function handleCopySuccess() {
    dispatch(showNotification(generateUUID(), t("ref_number_copied"), NOTIFICATION_KINDS.SUCCESS));
  }
  function handleCopyFailure() {
    dispatch(showNotification(generateUUID(), t("ref_number_copy_failure"), NOTIFICATION_KINDS.ERROR));
  }
  const refNumberElem = refNumber && (
    <div className={styles.refNumber}>
      <span>{t("ref_number", { number: refNumber })}</span>
        <Copy side="13px" onClick={() => copyToClipboard(refNumber, handleCopySuccess, handleCopyFailure)} />
    </div>
  );

  return (
    <>
      <DeliveryPackagesList orderId={orderId} courierId={data.get("courierId")} packages={data.get("packages")} active={props.selected} />
      <div className={styles.complimentaryNotice}><span>{t("is_complimentary")}</span></div>
      <div className={styles.giftNotice}><span>{t("is_gift")}</span></div>
      <div className={styles.noTrackingNotice}><span>{t("no_tracking_info")}</span></div>
      { refNumberElem }
    </>
  );
}

function formatAddress(partialAddress) {
  return (
    <React.Fragment>
      <div className="sg-font-bold">{partialAddress.get("name")}</div>
      <div>{partialAddress.get("addressAddressLine1")}, {partialAddress.get("addressPostcode")} {partialAddress.get("addressCity")}</div>
    </React.Fragment>
  )
}

export default DeliveryItem;