import { noop } from "lodash";

export const copyToClipboard = (textToCopy, onSuccess = noop, onFailure = noop) => {

  if (!navigator.clipboard) {
    // use old execCommand way
    try {
      const transientElement = document.createElement('textarea');
      transientElement.value = textToCopy;
      document.body.appendChild(transientElement);
      transientElement.select();
      document.execCommand('copy');
      document.body.removeChild(transientElement);
      onSuccess();
    }
    catch (ex) {
      onFailure(ex);
    }
  } else {
    navigator.clipboard.writeText(textToCopy).then(onSuccess).catch(onFailure);
  }
};
