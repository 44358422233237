const Conditional = (props) => {

  if (props.show === true) return props.children;
  return null;

}

Conditional.defaultProps = {
  show: true
}

export default Conditional;