import Navigation from "./Navigation";

// Default locales and helpers
export const SUPPORTED_LOCALES = ["en", "de"];
export const DEFAULT_LOCALE = "en";

export function localeNameFromCode(code) {
  let name = null;

  switch (code) {
    case "en":
      name = "English"
      break;
    case "de":
      name = "Deutsch"
      break;
  
    default:
      break;
  }

  return name;
}

// Updates the URL and changes the locale
export function switchLocale(currentUrl, currentState, i18n, desiredLocale) {
  const urlWithoutLocale = currentUrl.substr(3)
  Navigation.goto(urlWithoutLocale, currentState, desiredLocale);
}