import {fromJS} from "immutable";
import countryNames from 'react-phone-number-input/locale/en.json';

const US_STATES = fromJS([
  {"name": "Alabama", "code": "AL"},
  {"name": "Alaska", "code": "AK"},
  {"name": "Arizona", "code": "AZ"},
  {"name": "Arkansas", "code": "AR"},
  {"name": "California", "code": "CA"},
  {"name": "Colorado", "code": "CO"},
  {"name": "Connecticut", "code": "CT"},
  {"name": "Delaware", "code": "DE"},
  {"name": "Washington D.C.", "code": "DC"},
  {"name": "Florida", "code": "FL"},
  {"name": "Georgia", "code": "GA"},
  {"name": "Hawaii", "code": "HI"},
  {"name": "Idaho", "code": "ID"},
  {"name": "Illinois", "code": "IL"},
  {"name": "Indiana", "code": "IN"},
  {"name": "Iowa", "code": "IA"},
  {"name": "Kansas", "code": "KS"},
  {"name": "Kentucky", "code": "KY"},
  {"name": "Louisiana", "code": "LA"},
  {"name": "Maine", "code": "ME"},
  {"name": "Maryland", "code": "MD"},
  {"name": "Massachusetts", "code": "MA"},
  {"name": "Michigan", "code": "MI"},
  {"name": "Minnesota", "code": "MN"},
  {"name": "Mississippi", "code": "MS"},
  {"name": "Missouri", "code": "MO"},
  {"name": "Montana", "code": "MT"},
  {"name": "Nebraska", "code": "NE"},
  {"name": "Nevada", "code": "NV"},
  {"name": "New Hampshire", "code": "NH"},
  {"name": "New Jersey", "code": "NJ"},
  {"name": "New Mexico", "code": "NM"},
  {"name": "New York", "code": "NY"},
  {"name": "North Carolina", "code": "NC"},
  {"name": "North Dakota", "code": "ND"},
  {"name": "Ohio", "code": "OH"},
  {"name": "Oklahoma", "code": "OK"},
  {"name": "Oregon", "code": "OR"},
  {"name": "Pennsylvania", "code": "PA"},
  {"name": "Rhode Island", "code": "RI"},
  {"name": "South Carolina", "code": "SC"},
  {"name": "South Dakota", "code": "SD"},
  {"name": "Tennessee", "code": "TN"},
  {"name": "Texas", "code": "TX"},
  {"name": "Utah", "code": "UT"},
  {"name": "Vermont", "code": "VT"},
  // {"name": "Virgin Islands", "code": "VI"}, <= no delivery there
  {"name": "Virginia", "code": "VA"},
  {"name": "Washington", "code": "WA"},
  {"name": "West Virginia", "code": "WV"},
  {"name": "Wisconsin", "code": "WI"},
  {"name": "Wyoming", "code": "WY"}
])

const CA_PROVINCES = fromJS([
 {"name": "Alberta", "code": "AB"},
 {"name": "British Columbia", "code": "BC"},
 {"name": "Manitoba", "code": "MB"},
 {"name": "New Brunswick", "code": "NB"},
 {"name": "Newfoundland and Labrador", "code": "NL"},
 {"name": "Nova Scotia ", "code": "NS"},
 {"name": "Northwest Territories", "code": "NT"},
 {"name": "Nunavut", "code": "NU"},
 {"name": "Ontario", "code": "ON"},
 {"name": "Prince Edward Island", "code": "PE"},
 {"name": "Quebec", "code": "QC"},
 {"name": "Saskatchewan", "code": "SK"},
 {"name": "Yukon", "code": "YT"}
])

const ES_PROVINCES = fromJS([
  {"name": "A Coruña", "code": "A Coruña"},
  {"name": "Álava", "code": "Álava"},
  {"name": "Albacete", "code": "Albacete"},
  {"name": "Alicante", "code": "Alicante"},
  {"name": "Almería", "code": "Almería"},
  {"name": "Asturias", "code": "Asturias"},
  {"name": "Ávila", "code": "Ávila"},
  {"name": "Badajoz", "code": "Badajoz"},
  {"name": "Baleares", "code": "Baleares"},
  {"name": "Barcelona", "code": "Barcelona"},
  {"name": "Burgos", "code": "Burgos"},
  {"name": "Cáceres", "code": "Cáceres"},
  {"name": "Cádiz", "code": "Cádiz"},
  {"name": "Cantabria", "code": "Cantabria"},
  {"name": "Castellón", "code": "Castellón"},
  {"name": "Ceuta", "code": "Ceuta"},
  {"name": "Ciudad Real", "code": "Ciudad Real"},
  {"name": "Córdoba", "code": "Córdoba"},
  {"name": "Cuenca", "code": "Cuenca"},
  {"name": "Girona", "code": "Girona"},
  {"name": "Granada", "code": "Granada"},
  {"name": "Guadalajara", "code": "Guadalajara"},
  {"name": "Guipúzcoa", "code": "Guipúzcoa"},
  {"name": "Huelva", "code": "Huelva"},
  {"name": "Huesca", "code": "Huesca"},
  {"name": "Jaén", "code": "Jaén"},
  {"name": "La Rioja", "code": "La Rioja"},
  {"name": "Las Palmas", "code": "Las Palmas"},
  {"name": "León", "code": "León"},
  {"name": "Lleida", "code": "Lleida"},
  {"name": "Lugo", "code": "Lugo"},
  {"name": "Madrid", "code": "Madrid"},
  {"name": "Málaga", "code": "Málaga"},
  {"name": "Melilla", "code": "Melilla"},
  {"name": "Murcia", "code": "Murcia"},
  {"name": "Navarra", "code": "Navarra"},
  {"name": "Ourense", "code": "Ourense"},
  {"name": "Palencia", "code": "Palencia"},
  {"name": "Pontevedra", "code": "Pontevedra"},
  {"name": "Salamanca", "code": "Salamanca"},
  {"name": "Santa Cruz de Tenerife", "code": "Santa Cruz de Tenerife"},
  {"name": "Segovia", "code": "Segovia"},
  {"name": "Sevilla", "code": "Sevilla"},
  {"name": "Soria", "code": "Soria"},
  {"name": "Tarragona", "code": "Tarragona"},
  {"name": "Teruel", "code": "Teruel"},
  {"name": "Toledo", "code": "Toledo"},
  {"name": "Valencia", "code": "Valencia"},
  {"name": "Valladolid", "code": "Valladolid"},
  {"name": "Vizcaya", "code": "Vizcaya"},
  {"name": "Zamora", "code": "Zamora"},
  {"name": "Zaragoza", "code": "Zaragoza"},
])


export const getUSStates = () => {
  return US_STATES;
}

export const getCanadianProvinces = () => {
  return CA_PROVINCES;
}

export const getSpanishProvinces = () => {
  return ES_PROVINCES;
}

export const getStatesOrProvinces = (countryCode) => {
  if (!countryCode) {
    return null;
  }

  const code = String(countryCode).substr(0,2).toLowerCase();
  switch (code) {
    case "us":
      return getUSStates();
    case "ca":
      return getCanadianProvinces();
    case "es":
      return getSpanishProvinces();
    default:
      return null;
  }
}

export const getUSStatesWithCodeInTitle = () => {
  return US_STATES.map(x => x.set("name", `${x.get("name")} (${x.get("code")})`));
}

export const isUS = (code = "") => {
  return String(code).substr(0,2).toLowerCase() === "us";
}

export const isCanada = (code = "") => {
  return String(code).substr(0,2).toLowerCase() === "ca";
}

export const isSpain = (code = "") => {
  return String(code).substr(0,2).toLowerCase() === "es";
}

export const COUNTRY_CODE_KEY = "code";
export const COUNTRY_LABEL_KEY = "name";

export function getCountryName(code) {
  return getNameFromCountry(getCodeFromCountry(code));
}

const getCodeFromCountry = (country) => {
  // Country could be an object with code and name fields, or a code string
  const code = typeof country === "string" ? country : country.code;
  return code.toUpperCase();
}
const getNameFromCountry = (country) => {
  // Country could be an object with code and name fields, or a code string
  return typeof country === "string" ? countryNames[country] : country.name;
}
export const filterAndSortCountries = (countries) => {
  const memeCountries = ["AC", "AN", "AQ", "BV", "EH", "HM", "SJ", "TA"]; // We don't have the flag for those

  const countriesMap = countries
    .filter((country) => !memeCountries.includes(getCodeFromCountry(country)))
    .map((country) => ({
      [COUNTRY_CODE_KEY]: getCodeFromCountry(country),
      [COUNTRY_LABEL_KEY]: getNameFromCountry(country),
    }))
    .sort((a, b) => { if (a.name < b.name) return -1; if (a.name > b.name) return 1; return 0; });
  
  return countriesMap;
}

// Preferred countries for dropdowns of countries (list provided by Alex)
export const preferredCountries = fromJS([
  "US",
  "AT",
  "DE",
  "CA",
  "FR",
  "IT",
  "ES",
  "CH",
  "GB",
]);
