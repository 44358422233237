import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import {cancelDeleteShippingAddress} from "../../actions/AddressBookActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import AddressInline from "../../shared/AddressInline";
import Button from "../../shared/components/Button";
import { withTranslation } from "react-i18next";


class DeleteShippingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit() {
    const setErrors = (errors) => this.setState({errors: errors});
    return handleDeleteShippingAddress(this.props, setErrors);
  }

  handleCancel() {
    this.props.cancelDeleteShippingAddress();
  }

  render() {
    let { submitting } = this.props;

    return (
      <div className="form">
        <div className="fields">
          <p className="u-centered">{this.props.t("shipping.delete_address.text")}</p>
          <div className="addressContainer">
            <AddressInline
              showType={false}
              address={this.props.address} />
          </div>
        </div>
          
        <div className="buttons">
          <Button onClick={() => this.handleSubmit()}
            label={this.props.t("shipping.delete_address.ok_label")} active={!submitting} />
          <Button label={this.props.t("shipping.delete_address.cancel_label")} active={!submitting}
            onClick={() => this.handleCancel()}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
      </div>
    );
  }
}

// Delete this address from the address book
const ADDRESS_BOOK_DELETE_ADDRESS = gql`
  mutation customerRemoveShippingAddressFromAccount($data: RemoveShippingAddressFromAccountInput!) {
    customerRemoveShippingAddressFromAccount(input: $data) {
      errors { key message }
    }
  }
`;
function handleDeleteShippingAddress(props, setErrors) {
  const data = {
    addressId: props.addressId,
    accountId: props.accountId,
  };

  return props.customerRemoveShippingAddressFromAccount(data)
    .then((response) => {
      if (response.data.customerRemoveShippingAddressFromAccount.errors.length <= 0) {
        props.cancelDeleteShippingAddress();
      } else {
        let errors = createValidationErrors(response.data.customerRemoveShippingAddressFromAccount.errors);
        setErrors(errors);
      }
    })
    .catch((err) => {
      setErrors(createErrors(err));
    });
}

const withQuery = compose(
  graphql(ADDRESS_BOOK_DELETE_ADDRESS, {
    props: ({ mutate }) => ({
      customerRemoveShippingAddressFromAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withTranslation("common")(connect(null, {cancelDeleteShippingAddress})(withQuery(DeleteShippingAddressFormContainer)));