import { delay } from 'redux-saga'
import { call, take, put, fork, race } from 'redux-saga/effects'
import { showNotification, hideNotification } from "../actions/NotificationActions";
import { NOTIFICATION_KINDS } from "../actions/NotificationActions";
import { SOCKET_CONNECTED, SOCKET_DISCONNECTED } from "../actions/SystemActions";
import i18n from '../i18n';
import {generateUUID} from "../lib/UUID";

function* notifyReconnected(notificationId) {
  yield put(hideNotification(notificationId));
  // Show that we are connected.
  let successId = generateUUID();
  yield put(showNotification(successId,  i18n.t("common:socket_notifications.restored"), NOTIFICATION_KINDS.SUCCESS, false));
  yield call(delay, 2000);
  yield put(hideNotification(successId));
}

function* watchSocket() {
  while (true) {
    // We initiate the flow when we get a socket disconnect.
    yield take(SOCKET_DISCONNECTED);
    // We want to notify the customer that we lost connection to the system.
    // Sometimes the reconnects are fast, and so we setup a race condition so that
    // we don't notify unnecessarily.
    const {onTimer, _onConnected} = yield race({
      onTimer: call(delay, 2000),
      _onConnected: take(SOCKET_CONNECTED)
    })

    if (onTimer) {
      // Let's show the notification.
      let notificationId = generateUUID();
      yield put(showNotification(notificationId, i18n.t("common:socket_notifications.error"), NOTIFICATION_KINDS.WARNING, false));
      // Let's update the description if it takes too long.
      const {onLongWait} = yield race({
        onLongWait: call(delay, 15000), // 15 seconds.
        _onReconnected: take(SOCKET_CONNECTED)
      })
      if (onLongWait) {
        // Update description.
        yield put(showNotification(notificationId, i18n.t("common:socket_notifications.retrying"), NOTIFICATION_KINDS.WARNING, false));
        yield take(SOCKET_CONNECTED);
      }
      yield call(notifyReconnected, notificationId);
    } else if (_onConnected) {
      // Reconnected quickly, so we just ignore the initial problem.
    }  
  }
}

export default function* saga() {
  yield fork(watchSocket);
}