import React from "react";
import {connect} from "react-redux";
import {cancelShowGiftingDiscrepancy} from "../../actions/ShopActions";
import SmallMessage from "../shared/SmallMessage";
import Button from "../../shared/components/Button";
import Alarm from "../../shop/shared/icons/Error";
import { withTranslation } from "react-i18next";

class GiftingDiscrepancyFormContainer extends React.Component {

  handleCancel() {
    this.props.cancelShowGiftingDiscrepancy();
  }

  render() {
    let errorMessage = null;
    
    if (this.props.savedMessage === "" || this.props.message === this.props.savedMessage) {
      // NB: if we show this warning and msgs are the same, it means the gift boolean is false in the data 
      errorMessage = <span>{this.props.t("gifting_discrepancy.error_save_message")}</span>;
    } else if (this.props.message !== this.props.savedMessage) {
      errorMessage = <span>{this.props.t("gifting_discrepancy.error_discrepancy")}</span>;
    }

    return (
      <SmallMessage title={this.props.t("gifting_discrepancy.error_title")} titleAsGuideHeader
        icon={<Alarm colorDisabled="var(--secondary-gold-600)" />}
        message={errorMessage}
        button={<Button label={this.props.t("common:continue")} onClick={this.handleCancel.bind(this)} />}
      />
    );
  }
}

export default withTranslation("checkout")(connect(null, {cancelShowGiftingDiscrepancy})(GiftingDiscrepancyFormContainer));