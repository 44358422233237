import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from './lib/Locales';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    // https://github.com/i18next/i18next-http-backend
    backend: {
      backends: [
        // LocalStorageBackend,  // primary backend
        HttpApi               // secondary backend
      ],
      backendOptions: [{
      //   /* options for primary backend (local storage) */
      //   expirationTime: 7*24*60*60*1000,
      //   defaultVersion: 'v0.28.1',
      // }, {
        /* options for secondary backend (http api) */
        loadPath: '/locales/{{lng}}/{{ns}}.json' // http api load path for my own fallback
      }]
    },
    // https://www.i18next.com/overview/configuration-options
    fallbackLng: DEFAULT_LOCALE,
    supportedLngs: SUPPORTED_LOCALES,
    ns: ["accounts", "auth", "checkout", "common", "forms", "hallstein", "links", "products", "settings"],
    // Defaults:
    // nsSeparator: ':',
    // keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
    cleanCode: true, // keeps languages lowercase
    debug: false,
    wait: true,
    // https://github.com/i18next/i18next-browser-languageDetector
    detection: {
      order: ['path', 'querystring', 'navigator'],
      lookupFromPathIndex: 0,
      lookupQuerystring: 'locale',
      caches: [], // no cookie nor local storage
    }
  });

export default i18n;