import React from "react";
import {connect} from "react-redux";
import Button from "../../shared/components/Button";
import AccountHelptext from "../views/AccountHelptext";
import Navigation from "../../lib/Navigation";
import {
  requestChangePassword, 
  requestEditProfile
} from "../../actions/LoginActions";

import {
  cancelModifyAccount
  } from "../../actions/AccountActions";
import "./ModifyAccountFormContainer.css";

class ModifyAccountFormContainer extends React.Component {

  handleCancel() {
    this.props.cancelModifyAccount();
  }

  handleAddAccount() {
    this.handleCancel();
    Navigation.gotoSelectAccount();
  }

  handleChangePassword() {
    this.handleCancel();
    this.props.requestChangePassword();
  }

  handleUpdateProfile() {
    this.handleCancel();
    this.props.requestEditProfile();
  }

  render() {
    return (
      <div className="ModifyAccountFormContainer">
        <Button label="Update Profile" primary={false} onClick={this.handleUpdateProfile.bind(this)} />
        <Button label="Change Password" primary={false} onClick={this.handleChangePassword.bind(this)} />
        <Button label="Setup Another Account" primary={false} onClick={this.handleAddAccount.bind(this)} />
        <Button label="Close" primary={true} onClick={this.handleCancel.bind(this)} />
        <AccountHelptext />
      </div>
    )
  }
}

export default connect(null, {
  requestChangePassword,
  requestEditProfile,
  cancelModifyAccount
  })(ModifyAccountFormContainer);

