import React, {Component} from "react";
import gsap from "gsap";
import styles from "./MobileMenuTrigger.module.scss";

const didShow = (prevProps, nextProps) => (!prevProps.menuShowing && nextProps.menuShowing);
const didClose = (prevProps, nextProps) => (prevProps.menuShowing && !nextProps.menuShowing);

class MobileMenuTrigger extends Component {

  constructor(props) {
    super(props);
    this.line1 = React.createRef();
    this.line2 = React.createRef();
    this.line3 = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);

    // Base configuration for the lines
    this.leftX = 15;
    this.rightX = 40;

    this.topY = 16;
    this.bottomY = 34;
    this.middleY = this.topY + ((this.bottomY - this.topY) / 2);
  }

  componentDidMount() {
    if (this.props.menuShowing) {
      this.onShow();
    } 
  }

  componentWillUnmount() {
    this.onHide();
  }

  componentDidUpdate(prevProps) {
    if (didShow(prevProps, this.props)) {
      this.onShow();
    } else if (didClose(prevProps, this.props)) {
      this.onHide();
    }
  }

  onShow() {
    // Changes to an X
    const leftX = this.leftX + 2;
    const rightX = this.rightX - 2;
    const topY = this.topY - 1;
    const bottomY = this.bottomY + 1;

    gsap.to(this.line1.current, {duration: 0.3, attr: {x1: leftX, x2: rightX, y1: topY, y2: bottomY}})
    gsap.to(this.line2.current, {duration: 0.1, opacity: 0, attr: {x1: leftX + 3, x2: rightX - 7}})
    gsap.to(this.line3.current, {duration: 0.3, attr: {x1: leftX, x2: rightX, y1: bottomY, y2: topY}})

    // Hide document scroll bars while menu is open
    document.body.classList.add("hasMenuOpen");
  }

  onHide() {
    // Reverts to the original three horizontal lines
    gsap.to(this.line1.current, {duration: 0.3, attr: {x1: this.leftX, x2: this.rightX, y1: this.topY, y2: this.topY}})
    gsap.to(this.line2.current, {duration: 0.1, opacity: 1, attr: {x1: this.leftX, x2: this.rightX}})
    gsap.to(this.line3.current, {duration: 0.3, attr: {x1: this.leftX, x2: this.rightX, y1: this.bottomY, y2: this.bottomY}})

    // Show document scroll bars again
    document.body.classList.remove("hasMenuOpen");
  }


  render() {
    var oStyles = {};
    if (!this.props.show) oStyles.display = "none";
    let classes = this.props.transparent ? [styles.transparentModule] : [styles.module];
    if (this.props.menuShowing) classes.push(styles.fixed);
    return (
      <div className={classes.join(" ")} style={oStyles} onClick={this.handleClick}>
        <svg id="icon" width="50" height="50" viewBox="0 0 50 50">
          <g className={styles.lines} strokeWidth="3" stroke="#000" opacity="1">
           <line ref={this.line1} x1={this.leftX} y1={this.topY} x2={this.rightX} y2={this.topY} />
           <line ref={this.line2} x1={this.leftX} y1={this.middleY} x2={this.rightX} y2={this.middleY} />
           <line ref={this.line3} x1={this.leftX} y1={this.bottomY} x2={this.rightX} y2={this.bottomY} />
          </g>
        </svg>
      </div>
    );
  }

  handleClick() {
    this.props.onClick();
  }

}

MobileMenuTrigger.defaultProps = {
  show: false,
  menuShowing: false,
  transparent: false
}

export default MobileMenuTrigger;