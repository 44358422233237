import React from "react";
import styles from "./StatusTag.module.scss";

const COLORS = {
  BEIGE: styles.beige,
  BLUE: styles.blue,
  BLUE_ACCENT: styles.blueAccent,
  GOLD: styles.gold,
  GREEN: styles.green,
  GREY: styles.grey,
  RED: styles.red,
}

const SIZES = {
  NORMAL: styles.normalSize,
  SMALL: styles.smallSize,
}

const StatusTag = (props) => {
  const { color, size, text, className, alt, visible = true } = props;

  if (!color || !text || !visible) {
    return null;
  }

  const classes = [
    styles.module,
    className,
    color,
    size,
    alt ? styles.alt : null,
  ];

  return <span className={classes.join(" ")}>{props.children}{text.trim()}</span>;
}

StatusTag.COLORS = COLORS;
StatusTag.SIZES = SIZES;

StatusTag.defaultProps = {
  size: SIZES.NORMAL,
}

export default StatusTag;
