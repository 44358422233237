import React from "react";
import Navigation from "../lib/Navigation";
import SystemIsDownPage from "../SystemIsDownPage";

const GraphQLErrors = (props) => {

  if (props.error) {
    if (props.error.networkError) {
      // System is down/unreachable, no specific graphQLError
      return <SystemIsDownPage />;
    }
    
    // System is up but some/all graphQL queries returned in error
    const debugInfo = (
      <pre>{props.error.graphQLErrors.map(({ path, message }, i) => (
        <span key={i}>{path}:{message}</span>
      ))}</pre>
    );

    const lambdaWrongUser = (e) => e.message === "permission_denied" || e.message === "unauthorized";
    if (props.error.graphQLErrors.find(lambdaWrongUser)) {
      // Wrong or expired account id: gotoRoot will handle the rest
      Navigation.gotoRoot();
      return null;
    }

    // Not sure how to handle better in the general case
    return debugInfo
  }

  return null;

}

export default GraphQLErrors;