import React, { useEffect, useState } from "react";
import LoadBackgroundImage from "./LoadBackgroundImage";
import styles from "./PrismicImages.module.scss";

// Display images from prismic.
// If only one image: display it.
// If several images: display the first one normally and add thumbnails below
// allowing the user to click on any of them.
const PrismicImages = (props) => {
  const { images } = props;

  const hasSeveralImages = Array.isArray(images);
  const maxWidth = props.maxWidth;

  const [mainImageIndex, setMainImageIndex] = useState(0);
  useEffect(() => {
    
  }, [mainImageIndex]);

  const mainImage = hasSeveralImages ? images[mainImageIndex].image : images;
  const useBackgroundForMainImage = props.alwaysWithBackground || hasSeveralImages;
  const mainImageClasses = [
    styles.box,
    styles.main,
    useBackgroundForMainImage && styles.withBackground,
    "clickable",
  ]
  const thumbnails = hasSeveralImages && images.length > 1 && images.map((e, index) => (
    <Thumbnail
      key={index}
      image={e.image}
      index={index}
      selected={index === mainImageIndex}
      setMainImageIndex={setMainImageIndex}
    />
  ));
  
  const thumbnailsElem = thumbnails && (
    <div className={styles.thumbnails} style={{"--number-of-thumbnails-per-line": 4 }}>
      { thumbnails }
    </div>
  );

  return (
    <div className={styles.images} style={{maxWidth: maxWidth}}>
      <div className={mainImageClasses.join(" ")} onClick={props.onClick}>
        <div className={styles.image}>
          <LoadBackgroundImage prismicImage={mainImage} />
        </div>
      </div>
      { thumbnailsElem }
    </div>
  );
}

const Thumbnail = ({image, index, selected, setMainImageIndex}) => {
  const classes = [
    styles.box,
    styles.withBorder,
    styles.thumbnail,
    styles.withBackground,
    selected && styles.selected,
    "clickable",
  ]
  return (
    <div className={classes.join(" ")} onClick={() => setMainImageIndex(index)}>
      <div className={styles.image}>
        <LoadBackgroundImage prismicImage={image} />
      </div>
    </div>
  );
}

export default PrismicImages;