import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./SubscriptionForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelEditSubscriptionInCart} from "../../actions/ShopActions";
import {fromJS, Map} from "immutable";
import {validateGreaterThan} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

function validateForm(values) {
  let errors = Map();
  errors = validateGreaterThan(errors, values, "quantity", 0);
  errors = validateGreaterThan(errors, values, "frequencyWeeks", 0);
  return errors.toJS();
}

class EditSubscriptionFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = values.set("accountId", this.props.accountId);

    return this.props.customerChangeSubscriptionInCart(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerChangeSubscriptionInCart.errors.length <= 0) {
          this.props.cancelEditSubscriptionInCart();
        } else {
          let errors = createValidationErrors(response.data.customerChangeSubscriptionInCart.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleRemove() {
    let values = {
      accountId: this.props.accountId,
      productSku: this.props.productSku,
    }
    return this.props.customerRemoveSubscriptionFromCart(values)
      .then((response) => {
        if (response.data.customerRemoveSubscriptionFromCart.errors.length <= 0) {
          this.props.cancelEditSubscriptionInCart();
        } else {
          let errors = createValidationErrors(response.data.customerRemoveSubscriptionFromCart.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelEditSubscriptionInCart();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let initialValues = {
      quantity: data.customerCartItem.quantity,
      frequencyWeeks: data.customerCart.subscriptionFrequencyWeeks
    }
    return (
      <Form
        initialValues={initialValues}
        productTitle={data.customerCartItem.productTitle}
        frequency={fromJS(data.subscriptionFrequencyOptions)}
        errors={this.state.errors}
        validate={validateForm}
        showRemove={true}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onRemove={this.handleRemove.bind(this)}
        />
    )
  }
}

const CART_ITEM = gql`
  query customerCartItem($accountId: ID!, $productSku: ID!, $subscription: Boolean!) {
    customerCart(accountId: $accountId) {
      subscriptionFrequencyWeeks
    }
    customerCartItem(accountId: $accountId, productSku: $productSku, subscription: $subscription) {
      productSku
      productTitle
      quantity
    }
    subscriptionFrequencyOptions {
      title
      value
    }    
  }
`;

const EDIT_SUBSCRIPTION_IN_CART = gql`
  mutation customerChangeSubscriptionInCart($data: AddSubscriptionToCartInput!) {
    customerChangeSubscriptionInCart(input: $data) {
      errors { key message }
    }
  }
`;

const REMOVE_SUBSCRIPTION_FORM_CART = gql`
  mutation customerRemoveSubscriptionFromCart($data: AccountProductInput!) {
    customerRemoveSubscriptionFromCart(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CART_ITEM, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId,
        productSku: props.productSku,
        subscription: true
      }
    })
  }),
  graphql(EDIT_SUBSCRIPTION_IN_CART, {
    props: ({ mutate }) => ({
      customerChangeSubscriptionInCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(REMOVE_SUBSCRIPTION_FORM_CART, {
    props: ({ mutate }) => ({
      customerRemoveSubscriptionFromCart: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelEditSubscriptionInCart})(withQueries(EditSubscriptionFormContainer));

