import React from "react";
import SmallMessage from "./shop/shared/SmallMessage";
import Unhappy from "./shop/shared/icons/Unhappy";
import Button from "./shared/components/Button";
import Navigation from "./lib/Navigation";
import { connect } from "react-redux";
import styles from "./ErrorPages.module.scss";
import { useTranslation } from "react-i18next";

const NotFoundPage = (props) => {
  const { t } = useTranslation("common");
  const { accountId, debugInfo } = props;

  const commmonButtonProps = {
    size: Button.SIZES.MEDIUM,
  }
  const redirectButton = accountId
    ? <Button label={t("links:link_my_account")} onClick={() => Navigation.gotoAccount(accountId)} {...commmonButtonProps} />
    : <Button label={t("links:link_login")} onClick={() => Navigation.signIn()} {...commmonButtonProps} />

  const debugElem = process.env.NODE_ENV !== 'production' && debugInfo && (
    <p className={styles.debugInfo}>{ debugInfo}</p>
  );

  return (
    <div className={styles.module}>
      <SmallMessage
        icon={<Unhappy side="56px" colorDisabled="var(--secondary-gold-600)" />}
        title={t("not_found.title")}
        button={redirectButton} >
        <p>{t("not_found.text")}</p>
        { debugElem }
      </SmallMessage>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    accountId: ownProps.router && ownProps.router.params.accountId,
  }
}

export default connect(mapStateToProps)(NotFoundPage);
