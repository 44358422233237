import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/BillingAddressForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelChangeBillingAddress} from "../../actions/AccountActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validateBillingAddress} from "../../lib/AddressValidation";
import {applyStateProvinceRule} from "../../lib/AddressRules";
import { filterAndSortCountries } from "../../lib/CountriesAndStates";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import Cookies from "js-cookie";

function validateForm(values, props) {
  return validateBillingAddress(values).toJS();
}

class ChangeBillingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = applyStateProvinceRule({
      accountId: this.props.accountId,
      name: values.get("name"),
      addressAddressLine1: values.get("addressAddressLine1"),
      addressAddressLine2: values.get("addressAddressLine2", ""),
      addressCity: values.get("addressCity"),
      addressPostcode: values.get("addressPostcode"),
      addressStateProvince: values.get("addressStateProvince"),
      addressCountryCode: values.get("addressCountryCode")
    })

    return this.props.customerChangeBillingAddress(updatedValues)
      .then((response) => {
        if (response.data.customerChangeBillingAddress.errors.length <= 0) {
          this.props.cancelChangeBillingAddress();
        } else {
          let errors = createValidationErrors(response.data.customerChangeBillingAddress.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelChangeBillingAddress();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let countries = fromJS(filterAndSortCountries(data.countries));
    let account = fromJS(data.customerBillingAccount);
    let initialValues = {};
    if (account.get("addressStatus") === "provided") {
      initialValues = account.get("address").delete("__typename");
    }
    else {
      const assumedCountryCode = Cookies.get("assumed_country") || "";

      initialValues = fromJS({
        addressCountryCode: assumedCountryCode.toUpperCase(),
      });
    }

    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        validate={validateForm}
        countries={countries}
        isCreation={this.props.isCreation}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />
    )
  }
}


const GET_BILLING_ADDRESS = gql`
  query customerBillingAccount($accountId: ID!) {
    customerBillingAccount(accountId: $accountId) {
      address {
        name
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryCode
      }
      addressStatus
    }
    countries {
      code
      name
    }
  }
`;

const CHANGE_BILLING_ADDRESS = gql`
  mutation customerChangeBillingAddress($data: BillingAddressInput!) {
    customerChangeBillingAddress(input: $data) {
      errors { key message }
    }
  }
`;


const withQueries = compose(
  graphql(GET_BILLING_ADDRESS, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(CHANGE_BILLING_ADDRESS, {
    props: ({ mutate }) => ({
      customerChangeBillingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelChangeBillingAddress})(withQueries(ChangeBillingAddressFormContainer));

