import React from "react";
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import Button from "../../shared/components/Button";
import BillingHelptext from "../views/BillingHelptext";
import {
  requestAddCreditCard, 
  requestChangeCreditCard, 
  requestChangeBillingAddress,
  requestSetTaxInfo,
  cancelModifyBilling
  } from "../../actions/AccountActions";

import GraphQLErrors from "../../shared/GraphQLErrors";
import Navigation from "../../lib/Navigation";
import {fromJS} from "immutable";
import "./ModifyBillingFormContainer.css";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class ModifyBillingFormContainer extends React.Component {

  handleCancel() {
    this.props.cancelModifyBilling();
  }

  handleAddCard() {
    this.handleCancel();
    this.props.requestAddCreditCard(this.props.accountId);
  }

  handleChangeCard() {
    this.handleCancel();
    this.props.requestChangeCreditCard(this.props.accountId);
  }

  handleSetTaxInfo() {
    this.handleCancel();
    this.props.requestSetTaxInfo(this.props.accountId);
  }  

  handleChangeBillingAddress() {
    this.handleCancel();
    this.props.requestChangeBillingAddress(this.props.accountId);
  }

  handleAddAccount() {
    this.handleCancel();
    Navigation.gotoSelectAccount();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let defaultPaymentMethod = fromJS(data.customerDefaultPaymentMethod);
    let showChangeCard = data.customerBillingAccount.paymentMethod === "credit_card" && defaultPaymentMethod !== null;
    let showAddCard = data.customerBillingAccount.paymentMethod === "credit_card";
    let hasTaxId = data.customerBillingAccount.taxId && data.customerBillingAccount.taxId !== "";
    let taxButtonLabel = (hasTaxId) ? "Modify Tax ID Number" : "Add Tax ID Number";

    return (      
      <div className="ModifyBillingFormContainer">
        <Button label="Change Card" visible={showChangeCard} onClick={this.handleChangeCard.bind(this)} />
        <Button label="Add Card" visible={showAddCard} onClick={this.handleAddCard.bind(this)} /> 
        <Button label="Set Billing Address" onClick={this.handleChangeBillingAddress.bind(this)} />
        <Button label="Setup Another Account" onClick={this.handleAddAccount.bind(this)} />
        <Button label={taxButtonLabel} onClick={this.handleSetTaxInfo.bind(this)} />
        <Button label="Close" onClick={this.handleCancel.bind(this)} />
        <BillingHelptext />
      </div>
    )
  }
}

const GET_BILLING_ACCOUNT = gql`
  query customerBillingAccount($accountId: ID!) {
    customerBillingAccount(accountId:$accountId) {
      paymentMethod
      taxId
      validTaxId
    }
    customerDefaultPaymentMethod(accountId:$accountId) {
      default
    }    
  }
`

const withQuery = graphql(GET_BILLING_ACCOUNT, {
  options: (props) => ({
    variables: {
      accountId: props.accountId
    }
  })
})

export default connect(null, {
  requestAddCreditCard, 
  requestChangeCreditCard, 
  requestChangeBillingAddress,
  cancelModifyBilling,
  requestSetTaxInfo
  })(withQuery(ModifyBillingFormContainer));
