import React from "react";
import styles from "./NumberStepperButtons.module.scss";

const NumberStepperButton = (props) => {
  const { vertical = true } = props;

  const buttonSide = "15px";
  const strokeWidth = 2 * 10 / 15; // cf. viewbox and button size

  const classes = [
    styles.buttons,
    vertical ? styles.vertical : styles.horizontal,
  ];

  return (
    <div className={classes.join(" ")}>
      <div className={styles.incButton} onClick={props.onIncrement}>
        <svg width={buttonSide} height={buttonSide} viewBox="0 0 10 10">
          <g id="icons" strokeWidth={strokeWidth} stroke="var(--primary-blue-700)">
            <path d="M0,5 L10,5" />
            <path d="M5,0 L5,10" />
          </g>
        </svg>
      </div>
      <div className={styles.decButton} onClick={props.onDecrement}>
        <svg width={buttonSide} height={buttonSide} viewBox="0 0 10 10">
          <g id="icons" strokeWidth={strokeWidth} stroke="var(--primary-blue-700)">
            <path d="M0,4 L10,4" />
          </g>
        </svg>
      </div>
    </div>
  );
}

export default NumberStepperButton;