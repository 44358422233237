import React from "react";
import Button from "../../shared/components/Button";
import styles from "./EmptyCartPanel.module.scss";
import Basket from "../shared/icons/Basket";
import { useTranslation } from 'react-i18next';

const EmptyCartPanel = (props) => {
  const { t } = useTranslation("cart");

  return (
    <div className={styles.module}>
      <div className={styles.inner}>
        <Basket side="44px" colorDisabled="var(--primary-blue-700)"/>
        <h1 className="sg-text-large">{t("empty_title")}</h1>
        <p>{t("empty_text")}</p>
        <Button label={t("links:link_shop")} onClick={props.gotoShop} theme={Button.THEMES.DARK_BLUE} />
      </div>
    </div>
  )
}

export default EmptyCartPanel;
