import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import SelectedAccount from "../accounts/SelectedAccount";
import Navigation, { buildWebsiteLink } from "../../lib/Navigation";
import {showSupport} from "../../actions/SupportActions";
import CartSummary from "./CartSummary";
import { withRouter } from "react-router";
import HeaderMobile from "./HeaderMobile";
import useQueryMap from "../../hooks/useQueryMap";


const ShopHeaderMobile = (props) => {
  const [expanded, setExpandedPriv] = useState(props.expanded);
  const [scrollYBeforeExpansion, setScrollYBeforeExpansion] = useState(0);
  const query = useQueryMap();
  const setExpanded = (expanded) => {
    if (expanded) {
      // Save scroll position before expanding the menu
      setScrollYBeforeExpansion(window.scrollY);
    }

    setExpandedPriv(expanded);
  }
  useEffect(() => {
    if (!expanded) {
      // Wait for the content to be displayed to scroll again
      window.setTimeout(() => window.scrollTo(0, scrollYBeforeExpansion), 1);
    }
  }, [expanded, scrollYBeforeExpansion])

  useEffect(() => {
    // Make sure to reset the scroll on boundary change
    setScrollYBeforeExpansion(0);
  }, [props.boundary]);

  if (!props.show) return null;

  const mobileCartSummary = (
    <CartSummary accountId={props.accountId}
      onViewCart={() => Navigation.gotoCart(props.accountId)}
      // don't show "view cart" button but we can click on the icon for that
      showViewCart={false}
      tightDisplay={true}
      basketSize="32px" />
  );

  const toggleMenu = () => setExpanded(!expanded);

  const selectedAccount = (
    <SelectedAccount
      accountId={props.accountId} isMobile={true}
      closeMenu={toggleMenu} />
  );

  return (
    <HeaderMobile {...props}
      websiteRootSrc={buildWebsiteLink(query)}
      logoSrc="/assets/LogoSimple-Black.svg"
      customerName={props.customerName}
      cartSummary={mobileCartSummary}
      selectedAccount={selectedAccount}
      toggleMenu={toggleMenu}
      expanded={expanded}
    />
  );
}

ShopHeaderMobile.defaultProps = {
  show: true,
  expanded: false,
}

export default connect(null, {showSupport})(withRouter(ShopHeaderMobile));