import React from "react";
import Navigation from "../lib/Navigation";
import LoadingCircle from "../shared/components/forms/LoadingCircle";
import SmallMessage from "../shop/shared/SmallMessage";
import { useTranslation } from "react-i18next";
import styles from "./SignOutPage.module.scss";

const SignOutPage = ({auth}) => {
  const { t } = useTranslation("auth", { keyPrefix: "signout" });

  // Signs out, which will automatically redirect to login (see .env)
  // NB: timeout necessary as App.js has yet to subscribe to events when this
  // gets executed.
  window.setTimeout(Navigation.signOut, 1);

  return (
    <div className={styles.module}>
      <SmallMessage title={t("signing_out")}
        icon={<LoadingCircle />}
      />
    </div>
  );
}

export default SignOutPage;
