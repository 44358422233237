import React from "react";
import styles from "./SmallMessage.module.scss";

// TODO: instead of getting the icon as prop, define a few modes
// to handle all the possible use case

// TODO find better name for this common functionnality
const SmallMessage = (props) => {
  const {
    icon,
    title,
    message,
    children, // for complex messages
    button,
    titleAsGuideHeader = false,
  } = props;

  const classes = [
    styles.module,
    "smallMessage",
  ]

  const titleClasses = [
    titleAsGuideHeader ? "sg-guide-header-title" : null,
  ]

  // Icon is alwayw on top, then optional title, then message, then button.
  return (
    <div className={classes.join(" ")}>
      { icon }
      { title && <h1 className={titleClasses.join(" ")}>{title}</h1> }
      { message && <p>{message}</p> }
      { children }
      { button }
    </div>
  );
}

export default SmallMessage;