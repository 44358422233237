import React from "react";
import Icon from "./Icon";

// Icons/Core/eye-line and Icons/Core/eye-off-line
function Eye(props) {
  const svgPathDParameter = props.closed
    ? "M17.882,19.297 C16.1232137,20.4125543 14.0827336,21.0033291 12,21 C6.608,21 2.122,17.12 1.181,12 C1.61102966,9.67071751 2.78262898,7.54289671 4.521,5.934 L1.392,2.808 L2.807,1.393 L22.606,21.193 L21.191,22.607 L17.881,19.297 L17.882,19.297 Z M5.935,7.35 C4.57600495,8.58559011 3.62931677,10.2087833 3.223,12 C3.85621545,14.7706749 5.76027424,17.0798053 8.35955201,18.2293079 C10.9588298,19.3788105 13.9482248,19.2337573 16.424,17.838 L14.396,15.81 C12.6179636,16.9300196 10.3014797,16.6703404 8.8155697,15.1844303 C7.32965965,13.6985203 7.06998037,11.3820364 8.19,9.604 L5.935,7.35 Z M12.914,14.328 L9.672,11.086 C9.3083798,12.0115973 9.52792886,13.0646928 10.231118,13.767882 C10.9343072,14.4710711 11.9874027,14.6906202 12.913,14.327 L12.914,14.328 Z M20.807,16.592 L19.376,15.162 C20.044476,14.2093024 20.5203897,13.1351846 20.777,12 C20.2277894,9.59457996 18.7156493,7.51987023 16.5938749,6.26060418 C14.4721004,5.00133813 11.9265631,4.66782987 9.552,5.338 L7.974,3.76 C9.221,3.27 10.58,3 12,3 C17.392,3 21.878,6.88 22.819,12 C22.5125854,13.6656993 21.8238588,15.2375842 20.807,16.592 L20.807,16.592 Z M11.723,7.508 C13.0105666,7.42842891 14.2703652,7.90526687 15.1825492,8.81745084 C16.0947331,9.72963482 16.5715711,10.9894334 16.492,12.277 L11.722,7.508 L11.723,7.508 Z"
    : "M12,3 C17.392,3 21.878,6.88 22.819,12 C21.879,17.12 17.392,21 12,21 C6.608,21 2.122,17.12 1.181,12 C2.121,6.88 6.608,3 12,3 Z M12,19 C16.2001239,18.9990895 19.8418254,16.0946904 20.777,12 C19.8384161,7.90869843 16.1975813,5.00852825 12,5.00852825 C7.80241875,5.00852825 4.16158394,7.90869843 3.223,12 C4.15817464,16.0946904 7.79987614,18.9990895 12,19 Z M12,16.5 C9.51471863,16.5 7.5,14.4852814 7.5,12 C7.5,9.51471863 9.51471863,7.5 12,7.5 C14.4852814,7.5 16.5,9.51471863 16.5,12 C16.5,14.4852814 14.4852814,16.5 12,16.5 Z M12,14.5 C13.3807119,14.5 14.5,13.3807119 14.5,12 C14.5,10.6192881 13.3807119,9.5 12,9.5 C10.6192881,9.5 9.5,10.6192881 9.5,12 C9.5,13.3807119 10.6192881,14.5 12,14.5 L12,14.5 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default Eye;
