import React from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import FormError from "../../shared/components/forms/FormError";
import { useTranslation } from 'react-i18next';


const SubscriptionNameForm = (props) => {
  const { t } = useTranslation("common");

  const {errors, handleSubmit, submit, hideSubmitButton = false, submitting} = props;
  const submitLabel = submitting ? t("standby") : t("save");
  const submitButton = hideSubmitButton || (
    <Button label={submitLabel} primary={true} onClick={submit} active={!submitting} />
  );
  const cancelButton = props.onCancel && (
    <Button label={t("cancel")} active={!submitting} onClick={props.onCancel}
      theme={Button.THEMES.BLUE_OUTLINE}
      addCancelToTopRight={true} />
  );
  
  const buttons = !hideSubmitButton || cancelButton
    ? (<div className="buttons">
        { submitButton }
        { cancelButton }
      </div>)
    : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="fields">
        <Field 
          label={t("forms:subscription_name")}
          name="name"
          placeholder={t("forms:subscription_name_placeholder")}
          component={TextInputField}
          mode=""
          serverError={props.errors.name}
          />
      </div>
      { buttons }
        
      <FormError error={errors._error} />
    </form>
  )
}

const ReduxForm = reduxForm({
  form: "SubscriptionNameForm"
})(SubscriptionNameForm);

export default ReduxForm;