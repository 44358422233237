import React from "react";
import Button from "../../shared/components/Button";
import CreditCard from "../../shared/CreditCard";
import { useTranslation } from 'react-i18next';
import SetupCreditCardFormContainer from "../forms/SetupCreditCardFormContainer";
import AddCreditCardFormContainer from "../forms/AddCreditCardFormContainer";

const buildButton = (label, theme, action) => {
  return (
    <div className="buttons">
      <Button label={label} onClick={action}
        theme={theme} />
    </div>);
}

const CreditCardPaymentMethod = (props) => {
  const { t } = useTranslation("common");
  if (!props.show) return null;

  const card = props.defaultPaymentMethod;
  let element = null;
  let label = "";
  let action = null;

  if (props.defaultPaymentMethod) {
    label = t("credit_card.edit");
    action = props.onChangeCard;
    
    element = (
      <CreditCard key={card.get("paymentMethodId")}
        card={card} onDelete={props.onDeleteCard}
      />
    );
  } else if (props.clientSecret) {
    // The user never added a card: let him fill it in right here 
    element = (
      <SetupCreditCardFormContainer
        accountId={props.accountId}
        children={props.children}
        clientSecret={props.clientSecret}
      />
    );
  } else {
    // The user never added a card: let him fill it in right here 
    element = (
      <AddCreditCardFormContainer
        accountId={props.accountId}
        children={props.children}
      />
    );
  }

  const button = action && buildButton(label, Button.THEMES.BLUE_OUTLINE, action);

  return (
    <div className="CreditCardPaymentMethod">
      { element }
      { button }
    </div>
  );
}

CreditCardPaymentMethod.defaultProps = {
  usePrimaryButtonForAdd: true,
  show: true
}

export default CreditCardPaymentMethod;