import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {
  requestRenameSubscription,
  requestChangeSubscriptionShippingAddress,
  requestCancelSubscription,
  requestPauseSubscription,
  requestResumeSubscription,
  requestResumeSubscriptionScheduling,
  requestAdjustSubscription,
  requestDelayDelivery,
  } from "../../actions/AccountActions";
import {showNotification} from "../../actions/NotificationActions";
import {currencyFormat} from "../../lib/TextFormatter";
import AddressInline from "../../shared/AddressInline";
import Button from "../../shared/components/Button";
import SubscriptionDeliveries from "./SubscriptionDeliveries";
import {generateUUID} from "../../lib/UUID";
import {isSubscriptionActive, isSubscriptionPaused, isSubscriptionPauseDeferred, isSubscriptionCancellationDeferred, indexSurchargeProducts, getSubscriptionTotals} from "../../lib/SubscriptionHelper";
import RenameButton from "../shared/icons/RenameButton";
import StatusTag from "../../shared/components/StatusTag";
import { useTranslation, Trans } from "react-i18next";
import Subscription8PackEOL from "../../public_keyed_pages/product_8_pack_eol/Subscription8PackEOL";
import styles from "./SubscriptionPanel.module.scss";
import useResize from "../../hooks/useResize";
import { buildProductImageLink } from "../../lib/Navigation";


function SubscriptionPanel(props) {
  const {
    accountId,
    subscriptionId,
    subscription,
  } = props;

  function handleAdjustSubscription() {
    // Navigation.gotoEditSubscription(accountId, subscriptionId);
    props.requestAdjustSubscription(accountId, subscriptionId);
  }
  function handleRenameSubscription() {
    props.requestRenameSubscription(accountId, subscriptionId);
  }
  function handleChangeAddress() {
    props.requestChangeSubscriptionShippingAddress(accountId, subscriptionId);
  }
  function handlePauseSubscription() {
    props.requestPauseSubscription(accountId, subscriptionId);
  }
  function handleResumeSubscription() {
    props.requestResumeSubscription(accountId, subscriptionId);
  }
  function handleResumeSubscriptionScheduling() {
    props.requestResumeSubscriptionScheduling(accountId, subscriptionId, "paused");
  }
  function handleCancelSubscription() {
    props.requestCancelSubscription(accountId, subscriptionId);
  }
  function handleDelayDelivery() {
    props.requestDelayDelivery(accountId, subscriptionId);
  }

  const { t, i18n } = useTranslation("accounts");

  const moduleRef = useRef();
  const { width } = useResize(moduleRef);
  const [tight, setTight] = useState(true); // Default tight

  useEffect(() => {
    if (width > 0) {
      const cutoff = i18n.language === "de"
        ? 420 // DE strings are longer..
        : 380;

      if (width < cutoff && !tight) {
        setTight(true);
      } else if (width > cutoff && tight) {
        setTight(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, i18n.language])

  const buttons = [];
  const buttonDefaultProps = {
    theme: Button.THEMES.WHITE,
  }
  const active = isSubscriptionActive(subscription);
  const paused = isSubscriptionPaused(subscription) || isSubscriptionPauseDeferred(subscription);
  const cancelled = isSubscriptionCancellationDeferred(subscription);

  if (active) {
    buttons.push(
      <Button key="modify" {...buttonDefaultProps}
        label={t("subscription.actions.adjust")}
        onClick={handleAdjustSubscription}
        style={{gridColumn: "1 / -1"}} // take up all columns
      />,
      <Button key="address" {...buttonDefaultProps}
        label={t("subscription.actions.edit_delivery_address")}
        onClick={handleChangeAddress}
      />,
      <Button key="delay" {...buttonDefaultProps}
        label={t("subscription.actions.delay_next_delivery")}
        onClick={handleDelayDelivery}
      />,
      <Button key="pause" {...buttonDefaultProps}
        label={t("subscription.actions.pause")}
        onClick={handlePauseSubscription}
      />,
      <Button key="cancel" {...buttonDefaultProps}
        label={t("subscription.actions.cancel")}
        onClick={handleCancelSubscription}
      />
    );
  } else if (paused) {
    const isDeferred = isSubscriptionPauseDeferred(subscription);
          
    buttons.push(
      <Button key="reactivate" {...buttonDefaultProps}
        label={t("subscription.actions." + (isDeferred ? "undo_pause" : "resume"))}
        theme={Button.THEMES.BLUE}
        onClick={isDeferred ? handleResumeSubscriptionScheduling : handleResumeSubscription}
        style={{gridColumn: "1 / -1"}} // take up all columns
      />,
      <Button key="modify" {...buttonDefaultProps}
        label={t("subscription.actions.adjust")}
        onClick={handleAdjustSubscription}
        style={{gridColumn: "1 / -1"}} // take up all columns
      />,
      <Button key="address" {...buttonDefaultProps}
        label={t("subscription.actions.edit_delivery_address")}
        onClick={handleChangeAddress}
      />,
      <Button key="cancel" {...buttonDefaultProps}
        label={t("subscription.actions.cancel")}
        onClick={handleCancelSubscription}
      />
    );
  } else if (cancelled) {
    buttons.push(
      <Button key="reactivate" {...buttonDefaultProps}
        label={t("subscription.actions.undo_cancel")}
        onClick={handleResumeSubscriptionScheduling}
      />
    );
  } // else no button at all

  const classes = [
    styles.module,
    tight ? styles.tight : styles.spacious,
    subscription.get("status") === "active" ? styles.active : styles.inactive,
  ];

  return (
    <div className={classes.join(" ")} ref={moduleRef}>
      {/* anchor={subscription.get("subscriptionId")} */}
      <div className={styles.title}>
        <div className={styles.text}>
          <span>{subscription.get("name")}</span>
          <RenameButton side="16px" color="var(--grey-900" onClick={handleRenameSubscription} />
        </div>
        <StatusTagFor status={subscription.get("status")} />
      </div>

      <div className={styles.products}>
        <Products
          products={subscription.get("products")}
          addressSurcharges={subscription.get("shippingAddressSurcharges")}
        />
      </div>

      <div className={styles.address}>
        <div className="sg-grey-700 sg-font-bold u-bottom-margin-shorter">{t("subscription.delivery_every_n_to", { count: subscription.get("frequencyWeeks") })}</div>

        <AddressInline
          address={subscription.get("shippingAddress").set("email", "").set("phone", "")}
          onChangeAddress={handleChangeAddress} />
      </div>

      <div className={styles.nextDelivery}>
        <div className="sg-footnote sg-grey-400 u-bottom-margin-shorter">{t("subscription.next_estimated_delivery")}</div>

        <div className={styles.deliveries}>
          <SubscriptionDeliveries
            onlyDeliveryDate={true}
            accountId={accountId}
            subscriptionId={subscriptionId}
            importedNotice={() => renderImportedNotice(subscription, props.onViewDeliveries, t)}
            onViewDeliveries={props.onViewDeliveries}
          />
        </div>
      </div>

      <Subscription8PackEOL
        accountId={accountId}
        subscriptionId={subscriptionId}
        frequencyWeeks={subscription.get("frequencyWeeks")}
        products={subscription.get("products")}
        addressSurcharges={subscription.get("shippingAddressSurcharges")}
      />

      <div className={styles.buttons}>
        {buttons}
      </div>
    </div>
  );
}

function StatusTagFor({status}) {
  const { t } = useTranslation("accounts");

  let color = null;
  let text = t("subscription.status." + status);

  switch (status) {
    case "active": color = StatusTag.COLORS.GREEN; break;
    case "paused": color = StatusTag.COLORS.GOLD; break;
    case "pause_deferred": color = StatusTag.COLORS.GOLD; break;
    default: break;
  }
  return <StatusTag className={styles.tag} text={text} color={color} />
}

function renderImportedNotice(subscription, onViewDeliveries, t) {
  if (subscription.get("wasImported") && subscription.get("currentSeqId") === 2) {
    return (
      <div className="importedNotice">
        {t("subscription.recently_imported")}
        <div className="moreInfo" onClick={onViewDeliveries}>
          {t("subscription.view_details")}
        </div>
      </div>
    );
  }
  return null;
}

// function ModificationInfo({subscription}) {
//   const { t } = useTranslation("accounts");
  
//   if (subscription.get("canModifyNextDelivery") === true) {
//     return <div className="modificationStatus">{t("subscription.can_edit")}</div>
//   } else {
//     return <div className="modificationStatus">{t("subscription.edit_will_wait")}</div>
//   }
// }

function ProductLabel({product}) {
  const quantity = product.get("quantity");
  const title = product.get("productTitle");
  
  return (
    <div className={styles.label}>
      <span className={styles.quantity}>{quantity}x</span> {title.replace("Hallstein ", "")}
    </div>
  );
}

function Products({products, addressSurcharges}) {
  const { t } = useTranslation("accounts", { keyPrefix: "subscription"});

  let productElems = products.map((product) => {
    const productSku = product.get("productSku");

    const imageStyle = {
      backgroundImage: `url(${buildProductImageLink(productSku)})`,
    }
  
    return (
      <div key={productSku} className={styles.product}>
        <div className={styles.image} style={imageStyle} />
        <ProductLabel product={product} />
      </div>
    )
  });

  let indexedSurcharges = indexSurchargeProducts(addressSurcharges);
  let totals = getSubscriptionTotals(products, indexedSurcharges);

  return (
    <div className={products.size === 1 ? styles.alone : ""}>
      {productElems}
      <div className={styles.pricing}>
        <div className="sg-footnote sg-grey-400 u-bottom-margin-shorter">{t("total_per_delivery")}</div>

        <SubscriptionTotal total={totals.get("total")} currency={totals.get("currency")} />
        <SubscriptionSurcharges total={totals.get("surcharges")} currency={totals.get("currency")} />
      </div>
    </div>
  )
}

function SubscriptionTotal({total, currency}) {
  const { t } = useTranslation("accounts", { keyPrefix: "subscription"});
  const didMount = useRef(false);

  const dispatch = useDispatch()

  useEffect(() => {
    if (didMount.current) {
      dispatch(showNotification(generateUUID(), t("price_changed")));
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return (
    <div>
      <Trans i18nKey="accounts:subscription.price_per_delivery"
        components={{
          b: <span className="sg-font-bold" />
        }}
        values={{
          price: currencyFormat(total, currency, 2, false, false)
        }}
      />
    </div>
  );
}

function SubscriptionSurcharges({total, currency}) {
  const { t } = useTranslation("accounts", { keyPrefix: "subscription"});
  if (total === 0) return null;
  const surchargeAmount = currencyFormat(total, currency, 2, false, false);

  return (
    <div className={styles.surcharge}>
      {t("includes_surcharge", { surcharge: surchargeAmount })}
    </div>
  )
}

export default connect(null, {
  requestRenameSubscription,
  requestChangeSubscriptionShippingAddress,
  requestCancelSubscription,
  requestPauseSubscription,
  requestResumeSubscription,
  requestResumeSubscriptionScheduling,
  requestAdjustSubscription,
  requestDelayDelivery,
  })(SubscriptionPanel);
