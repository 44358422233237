import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./AdjustTransitionPreferenceForm.module.scss";
import ArrowButton from "../../shop/shared/icons/Arrow";


export function ChoiceBox({numberLeft, numberRight, onMatchBottles, children}) {
  return (
    <div className={styles.choice} onClick={() => onMatchBottles && onMatchBottles()}>
      <div className={styles.items}>
        <Box pack={8} number={numberLeft} />
        <ArrowButton
          direction={ArrowButton.DIRECTIONS.RIGHT}
          className={styles.arrow}
          side="40px"
          colorDisabled="var(--primary-blue-700)"
        />
        <Box pack={6} number={numberRight} />
      </div>
      { children }
    </div>
  );
}

function Box({pack, number}) {
  const { t } = useTranslation("accounts");
  const pricePerCase = pack === 8 ? "42.00" : "35.00"; // TODO

  const imageStyles = [
    styles.image,
    styles["pack" + pack],
  ];

  return (
    <div className={styles.box}>
      <div className={imageStyles.join(" ")} />
      <div className={styles.text}>
        <div className="sg-text-small u-no-margin sg-grey-700">
          <Trans
            i18nKey={`accounts:eol.popup.n_bottle`}
            components={{b: <b className="sg-font-bold" />}}
            values={{count: number, n: pack}}
          />
        </div>
        <div className="sg-footnote sg-grey-400 u-inline">
          {t("eol.popup.price", {price: pricePerCase})}
        </div>
      </div>
    </div>
  );
}