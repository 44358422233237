import React from "react";
import {Field, getFormValues, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import { useTranslation } from 'react-i18next';
import styles from "./PromoCodeForm.module.scss";
import { useSelector } from "react-redux";

function upcase(val) {
  return String(val).toUpperCase();
}

const PromoCodeForm = (props) => {
  const { t } = useTranslation("checkout");
  let {errors, handleSubmit, submit, submitting} = props;
  let submitLabel = submitting ? t("common:standby") : t("summary.promo_code.apply");

  const formValues = useSelector(state => getFormValues("PromoCodeForm")(state));
  const codeFormHasValue = !!formValues?.get("promoCode");

  return (
    <form className={styles.module} onSubmit={handleSubmit}>
      <div className={styles.inner}>
        <Field
          name="promoCode"
          label={t("summary.promo_code.label")}
          placeholder={t("summary.promo_code.placeholder")}
          component={TextInputField}
          normalize={upcase}
          mode=""
          table={false}
          serverError={errors.promoCode}
        />
        <Button label={submitLabel} className={styles.submitButton}
          onClick={submit} active={!submitting && codeFormHasValue} />
      </div>
    </form>
  );
}

const ReduxForm = reduxForm({
  form: "PromoCodeForm"
})(PromoCodeForm);

export default ReduxForm;