import React from "react";
import {Field} from "redux-form/immutable";
import {reduxForm} from "../../lib/ReduxFormWrapper";
import { PASSWORD_MIN_LENGTH } from "../../lib/Validators";
import TextInputField from "../../shared/components/forms/TextInputField";
import Button from "../../shared/components/Button";
import FieldError from "../../shared/components/forms/FieldError";
import Divider from "../../shop/shared/Divider";
import { useTranslation, Trans } from 'react-i18next';
import styles from "./SignIn.module.scss";

const SignInForm = props => {
  const { t } = useTranslation("auth");
  const { onSignUp, handleSubmit, submit, darkBackground = false } = props;
  
  const titleClasses = [
    "sg-text-large",
    darkBackground ? null : "sg-primary-blue-700",
  ];
  const buttonColor = darkBackground ? Button.THEMES.WHITE : Button.THEMES.BLACK;
  const forgotPasswordButton = (
    <Button
      label={t("signin.forgot_password")}
      href="/forgot-password" link={true}
      theme={buttonColor}
      className="sg-font-bold sg-text-small u-centered u-no-margin"
    />
  );

  return (
    <form className={styles.module} onSubmit={handleSubmit} method="POST" autoComplete="on">
      <p className={titleClasses.join(" ")}>{t("signin.welcome_back")}</p>
      <p>{ props.subtitle }</p>
      <div className="fields u-bottom-margin">
        <FieldError darkBackground={darkBackground} globalFormError={true}
          serverError={props.errors.loginForm} />
        <Divider spaceAround={Divider.SPACES.NORMAL} firstOrLast={true} />
        <Field
          label={t("forms:email")}
          name="username"
          autoComplete="username"
          type="email"
          required={true}
          autoFocus={true}
          spellCheck={false}
          placeholder={t("forms:email_placeholder")}
          component={TextInputField}
          noTable={true}
          darkBackground={darkBackground}
          serverError={props.errors.username}
        />
        <Field
          label={t("forms:password")}
          name="password"
          autoComplete="current-password"
          obscured
          placeholder="*******"
          minLength={PASSWORD_MIN_LENGTH}
          passwordrules={`minlength: ${PASSWORD_MIN_LENGTH};`}
          component={TextInputField}
          noTable={true}
          darkBackground={darkBackground}
          serverError={props.errors.password}
        />
        <Button
          label={t("signin.sign_in")}
          theme={Button.THEMES.BLUE}
          size={Button.SIZES.MEDIUM}
          className="expand u-bottom-margin-short"
          onClick={submit}
          type="submit"
        />
        { forgotPasswordButton }
      </div>

      <div>
        <p className="u-no-margin u-centered sg-text-small">
          <Trans
            i18nKey="auth:signin.have_no_account"
            components={{
              a: <Button link={true}
                  theme={buttonColor}
                  onClick={onSignUp}
                  className="sg-font-bold"
                />
            }}
          /> 
        </p>
      </div>
    </form>
  );
}

SignInForm.defaultProps = {
  darkBackground: true
}

const ReduxForm = reduxForm({
  form: 'signin'
})(SignInForm);

export default ReduxForm;