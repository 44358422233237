import React, {useState} from "react"
import {connect} from "react-redux";
import SmallMessage from "../../shop/shared/SmallMessage";
import {accountReady, accountReadyFailed} from "../../actions/AnonymousCheckoutActions";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { useTranslation } from "react-i18next";


const MonitorAccountCreation = ({accountId, accountReady, accountReadyFailed}) => {
  const { t } = useTranslation("auth");
  const [errorPollLimit] = useState(Date.now() + 8000); // 8 seconds.

  return (
    <div>
      <SmallMessage title={t("add_account.creating_title")}
        icon={<LoadingCircle />}
        message={t("add_account.creating_text")} />
      <Query query={ACCOUNT_STATUS} variables={{accountId: accountId}} pollInterval={2500} fetchPolicy="network-only">
        {({ loading, error, data, stopPolling }) => {
          if (loading) {
            return null;
          }
          if (error) {
            stopPolling();
            accountReadyFailed(accountId);
            return null;
          }
          if (data.customerAccountStatus.cartInitialized === false) {
            if (Date.now() > errorPollLimit) {
              accountReadyFailed(accountId);
              stopPolling();
            }
            return null;
          }
          if (data.customerAccountStatus.cartInitialized === true) {
            stopPolling();
            accountReady(accountId);
            return null;
          }
          return null;
        }}
      </Query>  
    </div>
  )
}

const ACCOUNT_STATUS = gql`
  query customerAccountStatus($accountId: ID!) {
    customerAccountStatus(accountId: $accountId) {
      accountId
      cartInitialized
      timestamp
    }
  }
`;

export default connect(null, {accountReady, accountReadyFailed})(MonitorAccountCreation);