import {generateUUID} from "./UUID";
import gql from "graphql-tag";

// Apollo seems to cache subscriptions depending on the name we gave them
// e.g. gql`subscription customerUpdatedAccounts {...
// The problem appears when several component use the same subscription names
// as unmounting one will unsubscribe both.
//
// As such names are meaningless to graphql anyway, we will wrap all
// graphl subscription with this function:

export function SubscriptionQueryWrapper(parameters, query) {
  const subscriptionRandomName = "sub" + generateUUID();

  const paramsWithParenthesis = parameters ? `(${parameters})` : '';

  const fullQuery = `
  subscription ${subscriptionRandomName}${paramsWithParenthesis} {
    ${query}
  }
  `;

  return gql`${fullQuery}`;
}
