import React from "react";
import {isProduction, getEnvironment, VERSION} from "../config";

export default function SystemVersion(props) {

  if (isProduction()) return null;
  return (
    <div className="SystemVersion">
      <span>{getEnvironment()} {VERSION}</span>
    </div>
  )

}
