import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import {hasSubscription} from "@jumpn/utils-graphql";
import { InMemoryCache as Cache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import absintheSocketLink from "./absinthe-socket-link";
import { setContext } from 'apollo-link-context';
import {GRAPHQL_ENDPOINT} from "../config";
import {fromJS} from "immutable";

// ApolloLink.split allows us to reach different endpoints depending on the
// result of its first parameter, e.g. split(isOk?, yesBranch, noBranch).
const withSubscriptionLink = absintheSocketLink;
const defaultLink = new HttpLink({
  mode: "cors",
  uri: GRAPHQL_ENDPOINT,
});
const splitLink = new ApolloLink.split(
  (operation) => hasSubscription(operation.query),
  withSubscriptionLink,
  defaultLink
);

const authLink = setContext((_, {headers}) => {
  // auth token.
  const token = localStorage.getItem("access_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})

export const createClient = (auth) => {

  // operation, response, graphQLErrors, networkError
  const logoutLink = onError(({ response, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      let unauthorizedError = fromJS(graphQLErrors).find((x) => {
        // x could be a string too, like "Internal server error".
        return x.get && x.get("message") === "unauthorized";
      })
      if (unauthorizedError) {
        auth.logout();
        response.errors = null;
        return;
      }
    }
    if (networkError && networkError.statusCode === 401) auth.logout();
  })

  const link = logoutLink.concat(authLink).concat(splitLink);
  
  return new ApolloClient({
    link: link,
    cache: new Cache({
      dataIdFromObject: object => {
        switch (object.__typename.toLowerCase()) {
          case "product": return object.sku;
          default: return defaultDataIdFromObject(object);
        }
      }
    }),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only"
      }
    }
  });

}