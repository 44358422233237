import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./ProductQuantityForm";
import {cancelAddProductToCart} from "../../actions/ShopActions";
import {Map, fromJS} from "immutable";
import {validateGreaterThan, validateLowerThanOrEqual} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import { NOTIFICATION_KINDS, showNotificationWithPhoto } from "../../actions/NotificationActions";
import { generateUUID } from "../../lib/UUID";
import { buildProductImageLink } from "../../lib/Navigation";
import { withTranslation } from "react-i18next";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { generateAddToCartSummary } from "../../lib/StatsSummary";
import { recordAddToCart } from "../../lib/Analytics";
import GraphQLErrors from "../../shared/GraphQLErrors";
import withCmsProductTitles from "../../hocs/withCmsProductTitles";

const MAX_NUMBER_ITEMS = 3;

function validateForm(values) {
  let errors = Map();
  errors = validateGreaterThan(errors, values, "quantity", 0);
  errors = validateLowerThanOrEqual(errors, values, "quantity", MAX_NUMBER_ITEMS);
  return errors.toJS();
}

class AddProductFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = values.set("accountId", this.props.accountId)
                              .set("productSku", this.props.productSku);    

    return this.props.customerAddProductToCart(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerAddProductToCart.errors.length <= 0) {
          const quantity = values.get("quantity");
          const productSku = this.props.productSku;
          const productTitle = this.props.productTitle;

          this.props.showNotificationWithPhoto(
            generateUUID(),
            buildProductImageLink(productSku),
            [
              `${quantity}x ${productTitle}`,
              this.props.t("cart:added_items", {count: quantity})
            ],
            NOTIFICATION_KINDS.SUCCESS,
          );
          this.notifyAddToCart(quantity);
          this.props.cancelAddProductToCart();
        } else {
          let errors = createValidationErrors(response.data.customerAddProductToCart.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelAddProductToCart();
  }

  handleRemove() {

  }

  notifyAddToCart(quantity) {
    // Find the product in the list of products.
    let products = fromJS(this.props.data.customerShopProducts);
    let product = products.find((p) => p.get("productSku") === this.props.productSku);
    // Find the pricing using cart currency.
    let pricing = product.get("pricing").find((p) => p.get("currency") === this.props.data.customerCart.currency);
    // Get the title from the cmsDataProductTitles.
    let title = this.props.cmsDataProductTitles.getIn([this.props.productSku, "longTitle"], product.get("productTitle") );
    // Trigger analytics event.
    recordAddToCart(
      generateAddToCartSummary(
        product.get("productSku"),
        title,
        quantity,
        false,
        pricing.get("value"),
        pricing.get("currency"),
        product.get("shopId")
      ).toJS()
    )
  }

  render() {
    if (this.props.data.loading || this.props.cmsDataProductTitlesLoading) return <LoadingLine />
    if (this.props.data.error || this.props.cmsDataProductTitlesError) return <GraphQLErrors error={this.props.data.error} />
  
    let initialValues = {
      quantity: 1,
    }

    let title = this.props.cmsDataProductTitles.getIn([this.props.productSku, "longTitle"], this.props.productTitle);

    return (
      <Form
        initialValues={initialValues}
        productTitle={title}
        errors={this.state.errors}
        validate={validateForm}
        maxNumberOfProducts={MAX_NUMBER_ITEMS}
        showRemove={false}
        isAddition={true}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onRemove={this.handleRemove.bind(this)}
        />
    )
  }
}

const ADD_PRODUCT_TO_CART = gql`
  mutation customerAddProductToCart($data: AddToCartInput!) {
    customerAddProductToCart(input: $data) {
      errors { key message }
    }
  }
`;

const INFO = gql`
  query info($accountId:ID!) {
    customerShopProducts(accountId:$accountId) {
      shopId
      productSku
      productTitle
      pricing {
        value
        currency
      }      
    }
    customerCart(accountId:$accountId) {
      currency
    }
  }
`;


const withQueries = compose(
  graphql(INFO, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ADD_PRODUCT_TO_CART, {
    props: ({ mutate }) => ({
      customerAddProductToCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

const mapDispatchToProps = dispatch => ({
  showNotificationWithPhoto: (id, photoUrl, message, kind) => {
    dispatch(showNotificationWithPhoto(id, photoUrl, message, kind));
  },
  cancelAddProductToCart: () => {
    dispatch(cancelAddProductToCart());
  }
});

export default withTranslation("cart")(connect(null, mapDispatchToProps)(withQueries(withCmsProductTitles(AddProductFormContainer))));

