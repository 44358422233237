import React from "react";
import { BOUNDARIES } from "../../routes";
import Navigation, { buildWebsiteLink } from "../../lib/Navigation";
import Button from "../../shared/components/Button";
import useQueryMap from "../../hooks/useQueryMap";
import { useTranslation } from 'react-i18next';


const HeaderMenu = (props) => {
  const { t } = useTranslation("links");
  
  const {
    buttonClassCallback,
    accountId,
    theme,
    useDesktopMode = false,
    size,
    dividerClass,
    onAfterclick = () => {},
  } = props;

  const commonButtonProps = {
    theme: theme,
    size: size,
    link: useDesktopMode,
  }

  const separator = <div className={dividerClass}/>;

  const query = useQueryMap();

  return (
    <React.Fragment>
      { !useDesktopMode &&
        <Button label={t("home")} {...commonButtonProps}
          href={buildWebsiteLink(query, "/")}
          className={buttonClassCallback()} />
      }
      <Button label={t("our_water")} {...commonButtonProps}
        href={buildWebsiteLink(query, "/our-water")}
        className={buttonClassCallback()} />
      <Button label={t("the_story")} {...commonButtonProps}
        href={buildWebsiteLink(query, "/the-story")}
        className={buttonClassCallback()} />
      <Button label={t("how_it_works")} {...commonButtonProps}
        href={buildWebsiteLink(query, "/how-it-works")}
        className={buttonClassCallback()} />
      {/* Real shop page */}
      <Button label={t("shop")} {...commonButtonProps}
        onClick={() => Navigation.gotoShop(accountId) & onAfterclick()}
        className={buttonClassCallback(BOUNDARIES.SHOP)} />
      { separator }
      <Button label={t("shop_my_account")} {...commonButtonProps}
        onClick={() => Navigation.gotoAccount(accountId) & onAfterclick()}
        className={buttonClassCallback(BOUNDARIES.ACCOUNT)} />
      <Button label={t("shop_settings")} {...commonButtonProps}
        onClick={() => Navigation.gotoSettings(accountId) & onAfterclick()}
        className={buttonClassCallback(BOUNDARIES.SETTINGS)} />
    </React.Fragment>
  );
}

export default HeaderMenu;
