import React, {useState} from "react";
import Form from "../forms/RegistrationForm";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {validatePresence, validateAcceptance, validatePhoneNumber} from "../../lib/Validators";
import {getCompletePhoneNrOrNothing} from "../../lib/TelephoneNormalizer";
import {Map, fromJS} from "immutable";
import { useTranslation } from "react-i18next";

function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "email");
  errors = validatePresence(errors, values, "password");
  errors = validatePresence(errors, values, "phone");
  errors = validatePhoneNumber(errors, values, "phone");
  errors = validateAcceptance(errors, values, "tacAccepted", "yes");

  return errors.toJS();
}

const CustomerInvitationDetails = (props) => {
  const { t } = useTranslation("accounts");
  const [errors, setErrors] = useState([]);

  const { inviteId } = props;
  
  const initialValues = fromJS({
    name: props.name,
    email: props.email,
    phone: props.phone,
  });

  const handleSubmit = (values) => {
    let updatedValues = Map({
      inviteId: inviteId,
      name: values.get("name"),
      email: values.get("email"),
      phone: getCompletePhoneNrOrNothing(values.get("phone")),
      password: values.get("password"),
      tacAccepted: values.get("tacAccepted") === "yes",
      marketingAccepted: values.get("marketingAccepted") === "yes",
    });
    return props.acceptCustomerAuthInvite(updatedValues.toJS())
      .then((response) => {
        if (response.data.acceptCustomerAuthInvite.errors.length <= 0) {
          props.onStarted(updatedValues.get("email"), updatedValues.get("password"));
        } else {
          setErrors(createValidationErrors(response.data.acceptCustomerAuthInvite.errors));
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });      
  }

  const handleCancel = () => {
    props.onBack();
  }

  return (
    <div className="CustomerInvitationDetails view">
      <div className="inner">
        <p>{t("invitation.normal.verify_details")}</p>
        <Form 
          initialValues={initialValues}
          errors={errors}
          validate={validateForm}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          darkBackground={true}
        />
      </div>
    </div>
  );
}

const ACCEPT_INVITE = gql`
  mutation acceptCustomerAuthInvite($data: AcceptCustomerAuthInviteInput!) {
    acceptCustomerAuthInvite(input: $data) {
      errors { key message }
    }
  }
`;


const withMutation = compose(
  graphql(ACCEPT_INVITE, {
    props: ({ mutate }) => ({
      acceptCustomerAuthInvite: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);


export default withMutation(CustomerInvitationDetails);