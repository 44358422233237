//
// Cf inputs in design system here: https://app.zeplin.io/project/5fc893a7d73bf085db2cc3f4/screen/6000481929f74e1b01c36373
//
// This file should be shared between the website and the shop
//

import React from "react";
import Checked from "../../shop/shared/icons/Checked";
// Button styles are imported globally in global.scss


// Buttons come in 3 sizes:
const SIZES = {
  MEDIUM: "medium",
  SMALL: "small",
  MINI: "mini",
};

// Button come in several themes:
const THEMES = {
  BLUE: "dark-blue",
  BLUE_OUTLINE: "blue-outline",
  BLACK: "black",
  GOLD: "gold",
  GREY_OUTLINE: "grey-outline",
  WHITE: "white",
  GREEN: "green",
};


function renderButton(classes, props) {
  let buttonLabel = props.label;

  if (!props.link && props.theme === THEMES.GREEN) {
    buttonLabel = (
      <>
        <Checked side="16px" />
        { buttonLabel }
      </>
    );
  }

  const hackClick = (e) => {
    // Dunno why but pressing the enter key in a form field trigger the next
    // button's on click (not even onkeydown). Hack: for onClick event, check
    // that the clientX/Y are not zero.
    if (e.clientX !== 0 || e.clientY !== 0) {
      click(props, e);
    }
  }

  return (
    <button className={classes.join(" ")}
      style={props.style}
      onKeyDown={(e) => (e.key === " " || e.key === "Enter") && click(props, e)}
      tabIndex="0"
      onClick={hackClick}
      type={props.type || null}
      disabled={!props.active}>
      <span>{buttonLabel || props.children}</span>
    </button>
  );
}

function renderLink(classes, props) {
  if (props.renderLink) {
    return props.renderLink(classes.join(" "), props);
  }

  const linkProps = {
    href: props.href,
    className: classes.join(" "),
    tabIndex: 0,
    target: props.target,
  }
  if (props.target === "_blank") {
    linkProps.rel = "noopener noreferrer";
  }

  return (
    <a {...linkProps} onClick={props.onClick}>
      {props.label || props.children}
    </a>
  );
}

// Handle click, after checking on the active state
// TODO? Should basic links follow the active prop?
function click(props, event) {
  if (event) {
    event.stopPropagation();

    if (event.nativeEvent) {
      event.nativeEvent.preventDefault();
    }
  }

  if (props.active) {
    return props.onClick(event);
  }
}

function Button(props) {
  if (!props.visible) {
    return null;
  }

  const isLink = !!props.href;
  // A link could be displayed like a button and vice versa.
  const classes = [
    "Button", // whether it should be styled like a link or a button
    props.className,
    props.link ? "themeLink" : "themeButton " + props.size,
    props.theme,
  ];

  // Mostly used to add the same functionality to the top right of the current
  // container (whose position is relative, e.g. the inner of CommandWindow).
  let topRightCancel = null;
  if (props.addCancelToTopRight && props.onClick) {
    topRightCancel = (
      <div className={"cancelButton"} onClick={(e) => click(props, e)}>
        <img src="/assets/icons/close-line.svg" alt="Cancel"/>
      </div>
    );
  }
  
  return (
    <React.Fragment>
      {topRightCancel}
      {isLink ? renderLink(classes, props) : renderButton(classes, props)}
    </React.Fragment>
  );
}

Button.defaultProps = {
  visible: true,
  active: true,
  theme: THEMES.BLUE,
  size: SIZES.SMALL,
  href: "",
  label: "",
  link: false, // Should display as link (not button)?
  className: "",
};

Button.SIZES = SIZES;
Button.THEMES = THEMES;

// Buttons to be used in a submenu: they get highlighted as the page hash
// gets updated.
export const buildSubmenuButton = (currentPageHash, hash, label) => {
  const classes = hash === currentPageHash ? "selected" : "";
  return (<Button href={hash} label={label} className={classes} link={true} />);
}

export default Button;
