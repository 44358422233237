
const currencyToSymbol = (currency) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "CAD":
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return currency;
  }
}

// E.g. in normal mode: EUR 120.00
// In tight mode, if there is no decimal it becomes $120
// In finance, currency is displayed before the amount, but sometimes we want it second.
export function currencyFormat(num, currency, decimals = 2, modeTight = false, currencyFirst = true) {
  if (modeTight && Math.round(num) === num) {
    decimals = 0;
  }
  const formattedValue = num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  if (modeTight) {
    // todo i18n change position depending on language/country?
    return currencyToSymbol(currency) + formattedValue;
  }
  
  return currencyFirst
    ? currency + " " + formattedValue
    : formattedValue + " " + currency;
}

export function formatInvoiceNr(nr) {
  if (nr === null) return "";
  if (nr === "") return "";
  return nr.slice(0,8) + "-" + nr.slice(8);
}