import React from "react";
import Icon from "./Icon";

// Icons/Core/shopping-basket-line
function Basket(props) {
  const svgPathDParameter = "M12,2 C15.3137085,2 18,4.6862915 18,8 L18,9 L22,9 L22,11 L20.833,11 L20.076,20.083 C20.0328488,20.6011013 19.5998951,20.9997143 19.08,21 L4.92,21 C4.40010491,20.9997143 3.96715122,20.6011013 3.924,20.083 L3.166,11 L2,11 L2,9 L6,9 L6,8 C6,4.6862915 8.6862915,2 12,2 Z M18.826,11 L5.173,11 L5.84,19 L18.159,19 L18.826,11 Z M13,13 L13,17 L11,17 L11,13 L13,13 Z M9,13 L9,17 L7,17 L7,13 L9,13 Z M17,13 L17,17 L15,17 L15,13 L17,13 Z M12,4 C9.86859861,4 8.11157007,5.67126453 8.005,7.8 L8,8 L8,9 L16,9 L16,8 C16,5.86859861 14.3287355,4.11157007 12.2,4.005 L12,4 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default Basket;
