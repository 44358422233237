import React from "react";
import styles from "./RadioSelectButton.module.scss"

function RadioSelectButton(props) {
  const { selected, onClick, children } = props;
  const classes = [
    styles.module,
    selected && styles.uSelected,
    onClick && styles.clickable,
  ];

  const handleClick = (e) => {
    if (onClick) {
      e.stopPropagation();
      onClick(e);
    }
  }

  return (
    <div className={classes.join(" ")}>
      <div className={styles.radiobox} onClick={handleClick}>
        <div className={styles.tick} />
      </div>
      <div className={styles.right}>
        { children }
      </div>
    </div>
  );
}

export default RadioSelectButton;
