import React from "react";
import {connect} from "react-redux";
import FooterActions from "../shared/FooterActions";
import LoadBackgroundImage from "../shared/components/LoadBackgroundImage";
import { buildWebsiteLink } from "../lib/Navigation";
import {showSupport} from "../actions/SupportActions";
import Button from "../shared/components/Button";
import useQueryMap from "../hooks/useQueryMap";
import { useTranslation } from 'react-i18next';
import styles from "./BaseLayout.module.scss";
import SmallMessage from "../shop/shared/SmallMessage";
import LoadingCircle from "../shared/components/forms/LoadingCircle";
import CloseXCircle from "../shop/shared/icons/CloseXCircle";


const BaseLayout = (props) => {
  const {
    showSupport,
    data,
    customErrorMessage = null,
    backgroundImageUrl = "/assets/auth/login-bg.jpg",
    darkBackground = true,
    children,
  } = props;
  const { t } = useTranslation("accounts");
  const query = useQueryMap();
  const logoPath = darkBackground ? "/assets/LogoSimple-White.svg" : "/assets/LogoSimple-Black.svg";
  const baseClasses = [
    styles.module,
    darkBackground ? styles.darkBackground : styles.lightBackground,
    "u-flex-center",
    "u-centered",
  ];

  let content = children;
  if (data.loading) {
    content = (
      <SmallMessage title={t("common:standby")}
        icon={<LoadingCircle color="var(--color)" />}
        message={t("keyed_information.wait")}
      />
    );
  } else if (data.error) {
    const errorMessage = customErrorMessage || t("keyed_information.error")

    content = (
      <SmallMessage title={t("common:error")}
        icon={<CloseXCircle color="var(--color)" />}
        message={errorMessage}
      />
    );
  }

  return (
    <div className={baseClasses.join(" ")}>
      <LoadBackgroundImage
        className={styles.bgImage}
        loadedClassName={styles.bgImageLoaded}
        url={backgroundImageUrl}
      />
      <div className={styles.container}>
        <img
          className={styles.logo}
          src={logoPath}
          alt="Hallstein - Artesian Water"
          width={210}
          height={87}
        />
        
        {content}
        
        <Button label={t("auth:signin.back_to_hallstein")} link
          theme={Button.THEMES.WHITE} className="u-whitespace-small sg-font-bold"
          href={buildWebsiteLink(query)} />

        <div className={styles.footer + " u-centered sg-font-bold"}>
          <FooterActions
            showSupport={showSupport}
            darkBackground={true}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(null, {showSupport})(BaseLayout);
