import {createSubmitRequestTypes} from "./helpers";

export const REQUEST_ADD_ACCOUNT = "ACCOUNT:REQUEST_ADD_ACCOUNT";
export const ADD_ACCOUNT = createSubmitRequestTypes("ACCOUNT:ADD_ACCOUNT");
export const requestAddAccount = () => ({ type: REQUEST_ADD_ACCOUNT });
export const cancelAddAccount = () => ({ type: ADD_ACCOUNT.CANCEL });

export const REQUEST_RENAME_ACCOUNT = "ACCOUNT:REQUEST_RENAME_ACCOUNT";
export const RENAME_ACCOUNT = createSubmitRequestTypes("ACCOUNT:RENAME_ACCOUNT");
export const requestRenameAccount = (accountId) => ({ type: REQUEST_RENAME_ACCOUNT, accountId });
export const cancelRenameAccount = () => ({ type: RENAME_ACCOUNT.CANCEL });

export const YES_NOTIFICATION = "SHOP:YES_NOTIFICATION";
export const NO_NOTIFICATION = "SHOP:NO_NOTIFICATION";
export const yesNotification = () => ({ type: YES_NOTIFICATION});
export const noNotification = () => ({ type: NO_NOTIFICATION});

export const REQUEST_RENAME_SUBSCRIPTION = "SHOP:REQUEST_RENAME_SUBSCRIPTION";
export const RENAME_SUBSCRIPTION = createSubmitRequestTypes("SHOP:REQUEST_RENAME_SUBSCRIPTION");
export const requestRenameSubscription = (accountId, subscriptionId) => ({ type: REQUEST_RENAME_SUBSCRIPTION, accountId, subscriptionId });
export const cancelRenameSubscription = () => ({ type: RENAME_SUBSCRIPTION.CANCEL });

// Modify a subscription's shipping address: uses the address book forms
export const REQUEST_CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS = "SHOP:REQUEST_CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS";
export const CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS = createSubmitRequestTypes("SHOP:CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS");
export const requestChangeSubscriptionShippingAddress = (accountId, subscriptionId) => ({ type: REQUEST_CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS, accountId, subscriptionId });
export const cancelChangeSubscriptionShippingAddress = () => ({ type: CHANGE_SUBSCRIPTION_SHIPPING_ADDRESS.CANCEL });

export const REQUEST_CANCEL_SUBSCRIPTION = "SHOP:REQUEST_CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = createSubmitRequestTypes("SHOP:CANCEL_SUBSCRIPTION");
export const requestCancelSubscription = (accountId, subscriptionId) => ({ type: REQUEST_CANCEL_SUBSCRIPTION, accountId, subscriptionId });
export const cancelCancelSubscription = () => ({ type: CANCEL_SUBSCRIPTION.CANCEL });

export const REQUEST_DELAY_DELIVERY = "SHOP:REQUEST_DELAY_DELIVERY";
export const DELAY_DELIVERY = createSubmitRequestTypes("SHOP:DELAY_DELIVERY");
export const requestDelayDelivery = (accountId, subscriptionId) => ({ type: REQUEST_DELAY_DELIVERY, accountId, subscriptionId });
export const cancelDelayDelivery = () => ({ type: DELAY_DELIVERY.CANCEL });

export const REQUEST_CONFIRM_DELAY_DELIVERY = "SHOP:REQUEST_CONFIRM_DELAY_DELIVERY";
export const CONFIRM_DELAY_DELIVERY = createSubmitRequestTypes("SHOP:CONFIRM_DELAY_DELIVERY");
export const requestConfirmDelayDelivery = (accountId, subscriptionId, desiredDeliveryDate) => ({ type: REQUEST_CONFIRM_DELAY_DELIVERY, accountId, subscriptionId, desiredDeliveryDate });
export const cancelConfirmDelayDelivery = () => ({ type: CONFIRM_DELAY_DELIVERY.CANCEL });

export const REQUEST_PAUSE_SUBSCRIPTION = "SHOP:REQUEST_PAUSE_SUBSCRIPTION";
export const PAUSE_SUBSCRIPTION = createSubmitRequestTypes("SHOP:PAUSE_SUBSCRIPTION");
export const requestPauseSubscription = (accountId, subscriptionId) => ({ type: REQUEST_PAUSE_SUBSCRIPTION, accountId, subscriptionId });
export const cancelPauseSubscription = () => ({ type: PAUSE_SUBSCRIPTION.CANCEL });

export const REQUEST_RESUME_SUBSCRIPTION_SCHEDULING = "SHOP:REQUEST_RESUME_SUBSCRIPTION_SCHEDULING";
export const RESUME_SUBSCRIPTION_SCHEDULING = createSubmitRequestTypes("SHOP:RESUME_SUBSCRIPTION_SCHEDULING");
export const requestResumeSubscriptionScheduling = (accountId, subscriptionId, state) => ({ type: REQUEST_RESUME_SUBSCRIPTION_SCHEDULING, accountId, subscriptionId, state});
export const cancelResumeSubscriptionScheduling = () => ({ type: RESUME_SUBSCRIPTION_SCHEDULING.CANCEL });

export const REQUEST_RESUME_SUBSCRIPTION = "SHOP:REQUEST_RESUME_SUBSCRIPTION";
export const RESUME_SUBSCRIPTION = createSubmitRequestTypes("SHOP:RESUME_SUBSCRIPTION");
export const requestResumeSubscription = (accountId, subscriptionId) => ({ type: REQUEST_RESUME_SUBSCRIPTION, accountId, subscriptionId });
export const cancelResumeSubscription = () => ({ type: RESUME_SUBSCRIPTION.CANCEL });

export const REQUEST_CONFIRM_RESUME_SUBSCRIPTION = "SHOP:REQUEST_CONFIRM_RESUME_SUBSCRIPTION";
export const CONFIRM_RESUME_SUBSCRIPTION = createSubmitRequestTypes("SHOP:CONFIRM_RESUME_SUBSCRIPTION");
export const requestConfirmResumeSubscription = (accountId, subscriptionId, desiredDeliveryDate) => ({ type: REQUEST_CONFIRM_RESUME_SUBSCRIPTION, accountId, subscriptionId, desiredDeliveryDate });
export const cancelConfirmResumeSubscription = () => ({ type: CONFIRM_RESUME_SUBSCRIPTION.CANCEL });

export const REQUEST_ADJUST_SUBSCRIPTION = "SHOP:REQUEST_ADJUST_SUBSCRIPTION";
export const ADJUST_SUBSCRIPTION = createSubmitRequestTypes("SHOP:ADJUST_SUBSCRIPTION");
export const requestAdjustSubscription = (accountId, subscriptionId) => ({ type: REQUEST_ADJUST_SUBSCRIPTION, accountId, subscriptionId });
export const cancelAdjustSubscription = () => ({ type: ADJUST_SUBSCRIPTION.CANCEL });



// Billing

export const REQUEST_ADD_CREDIT_CARD = "SHOP:REQUEST_ADD_CREDIT_CARD";
export const ADD_CREDIT_CARD = createSubmitRequestTypes("SHOP:ADD_CREDIT_CARD");
export const requestAddCreditCard = (accountId) => ({ type: REQUEST_ADD_CREDIT_CARD, accountId });
export const cancelAddCreditCard = () => ({ type: ADD_CREDIT_CARD.CANCEL });

export const REQUEST_CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD = "SHOP:REQUEST_CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD";
export const CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD = createSubmitRequestTypes("SHOP:CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD");
export const requestChangePaymentTermsToCreditCard = (accountId) => ({ type: REQUEST_CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD, accountId });
export const cancelChangePaymentTermsToCreditCard = () => ({ type: CHANGE_PAYMENT_TERMS_TO_CREDIT_CARD.CANCEL });

export const REQUEST_CHANGE_CREDIT_CARD = "SHOP:REQUEST_CHANGE_CREDIT_CARD";
export const CHANGE_CREDIT_CARD = createSubmitRequestTypes("SHOP:CHANGE_CREDIT_CARD");
export const requestChangeCreditCard = (accountId) => ({ type: REQUEST_CHANGE_CREDIT_CARD, accountId });
export const cancelChangeCreditCard = () => ({ type: CHANGE_CREDIT_CARD.CANCEL });

const DEFAULT_TIMEOUT_S = 5;
export const REQUEST_RETRY_PAYMENT = "SHOP:REQUEST_RETRY_PAYMENT";
export const RETRY_PAYMENT = createSubmitRequestTypes("SHOP:RETRY_PAYMENT");
export const requestRetryPayment = (accountId, salesOrderId, timeoutSeconds = DEFAULT_TIMEOUT_S) => ({ type: REQUEST_RETRY_PAYMENT, accountId, salesOrderId, timeoutSeconds });
export const cancelRetryPayment = () => ({ type: RETRY_PAYMENT.CANCEL });

export const REQUEST_CHANGE_BILLING_ADDRESS = "SHOP:REQUEST_CHANGE_BILLING_ADDRESS";
export const CHANGE_BILLING_ADDRESS = createSubmitRequestTypes("SHOP:CHANGE_BILLING_ADDRESS");
export const requestChangeBillingAddress = (accountId, isCreation) => ({ type: REQUEST_CHANGE_BILLING_ADDRESS, accountId, isCreation });
export const cancelChangeBillingAddress = () => ({ type: CHANGE_BILLING_ADDRESS.CANCEL });

export const REQUEST_SET_BILLING_ADDRESS = "SHOP:REQUEST_SET_BILLING_ADDRESS";
export const SET_BILLING_ADDRESS = createSubmitRequestTypes("SHOP:SET_BILLING_ADDRESS");
export const requestSetBillingAddress = (accountId, address) => ({ type: REQUEST_SET_BILLING_ADDRESS, accountId, address });
export const cancelSetBillingAddress = () => ({ type: SET_BILLING_ADDRESS.CANCEL });

export const REQUEST_MODIFY_BILLING = "SHOP:REQUEST_MODIFY_BILLING";
export const MODIFY_BILLING = createSubmitRequestTypes("SHOP:MODIFY_BILLING");
export const requestModifyBilling = (accountId) => ({ type: REQUEST_MODIFY_BILLING, accountId });
export const cancelModifyBilling = () => ({ type: MODIFY_BILLING.CANCEL });

export const REQUEST_SET_TAX_INFO = "SHOP:REQUEST_SET_TAX_INFO";
export const SET_TAX_INFO = createSubmitRequestTypes("SHOP:SET_TAX_INFO");
export const requestSetTaxInfo = (accountId) => ({ type: REQUEST_SET_TAX_INFO, accountId });
export const cancelSetTaxInfo = () => ({ type: SET_TAX_INFO.CANCEL });

export const DOWNLOAD_INVOICE = createSubmitRequestTypes("SHOP:DOWNLOAD_INVOICE");
export const downloadInvoice = (accountId, invoiceNr) => ({ type: DOWNLOAD_INVOICE.SUBMIT, accountId, invoiceNr });

export const REQUEST_MODIFY_ACCOUNT = "SHOP:REQUEST_MODIFY_ACCOUNT";
export const MODIFY_ACCOUNT = createSubmitRequestTypes("SHOP:MODIFY_ACCOUNT");
export const requestModifyAccount = (accountId) => ({ type: REQUEST_MODIFY_ACCOUNT, accountId });
export const cancelModifyAccount = () => ({ type: MODIFY_ACCOUNT.CANCEL });

export const REQUEST_ADJUST_TRANSITION_PREFERENCE = "SHOP:REQUEST_ADJUST_TRANSITION_PREFERENCE";
export const ADJUST_TRANSITION_PREFERENCE = createSubmitRequestTypes("SHOP:ADJUST_TRANSITION_PREFERENCE");
export const requestAdjustTransitionPreference = (accountId, subscriptionId, number8Cases) => ({ type: REQUEST_ADJUST_TRANSITION_PREFERENCE, accountId, subscriptionId, number8Cases });
export const cancelAdjustTransitionPreference = () => ({ type: ADJUST_TRANSITION_PREFERENCE.CANCEL });
