// Adds two amounts together.
// Expecting the format to be "100,00", "1200,40" which is always
// the case for checkout data.
export function addStringAmounts(amount1, amount2) {
  // We multiply by 100, then add together.
  // Then we insert the comma back.
  let result = String(toInteger(amount1) + toInteger(amount2));
  return addDecimal(pad(result));
}

export function formatIntegerToDecimalString(val) {
  return addDecimal(pad(String(val * 100)));
}

export function stringToFloat(val) {
  return parseFloat(String(val).replace(/[.,]/, "."), 10);
}

function toInteger(amount) {
  return parseInt(amount.replace(/[.,]/, ""), 10);
}

function pad(str) {
  return str.padStart(3, "0");
}

function addDecimal(str) {
  return `${str.substring(0, str.length-2)}.${str.substring(str.length-2)}`;
}


export const WALLET_CARD_TYPES = ["google_pay", "apple_pay"];
export const ALL_CARD_TYPES = ["card"].concat(WALLET_CARD_TYPES);
