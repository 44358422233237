import {List} from "immutable";

export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_AUTH_CLIENTID,
  callbackUrl: process.env.REACT_APP_AUTH_CALLBACKURL,
  audience: process.env.REACT_APP_AUTH_AUDIENCE
}

export const VERSION = process.env.REACT_APP_VERSION;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const SOCKET_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;
export const WAITING_LIST_API_ENDPOINT = process.env.REACT_APP_WAITING_LIST_API_ENDPOINT;
export const WEBSITE_ROOT = process.env.REACT_APP_WEBSITE_ROOT;
export const US_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_US_STRIPE_PUBLISHABLE_KEY;
export const EU_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_EU_STRIPE_PUBLISHABLE_KEY;
export const CH_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_CH_STRIPE_PUBLISHABLE_KEY;
export const CA_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_CA_STRIPE_PUBLISHABLE_KEY;
export const UK_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_UK_STRIPE_PUBLISHABLE_KEY;
export const LOGOUT_RETURN_URL = process.env.REACT_APP_LOGOUT_RETURN_URL;
export const CUSTOMER_SUPPORT_EMAIL = process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL;
export const SUPPORT_DOCS_ENDPOINT = process.env.REACT_APP_SUPPORT_DOCS_ENDPOINT;
export const INVOICES_DOWNLOAD_URL = process.env.REACT_APP_INVOICES_DOWNLOAD_URL;
export const GOOGLE_TAGMANAGER_ID = process.env.REACT_APP_GOOGLE_TAGMANAGER_ID;
export const PRISMIC_REPOSITORY = process.env.REACT_APP_PRISMIC_REPOSITORY;
export const PRISMIC_GRAPHQL_ENDPOINT= process.env.REACT_APP_PRISMIC_GRAPHQL_ENDPOINT;
export const PERMANENT_QUERY_PARAMS = process.env.REACT_APP_PERMANENT_QUERY_PARAMS;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const COOKIE_DOMAIN_SECURE = process.env.REACT_APP_COOKIE_DOMAIN_SECURE;

export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;

export function getUseSecureCookieDomain() {
  return COOKIE_DOMAIN_SECURE === "YES";
}

export function getStripePublishableKey(shopId) {
  switch (shopId) {
    case "alpine-water-gmbh":
      return EU_STRIPE_PUBLISHABLE_KEY;

    case "alpine-water-usa-llc":
      return US_STRIPE_PUBLISHABLE_KEY;

    case "alpine-water-schweiz-gmbh":
      return CH_STRIPE_PUBLISHABLE_KEY;

    case "hallstein-water-canada-inc":
      return CA_STRIPE_PUBLISHABLE_KEY;
      
    case "alpine-water-uk":
      return UK_STRIPE_PUBLISHABLE_KEY;
      
    default:
      throw new Error(`No Stripe key for jurisdiction ${shopId}`);
  }
}

export function getPermanentQueryParams() {
  return List(PERMANENT_QUERY_PARAMS.split(","))
}

export function isProduction() {
  return process.env.REACT_APP_ENV === "production";
}

export function isStaging() {
  return process.env.REACT_APP_ENV === "staging";
}

export function isDevelopment() {
  return !isProduction();
}

export function getEnvironment() {
  if (isProduction()) return "Production";
  if (isStaging()) return "Staging";
  return "Development"
}