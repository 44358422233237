import {takeLatest} from "redux-saga";
import {fork, put, take, race} from "redux-saga/effects";
import {generateUUID} from "../lib/UUID";
import MessageBus from "../lib/MessageBus";
import {showModalWindow, removeModalWindow} from "../actions/WindowActions";
import {showNotification} from "../actions/NotificationActions";
import i18n from '../i18n';

import {
  REQUEST_EDIT_PROFILE,
  EDIT_PROFILE,
  REQUEST_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
  REQUEST_CHANGE_LOCALE,
  CHANGE_LOCALE,
} from "../actions/LoginActions";

function* doRequestChangePassword() {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangePasswordWindow", {}, i18n.t("settings:profile.modify_password.popup.title")));

  // eslint-disable-next-line
  const {cancelled, succeeded} = yield race({
    cancelled: take((action) => action.type === CHANGE_PASSWORD.CANCEL),
    succeeded: take((action) => action.type === CHANGE_PASSWORD.SUCCESS),
  })

  if (succeeded) {
    yield put(showNotification(generateUUID(), i18n.t("settings:profile.modify_password.on_success")));
  }
  yield put(removeModalWindow(windowId));  
}

function* watchRequestChangePassword() {
  yield takeLatest(REQUEST_CHANGE_PASSWORD, doRequestChangePassword);
}

function* doRequestChangeLocale() {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangeLocaleWindow", {}, i18n.t("settings:language.popup.title")));

  // eslint-disable-next-line
  const {cancelled, succeeded} = yield race({
    cancelled: take((action) => action.type === CHANGE_LOCALE.CANCEL),
    succeeded: take((action) => action.type === CHANGE_LOCALE.SUCCESS),
  })

  if (succeeded) {
    yield put(showNotification(generateUUID(), i18n.t("settings:language.on_success")));
  }
  yield put(removeModalWindow(windowId));  
}

function* watchRequestChangeLocale() {
  yield takeLatest(REQUEST_CHANGE_LOCALE, doRequestChangeLocale);
}

function* doRequestEditProfile() {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "EditProfileWindow", {}, i18n.t("settings:profile.modify_profile.popup.title")));

  // eslint-disable-next-line
  const {cancelled, succeeded} = yield race({
    cancelled: take((action) => action.type === EDIT_PROFILE.CANCEL),
    succeeded: take((action) => action.type === EDIT_PROFILE.SUCCESS),
  })

  if (succeeded) {
    yield put(showNotification(generateUUID(), i18n.t("settings:profile.modify_profile.on_success")));
    MessageBus.publish("auth", "profileUpdated", {name: succeeded.name});
  }
  yield put(removeModalWindow(windowId));  
}

function* watchRequestEditProfile() {
  yield takeLatest(REQUEST_EDIT_PROFILE, doRequestEditProfile);
}

export default function* AuthSaga() {
  yield fork(watchRequestChangePassword);
  yield fork(watchRequestChangeLocale);
  yield fork(watchRequestEditProfile);
}