import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {cartPopulated, cartPopulationFailed} from "../../actions/AnonymousCheckoutActions";
import {createErrors} from "../../lib/ErrorFormatter";
import SmallMessage from "../../shop/shared/SmallMessage";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { useTranslation } from "react-i18next";


const PopulateCartFormContainer = ({cartId, accountId, transferAnonymousCartToAccount, cartPopulated, cartPopulationFailed}) => {
  const { t } = useTranslation("checkout");
  const [status, setStatus] = useState("idle");
  // eslint-disable-next-line no-unused-vars
  const [errorMsg, setErrorMsg] = useState("");
  // We want to start populating the cart as soon as mounted
  // We will then monitor and wait for the cart to have been populated.

  useEffect(() => {
    function startTransfer(cartId, accountId) {
      transferAnonymousCartToAccount({anonymousCartId: cartId, accountId: accountId})
      .then((response) => {
        if (response.data.customerTransferAnonymousCartToAccount.errors.length <= 0) {
          setStatus("waiting-checkout");
        } else {
          console.log("ERRORS", response.data.customerTransferAnonymousCartToAccount.errors);
          setStatus("failed");
        }
      })
      .catch((err) => {
        setErrorMsg(createErrors(err)._error);
        setStatus("failed");
      })
    }

    if (status === "idle") {
      setStatus("processing");
      startTransfer(cartId, accountId);
    }
  }, [cartId, accountId, status, transferAnonymousCartToAccount]);

  useEffect(() => {
    if (status === "waiting-checkout") {
      cartPopulated();
    } else if (status === "failed") {
      cartPopulationFailed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <SmallMessage title={t("preparing.title")}
      icon={<LoadingCircle />}
      message={t("preparing.adding_products")} />
  )    
}

const TRANSFER_CART = gql`
  mutation customerTransferAnonymousCartToAccount($data: TransferAnonymousCartToAccountInput!) {
    customerTransferAnonymousCartToAccount(input: $data) {
      errors { key message }
    }
  }
`

const withMutation = compose(
  graphql(TRANSFER_CART, {
    props: ({ mutate }) => ({
      transferAnonymousCartToAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default connect(null, {cartPopulated, cartPopulationFailed})(withMutation(PopulateCartFormContainer));