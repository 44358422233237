import React from "react";
import styles from "./Panel.module.scss";

function Subpanel(props) {
  const {title, subtitle, content, button} = props;
  
  const titleElement = title && <div className={styles.title}>{title}</div>
  const subtitleElement = subtitle && <div className={styles.subtitle}>{subtitle}</div>;
  const contentOrChildren = content || props.children;
  const contentElement = contentOrChildren && <div className={styles.content}>{contentOrChildren}</div>;
  const actionsClasses = [styles.actions];
  
  return (
    <div className={styles.subpanel + " subpanel"}>
      <div className={styles.labels}>
        { titleElement }
        { subtitleElement }
        { contentElement }
      </div>

      <div className={actionsClasses.join(" ")}>
        {button}
      </div>
    </div>
  )
}

export default Subpanel;
