import React, {Component} from "react";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import ToggleButton from "../../shared/components/forms/ToggleButton";
import { withTranslation } from "react-i18next";
import LeftRight from "../shared/LeftRight";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class NotificationsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      paymentReceiptsSubmitting: false,
      shipmentNotificationsSubmitting: false,
      subscriptionPaymentReceiptsSubmitting: false
    }
  }

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountId && (prevProps.accountId !== this.props.accountId)) {
      this.subscribe();
    }
  }

  subscribe() {
    if (this.unsubscribeNotificationChanges) {
      this.unsubscribeNotificationChanges();
    }
    this.unsubscribeNotificationChanges = this.props.subscribeToNotificationChanges(this.props, {});
  }


  render() {
    let {data} = this.props;
    if (data.loading && !data.customerAccountNotifications) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    const notifications = fromJS(data.customerAccountNotifications).get("notifications");

    let paymentReceipts = "off"; // payment_receipts
    let shipmentNotifications = "off"; // shipments
    let subscriptionPaymentReceipts = "off"; // subscription_payment_receipts

    if (notifications.includes("payment_receipts")) {
      paymentReceipts = "on";
    }

    if (notifications.includes("shipments")) {
      shipmentNotifications = "on";
    }

    if (notifications.includes("subscription_payment_receipts")) {
      subscriptionPaymentReceipts = "on";
    }

    return (
      <div className="sg-text-normal">
        <LeftRight customStyle={{"marginBottom": "18px"}}
          left={this.props.t("notifications.shipment")}
          right={
          <ToggleButton state={shipmentNotifications}
            submitting={this.state.shipmentNotificationsSubmitting}
            onToggle={this.handleToggleShipmentNotifications.bind(this)} />
        } />
        <LeftRight customStyle={{"marginBottom": "18px"}}
          left={this.props.t("notifications.sub_receipts")}
          right={
          <ToggleButton state={subscriptionPaymentReceipts}
            submitting={this.state.subscriptionPaymentReceiptsSubmitting}
            onToggle={this.handleToggleSubscriptionPaymentReceipts.bind(this)} />
        } />
        <LeftRight customStyle={{"marginBottom": "18px"}}
          left={this.props.t("notifications.one_time_receipts")}
          right={
          <ToggleButton state={paymentReceipts}
            submitting={this.state.paymentReceiptsSubmitting}
            onToggle={this.handleTogglePaymentReceipts.bind(this)} />
        } />
      </div>
    )
  }

  enableNotification(notification, updatedState) {
    return this.props.customerEnableNotification({accountId: this.props.accountId, notification: notification})
      .then((response) => {
        if (response.data.customerEnableNotification.errors.length <= 0) {
          this.setState(updatedState);
        }
      })
      .catch((err) => {
        console.warn("Error", err);
        this.setState(updatedState);
      })    
  }

  disableNotification(notification, updatedState) {
    return this.props.customerDisableNotification({accountId: this.props.accountId, notification: notification})
      .then((response) => {
        if (response.data.customerDisableNotification.errors.length <= 0) {
          this.setState(updatedState);
        }
      })
      .catch((err) => {
        console.warn("Error", err);
        this.setState(updatedState);
      })    
  }

  handleToggleShipmentNotifications(value) {
    this.setState({shipmentNotificationsSubmitting: true});
    if (value === "on") {
      this.enableNotification("shipments", {shipmentNotificationsSubmitting: false});
    } else {
      this.disableNotification("shipments", {shipmentNotificationsSubmitting: false});
    }
  }

  handleToggleSubscriptionPaymentReceipts(value) {
    this.setState({subscriptionPaymentReceiptsSubmitting: true});
    if (value === "on") {
      this.enableNotification("subscription_payment_receipts", {subscriptionPaymentReceiptsSubmitting: false});
    } else {
      this.disableNotification("subscription_payment_receipts", {subscriptionPaymentReceiptsSubmitting: false});
    }
  }

  handleTogglePaymentReceipts(value) {
    this.setState({paymentReceiptsSubmitting: true});
    if (value === "on") {
      this.enableNotification("payment_receipts", {paymentReceiptsSubmitting: false});
    } else {
      this.disableNotification("payment_receipts", {paymentReceiptsSubmitting: false});
    }
  }

}

const ENABLE_NOTIFICATION = gql`
  mutation customerEnableNotification($data: NotificationInput!) {
    customerEnableNotification(input: $data) {
      errors { key message }
    }
  }
`;

const DISABLE_NOTIFICATION = gql`
  mutation customerDisableNotification($data: NotificationInput!) {
    customerDisableNotification(input: $data) {
      errors { key message }
    }
  }
`;

const ACCOUNT_NOTIFICATIONS = gql`
  query customerAccountNotifications($accountId:ID!) {
    customerAccountNotifications(accountId:$accountId) {
      accountId
      notifications
    }
  }
`;

const withQueries = compose(
  graphql(ACCOUNT_NOTIFICATIONS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ENABLE_NOTIFICATION, {
    props: ({ mutate }) => ({
      customerEnableNotification: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(DISABLE_NOTIFICATION, {
    props: ({ mutate }) => ({
      customerDisableNotification: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
); 

const ON_UPDATED_ACCOUNT_NOTIFICATIONS = SubscriptionQueryWrapper("$accountId: ID!", `
  updatedAccountNotifications(accountId: $accountId) {
    accountId
  }`
);

const mapDispatchToProps = dispatch => ({
  subscribeToNotificationChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ACCOUNT_NOTIFICATIONS,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  }
})


export default withTranslation("accounts")(connect(null, mapDispatchToProps)(withQueries(NotificationsPanel)));
