import React, {Component} from "react";
import {connect} from "react-redux";
import { submit, isValid, isSubmitting, getFormValues } from "redux-form/immutable";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import GraphQLErrors from "../shared/GraphQLErrors";
import Button from "../shared/components/Button";
import ShopHeader from "./shared/ShopHeader";
import ShopHeaderMobile from "./shared/HeaderMobile";
import SubscriptionNameForm from "./forms/SubscriptionNameForm";
import {NOTIFICATION_KINDS, showNotification} from "../actions/NotificationActions";
import {getMediaQuerySize} from "../reducers/SystemReducer";
import MultiSubscriptionForm from "./forms/MultiSubscriptionForm";
import {hasProductInCartRequiringDispenser} from "../lib/ShopTools";
import {validateGreaterThan, validatePresence} from "../lib/Validators";
import {Map, fromJS} from "immutable";
import {requestQuantityAssistantForForm} from "../actions/ShopActions";
import {createValidationErrors, createErrors} from "../lib/ErrorFormatter";
import Navigation from "../lib/Navigation";
import Basket from "./shared/icons/Basket";
import "./SubPage.scss";
import withCms from "../hocs/withCms";
import { getProductForJurisdiction } from "../lib/ProductsInformation";
import LoadingLine from "../shared/components/forms/LoadingLine";
import { withTranslation } from "react-i18next";


// Two steps: define name then select products
// The state.subscriptionName being no empty brings the user to step 2.
class SubPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      subscriptionName: "", // Must be non empty to go to step 2
      selectedProductsString: "", // displays the current selection in the footer
      errors: [],
    }
  }

  validateNameForm(values) {
    let errors = Map();
    errors = validatePresence(errors, values, "name");
    return errors.toJS();
  }

  validateProductsForm(values) {
    let errors = Map();

    // Validate only when there are products as the CMS product data might
    // not be loaded right away
    if (values.get("products")) {
      errors = validateGreaterThan(errors, values, "frequencyWeeks", 0);

      // At least 1 product needs to have quantity above 0.
      // Products with 0 will be removed before sending.
      // We also need to build a string with the currently selected products
      const normalizeTitle = (title) => title.replace("Hallstein", "");
      const productsString = values.get("products").filter((value) => value.get("quantity") > 0).map((value) => {
        const quantity = value.get("quantity");
        const name = normalizeTitle(value.get("productTitle"));

        return `${quantity} x ${name}`;
      }).join(" & ");

      // User can select no products
      // if (productsString === "") {
      //   errors = errors.set("products", Map({_error: "Please select products and quantities before continuing."}));
      // }
      this.setState({ selectedProductsString: productsString });
    }

    return errors.toJS();
  }

  handleRemoteSubmit() {
    console.error("Cannot handle remote submit for step " + this.props.step);
  }

  handleAddSubscriptionToCartSuccess(updatedValues) {
    if (typeof this.props.onSuccess === "function") {
      this.props.showSuccessNotification("Your subscription was modified.");
      return this.props.onSuccess();
    }

    if (hasProductInCartRequiringDispenser(updatedValues.get("products"))) {
      Navigation.gotoDispenserGuide(this.props.accountId);
    } else {
      Navigation.gotoCart(this.props.accountId);
    }
  }

  handleNameSubmit(values, callAfterStateUpdate = null) {
    this.setState({ subscriptionName: values.get("name") }, callAfterStateUpdate);
    
    if (typeof this.props.gotoNextStep === "function") {
      return this.props.gotoNextStep();
    }
  }

  handleRemoveSubscription() {
    return this.props.customerRemoveSubscriptionFromCart({accountId: this.props.accountId})
      .then((response) => {
        if (response.data.customerRemoveSubscriptionFromCart.errors.length <= 0) {
          this.props.showSuccessNotification("Your subscription was removed.");
          return this.props.onSuccess();
        } else {
          let errors = createValidationErrors(response.data.customerRemoveSubscriptionFromCart.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });
  }

  handleProductsSubmit(values) {
    let updatedProducts = 
      values.get("products")
      // Remove any item that has 0 quantity.
      .filter(x => x.get("quantity") > 0)
      // Just add sku and quantity.
      .map(x => {
        return fromJS({
          productSku: x.get("productSku"),
          quantity: x.get("quantity")
        })
      })
      
    let updatedValues = Map({
      accountId: this.props.accountId,
      frequencyWeeks: values.get("frequencyWeeks"),
      products: updatedProducts,
      name: this.state.subscriptionName,
    })

    return this.props.customerAddSubscriptionToCart(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerAddSubscriptionToCart.errors.length <= 0) {
          this.handleAddSubscriptionToCartSuccess(updatedValues);
        } else {
          if (response.data.customerAddSubscriptionToCart.errors[0].key === "products") {
            // User selected only one bottle case
            if (this.props.formOnly) {
              const error = {
                products: "The minimum subscription is either one 5-Gallon Bottle or two 750ml 6-Bottle Cases",
              }
              this.setState({errors: error});
            }
            else {
              // Display minimum subscription as a modal
              // this.props.requestShowMinimumSubscriptionWarning();
            }
          } else {
            const errors = createValidationErrors(response.data.customerAddSubscriptionToCart.errors);
            this.setState({errors: errors});
          }
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
        window.alert("An error occured while adding the water subscription. Please try again.");
      });
  }

  handleCancel() {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
    else if (this.props.routePathName.lastIndexOf("cart/subscription") > -1) {
      // redirect back to cart
      Navigation.gotoCart(this.props.accountId);
    } else {
      // redirect back to shop
      Navigation.gotoShop(this.props.accountId);
    }
  }

  render() {
    let {error, customerSubscriptionProducts, subscriptionFrequencyOptions, customerCart} = this.props.data;
    const loading = this.props.forceLoading || this.props.data.loading;

    const shopHeaderOptions = {
      boundary: this.props.boundary,
      accountId: this.props.accountId,
    };
    const showMobileHeader = this.props.isNarrowSize;
    const shopHeader = (
      <React.Fragment>
        <ShopHeader show={!showMobileHeader} {...shopHeaderOptions} />
        <ShopHeaderMobile show={showMobileHeader} {...shopHeaderOptions} />
      </React.Fragment>
    );
    
    const baseClasses = ["SubPage", showMobileHeader && "withMobileHeader"];

    if (loading) {
      if (this.props.formOnly) {
        return (<LoadingLine />);
      }

      return (
        <div className={baseClasses.join(" ")}>
          <div className="inner">
          <LoadingLine />
          </div>

          { shopHeader }
        </div>
      )
    }
    if (error) return <GraphQLErrors error={error} />

    const cart = fromJS(customerCart);

    let formForName = null;
    let formForWater = null;
    let commentForStep = null;

    if (this.props.formOnly) {
      baseClasses.push("chooseSubName");

      const initialValues = {
        name: cart.get("subscriptionName") || this.state.subscriptionName,
      };

      formForName = (
        <SubscriptionNameForm
          initialValues={initialValues}
          errors={this.state.errors}
          validate={(values) => this.validateNameForm(values)}
          onSubmit={(values) => this.handleNameSubmit(values)}
          hideSubmitButton={true}
          />
      );
      commentForStep = "Give your subscription a memorable name and click continue";
      
      baseClasses.push("chooseProducts");

      let availableProducts = fromJS(customerSubscriptionProducts);
      let frequencyOptions = fromJS(subscriptionFrequencyOptions);

      formForWater = this.renderProducts(availableProducts, frequencyOptions, cart, this.props.formOnly);

      if (this.state.selectedProductsString !== "") {
        commentForStep = (
          <React.Fragment>
            <Basket colorDisabled="var(--grey-900)" className="basket"/>
            <span>{ this.state.selectedProductsString }</span>
          </React.Fragment>
        );
      }
    }

    if (this.props.formOnly) {
      const actionButton = this.state.selectedProductsString !== ""
        ? <Button label={this.props.t("save")}
            active={this.props.valid && !this.props.submitting}
            onClick={() => {
              this.handleNameSubmit(this.props.nameFormValues, () => this.handleProductsSubmit(this.props.multiFormValues));
            }} />
        : <Button label={this.props.t("remove_subscription")}
            active={this.props.valid && !this.props.submitting}
            onClick={() => {
              this.handleRemoveSubscription();
            }} />;

      return (
        <React.Fragment>
          <p className="u-centered grey-900">{this.props.t("modify_subscription_text")}</p>

          {/* Faking one form for modal styling */}
          <div className="form">
            <div className="fields">
              { formForName }
              { formForWater }
            </div>
          </div>

          <div className="buttons">
            { actionButton }
            <Button label={this.props.t("common:cancel")}
              onClick={this.handleCancel.bind(this)}
              theme={Button.THEMES.BLUE_OUTLINE}
              addCancelToTopRight={true} />
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={baseClasses.join(" ")}>
        <div className="inner">
          { formForName && 
            <div>
              <h1>Start a Hallstein Subscription</h1>
              <p>Give your subscription a name to start</p>
              { formForName }
            </div>
          }
          { formForWater }
        </div>

        <div className="SubscriptionFooter footer">
          <p>{ commentForStep }</p>
          <Button label="Continue"
            active={this.props.valid && !this.props.submitting}
            onClick={this.handleRemoteSubmit.bind(this)} />
        </div>

        {/* Display header last so no absolutely positionned elements may overlap */}
        { shopHeader }
      </div>
    ) 
  }

  renderProducts(availableProducts, frequencyOptions, cart, basicForm) {
    if (!this.props.cmsLoaded) {
      return <LoadingLine />;
    }
    
    // Get all subscription products in cart
    let mappedQuantities = 
      cart.get("items").filter(x => x.get("subscription") === true)
          .reduce((acc, x) => {
            return acc.set(x.get("productSku"), x.get("quantity"));
          }, Map());

    let products = availableProducts.map(x => {
      // Get more details for fixtures.
      const item = getProductForJurisdiction(this.props.cmsData, cart.get("shopId"), x.get("productSku"));
      if (item) {
        return x.set("quantity", mappedQuantities.get(x.get("productSku"), 0))
                .set("description", item.get("description"))
                .set("note", item.get("note"));
      } else {
        return x.set("quantity", mappedQuantities.get(x.get("productSku"), 0))
                .set("description", x.get("description"))
                .set("note", x.get("note"));

      }
    })

    let frequencyWeeks = 4; // default
    if (cart.get("subscription") === true) {
      frequencyWeeks = cart.get("subscriptionFrequencyWeeks");
    }

    let initialValues = Map({
      frequencyWeeks: frequencyWeeks,
      products: products
    });

    return (
      <MultiSubscriptionForm
        showFrequency={false}
        initialValues={initialValues}
        surcharges={Map()}
        disclaimer={this.props.t("common:surcharge_disclaimer")}
        frequency={frequencyOptions}
        errors={this.state.errors}
        validate={(values) => this.validateProductsForm(values)}
        showSubmitButtons={false}
        onSubmit={this.handleProductsSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onQuantityAssistant={this.handleQuantityAssistant.bind(this)}
        basicForm={basicForm}
        />
    )
  }

  handleQuantityAssistant(index) {
    this.props.requestQuantityAssistantForForm("MultiSubscriptionForm", `products[${index}].quantity`, "frequencyWeeks")
  }

}

const mapStateToProps = (state) => {
  return {
    isMediumSize: getMediaQuerySize(state.get("system")) === "mq-md",
    isNarrowSize: getMediaQuerySize(state.get("system")) === "mq-sm",
    valid: isValid("MultiSubscriptionForm")(state),
    submitting: isSubmitting("MultiSubscriptionForm")(state),
    nameFormValues: getFormValues("SubscriptionNameForm")(state),
    multiFormValues: getFormValues("MultiSubscriptionForm")(state),
  }
}

// Get shop subscription products

const SUBSCRIPTION_PRODUCTS = gql`
  query customerSubscriptionProducts($accountId:ID!) {
    customerSubscriptionProducts(accountId:$accountId) {
      shopId
      productSku
      productTitle
      pricing {
        value
        currency
      }
    }
    subscriptionFrequencyOptions {
      value
      title
    }
    customerCart(accountId:$accountId) {
      shopId
      allowedSubscriptions
      allowedWaterOrders
      allowedProductOrders
      subscriptionFrequencyWeeks
      subscription
      subscriptionName
      items {
        productSku,
        quantity,
        subscription
      }
    }
  }
`;

const ADD_SUBSCRIPTION_TO_CART = gql`
  mutation customerAddSubscriptionToCart($data: CustomerAddSubscriptionToCartInput!) {
    customerAddSubscriptionToCart(input: $data) {
      errors { key message}
    }
  }
`;

const REMOVE_SUBSCRIPTION_FROM_CART = gql`
  mutation customerRemoveSubscriptionFromCart($data: CustomerAccountInput!) {
    customerRemoveSubscriptionFromCart(input: $data) {
      errors { key message }
    }
  }
`;


const withQuery = compose(
  graphql(SUBSCRIPTION_PRODUCTS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ADD_SUBSCRIPTION_TO_CART, {
    props: ({mutate}) => ({
      customerAddSubscriptionToCart: (data) => mutate({
        variables: {data: data}
      })
    })
  }),
  graphql(REMOVE_SUBSCRIPTION_FROM_CART, {
    props: ({ mutate }) => ({
      customerRemoveSubscriptionFromCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

const mapDispatchToProps = dispatch => ({
  requestQuantityAssistantForForm: (name, quantityField, frequencyWeeksField) => {
    dispatch(requestQuantityAssistantForForm(name, quantityField, frequencyWeeksField));
  },
  submit: (form) => {
    dispatch(submit(form));
  },
  showSuccessNotification: (title) => {
    dispatch(showNotification("subscription-change", title, NOTIFICATION_KINDS.SUCCESS))
  },
})

export default withTranslation("cart")(connect(mapStateToProps, mapDispatchToProps)(withQuery(withCms(SubPage))));