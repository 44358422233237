import React, {useEffect, useRef} from "react";
import { NOTIFICATION_KINDS } from "../actions/NotificationActions";
import Checked from "../shop/shared/icons/Checked";
import Forbidden from "../shop/shared/icons/Forbidden";
import Error from "../shop/shared/icons/Error";
import gsap from "gsap";
import styles from "./NotificationsContainer.module.scss";

const Notification = (props) => {
  const { message, kind = NOTIFICATION_KINDS.SUCCESS, } = props;

  const refModule = useRef(null);
  const refIcon = useRef(null);

  useEffect(() => {
    const animation = gsap.timeline({paused: true});

    gsap.set(refModule.current, {opacity: 0});
    gsap.set(refIcon.current, {scale: 0});
    
    animation
      .to(refModule.current, {opacity: 1, duration: 0.3, delay: 0.5 })
      .to(refIcon.current, {scale: 1, duration: 0.6, ease: "back.out(4)"}, "<")
      .play();

    return () => animation.kill()
  }, []);

  let icon = null;
  switch (kind) {
    case NOTIFICATION_KINDS.SUCCESS:
      icon = <Checked colorDisabled="var(--accent-green-600)" />
      break;
    case NOTIFICATION_KINDS.WARNING:
      icon = <Error colorDisabled="var(--accent-red-600)" />
      break;
    case NOTIFICATION_KINDS.ERROR:
      icon = <Forbidden colorDisabled="var(--accent-red-600)" />
      break;
    default:
      icon = null;
  }

  const withPhoto = !!props.photo;
  var classes = [
    styles.notification,
    `Notification-${kind}`,
    withPhoto ? styles.withPhoto : styles.simple,
  ];

  const commonProps = {
    message: message,
    icon: icon,
    refIcon: refIcon,
  }
  
  return (
    <div className={classes.join(" ")} id={props.id} ref={refModule}>
      {withPhoto ? <NotificationWithPhoto {...commonProps} photoUrl={props.photoUrl} /> : <NotificationSimple {...commonProps} />}
    </div>
  );
}

function NotificationSimple({message, icon, refIcon}) {
  return (
    <>
      <div ref={refIcon} className={styles.icon}>
        { icon }
      </div>
      <Message message={message} />
    </>
  );
}

function NotificationWithPhoto({photoUrl, message, icon, refIcon}) {
  const photoStyle = {
    backgroundImage: `url(${photoUrl})`,
  }

  return (
    <>
      <div className={styles.icon} ref={refIcon}>{icon}</div>
      <div className={styles.col1}>
        <div className={styles.photo} style={photoStyle} />
      </div>
      <div className={styles.col2}>
        <Message message={message} />
        {/* {renderUndo()} */}
      </div>
    </>
  );
}

function Message({message}) {
  // Message could contain several lines
  const lines = message.map
    ? message.map((l, index) => <span key={index}>{l}</span>)
    : message;

  return <p className={styles.message}>{lines}</p>;
}


export default Notification;