import React from "react";
import { cx, addClassName } from "../lib/ClassSet";
import "./CommandWindow.scss";
import GuideHeader from "../GuideHeader";

const CommandWindow = (props) => {
  let styles = {
    zIndex: props.index
  }
  if (props.options.height) {
    styles.height = props.options.height
  }

  let classes = cx({
    "CommandWindow": true,
    "s-active": props.active,
    "s-inactive": !props.active,
    "s-modal": props.modal,
    "s-headless": props.headless,
    "s-invisible": props.invisible,
    "centeredContent": props.centered,
  })

  classes = addClassName(true, classes, `kind-${props.kind}`);

  return (
    <div className={classes} style={styles}>
      <div className="inner">
        <div className="header">
          <GuideHeader title={props.title} />
        </div>
        <div className="content size-large">
          {props.children}
        </div>
      </div>
    </div>
  );

}

CommandWindow.defaultProps = {
  title: "Command Window",
  index: 0, // zIndex so we know how to stack em.
  active: true,
  modal: false,
  headless: false,
  options: {}
}

export default CommandWindow;