import React from "react";
import {connect} from "react-redux";
import EditSubPage from "../EditSubPage";
import {
  cancelAdjustSubscription
} from "../../actions/AccountActions";

class AdjustSubscriptionFormContainer extends React.Component {

  handleClose() {
    this.props.cancelAdjustSubscription();
  }

  render() {
    return (
      <div className="form">
        <EditSubPage accountId={this.props.accountId}
          formOnly={true}
          subscriptionId={this.props.subscriptionId}
          onCancel={this.handleClose.bind(this)}
          onSuccess={this.handleClose.bind(this)} />
      </div>
    );
  }
}

export default connect(null, {
  cancelAdjustSubscription,
  })(AdjustSubscriptionFormContainer);

