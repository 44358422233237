import {takeLatest} from "redux-saga";
import {fork, put, take, race} from "redux-saga/effects";
import {generateUUID} from "../lib/UUID";
import * as ShopActions from "../actions/ShopActions";
import * as AddressBookActions from "../actions/AddressBookActions";
import {change as changeFormValue} from "redux-form";
import {showModalWindow, updateModalWindow, removeModalWindow} from "../actions/WindowActions";
import {showNotification} from "../actions/NotificationActions";
import Navigation from "../lib/Navigation";
import {Map} from "immutable";
import i18n from "../i18n";


// Show a warning modal when the gift state is wrong.
function* requestShowGiftingDiscrepancy(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "GiftingDiscrepancy", { message: baseAction.message, savedMessage: baseAction.savedMessage }, i18n.t("checkout:gifting_discrepancy.title"), Map({headless: true})));
  yield take([ShopActions.SHOW_GIFTING_DISCREPANCY.CANCEL, ShopActions.SHOW_GIFTING_DISCREPANCY.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestShowGiftingDiscrepancy() {
  yield takeLatest(ShopActions.REQUEST_SHOW_GIFTING_DISCREPANCY, requestShowGiftingDiscrepancy);
}


// Add subscription to cart.
function* requestAddSubscriptionToCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AddSubscriptionToCartWindow", {accountId: baseAction.accountId, quantity: baseAction.quantity, frequencyWeeks: baseAction.frequencyWeeks}, i18n.t("cart:add_subscription")));
  // eslint-disable-next-line
  const {cancelled, succeeded, assistant} = yield race({
    cancelled: take((action) => action.type === ShopActions.ADD_WATER_SUBSCRIPTION_TO_CART.CANCEL),
    succeeded: take((action) => action.type === ShopActions.ADD_WATER_SUBSCRIPTION_TO_CART.SUCCESS),
    statusChange: take((action) => action.type === ShopActions.REQUEST_QUANTITY_ASSISTANT)
  })
  yield put(removeModalWindow(windowId));
  // eslint-disable-next-line
  const {cancelledAssistant, succeededAssistant} = yield race({
    cancelledAssistant: take((action) => action.type === ShopActions.QUANTITY_ASSISTANT.CANCEL),
    succeededAssistant: take((action) => action.type === ShopActions.QUANTITY_ASSISTANT.SUCCESS),
  });
  if (succeededAssistant) {
    yield put(ShopActions.requestAddWaterSubscriptionToCart(baseAction.accountId, succeededAssistant.quantity, succeededAssistant.frequencyWeeks));
  } else {
    yield put(ShopActions.requestAddWaterSubscriptionToCart(baseAction.accountId, baseAction.quantity, baseAction.frequencyWeeks));
  }
}
function* watchRequestAddSubscriptionToCart() {
  yield takeLatest(ShopActions.REQUEST_ADD_WATER_SUBSCRIPTION_TO_CART, requestAddSubscriptionToCart);
}

// Request modify subscription.
function* requestAdjustSubscriptionInCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AdjustSubscriptionInCartWindow", {accountId: baseAction.accountId}, i18n.t("cart:modify_subscription")));
  yield take([ShopActions.ADJUST_SUBSCRIPTION.CANCEL, ShopActions.ADJUST_SUBSCRIPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAdjustSubscriptionInCart() {
  yield takeLatest(ShopActions.REQUEST_ADJUST_SUBSCRIPTION_IN_CART, requestAdjustSubscriptionInCart);
}

// Add subscription to cart, update if necessary. No form.
function* requestAutoAddSubscriptionToCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AutoAddSubscriptionToCartWindow", {accountId: baseAction.accountId, quantity: baseAction.quantity, frequencyWeeks: baseAction.frequencyWeeks}, i18n.t("cart:adding_subscription")));
  yield take([ShopActions.AUTO_ADD_WATER_SUBSCRIPTION_TO_CART.CANCEL, ShopActions.AUTO_ADD_WATER_SUBSCRIPTION_TO_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAutoAddSubscriptionToCart() {
  yield takeLatest(ShopActions.REQUEST_AUTO_ADD_WATER_SUBSCRIPTION_TO_CART, requestAutoAddSubscriptionToCart);
}

// Edit subscription in cart.
function* requestEditSubscriptionInCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "EditSubscriptionInCartWindow", {accountId: baseAction.accountId}, i18n.t("cart:change_subscription")));
  yield take([ShopActions.EDIT_SUBSCRIPTION_IN_CART.CANCEL, ShopActions.EDIT_SUBSCRIPTION_IN_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestEditSubscriptionInCart() {
  yield takeLatest(ShopActions.REQUEST_EDIT_SUBSCRIPTION_IN_CART, requestEditSubscriptionInCart);
}

// Remove subscription from cart.
function* requestRemoveSubscriptionFromCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "RemoveSubscriptionFromCartWindow", {accountId: baseAction.accountId}, i18n.t("cart:remove_subscription_from_cart")));
  yield take([ShopActions.REMOVE_SUBSCRIPTION_FROM_CART.CANCEL, ShopActions.REMOVE_SUBSCRIPTION_FROM_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestRemoveSubscriptionFromCart() {
  yield takeLatest(ShopActions.REQUEST_REMOVE_SUBSCRIPTION_FROM_CART, requestRemoveSubscriptionFromCart);
}


// Quantity Assistant.
function* requestQuantityAssistant(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "QuantityAssistantWindow", {}, i18n.t("accounts:subscription.edit.quantity_assistant")));
  yield take([ShopActions.QUANTITY_ASSISTANT.CANCEL, ShopActions.QUANTITY_ASSISTANT.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestQuantityAssistant() {
  yield takeLatest(ShopActions.REQUEST_QUANTITY_ASSISTANT, requestQuantityAssistant);
}



// Add product to cart.
function* requestAddProductToCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AddProductToCartWindow", {accountId: baseAction.accountId, productSku: baseAction.productSku, productTitle: baseAction.productTitle}, i18n.t("products:add_dispenser_to_cart_label")));
  yield take([ShopActions.ADD_PRODUCT_TO_CART.CANCEL, ShopActions.ADD_PRODUCT_TO_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAddProductToCart() {
  yield takeLatest(ShopActions.REQUEST_ADD_PRODUCT_TO_CART, requestAddProductToCart);
}

// Edit product to cart.
function* requestEditProductInCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "EditProductInCartWindow", {accountId: baseAction.accountId, productSku: baseAction.productSku, productTitle: baseAction.productTitle, noDelete: baseAction.noDelete}, i18n.t("accounts:subscription.actions.change_quantity")));
  yield take([ShopActions.EDIT_PRODUCT_IN_CART.CANCEL, ShopActions.EDIT_PRODUCT_IN_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestEditProductInCart() {
  yield takeLatest(ShopActions.REQUEST_EDIT_PRODUCT_IN_CART, requestEditProductInCart);
}

// Remove product from cart.
function* requestRemoveProductFromCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "RemoveProductFromCartWindow", {accountId: baseAction.accountId, productSku: baseAction.productSku}, i18n.t("cart:remove_from_cart")));
  yield take([ShopActions.REMOVE_PRODUCT_FROM_CART.CANCEL, ShopActions.REMOVE_PRODUCT_FROM_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestRemoveProductFromCart() {
  yield takeLatest(ShopActions.REQUEST_REMOVE_PRODUCT_FROM_CART, requestRemoveProductFromCart);
}

// Show waiting list
function* requestProductWaitingList(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ProductWaitingListWindow", {shopId: baseAction.shopId, accountId: baseAction.accountId, item: baseAction.item}, i18n.t("common:waiting_list.title"), Map({headless: true})));
  // eslint-disable-next-line
  const {cancelled, succeeded} = yield race({
    cancelled: take((action) => action.type === ShopActions.PRODUCT_WAITING_LIST.CANCEL),
    succeeded: take((action) => action.type === ShopActions.PRODUCT_WAITING_LIST.SUCCESS),
  })

  if (succeeded) {
    yield put(showNotification(generateUUID(), i18n.t("common:waiting_list.ok_message")));    
  }
  yield put(removeModalWindow(windowId));
}
function* watchRequestProductWaitingList() {
  yield takeLatest(ShopActions.REQUEST_PRODUCT_WAITING_LIST, requestProductWaitingList);
}


// Checkout cart.
function* requestCheckoutCart(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "CheckoutCartWindow", {accountId: baseAction.accountId}, i18n.t("checkout:initiating"), Map({headless: true, centered: true})));
  yield take([ShopActions.CHECKOUT_CART.CANCEL, ShopActions.CHECKOUT_CART.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestCheckoutCart() {
  yield takeLatest(ShopActions.REQUEST_CHECKOUT_CART, requestCheckoutCart);
}

// Cancel checkout.
function* requestCancelCheckout(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "CancelCheckoutWindow", {accountId: baseAction.accountId, orderId: baseAction.orderId}, i18n.t("checkout:notifications.cancelling_title"), Map({headless: true, centered: true})));
  yield take([ShopActions.CANCEL_CHECKOUT.CANCEL, ShopActions.CANCEL_CHECKOUT.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestCancelCheckout() {
  yield takeLatest(ShopActions.REQUEST_CANCEL_CHECKOUT, requestCancelCheckout);
}

// Gifting form.
function* requestAddGiftingOption(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AddGiftingOptionWindow", {accountId: baseAction.accountId, orderId: baseAction.orderId}, i18n.t("checkout:shipping.gift_title")));
  yield take([ShopActions.ADD_GIFTING_OPTION.CANCEL, ShopActions.ADD_GIFTING_OPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAddGiftingOption() {
  yield takeLatest(ShopActions.REQUEST_ADD_GIFTING_OPTION, requestAddGiftingOption);
}

// Gifting form.
function* requestRemoveGiftingOption(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "RemoveGiftingOptionWindow", {accountId: baseAction.accountId, orderId: baseAction.orderId}, i18n.t("checkout:shipping.gift_title")));
  yield take([ShopActions.REMOVE_GIFTING_OPTION.CANCEL, ShopActions.REMOVE_GIFTING_OPTION.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestRemoveGiftingOption() {
  yield takeLatest(ShopActions.REQUEST_REMOVE_GIFTING_OPTION, requestRemoveGiftingOption);
}

function* requestChangeShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ChangeShippingAddressWindow", {
    accountId: baseAction.accountId,
    orderId: baseAction.orderId,
    originalAddressId: baseAction.addressId,
    editedAddressId: baseAction.editedAddressId, // The form needs to know which address has been edited
  }, i18n.t("common:shipping.edit_address")));
  // eslint-disable-next-line
  const {success, cancel, addAddress, editAddress, deleteAddress} = yield race({
    success: take((action) => action.type === ShopActions.CHANGE_SHIPPING_ADDRESS.SUCCESS),
    cancel:  take((action) => action.type === ShopActions.CHANGE_SHIPPING_ADDRESS.CANCEL),
    addAddress: take((action) => action.type === AddressBookActions.REQUEST_ADD_SHIPPING_ADDRESS),
    editAddress: take((action) => action.type === AddressBookActions.REQUEST_EDIT_SHIPPING_ADDRESS),
    deleteAddress: take((action) => action.type === AddressBookActions.REQUEST_DELETE_SHIPPING_ADDRESS),
  });
  yield put(removeModalWindow(windowId));

  if (addAddress) {
    yield take([AddressBookActions.ADD_SHIPPING_ADDRESS.CANCEL, AddressBookActions.ADD_SHIPPING_ADDRESS.SUCCESS]);
    yield requestChangeShippingAddress(baseAction);
  }
  if (editAddress) {
    const moreActions = { ...baseAction, editedAddressId: AddressBookActions.EDIT_SHIPPING_ADDRESS.ADDRESS_ID };
    yield take([AddressBookActions.EDIT_SHIPPING_ADDRESS.CANCEL, AddressBookActions.EDIT_SHIPPING_ADDRESS.SUCCESS]);
    yield requestChangeShippingAddress(moreActions);
  }
  if (deleteAddress) {
    yield take([AddressBookActions.DELETE_SHIPPING_ADDRESS.CANCEL, AddressBookActions.DELETE_SHIPPING_ADDRESS.SUCCESS]);
    yield requestChangeShippingAddress(baseAction);
  }
}
function* watchRequestChangeShippingAddress() {
  yield takeLatest(ShopActions.REQUEST_CHANGE_SHIPPING_ADDRESS, requestChangeShippingAddress);
}

// Checkout cart.
// function* requestPlaceOrder(baseAction) {
// }
function* watchRequestPlaceOrder() {
  while(true) {
    let baseAction = yield take(ShopActions.REQUEST_SHOW_ORDER_STATUS);
    let windowId = generateUUID();
    yield put(showModalWindow(windowId, "PlaceOrderWindow", {accountId: baseAction.accountId, orderId: baseAction.orderId, status: baseAction.status, errorMsg: baseAction.errorMsg, invoiceNr: baseAction.invoiceNr}, "", Map({headless: true})));
    let showWindow = true;
    while(showWindow) {
      const {cancelled, succeeded, statusChange} = yield race({
        cancelled: take((action) => action.type === ShopActions.SHOW_ORDER_STATUS.CANCEL),
        succeeded: take((action) => action.type === ShopActions.SHOW_ORDER_STATUS.SUCCESS),
        statusChange: take((action) => action.type === ShopActions.REQUEST_SHOW_ORDER_STATUS)
      })
      if (succeeded) {
        Navigation.gotoAccount(baseAction.accountId);   
        showWindow = false;   
      }
      if (statusChange) {
        yield put(updateModalWindow(windowId, {accountId: baseAction.accountId, orderId: baseAction.orderId, status: statusChange.status, errorMsg: statusChange.errorMsg, invoiceNr: statusChange.invoiceNr}, "", Map({headless: true})));  
      }
      if (cancelled) {
        showWindow = false;
      }
    }
    yield put(removeModalWindow(windowId));
  }
}

function* requestShowOrderStatusWithMonitor(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "OrderStatusWithMonitorWindow", {accountId: baseAction.accountId, orderId: baseAction.orderId}, "", Map({headless: true, invisible: true})));
  yield take([ShopActions.SHOW_ORDER_STATUS.CANCEL, ShopActions.SHOW_ORDER_STATUS.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestShowOrderStatusWithMonitor() {
  yield takeLatest(ShopActions.REQUEST_SHOW_ORDER_STATUS_WITH_MONITOR, requestShowOrderStatusWithMonitor);
}


// Quantity Assistant.
function* requestQuantityAssistantForGuide(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "QuantityAssistantWindow", {}, i18n.t("accounts:subscription.edit.quantity_assistant")));
  // eslint-disable-next-line
  const {cancelledAssistant, succeededAssistant} = yield race({
    cancelledAssistant: take((action) => action.type === ShopActions.QUANTITY_ASSISTANT.CANCEL),
    succeededAssistant: take((action) => action.type === ShopActions.QUANTITY_ASSISTANT.SUCCESS),
  });
  if (succeededAssistant) {
    yield put(ShopActions.updateGuideWaterSubscriptionQuantity(succeededAssistant.quantity));
  }
  yield put(removeModalWindow(windowId));
}
function* watchRequestQuantityAssistantForGuide() {
  yield takeLatest(ShopActions.REQUEST_QUANTITY_ASSISTANT_FOR_GUIDE, requestQuantityAssistantForGuide);
}

// Quantity Assistant for Form.
function* requestQuantityAssistantForForm(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "QuantityAssistantWindow", {}, i18n.t("accounts:subscription.edit.quantity_assistant")));
  // eslint-disable-next-line
  const {cancelledAssistant, succeededAssistant} = yield race({
    cancelledAssistant: take((action) => action.type === ShopActions.QUANTITY_ASSISTANT.CANCEL),
    succeededAssistant: take((action) => action.type === ShopActions.QUANTITY_ASSISTANT.SUCCESS),
  });
  if (succeededAssistant) {
    yield put(changeFormValue(baseAction.form, baseAction.quantityField, succeededAssistant.quantity));
    yield put(changeFormValue(baseAction.form, baseAction.frequencyWeeksField, succeededAssistant.frequencyWeeks));
  }
  yield put(removeModalWindow(windowId));
}
function* watchRequestQuantityAssistantForForm() {
  yield takeLatest(ShopActions.REQUEST_QUANTITY_ASSISTANT_FOR_FORM, requestQuantityAssistantForForm);
}


export default function* saga() {
  yield fork(watchRequestCheckoutCart);
  yield fork(watchRequestAdjustSubscriptionInCart);
  yield fork(watchRequestCancelCheckout);
  yield fork(watchRequestAddGiftingOption);
  yield fork(watchRequestRemoveGiftingOption);
  yield fork(watchRequestAddSubscriptionToCart);
  yield fork(watchRequestAutoAddSubscriptionToCart);
  yield fork(watchRequestEditSubscriptionInCart);
  yield fork(watchRequestRemoveSubscriptionFromCart);
  yield fork(watchRequestQuantityAssistant);
  yield fork(watchRequestQuantityAssistantForGuide);
  yield fork(watchRequestQuantityAssistantForForm);
  yield fork(watchRequestAddProductToCart);
  yield fork(watchRequestEditProductInCart);
  yield fork(watchRequestRemoveProductFromCart);
  yield fork(watchRequestProductWaitingList);
  yield fork(watchRequestChangeShippingAddress);
  yield fork(watchRequestPlaceOrder);
  yield fork(watchRequestShowOrderStatusWithMonitor);
  yield fork(watchRequestShowGiftingDiscrepancy);
}
