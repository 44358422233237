import React from "react";
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import {showSupport} from "../../actions/SupportActions";
import Button from "../../shared/components/Button";
import Checkbox from "../../shared/components/forms/Checkbox";
import Gift from "./icons/Gift";
import styles from "./SendAsGiftQuestion.module.scss";

const SendAsGiftQuestion = (props) => {
  const { t } = useTranslation("checkout");
  if (props.show === false) return null;

  return (
    <div className={styles.module}>
      <Gift colorDisabled="var(--primary-blue-700" />
      <div className={styles.text}>
        {t("shipping.gift.address")}&nbsp;
        <Button link={true} className="desktopOnly"
          onClick={() => props.showSupport("send-as-gift")}>
          {t("shipping.gift.learn")}
        </Button>
      </div>
      <Checkbox className="blue"
        onChange={(e) => props.onClick(e[0] === "y")}
        name="whatever"
        input={{
          value: props.checked ? "yes" : "no",
          onChange: (event) => {
            props.onClick(event === "yes");
          },
        }}
        submitting={props.submitting}
        meta={{ error: false, touched: true }}
        />
    </div>
  )

}

SendAsGiftQuestion.defaultProps = {
  show: true
}

export default connect(null, {showSupport})(SendAsGiftQuestion);