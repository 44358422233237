import React, {Component} from "react";
import TableOfContents from "./views/TableOfContents";
import SupportDoc from "./views/SupportDoc";
import { cx } from "../shared/lib/ClassSet";
import {connect} from "react-redux";
import * as SupportActions from "../actions/SupportActions";
import "./CustomerSupport.css";
import CloseX from "../shop/shared/icons/CloseX";
import { withTranslation } from "react-i18next";

class CustomerSupport extends Component {

  render() {
    if (!this.props.show) {
      return null;
    }

    let classes = cx({
      "CustomerSupport": true,
      "u-active": this.props.active
    })

    return (
      <div className={classes}>
        <div className="inner">
          <div className="header">
            <div className="icon" /><div className="title">{this.props.t("support.title_full")}</div>
            <div className="buttons">
              <CloseX color="var(--grey-700)" onClick={this.handleToggle.bind(this)} />
            </div>
          </div>
          <div className="body">
            {this.renderToc()}
            {this.renderDoc()}          
          </div>
        </div>
        <div className="expander" onClick={this.handleToggle.bind(this)} >
          <div className="inner">
            <div className="icon" />
            <div className="title">{this.props.t("support.title")}</div>
          </div>
        </div>
      </div>
    )
  }

  renderToc() {
    if (this.props.page !== "toc") return null;
    return <TableOfContents 
            onClick={this.handleChangePage.bind(this)} />
  }

  renderDoc() {
    if (this.props.page === "toc") return null;
    return <SupportDoc 
              page={this.props.page} 
              onBack={this.handleBack.bind(this)}
              onChangePage={this.handleChangePage.bind(this)}
              onClose={this.handleClose.bind(this)}
              handleBack={() => this.handleBack()} />
  }

  handleChangePage(slug) {
    this.props.openSupportPage(slug);
  }

  handleToggle() {
    if (this.props.active) {
      this.props.closeSupport();
    } else {
      this.props.showSupport();
    }
  }

  handleBack() {
    this.props.openSupportPage("toc");
  }

  handleClose() {
    this.props.closeSupport();
  }

}

function mapStateToProps(state) {
  return {
    active: state.getIn(["support", "active"]),
    page: state.getIn(["support", "page"])
  }
}

export default withTranslation("common")(connect(mapStateToProps, {...SupportActions})(CustomerSupport));