import { useEffect, useState } from "react"

export default function useResize(myRef) {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (myRef.current) {
        setWidth(myRef.current.offsetWidth);
        setHeight(myRef.current.offsetHeight);
      }
    }

    // On load, the ref is not ready and its dimensions are 0,0.
    const withDelay = (fct) => {
      setTimeout(fct, 1);
    }

    window.addEventListener('load', withDelay(handleResize));
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('load', withDelay(handleResize));
      window.removeEventListener('resize', handleResize);
    }
  }, [myRef]);

  return { width, height }
}