import {takeLatest} from "redux-saga";
import {fork, put, take} from "redux-saga/effects";
import {generateUUID} from "../lib/UUID";
import * as AddressBookActions from "../actions/AddressBookActions";
import {showModalWindow, removeModalWindow} from "../actions/WindowActions";
import i18n from "../i18n";

// Request add new shipping address
function* requestAddShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "AddShippingAddressWindow", {accountId: baseAction.accountId}, i18n.t("common:shipping.create_address_title")));
  yield take([AddressBookActions.ADD_SHIPPING_ADDRESS.CANCEL, AddressBookActions.ADD_SHIPPING_ADDRESS.SUCCESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestAddShippingAddress() {
  yield takeLatest(AddressBookActions.REQUEST_ADD_SHIPPING_ADDRESS, requestAddShippingAddress);
}

// Request edit shipping address
function* requestEditShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "EditShippingAddressWindow", {accountId: baseAction.accountId, addressId: baseAction.addressId, address: baseAction.address}, i18n.t("common:shipping.edit_address")));
  yield take([AddressBookActions.EDIT_SHIPPING_ADDRESS.CANCEL, AddressBookActions.EDIT_SHIPPING_ADDRESS.SUCCESS, AddressBookActions.REQUEST_EDIT_SHIPPING_ADDRESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestEditShippingAddress() {
  yield takeLatest(AddressBookActions.REQUEST_EDIT_SHIPPING_ADDRESS, requestEditShippingAddress);
}

// Request delete shipping address
function* requestDeleteShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "DeleteShippingAddressWindow", {accountId: baseAction.accountId, addressId: baseAction.addressId, address: baseAction.address}, i18n.t("common:shipping.delete_address.title")));
  yield take([AddressBookActions.DELETE_SHIPPING_ADDRESS.CANCEL, AddressBookActions.DELETE_SHIPPING_ADDRESS.SUCCESS, AddressBookActions.REQUEST_DELETE_SHIPPING_ADDRESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestDeleteShippingAddress() {
  yield takeLatest(AddressBookActions.REQUEST_DELETE_SHIPPING_ADDRESS, requestDeleteShippingAddress);
}

// Request confirmation to add already filled shipping address
function* requestConfirmShippingAddress(baseAction) {
  let windowId = generateUUID();
  yield put(showModalWindow(windowId, "ConfirmShippingAddressWindow", {accountId: baseAction.accountId, walletType: baseAction.walletType, address: baseAction.address}, i18n.t("common:shipping.confirm_address.title")));
  yield take([AddressBookActions.CONFIRM_SHIPPING_ADDRESS.CANCEL, AddressBookActions.CONFIRM_SHIPPING_ADDRESS.SUCCESS, AddressBookActions.REQUEST_CONFIRM_SHIPPING_ADDRESS]);
  yield put(removeModalWindow(windowId));
}
function* watchRequestConfirmShippingAddress() {
  yield takeLatest(AddressBookActions.REQUEST_CONFIRM_SHIPPING_ADDRESS, requestConfirmShippingAddress);
}

export default function* saga() {
  yield fork(watchRequestAddShippingAddress);
  yield fork(watchRequestEditShippingAddress);
  yield fork(watchRequestDeleteShippingAddress);
  yield fork(watchRequestConfirmShippingAddress);
}