import React from "react";
import Button from "../shared/components/Button";
import Navigation from "../lib/Navigation";
import Modal from "./shared/Modal";
import Error from "./shared/icons/Error";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import LoadingCircle from "../shared/components/forms/LoadingCircle";
import styles from "./InsufficientStockPage.module.scss";
import { useTranslation } from "react-i18next";

const InsufficientStockPage = (props) => {
  const { t } = useTranslation("checkout");
  const backToShopFn = () => Navigation.gotoCart(props.accountId);

  return (
    <Modal displayHeader={false} mode={Modal.MODES.FULLPAGE}
      buttons={<Button label={t("back_to_cart")} theme={Button.THEMES.BLUE} onClick={backToShopFn} />}>
      <Query query={STOCK_INFO} variables={{orderId: props.orderId}} fetchPolicy="network-only">
        {({ loading, error, data}) => {
          if (loading) {
            return <LoadingCircle />
          }
          if (error) {
            return <NoInfoErrorMessage />
          }

          if (data.customerOrderInsufficientStockInfo.length > 0) {
            let outOfStockElems = data.customerOrderInsufficientStockInfo.map((item) => {
              return (
                <div key={item.productSku}>{item.productTitle}</div>
              )
            })
            return (
              <>
                <Error side="50px" colorDisabled="var(--secondary-gold-600)" />
                <h1>{t("insufficient_stock.title")}</h1>
                <p className="u-bottom-margin">{t("insufficient_stock.message")}</p>
                <div className={styles.outOfStockContainer}>
                  { outOfStockElems }
                </div>
              </>
            )
          } else {
            return <NoInfoErrorMessage />
          }
        }}
      </Query>
    </Modal>
  );
}

function NoInfoErrorMessage() {
  const { t } = useTranslation("checkout");

  return (
    <>
      <Error side="50px" colorDisabled="var(--secondary-gold-600)" />
      <h1>{t("insufficient_stock.title")}</h1>
      <p className="u-bottom-margin">{t("insufficient_stock.message_generic")}</p>
    </>
  )
}

const STOCK_INFO = gql`
  query customerOrderInsufficientStockInfo($orderId: ID!) {
    customerOrderInsufficientStockInfo(salesOrderId: $orderId) {
      productSku
      productTitle
    }
  }
`;

export default InsufficientStockPage;