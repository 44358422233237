import React, {Component} from "react";
import {List} from "immutable";
import styles from "./Select.module.scss";
import ArrowButton from "../../../shop/shared/icons/Arrow";
import i18n from "../../../i18n";

const DIRECTIONS = {
  ASCENDING: styles.ascending,
  DESCENDING: styles.descending,
  NONE: styles.ascending + " " + styles.descending,
}

const SIZES = {
  NORMAL: styles.normalSize,
  SMALL: styles.smallSize,
  SMALLER: styles.smallerSize,
}

class Select extends Component {

  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.moduleRef = React.createRef();
    this.curtainRef = React.createRef();
    this.state = {
      open: false,
      direction: DIRECTIONS.DESCENDING,
      currentIndex : 0,
      x: 0,
      y: 0,
      w: 0,
      height: 0,
      maxHeight: 0,
      searchTerm: "",
      searchTimerId: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    
    // Init the currently selected index
    let {data, value} = this.props;
    if (data && value) {
      const currentIndex = data.map(e => e.get("code")).indexOf(value);
      this.setState({ currentIndex: currentIndex });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentIndex !== this.state.currentIndex && this.curtainRef.current) {
      const curtainElement = this.curtainRef.current;
      curtainElement.scrollTo({
        top: this.state.currentIndex * (curtainElement.scrollHeight / curtainElement.childElementCount),
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (this.state.open) {
      this.showDropdown(); // recompute size and position
    }
  }

  toggleDropdown() {
    if (this.state.open) {
      this.setState({open: false});
    } else {
      this.showDropdown();
    }
  }

  getItemHeight() {
    // Hacky as those are defined in the css as well
    switch (this.props.size) {
      case SIZES.SMALLER:
        return 28;
      case SIZES.NORMAL:
      case SIZES.SMALL:
      default:
        return 57;
    }
  }

  showDropdown() {
    let rect = this.moduleRef.current.getBoundingClientRect();

    // Let's try to open it above when there isn't enough space below the field
    // We can do that only because we know an item height is 57px height.
    const MIN_Y_SPACE_FROM_EDGE = 20;
    const ITEM_HEIGHT = this.getItemHeight();
    const TOP_BOTTOM_BORDERS = 2;
    const fullDropdownHeight = this.props.data.size * ITEM_HEIGHT + TOP_BOTTOM_BORDERS;
    const potentialHeightAbove = rect.top - MIN_Y_SPACE_FROM_EDGE;
    const potentialHeightBelow = window.innerHeight - rect.bottom - MIN_Y_SPACE_FROM_EDGE;

    const variableStyling = {};
    
    if (fullDropdownHeight < potentialHeightBelow) {
      // All visible below the input
      variableStyling.y = rect.bottom - 1;
      variableStyling.height = fullDropdownHeight;
      this.setState({ direction: DIRECTIONS.DESCENDING });
    }
    else if (fullDropdownHeight < potentialHeightAbove) {
      // All visible above the input
      variableStyling.y = rect.top - fullDropdownHeight + 1;
      variableStyling.height = fullDropdownHeight;
      this.setState({ direction: DIRECTIONS.ASCENDING });
    }
    else if (fullDropdownHeight < 2 * potentialHeightBelow) {
      // Not enough space below but not too long either
      variableStyling.y = rect.bottom - 1;
      variableStyling.maxHeight = potentialHeightBelow;
      this.setState({ direction: DIRECTIONS.DESCENDING });
    }
    else {
      // Use as much vertical space as necessary
      variableStyling.y = Math.max(MIN_Y_SPACE_FROM_EDGE, rect.bottom - fullDropdownHeight);
      variableStyling.maxHeight = window.innerHeight - (2 * MIN_Y_SPACE_FROM_EDGE);
      this.setState({ direction: DIRECTIONS.NONE });
    }

    this.setState({
      open: true,
      x: rect.left,
      y: 0, // reset
      w: rect.width,
      height: 0, // reset
      maxHeight: 0, // reset
      ...variableStyling,
    });
  }

  handleItemClick(value, index) {
    if (this.props.value !== value) this.props.onChange(value);
    this.setState({open: false, currentIndex: index});
  }

  handleTextSearch(lastKeyPressed) {
    const searchTerm = this.state.searchTerm + lastKeyPressed.toUpperCase();

    // First, try to find names which starts with that searchTerm
    let newIndex = this.props.data.findIndex((datum) => datum.get("name").toUpperCase().startsWith(searchTerm));
    if (newIndex === -1) {
      // Try again, but with names which contain that searchTerm
      newIndex = this.props.data.findIndex((datum) => datum.get("name").toUpperCase().match(searchTerm));
    }
    if (newIndex === -1) {
      // No match at all: stay in place
      newIndex = this.state.currentIndex;
    }

    // Remember for next key press within timeout
    window.clearTimeout(this.state.searchTimerId);
    const newTimeoutId = window.setTimeout(() => {
      // Reset
      this.setState({
        searchTerm: "",
        searchTimerId: 0,
      });
    }, this.props.timeoutForSearchMS);
    this.setState({
      searchTerm: searchTerm,
      searchTimerId: newTimeoutId,
    });

    return newIndex;
  }

  handleKeyDown(event) {
    // Allow basic keyboard interaction
    const isTabKey = event.keyCode === 9;
    if (!isTabKey) {
      event.preventDefault();
    }

    if (!this.state.open && event.key === " ") {
      // Open dropdown on space key, but do not close as we want to handle
      // space key on search
      return this.toggleDropdown();
    }
    else if (this.state.open && this.curtainRef.current) {
      let newIndex = this.state.currentIndex;

      if (event.key.match(/^[a-zA-Z ]$/)) {
        newIndex = this.handleTextSearch(event.key);
        newIndex = Math.max(newIndex, 0);
      }
      else if (event.key === "ArrowUp") {
        newIndex = Math.max(this.state.currentIndex - 1, 0);
      }
      else if (event.key === "ArrowDown") {
        newIndex = Math.min(this.state.currentIndex + 1, this.props.data.size - 1);
      }
      else if (event.key === "Escape") {
        this.setState({ open: false });
      }
      else if (event.key === "Enter") {
        const current = this.props.data.get(this.state.currentIndex);
        if (current) {
          const value = current.get("code");
          this.handleItemClick(value, this.state.currentIndex);
        }
      }

      if (this.state.currentIndex !== newIndex) {
        this.setState({ currentIndex: newIndex });
      }
    }
  }

  buildImageWithSprite(value, title, spritePath) {
    return (
      <React.Fragment>
        <div className={styles.image + " " + value.toLowerCase()} style={{backgroundImage: `url(${spritePath})`}} />
        { title }
      </React.Fragment>
    );
  }

  buildOptionTitle(value, title) {
    return this.props.withSpritePath
      ? this.buildImageWithSprite(value, title, this.props.withSpritePath)
      : title;
  }

  render() {
    const {
      data,
      value, valueKey, titleKey,
      placeholder = i18n.t("forms:select_placeholder"),
      size,
      noControl,
    } = this.props;

    let dropdown = null;
    if (this.state.open) {
      let options = data.map((option, i) => {
        return <Option
                  key={option.get(valueKey)}
                  active={i === this.state.currentIndex}
                  center={this.props.centerOptions}
                  value={option.get(valueKey)}
                  title={this.buildOptionTitle(option.get(valueKey), option.get(titleKey))}
                  onClick={(value) => this.handleItemClick(value, i)} />
      });

      const MIN_OPTIONS_FOR_PREFERRED_VALUES = 10;
      if (this.props.preferredValues && options.size > MIN_OPTIONS_FOR_PREFERRED_VALUES) {
        // Create an Option from each preferred value and add them to the list
        const preferredOptions = this.props.preferredValues.map((v) => {
          const opt = options.find((o) => o.key === v);

          return opt
            ? React.cloneElement(opt, { preferredValue: true })
            : null;
        });
        options = options.unshift(preferredOptions, null);
      }

      dropdown = <OptionList
                  curtainRef={this.curtainRef}
                  options={options}
                  left={this.state.x}
                  top={this.state.y}
                  width={this.state.w}
                  height={this.state.height}
                  maxHeight={this.state.maxHeight}
                  onClose={this.toggleDropdown} />
    }

    // Lookup title of value.
    let title = placeholder;
    let selectedData = data.find(option => option.get(valueKey) === value);
    if (selectedData) {
      title = selectedData.get(titleKey);
    }
    if (this.props.valuePrefix) {
      title = <>{this.props.valuePrefix}<span className="select-value">{title}</span></>;
    }

    const controlClasses = [styles.control];

    const classes = [
      styles.module,
      size,
      this.props.darkBackground && styles.darkBackground,
      this.state.direction,
      this.props.focused && styles.focus,
      this.props.error && styles.error,
      this.state.open ? styles.selecting + " select-selecting" : null,
      noControl ? styles.noControl : null,
    ];

    const optionalInput = this.props.withInput;
    if (optionalInput) {
      classes.push(styles.withOptionnalInput);
    }
    if (this.props.withSpritePath) {
      classes.push(styles.withSpritePath);
      // use image for title
      if (selectedData) {
        const imageElem = this.buildImageWithSprite(selectedData.get(valueKey), "", this.props.withSpritePath);
        let textTitle = title;
        if (this.props.titleFromCode) {
          textTitle = this.props.titleFromCode(selectedData.get(valueKey), title);
        }

        title = <React.Fragment>
          { imageElem }
          <span className={styles.compensateScaling}>{ textTitle }</span>
        </React.Fragment>
      }
    }
    const clickingAttrs = {
      role:"listbox",
      tabIndex:"0",
      onClick: this.toggleDropdown.bind(this),
      onKeyDown: (e) => this.handleKeyDown(e),
    }

    const ctrlClickingAttrs = optionalInput && clickingAttrs;
    const baseClickingAttrs = !optionalInput && clickingAttrs;

    return (
      <div ref={this.moduleRef} className={classes.join(" ")}>
        <div className={styles.fullInputArea} {...baseClickingAttrs}>
          {/* Control */}
          <div className={controlClasses.join(" ")} {...ctrlClickingAttrs}>
            <div className={styles.value}>{title}</div>
            <ArrowButton className={styles.icon}
              arrowStyle={ArrowButton.STYLES.DROP}
              direction={this.state.open ? ArrowButton.DIRECTIONS.UP : ArrowButton.DIRECTIONS.DOWN}
            />
          </div>
          {/* Optionnal input e.g. for phone inputs, in which case the control
            only handles the country flag selection */}
          { optionalInput }
        </div>
        { dropdown }
      </div>
    );
  }

}

const Option = (props) => {
  const {title, value, active, onClick, preferredValue, center} = props;

  const classes = [
    styles.item,
    active ? styles.active : null,
    preferredValue ? styles.preferredValue : styles.normalValue,
    center ? styles.centered : null,
    "select-option",
  ];
  
  return (
    <div className={classes.join(" ")} onClick={() => onClick(value)} role="listitem">
      {title}
    </div>
  );
}

const OptionList = ({options, left, top, width, height, maxHeight, onClose, curtainRef}) => {
  let dropdownStyles = height
    ? { left, top, width, height }
    : { left, top, width, maxHeight };

  return (
    <div className={styles.curtain} onClick={onClose}>
      <div className={styles.dropdown} style={dropdownStyles} role="list"
        ref={curtainRef}>
        {options}
      </div>
    </div>
  );
}


Select.defaultProps = {
  showBlank: true,
  titleKey: "title",
  valueKey: "id",
  data: List(),
  darkBackground: false,
  timeoutForSearchMS: 1000,
  size: SIZES.NORMAL,
  centerOptions: false,
}

Select.SIZES = SIZES;

export default Select;
