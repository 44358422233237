import React from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";

export default function OrderVersionMonitor(props) {

  let versionVariables = {
    orderId: props.orderId
  }

  return (
    <Query query={VERSION} variables={versionVariables} pollInterval={2000} fetchPolicy="no-cache">
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return null;
        if (data.customerCheckoutVersion && data.customerCheckoutVersion.version !== props.version) {
          props.onChange(data.customerCheckoutVersion.version);
        }
        return null;
      }}
    </Query>
  )

}

const VERSION = gql`
  query versionInfo($orderId: ID!) {
    customerCheckoutVersion(orderId: $orderId) {
      version
    }
  }
`
