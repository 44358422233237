import React from "react";
import {connect} from "react-redux";
import {yesNotification, noNotification} from "../../actions/AccountActions";
import Button from "../../shared/components/Button";
import { useTranslation } from 'react-i18next';

const ChangeShippingAddressNotification = (props) => {
  const { t } = useTranslation("accounts", { keyPrefix: "deliveries.change_address"});

  return (
    <form>
      <p className="fields u-centered">{t("info")}</p>
      <div className="buttons">
        <Button label={t("confirm")} onClick={props.yesNotification} />
        <Button label={t("cancel")} onClick={props.noNotification}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
    </form>
  );
}

export default connect(null, {yesNotification, noNotification})(ChangeShippingAddressNotification);