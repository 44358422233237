import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import AddressInline from "../../shared/AddressInline";
import {cancelConfirmShippingAddress} from "../../actions/AddressBookActions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { applyStateProvinceRule } from "../../lib/AddressRules";
import { getCompletePhoneNrOrNothing } from "../../lib/TelephoneNormalizer";
import { createErrors, createValidationErrors } from "../../lib/ErrorFormatter";
import Button from "../../shared/components/Button";
import SetupShippingAddressFormContainer from "./SetupShippingAddressFormContainer";

export function ConfirmShippingAddressFormContainer(props) {
  const {
    accountId,
    walletType,
    address,
    cancelConfirmShippingAddress,
  } = props;

  const { t } = useTranslation("common", { keyPrefix: "shipping.confirm_address"});

  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [customerAddShippingAddressToAccount, {error: mutationError}] = useMutation(ADDRESS_BOOK_ADD_ADDRESS);

  useEffect(() => {
    if (mutationError) {
      setErrors(createErrors(mutationError));
    }
  }, [mutationError]);

  function handleSubmit() {
    let updatedValues = applyStateProvinceRule(
      address.set("accountId", accountId)
             .set("phoneNr", getCompletePhoneNrOrNothing(address.get("phoneNr")))
             .delete("addressCountryName")
    );

    setSubmitting(true);

    return customerAddShippingAddressToAccount({
      variables: {
        data: {
          ...updatedValues.toJS()
        }
      }})
      .then((response) => {
        if (response.data.customerAddShippingAddressToAccount.errors.length <= 0) {
          handleCancel();
        } else {
          const errors = createValidationErrors(response.data.customerAddShippingAddressToAccount.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function handleCancel() {
    cancelConfirmShippingAddress();
  }

  if (errors) {
    // The address we got is invalid, so we need to ask the user to edit it
    return (
      <div>
        <p className="u-centered">{t("need_edit_address")}</p>
        <SetupShippingAddressFormContainer accountId={accountId}
          prefilledAddress={address}
          upstreamErrors={errors}
          onAddressAdded={handleCancel}
        />
        <Button onClick={handleCancel} label={t("cancel")}
          style={{display: "none"}} // We only want the top right cancel button
          addCancelToTopRight={true} active={!submitting}
        />
      </div>
    );
  }
  
  return (
    <div className="form">
      <p className="u-centered">{t("text", { wallet_type: walletType })}</p>
      <p className="u-centered sg-text-lead">
        <AddressInline
          inline={true}
          showType={false}
          address={address} />
      </p>
      <div className="buttons">
        <Button onClick={handleSubmit}
          label={t("confirm")} active={!submitting} />
        <Button onClick={handleCancel} label={t("cancel")}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} active={!submitting}
        />
      </div>
    </div>
  );
}

// Adds shipping address to the address book instead of just assigning it
const ADDRESS_BOOK_ADD_ADDRESS = gql`
  mutation customerAddShippingAddressToAccount($data: AddShippingAddressToAccountInput!) {
    customerAddShippingAddressToAccount(input: $data) {
      errors { key message }
    }
  }
`;


export default connect(null, {cancelConfirmShippingAddress})(ConfirmShippingAddressFormContainer);
