import React from "react";
import {withRouter} from "react-router"
import {connect} from "react-redux";
import CartSummary from "./CartSummary";
import Navigation from "../../lib/Navigation";
import {requestCheckoutCart} from "../../actions/ShopActions";
import styles from "./ShopFooter.module.scss";

const ShopFooter = (props) => {

  let checkout = () => props.requestCheckoutCart(props.accountId);
  let viewCart = () => Navigation.gotoCart(props.accountId);

  return (
    <div className={styles.module + " footer"}>
      <CartSummary 
        accountId={props.accountId} 
        showViewCart={!Navigation.isCartPage(props.location.pathname)}
        showCheckout={props.showCheckout}
        onViewCart={viewCart} 
        onCheckout={checkout} />
    </div>
  );
}

ShopFooter.defaultProps = {
  showCheckout: false
}

export default connect(null, {requestCheckoutCart})(withRouter(ShopFooter));