import React from "react";
import styles from "./Icon.module.scss";

function Icon(props) {
  const {
    className,
    side = "24px",
    color = "var(--grey-400)",
    colorDisabled = "var(--grey-200)",
    inFlow,
    onClick,
    svgPathDParameter,
    svgPointsParameter,
    animation = null,
  } = props;

  const classes = [className, styles.module,
    inFlow ? styles.inFlow : "",
    !onClick ? styles.notClickable : "",
    `side-${side}`,
  ];
  const fillColor = onClick ? color : colorDisabled;

  const figure = svgPathDParameter
    ? <path className="svg-shape" d={svgPathDParameter} fill={fillColor}></path>
    : <polygon className="svg-shape" points={svgPointsParameter} fill={fillColor}></polygon>;

  return (
    <svg className={classes.join(" ")}
      width={side} height={side} viewBox="0 0 24 24"
      onClick={onClick}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        { animation }
        { figure }
      </g>
    </svg>
  );
}

export default Icon;
