import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./GiftingMessageForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelAddGiftingOption} from "../../actions/ShopActions";
import {Map, fromJS} from "immutable";
import {validatePresence} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { withTranslation } from "react-i18next";

function validateForm(values, t) {
  let errors = Map();
  errors = validatePresence(errors, values, "message", t("shipping.gift.empty_message"));
  return errors.toJS();
}

class GiftingMessageFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      messageSaved: false,
      errors: [],
    }
  }

  handleSubmit(values) {
    let updatedValues = values.set("orderId", this.props.orderId)
                              .set("accountId", this.props.accountId)
                              .set("template", "default");

    return this.props.customerMarkAsGift(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerMarkAsGift.errors.length <= 0) {
          // Keep that submitted msg in the state so the form take advantage of it
          this.setState({ messageSaved: true });
          this.props.onMarkedAsGift(values.get("message"))
        } else {
          let errors = createValidationErrors(response.data.customerMarkAsGift.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelAddGiftingOption();
  }

  handleChange(values) {
    // save message stub in the parent's state
    if (this.props.onMessageUpdate) {
      // Optionally save message in parent's state
      this.props.onMessageUpdate(values.get("message"));
    }

    if (this.state.messageSaved) {
      this.setState({ messageSaved: false });
    }
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let info = fromJS(data.customerCheckoutInfo);
    let initialValues = Map();
    const message = info.get("gift") === true
      ? info.get("giftMessage")
      : this.props.initialMessage;
    
    initialValues = Map({
      message: message,
    });
    
    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        validate={(values) => validateForm(values, this.props.t)}
        messageSaved={this.state.messageSaved}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onChange={(values) => this.handleChange(values)}
        />
    )
  }
}


const GET_ORDER = gql`
  query customerCheckoutInfo($accountId: ID!, $orderId: ID!) {
    customerCheckoutInfo(accountId: $accountId, orderId: $orderId) {
      orderId
      gift
      giftMessage
    }
  }
`;

const MARK_AS_GIFT = gql`
  mutation customerMarkAsGift($data: CustomerMarkAsGiftInput!) {
    customerMarkAsGift(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(GET_ORDER, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        orderId: props.orderId,
        accountId: props.accountId
      }
    })
  }),
  graphql(MARK_AS_GIFT, {
    props: ({ mutate }) => ({
      customerMarkAsGift: (data) => mutate({
        variables: { data: data }
      })
    })
  })
);

export default withTranslation("checkout")(connect(null, {cancelAddGiftingOption})(withQueries(GiftingMessageFormContainer)));
