import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/ChangeShippingAddressForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import { cancelChangeSubscriptionShippingAddress } from "../../actions/AccountActions";
import { requestAddShippingAddress, requestEditShippingAddress, requestDeleteShippingAddress } from "../../actions/AddressBookActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class ChangeSubscriptionShippingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      originalSelectedId: null,
      selectedId: null,
      errors: [],
    }
  }

  // Assign the 
  handleSubmit(values) {
    if (this.state.selectedId === this.state.originalSelectedId) {
      this.props.cancelChangeSubscriptionShippingAddress();
    } else {
      return this.props.customerAssignSubscriptionExistingShippingAddress({subscriptionId: this.props.subscriptionId, accountId: this.props.accountId, addressId: this.state.selectedId})
        .then((response) => {
          if (response.data.customerAssignSubscriptionExistingShippingAddress.errors.length <= 0) {
            this.props.cancelChangeSubscriptionShippingAddress();
          } else {
            let errors = createValidationErrors(response.data.customerAssignSubscriptionExistingShippingAddress.errors);
            this.setState({errors: errors});            
          }
        })
        .catch((err) => {
          this.setState({errors: createErrors(err)});
        });      
    }
  }

  handleCancel() {
    this.props.cancelChangeSubscriptionShippingAddress();
  }

  handleAddAddress() {
    this.props.requestAddShippingAddress(this.props.accountId);
  }

  handleEditAddress(addressId, address) {
    this.props.requestEditShippingAddress(this.props.accountId, addressId, address);
  }

  handleDeleteAddress(addressId, address) {
    this.props.requestDeleteShippingAddress(this.props.accountId, addressId, address);
  }

  handleSelectAddressId(id) {
    this.setState({
      selectedId: id
    })
  }


  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />

    const selectedAddress = fromJS(data.customerSubscriptionInfo.shippingAddress);
    const usedAddresses = fromJS(data.customerShippingAddresses);

    // Init selectedId
    if (this.state.originalSelectedId === null) {
      const currentAddress = this.findCurrentAddress(usedAddresses, selectedAddress);
      const selectedId = currentAddress
        ? currentAddress.get("addressId", "")
        : "";

      this.setState({
        originalSelectedId: selectedId,
        selectedId: selectedId,
      });
    }

    return (
      <Form
        errors={this.state.errors}
        usedShippingAddresses={usedAddresses}
        selectedId={this.state.selectedId}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onSelect={this.handleSelectAddressId.bind(this)}
        onNewAddress={this.handleAddAddress.bind(this)}
        onEditAddress={(addressId, address) => this.handleEditAddress(addressId, address)}
        onDeleteAddress={(addressId, address) => this.handleDeleteAddress(addressId, address)}
        />
    );
  }

  // The current shipping address is usually one of the addresses in the address
  // book, but that's not always the case as those can be edited and deleted.
  findCurrentAddress(usedAddresses, selectedAddress) {
    let currentAddress = selectedAddress.delete("__typename");
    return usedAddresses.find(address => {
      return address.get("address").delete("__typename").equals(currentAddress);
    });
  }
}

const GET_SUBSCRIPTION = gql`
  query customerSubscriptionInfo($accountId: ID!, $subscriptionId: ID!) {
    customerSubscriptionInfo(accountId: $accountId, subscriptionId: $subscriptionId) {
      shippingAddress {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressStateProvince
        addressPostcode
        addressCountryCode           
        addressCountryName        
      }
    }
    customerShippingAddresses(accountId: $accountId) {
      addressId
      address {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
    }
  }
`;

// sets the subscription's shipping address from the address book's selected address
const ASSIGN_EXISTING_ADDRESS = gql`
  mutation customerAssignSubscriptionExistingShippingAddress($data: SubscriptionExistingShippingAddressInput!) {
    customerAssignSubscriptionExistingShippingAddress(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(GET_SUBSCRIPTION, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(ASSIGN_EXISTING_ADDRESS, {
    props: ({ mutate }) => ({
      customerAssignSubscriptionExistingShippingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

export default connect(null, {
  cancelChangeSubscriptionShippingAddress,
  requestAddShippingAddress,
  requestEditShippingAddress,
  requestDeleteShippingAddress,
})(withQueries(ChangeSubscriptionShippingAddressFormContainer));

