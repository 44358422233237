import React, { useEffect } from "react";
import {connect} from "react-redux";
import {requestRenameAccount, requestAddAccount} from "../actions/AccountActions";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import GraphQLErrors from "../shared/GraphQLErrors";
import Navigation from "../lib/Navigation";
import useQueryMap from "../hooks/useQueryMap";
import LoadingCircle from "../shared/components/forms/LoadingCircle";

function isNewRegistration(query) {
  return query.get("new", "") === "1";
}
function isGoogleNewFlow(query) {
  return query.get("flow", "") === "new";
}

const gotoAccount = (query, accountId, page, productId, desiredLocale) => {
  if (isNewRegistration(query) || isGoogleNewFlow(query) || page === "shop") {
    if (productId) {
      Navigation.gotoShopProductReplaceHistory(accountId, productId, desiredLocale);
    } else {
      Navigation.gotoShopReplaceHistory(accountId, desiredLocale);
    }
  } else if (page === "settings") {
    Navigation.gotoSettingsReplaceHistory(accountId, desiredLocale);
  } else {
    Navigation.gotoAccountReplaceHistory(accountId, desiredLocale);
  }

  return null;
}


const RedirectToAccountPage = (props) => {
  const query = useQueryMap();
  let { data, page } = props;

  useEffect(() => {
    if (data.loading || data.error) {
      return null;
    }

    const { customerProfile, customerAccounts } = data;

    // The route's match for productId comes as an "undefined" string
    const productId = props.productId && props.productId !== "undefined" && props.productId;

    const desiredLocale = customerProfile && customerProfile.locale;
    
    // Best case: we know the client last used account
    if (customerProfile && customerProfile.lastSelectedAccountId) {
      return gotoAccount(query, customerProfile.lastSelectedAccountId, page, productId, desiredLocale);
    }

    // Otherwise, show the first valid account in the list
    const firstEnabledAccout = customerAccounts.filter(e => e.cartInitialized === true)[0];
    if (!firstEnabledAccout) {
      return Navigation.signOut();
    }
    return gotoAccount(query, firstEnabledAccout.accountId, page, productId, desiredLocale);
  }, [data, page, query, props.productId]);
  
  return data.error
    ? <GraphQLErrors error={data.error} />
    : <LoadingCircle center />;
}


const GET_ACCOUNTS = gql`
  query customerAccounts {
    customerAccounts {
      accountId
      name
      jurisdictionId
      notifications
      cartInitialized
      allowedWaterOrders
    }
    customerProfile {
      lastSelectedAccountId
      locale
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  requestAddAccount: () => dispatch(requestAddAccount()),
  requestRenameAccount: (accountId) => dispatch(requestRenameAccount(accountId))
})

const withQuery = compose(
  graphql(GET_ACCOUNTS)
);

export default connect(null, mapDispatchToProps)(withQuery(RedirectToAccountPage));
