import React from "react";
import StatusTag from "../../../shared/components/StatusTag";
import {formatStatus, isNegativeStatus, isPositiveStatus} from "../../../lib/DeliveryTools";
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import { useTranslation } from "react-i18next";

export default function DeliveryPackageStatusTag({status, loading, className=""}) {
  const { t } = useTranslation("accounts", { keyPrefix: "deliveries.status" });
  const commonProps = {
    className: className,
  }

  if (loading) {
    return <LoadingLine />
  } else if (status === "") {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.GREY} text="Info Missing" />;
  } else if (isNegativeStatus(status)) {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.RED} text={formatStatus(status, t)} />;
  } else if (isPositiveStatus(status)) {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.GREEN} text={formatStatus(status, t)} />;
  } else {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.GREY} text={formatStatus(status, t)} />;
  }

}
