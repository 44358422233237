import React from "react";
import styles from "./AddressNameAndType.module.scss";

function formatAddressType(str) {
  return (str === "business") ? "commercial" : "residential";
}

const AddressNameAndType = (props) => {
  let { address, showType = true } = props;

  const classes = [styles.module, showType && styles.showType];
  
  return (
    <div className={classes.join(" ")}>
      <div className={styles.name}>{ address.get("name") }</div>
      <div className={styles.type}>{ formatAddressType(address.get("type")) }</div>
    </div>
  );
}

export default AddressNameAndType;