import React, {useRef} from "react"
import styles from "./HeaderDesktop.module.scss";
import { buildWebsiteLink } from "../../lib/Navigation";
import useQueryMap from "../../hooks/useQueryMap";

const HeaderDesktop = (props) => {
  const { darkBackground } = props;

  const baseRef = useRef();
  const stickyState = "revealed";

  const query = useQueryMap();

  if (!props.show) return null;

  // On dark bg, wrapper should be absolute so the image goes all the way to
  // the top (the header overlaps on the image).
  const wrapperStyle = darkBackground
    ? styles.absoluteWrapper
    : styles.relativeWrapper;

  // Should be transparent only when the bg is dark and it's at the top so no
  // stickyState (when scrolling up it should never be transparent)
  const transparent = darkBackground && stickyState !== "revealed" && stickyState !== "hidden";

  let baseStyles = [styles.module];
  if (transparent) {
    baseStyles.push("headerOnDarkBackground"); // Global style name
  }
  if (stickyState === "revealed") {
    baseStyles.push(styles.stickyModule);
  }

  const submenu = props.submenu && (
    <div className={styles.submenu}>
      {props.submenu}
    </div>
  );

  return (
    <div className={wrapperStyle}>
      { props.banner }
      <div className={baseStyles.join(" ")} ref={baseRef}>
        <div className={styles.inner}>
          <a className={styles.logo} href={buildWebsiteLink(query)}
            aria-label="Go to Hallstein Water">
            <img
              src={transparent ? props.logoUrlLight : props.logoUrlDark}
              alt="Hallstein - Artesian Water"
              width={116}
              height={48}
            />
          </a>

          <div className={styles.navigation}>{ props.menu }</div>

          <div className={styles.userSpecifics}>
            <div className={styles.cartSummary}>
              { props.cart }
            </div>
            { props.account }
          </div>
        </div>
        {submenu}
      </div>
    </div>
  );
}

HeaderDesktop.defaultProps = {
  show: true,
  submenu: null,
}

export default HeaderDesktop;