const isRetina = function() {
  return (window.devicePixelRatio > 1);
}

const retinaURL = function(url) {
  return url.replace(/(\.[a-z]{3})$/, "@2x$1");
}

const optimalResURL = function(url) {
  if (isRetina()) return retinaURL(url);
  return url;
}

const optimalResURLFromMap = function(data = Map()) {
  if (data.get("retina", true) === false) return data.get("url");
  return optimalResURL(data.get("url"));  
}

const getUrlDependingOnWindowWidth = function(windowWidth, url, mobileUrl) {
  if (windowWidth <= 700) return url.replace(/(\.[a-z]{3})$/, "-mobile$1");
  return url;
}

const getImageUrlForProduct = function(sku) {
  return `/assets/product-${sku}-main.png`;
}
const getImageUrlForSubscription = function(sku) {
  return `/assets/subscription-${sku}-main.png`;
}

module.exports.isRetina = isRetina;
module.exports.retinaURL = retinaURL;
module.exports.optimalResURL = optimalResURL;
module.exports.optimalResURLFromMap = optimalResURLFromMap;
module.exports.getUrlDependingOnWindowWidth = getUrlDependingOnWindowWidth;
module.exports.getImageUrlForProduct = getImageUrlForProduct;
module.exports.getImageUrlForSubscription = getImageUrlForSubscription;
