import React, {useState} from "react";
import {connect} from "react-redux";
import SignIn from "../../login/views/SignIn";
import {requestRegister, signedIn} from "../../actions/AnonymousCheckoutActions";
import {validateEmail, validatePassword} from "../../lib/Validators";
import Auth from "../../Auth";
import {Map} from "immutable";
import { useTranslation } from "react-i18next";


const SignInFormContainer = (props) => {
  const { t } = useTranslation("checkout", { keyPrefix: "anonymous_checkout" });
  const [errors, setErrors] = useState([])

  function handleSignUp(e) {
    e.preventDefault();
    props.requestRegister();    
  }

  return (
    <>
      <SignIn
        subtitle={t("sign_in_text")}
        onSubmit={(values) => handleSubmit(values, setErrors, props.cartId, props.signedIn)}
        onSignUp={handleSignUp}
        darkBackground={false}
        errors={errors}
        validate={(values) => validateForm(values, setErrors)}
      />
    </>
  )
}

function handleSubmit(values, setErrors, cartId, signedInFn) {
  const data = values.toJS();
  const onError = (descr) => setErrors({"loginForm": descr});
  const onSuccess = (params) => signedInFn(params);
  new Auth().loginWithCredentials(data.username, data.password, {cartId: cartId}, {onError: onError, onSuccess: onSuccess});
}

function validateForm(values, setErrors) {
  setErrors([]); // Reset error state as we want to stop showing the "Wrong
  // username or password" message when the user modifies the fields.
  
  let errors = Map();
  errors = validateEmail(errors, values, "username");
  errors = validatePassword(errors, values, "password");
  return errors.toJS();
}

export default connect(null, {requestRegister, signedIn})(SignInFormContainer);
