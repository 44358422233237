import React, {Component} from "react";
import {Map, List} from "immutable";
import { cx, addClassName } from "./lib/ClassSet";
import "./Table.scss";

class Table extends Component {

  render() {

    let {data, renderer, rendererParams, itemKey, selected, getItemKey, getIsSelected} = this.props;

    let getKeyFn = (item) => item.get(itemKey);
    if (getItemKey) {
      getKeyFn = getItemKey;
    }

    // let isSelectedFn = (item) => item.get(itemKey) === selected;
    let isSelectedFn = (item) => getKeyFn(item) === selected;
    if (getIsSelected) {
      isSelectedFn = getIsSelected;
    }

    let items = data.map((item) => {
      return React.createElement(renderer, {
        data: item, 
        key: getKeyFn(item),
        selected: isSelectedFn(item),
        onClick: this.props.onItemClick,
        onCommand: this.props.onCommand,
        ...rendererParams
      });
    }).toList();

    let classes = cx({
      "Table": true,
      "mode-list": this.props.mode === "list",
      "mode-table": this.props.mode === "table"
    })

    classes = addClassName(this.props.extraClassName !== "", classes, this.props.extraClassName);

    if (this.props.mode === "list") {
      return (
        <div className={classes}>
          <ul>
            {items}
          </ul>
        </div>
      )
    }

    let tableHeader = null;
    if (this.props.tableHeader.size > 0) {
      let ths = this.props.tableHeader.map((v,i) => {
        if (Map.isMap(v)) {
          return <td key={`row-${i}`} className={this.computeClassName(v.get("id", "--"))}>{v.get("label", "--")}</td>
        } else {
          return <td key={`row-${i}`}>{v}</td>
        }
      });
      tableHeader = (
        <thead>
          <tr>
            {ths}
          </tr>
        </thead>
      )
    }

    return (
      <div className={classes}>
        <table>
         {tableHeader}
          <tbody>
            {items}
          </tbody>
        </table>
      </div>
    )
  }

  computeClassName(id) {
    let className = "f---";
    
    if (this.props.moduleSASS) {
      const upperFirst = id.charAt(0).toUpperCase() + id.slice(1);
      className = this.props.moduleSASS[`f${upperFirst}`]
    } else {
      className = `f-${id}`;
    }

    return className;
  }
}

export default Table;


Table.defaultProps = {
  itemKey: "id",
  extraClassName: "",
  tableHeader: List([]),
  rendererParams: {},
  mode: "list",
  moduleSASS: null,
}