import React from "react";
import Icon from "./Icon";

// Icons/Core/add-line
function Add(props) {
  const svgPointsParameter = "11 11 11 5 13 5 13 11 19 11 19 13 13 13 13 19 11 19 11 13 5 13 5 11";
  return (<Icon {...props} svgPointsParameter={svgPointsParameter} />);
}

export default Add;
