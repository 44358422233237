import React from "react";
import {longDateFormat} from "../../lib/DateFormatter";
import { distanceInDays } from "../../lib/DateFormatter";
import { useTranslation } from "react-i18next";
import Button from "../../shared/components/Button";
import { fromJS } from "immutable";
import { isCurrent } from "../../lib/SubscriptionHelper";
import DeliveryItem from "../shared/DeliveryItem";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { useQuery } from "react-apollo";
import GraphQLErrors from "../../shared/GraphQLErrors";
import gql from "graphql-tag";

// Used by the resume subscription 
function ConfirmDeliveryDate(props) {
  const {
    accountId,
    subscriptionId,
    i18nPrefix,
    onTryAnotherDate,
    desiredDeliveryDateStr,
  } = props;
  
  const { t } = useTranslation("accounts");

  const { data, loading, error } = useQuery(DATE_INFOS, {
    variables: { accountId: accountId, subscriptionId: subscriptionId, desiredDeliveryDate: desiredDeliveryDateStr }
  });

  if (loading) return <LoadingLine />;
  if (error) return <GraphQLErrors error={error} />
  
  const deliveryDateStr = data.customerSubscriptionScheduleDate.deliveryDate;
  const deliveryDate = new Date(deliveryDateStr);

  const formattedDate = longDateFormat(deliveryDate);
  const howManyDays = t("accounts:subscription.from_now", { n_days: distanceInDays(deliveryDate, new Date()) });

  const is_date_different = desiredDeliveryDateStr !== deliveryDateStr;
  const text_key = i18nPrefix + (is_date_different ? "text_date_different" : "text_date_ok");

  return (
    <div className="u-centered sg-grey-900">
      <p className="u-bottom-margin">{t(text_key)}</p>
      <div className="sg-font-bold">
        <div className="sg-text-lead sg-grey-900 u-no-margin">{formattedDate}</div>
        <div className="sg-footnote sg-grey-400">({howManyDays})</div>
        <p>
          <Button onClick={onTryAnotherDate} link label={t(i18nPrefix + "try_another_date")} />
        </p>
      </div>
      <div className="u-whitespace-small sg-footnote sg-grey-400">{t(i18nPrefix + "charge_info")}</div>
      {props.errors}
      <ConflictedDeliveries {...props} deliveryDate={deliveryDate} />
    </div>
  )
}

const DATE_INFOS = gql`
  query customerSubscriptionScheduleDate($accountId: ID!, $subscriptionId: ID!, $desiredDeliveryDate: String) {
    customerSubscriptionScheduleDate(accountId: $accountId, subscriptionId: $subscriptionId, desiredDeliveryDate: $desiredDeliveryDate) {
      deliveryDate
    }
  }
`


const NEARBY_MAX_DAYS = 7 * 24 * 60 * 60 * 1000; // One week => nearby

function ConflictedDeliveries(props) {
  const {
    accountId,
    subscriptionId,
    i18nPrefix,
    deliveryDate,
  } = props;

  const { t } = useTranslation("accounts");

  const { data, loading, error } = useQuery(DELIVERIES_INFOS, {
    variables: { accountId: accountId, subscriptionId: subscriptionId }
  });

  if (loading) return null; // Optional display so no progress bar for this
  if (error) return <GraphQLErrors error={error} />
  
  const customerSubscriptionDeliveries = data.customerSubscriptionDeliveries;

  const areNearby = (date1, date2) => {
    return Math.abs(date1 - date2) < NEARBY_MAX_DAYS;
  }

  const nearbyDeliveries = fromJS(customerSubscriptionDeliveries)
    .filter((d) => isCurrent(d) && areNearby(new Date(d.get("deliveryDate")), deliveryDate));
  
  if (nearbyDeliveries.size === 0) {
    return null;
  }
  
  const nearbyDeliveriesElems = nearbyDeliveries.map((d) => (
    <DeliveryItem delivery={d}
      key={d.get("deliveryId")}
      withoutPanel
    />
  ));

  return (
    <div className="u-whitespace-small">
      <p className="sg-footnote sg-accent-red-400 u-bottom-margin-short">
        {t(i18nPrefix + "caution_similar_date", { count: nearbyDeliveries.size })}
      </p>
      {nearbyDeliveriesElems}
    </div>
  )
}

const DELIVERIES_INFOS = gql`
  query customerSubscriptionDeliveries($accountId:ID!, $subscriptionId:ID!) {
    customerSubscriptionDeliveries(accountId: $accountId, subscriptionId: $subscriptionId) {
      deliveryId
      products {
        productSku
        productTitle
        quantity
      }
      requestedDeliveryDate
      deliveryDate
      requestStatus
      status
    }
  }
`

export default ConfirmDeliveryDate;

