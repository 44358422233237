import React from "react";
import styles from "./PromoCode.module.scss";

export default function PromoCode({active, code}) {

  if (active !== true) return null;
  return (
    <div className={styles.module}>
      <span className={styles.prefix}>Applied Code: </span><span className={styles.code}>{code}</span>
    </div>
  )
}

PromoCode.defaultProps = {
  active: false,
  code: ""
}