import React from "react";
import SmallMessage from "../shared/SmallMessage";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import Checked from "../shared/icons/Checked";
import SmallErrorMessage from "../shared/SmallErrorMessage";
import Button from "../../shared/components/Button";
import { useTranslation } from 'react-i18next';

const RetryPaymentForm = (props) => {
  const { t } = useTranslation("common");
  const {
    loading, errors, timeout,
    onCancel, onChangeCreditCard, onRetry,
  } = props;

  if (loading) {
    return (
      <SmallMessage title={t("credit_card.charging_title")}
        titleAsGuideHeader
        icon={<LoadingCircle />}
        message={t("credit_card.charging_text")} />
    );
  }

  if (timeout) {
    return (
      <SmallErrorMessage title={t("credit_card.could_not_charge_title")}
        titleAsGuideHeader
        message={t("credit_card.could_not_charge_timeout_text")}
        button={<Button label={t("credit_card.retry_charging")} onClick={onRetry} />}
      >
        {/* Cancel button at top right */}
        <Button className="desktopOnly mobileOnly" label={t("credit_card.continue_button")} onClick={onCancel} addCancelToTopRight />
      </SmallErrorMessage>
    );
  }
  
  if (errors.length > 0) {
    return (
      <SmallErrorMessage title={t("credit_card.could_not_charge_title")}
        titleAsGuideHeader
        message={t("credit_card.could_not_charge_error_text")}
        button={<Button label={t("credit_card.edit")} onClick={onChangeCreditCard} />}
      >
        {/* Cancel button at top right */}
        <Button className="desktopOnly mobileOnly" label={t("credit_card.continue_button")} onClick={onCancel} addCancelToTopRight />
      </SmallErrorMessage>
    );
  }

  return (
    <SmallMessage title={t("credit_card.charging_success_title")}
     titleAsGuideHeader
     icon={<Checked colorDisabled="var(--accent-green-600)" />}
      message={t("credit_card.charging_success_text")}
      button={<Button label={t("credit_card.continue_button")} onClick={onCancel} addCancelToTopRight />}
    />
  );
}

export default RetryPaymentForm;
