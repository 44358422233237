import {validatePresence, validatePhoneNumber} from "./Validators";
import {hasStateProvinceForAddress} from "./AddressRules";
import {Map} from "immutable";

function validateAddress(values, errors) {
  errors = validatePresence(errors, values, "name");
  errors = validatePresence(errors, values, "addressCountryCode");
  errors = validatePresence(errors, values, "addressAddressLine1");
  errors = validatePresence(errors, values, "addressCity");
  if (hasStateProvinceForAddress(values)) {
    // No need for validateIncludes as the value comes from dropdowns
    errors = validatePresence(errors, values, "addressStateProvince");
  }
  errors = validatePresence(errors, values, "addressPostcode");
  return errors;  
}

export function validateShippingAddress(values, errors=Map()) {
  errors = validatePresence(errors, values, "type");
  // Phone number is optional so we don't validate its presence
  errors = validatePhoneNumber(errors, values, "phoneNr");
  return validateAddress(values, errors);
}

export function validateBillingAddress(values, errors=Map()) {
  return validateAddress(values, errors);
}
