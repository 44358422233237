import React from "react";
import styles from "./GuideHeader.module.scss";

const GuideHeader = (props) => {

  return (
    <div className={styles.module}>
      <div className={styles.header}/>
      <div className={styles.inner}>
        <div className={styles.title}>{props.title}</div>
        <div className="step">{props.step}</div>
      </div>
    </div>
  )
}

GuideHeader.defaultProps = {
  title: "Title",
  step: ""
}

export default GuideHeader;