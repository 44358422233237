import {connect} from "react-redux";
import App from "./App";
import {requestProfile} from "./actions/LoginActions";
import {setSocketConnected, setSocketDisconnected} from "./actions/SystemActions";
import {getModals} from "./reducers/WindowsReducer";
import {getMediaQueryClassName} from "./reducers/SystemReducer";
import {getNotifications} from "./reducers/NotificationsReducer";
import {withRouter} from "react-router";
import "./i18n";
import "./shared/styles/global.scss";


function mapStateToProps(state, ownProps) {
  return {
    windows: state.getIn(["windows", "windows"]),
    modals: getModals(state.get("windows")),
    modalActive: state.getIn(["windows", "modalActive"]),
    windowWidth: state.getIn(["system", "width"]),
    getNotifications: () => getNotifications(state),
    mediaQueryClassName: getMediaQueryClassName(state.get("system"))
  }
}

export default connect(mapStateToProps, {requestProfile, setSocketConnected, setSocketDisconnected})(withRouter(App));