import React, {Component} from "react";
import Button from "../../shared/components/Button";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import Checked from "../shared/icons/Checked";
import SmallMessage from "../shared/SmallMessage";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import SmallErrorMessage from "../shared/SmallErrorMessage";
import { withTranslation } from "react-i18next";

const PROCESS_STATUS = gql`
  query customerSubscriptionProcessStatus($subscriptionId: ID!, $requestId: ID!) {
    customerSubscriptionProcessStatus(subscriptionId: $subscriptionId, requestId: $requestId) {
      status
      message
      updatedAt
    }
  }
`

const POLL_INTERVAL = 2000;
const TIMER_WAIT = 4000; // 4 seconds

class CancelSubscriptionProgressForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // Show a back button in case it takes too long and maybe we are stuck.
      showFallbackBackButton: false
    }
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }  

  startTimer() {
    this.timeoutId = window.setTimeout(this.onTimer.bind(this), TIMER_WAIT)
  }

  stopTimer() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }  

  onTimer() {
    this.setState({showFallbackBackButton: true});
  }  

  render() {
    let variables = {
      subscriptionId: this.props.subscriptionId,
      requestId: this.props.requestId
    }
    return (
      <div className="CancelSubscriptionProgressForm">
        <Query query={PROCESS_STATUS} variables={variables} pollInterval={POLL_INTERVAL} fetchPolicy="network-only">
          {({ loading, error, data, stopPolling }) => {
            
            if (loading) {
              return this.renderLoadingStatus();
            }

            if (error) {
              stopPolling();
              return this.renderFailed(error);
            }

            if (data.customerSubscriptionProcessStatus.status === "failed") {
              stopPolling();
              return this.renderFailed();
            }

            if (data.customerSubscriptionProcessStatus.status === "cancelled") {
              stopPolling();
              return this.renderCompleted();
            }

            if (data.customerSubscriptionProcessStatus.status === "deferred") {
              stopPolling();
              return this.renderDeferred();
            }

            return this.renderLoadingStatus();
          }}
        </Query>            
      </div>
    )
  }

  renderLoadingStatus() {
    if (this.state.showFallbackBackButton) {
      return (
        <SmallMessage title={this.props.t("common:standby")}
          icon={<LoadingCircle />}
          message={this.props.t("subscription.cancel_progress.takes_longer")}
          button={<Button label={this.props.t("common:go_back")} onClick={this.props.onBack} />}
        />
      );
    } 

    return (
      <SmallMessage title={this.props.t("common:standby")}
        icon={<LoadingCircle />}
        message={this.props.t("subscription.cancel_progress.please_wait")}
      />
    );
  }

  renderFailed(error) {
    return (
      <SmallErrorMessage
        message={this.props.t("subscription.cancel_progress.try again")}
        button={<Button label={this.props.t("common:try_again")} onClick={this.props.onBack} />}
      />
    );
  }

  renderDeferred() {
    return (
      <SmallMessage title={this.props.t("subscription.cancel_progress.ongoing_delivery_title")}
        icon={<Checked side="80px" colorDisabled="var(--primary-blue-700)" />}
        message={this.props.t("subscription.cancel_progress.ongoing_delivery_text")}
        button={<Button label={this.props.t("common:continue")} onClick={this.props.onCompleted} />}
      />
    ); 
  }  

  renderCompleted() {
    return (
      <SmallMessage
        icon={<Checked side="80px" colorDisabled="var(--primary-blue-700)" />}
        message={this.props.t("subscription.cancel_progress.success")}
        button={<Button label={this.props.t("common:continue")} onClick={this.props.onCompleted} />}
      />
    ); 
  }  
}

export default withTranslation("accounts")(CancelSubscriptionProgressForm);