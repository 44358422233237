import React, { Component } from "react";
import { cx } from "../../lib/ClassSet";
import FieldError from "./FieldError";
import styles from "./Checkbox.module.scss";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      focused: false,
    };
  }

  render() {
    const {
      serverError,
      onValue,
      input: { value },
      meta: { touched, error },
      darkBackground,
      submitting = false,
    } = this.props;
    const checked = value === onValue;
    let classes = cx({
      [styles.module]: true,
      [this.props.className]: this.props.className,
      [styles.active]: this.props.active,
      [styles.inactive]: !this.props.active,
      [styles.checked]: checked,
      [styles.unchecked]: !checked,
      [styles.darkBackground]: darkBackground,
      [styles.submitting]: submitting,
    });

    return (
      <div className={classes}
        onKeyDown={(e) => (e.key === " " || e.key === "Enter") && (e.preventDefault(), this.handleClick())}
        role="checkbox" aria-checked={value === onValue} tabIndex="0">
        <div className={styles.inner}>
          <div className={styles.box} onClick={this.handleClick}>
            <div className={styles.icon} />
          </div>
          <div className={styles.label}>{this.props.label}</div>
        </div>
        <FieldError
          serverError={serverError}
          clientError={error}
          showClientError={touched && error}
          darkBackground={darkBackground}
        />
      </div>
    );
  }

  handleClick(e) {
    if (this.props.active && !this.props.submitting) {
      if (this.props.input.value === this.props.onValue) {
        this.props.input.onChange(this.props.offValue);
      } else {
        this.props.input.onChange(this.props.onValue);
      }
    }
  }

  handleFocus(e) {
    e.target.select();
    this.setState({ focused: true });
  }

  handleBlur(e) {
    this.setState({ focused: false });
  }
}

Checkbox.defaultProps = {
  active: true,
  onValue: "yes",
  offValue: "no",
  darkBackground: false,
};

export default Checkbox;
