import React from "react";
import StatusTag from "../../shared/components/StatusTag";
import { useTranslation } from 'react-i18next';

const AccountStatus = (props) => {
  const { t } = useTranslation("common");
  const {activeSubscriptions, pausedSubscriptions} = props;

  const tagCommonProps = {
    size: StatusTag.SIZES.SMALL,
    alt: true,
  }

  if (!activeSubscriptions && !pausedSubscriptions) {
    return (
      <StatusTag color={StatusTag.COLORS.GREY}
        text={t("subscription_active", { count: 0 })}
        {...tagCommonProps}
      />
    );
  }

  const active = Number.isInteger(activeSubscriptions) && activeSubscriptions > 0;
  const paused = Number.isInteger(pausedSubscriptions) && pausedSubscriptions > 0;

  return (
    <React.Fragment>
      {active && <StatusTag color={StatusTag.COLORS.GREEN}
        text={t("subscription_active", { count: activeSubscriptions })}
        {...tagCommonProps} />}
      {paused && <StatusTag color={StatusTag.COLORS.GOLD}
        text={t("subscription_paused", { count: pausedSubscriptions })}
        {...tagCommonProps} />}
    </React.Fragment>
  );
}

export default AccountStatus;
