import React from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import TelephoneInputField from "../../shared/components/forms/TelephoneInputField";
import FormError from "../../shared/components/forms/FormError";
import normalizePhoneNr from "../../lib/TelephoneNormalizer";
import { useTranslation } from 'react-i18next';

function EditProfileForm(props) {
  const { t } = useTranslation("settings");

  const {errors, handleSubmit, submit, submitting} = props;
  const submitLabel = submitting ? t("common:standby") : t("profile.modify_profile.popup.ok_label");

  return (
    <form className="EditProfileForm" onSubmit={handleSubmit}>
      <p className="u-centered">{t("profile.modify_profile.popup.text")}</p>
      <div className="fields">
        <Field
          label={t("forms:full_name")}
          name="name"
          component={TextInputField}
          mode=""
          serverError={props.errors.name}
          />
        <Field
          label={t("forms:email")}
          name="email"
          component={TextInputField}
          mode=""
          serverError={props.errors.email}
          />
        <Field
          label={t("forms:phone_number")}
          name="phone"
          normalize={normalizePhoneNr}
          component={TelephoneInputField}
          mode=""
          serverError={props.errors.phone}
          />
      </div>
      <div className="buttons">
        <Button label={submitLabel} primary={true} onClick={submit} active={!submitting} />
        <Button label={t("profile.modify_profile.popup.cancel_label")} active={!submitting} onClick={props.onCancel}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
        
      <FormError error={errors._error} />
    </form>
  )
}

const ReduxForm = reduxForm({
  form: "EditProfileForm"
})(EditProfileForm);

export default ReduxForm;