import React from "react";
import Icon from "./Icon";

// Icons/Core/edit-2-fill
function RenameButton(props) {
  const svgPathDParameter = "M9.243,19 L21,19 L21,21 L3,21 L3,16.757 L12.9,6.857 L17.142,11.101 L9.242,19 L9.243,19 Z M14.313,5.444 L16.435,3.322 C16.8254999,2.93161806 17.4585001,2.93161806 17.849,3.322 L20.678,6.151 C21.0683819,6.54149985 21.0683819,7.17450015 20.678,7.565 L18.556,9.686 L14.314,5.444 L14.313,5.444 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default RenameButton;
