import React from "react";
import BackgroundImage from "./BackgroundImage";
import "./WarningMessage.css";

const WarningMessage = (props) => {
  if (!props.show) return null;
  
  return (
    <div className="WarningMessage">
      <BackgroundImage width="14px" height="14px" src="/assets/icon-exclamation.png" />
      <p><span className="title">{props.title}</span> {props.message}</p>
    </div>
  )

}

WarningMessage.defaultProps = {
  show: true,
  title: "Warning:",
  message: "message",
  showIcon: true
}

export default WarningMessage;