import React from "react";
import {optimalResURL} from "../lib/MediaTools";
import { addClassName } from "./lib/ClassSet";

const BackgroundImage = (props) => {
  let styles = {
    width: props.width,
    height: props.height,
    backgroundImage: `url(${optimalResURL(props.src)})`,
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: props.backgroundSize || "cover"
  }
  let action = (e) => null;
  if (props.onClick) action = props.onClick
  let classNames = addClassName(props.className.length > 0, "BackgroundImage", props.className);
  return (
    <div className={classNames} style={styles} onClick={action} title={props.title} />
  )
}

BackgroundImage.defaultProps = {
  width: "100px",
  height: "100px",
  retina: true,
  title: "",
  backgroundSize: null,
  src: "",
  className: ""
}

export default BackgroundImage;
