import {fromJS, Map} from "immutable";

import {
  PRODUCT_CMS_DATA
} from "../actions/CmsActions";

const initialState = fromJS({
  status: "not_loaded", // not_loaded, loading, loaded, error
  data: Map()
})

function normalizeData(data) {
  return data.get("edges").reduce((acc, edge) => {
    return acc.set(edge.getIn(["node", "jurisdiction"]), edge.get("node"));
  }, Map());
}

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case PRODUCT_CMS_DATA.LOADING:
      return state.set("status", "loading");

    case PRODUCT_CMS_DATA.SUCCESS:
      return state.set("status", "loaded").set("data", normalizeData(fromJS(action.data)));

    case PRODUCT_CMS_DATA.FAILED:
      // If we already have a loaded state, then we don't overwrite what we have.
      if (state.get("status") === "loaded") return state;
      return state.set("status", "error");

    default:
      return state;
  }
}

export default reducer;