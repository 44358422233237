import React from "react";
import { Ring } from '@uiball/loaders';
import styles from "./LoadingCircle.module.scss";

const LoadingCircle = (props) => {
  if (!props.show) return null;

  const classes = [
    props.className,
    styles.module,
    props.center ? styles.center : null,
  ]

  return (
    <div className={classes.join(" ")}>
      <Ring size={props.size}
        color={props.color} />
    </div>
  );
}

LoadingCircle.SIZES = {
  NORMAL: 60,
  SMALL: 32,
}

LoadingCircle.defaultProps = {
  show: true,
  color: "var(--primary-blue-800)",
  size: LoadingCircle.SIZES.NORMAL,
  center: false, // Draw at center of viewport
}

export default LoadingCircle;