import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./ChangeShippingAddressForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelChangeShippingAddress} from "../../actions/ShopActions";
import { requestAddShippingAddress, requestEditShippingAddress, requestDeleteShippingAddress } from "../../actions/AddressBookActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class ChangeShippingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      originalSelectedId: null,
      selectedId: null,
      errors: [] 
    }
  }

  handleSubmit() {
    if (this.state.selectedId === this.state.originalSelectedId) {
      this.props.cancelChangeShippingAddress();
    } else {
      const onSuccess = this.props.cancelChangeShippingAddress;
      return this.handleCustomerAssignAddress(this.state.selectedId, onSuccess);
    }
  }

  handleCustomerAssignAddress(addressId, onSuccess) {
    return this.props.customerAssignExistingShippingAddress({orderId: this.props.orderId, accountId: this.props.accountId, addressId: addressId})
      .then((response) => {
        if (response.data.customerAssignExistingShippingAddress.errors.length <= 0) {
          onSuccess();
        } else {
          let errors = createValidationErrors(response.data.customerAssignExistingShippingAddress.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });
  }

  handleCancel() {
    this.props.cancelChangeShippingAddress();
  }

  handleAddAddress() {
    this.props.requestAddShippingAddress(this.props.accountId);
  }

  handleEditAddress(addressId, address) {
    this.props.requestEditShippingAddress(this.props.accountId, addressId, address);
  }

  handleDeleteAddress(addressId, address) {
    this.props.requestDeleteShippingAddress(this.props.accountId, addressId, address);
  }

  handleSelectAddressId(id) {
    this.setState({
      selectedId: id
    })
  }

  componentDidUpdate(prevProps) {
    if (this.state.originalSelectedId !== null) return;

    const shippingAddress = this.props.data.customerCheckoutInfo?.shippingAddress;
    const customerShippingAddresses = this.props.data.customerShippingAddresses;

    if (!shippingAddress || !customerShippingAddresses) return;

    const selectedAddress = fromJS(shippingAddress);
    const usedAddresses = fromJS(customerShippingAddresses);

    let selectedId = null;
    if (this.props.originalAddressId && this.addressExists(usedAddresses, this.props.originalAddressId)) {
      // Use original address id passed by the saga
      selectedId = this.props.originalAddressId;
    }
    else {
      // Try to match the shipping address
      const currentAddress = this.findCurrentAddress(usedAddresses, selectedAddress);
      selectedId = currentAddress && currentAddress.get("addressId", "");
    }
    
    // When editing address the modal remembers the id and gives it back as
    // an "editedAddressId" prop. In such a case we reassign that modified
    // address to the shipping address.
    const cameBackFromEditingAddress = !!this.props.editedAddressId;
    if (cameBackFromEditingAddress && this.props.editedAddressId === this.props.originalAddressId) {
      this.handleCustomerAssignAddress(selectedId, () => {});
    }

    if (selectedId) {
      this.setState({
        originalSelectedId: selectedId,
        selectedId: selectedId,
      });
    }
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />

    const selectedAddress = fromJS(data.customerCheckoutInfo.shippingAddress);
    const usedAddresses = fromJS(data.customerShippingAddresses);

    return (
      <Form
        errors={this.state.errors}
        selectedAddress={selectedAddress}
        usedShippingAddresses={usedAddresses}
        originalSelectedId={this.state.originalSelectedId}
        selectedId={this.state.selectedId}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onSelect={this.handleSelectAddressId.bind(this)}
        onNewAddress={this.handleAddAddress.bind(this)}
        onEditAddress={(addressId, address) => this.handleEditAddress(addressId, address)}
        onDeleteAddress={(addressId, address) => this.handleDeleteAddress(addressId, address)}
        />
    );
  }

  // The current shipping address is usually one of the addresses in the address
  // book, but that's not always the case as those can be edited and deleted.
  findCurrentAddress(usedAddresses, selectedAddress) {
    let currentAddress = selectedAddress.delete("__typename");
    return usedAddresses.find(address => {
      return address.get("address").delete("__typename").equals(currentAddress);
    });
  }
  addressExists(usedAddresses, addressId) {
    return usedAddresses.find((e) => e.get("addressId") === addressId)
  }
}

const CUSTOMER_ADDRESSES = gql`
  query customerAddresses($accountId:ID!, $orderId:ID!) {
    customerCheckoutInfo(accountId: $accountId, orderId: $orderId) {
      shippingAddressStatus
      shippingAddress {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
    }
    customerShippingAddresses(accountId: $accountId) {
      addressId
      address {
        type
        name
        attName
        phoneNr
        email
        addressAddressLine1
        addressAddressLine2
        addressCity
        addressPostcode
        addressStateProvince
        addressCountryName
        addressCountryCode
      }
    }
  }
`;

// sets the order's shipping address from the address book's selected address
const ASSIGN_EXISTING_ADDRESS = gql`
  mutation customerAssignExistingShippingAddress($data: OrderExistingShippingAddressInput!) {
    customerAssignExistingShippingAddress(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CUSTOMER_ADDRESSES, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId,
        orderId: props.orderId
      }
    })
  }),
  graphql(ASSIGN_EXISTING_ADDRESS, {
    props: ({ mutate }) => ({
      customerAssignExistingShippingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {
  cancelChangeShippingAddress,
  requestAddShippingAddress,
  requestEditShippingAddress,
  requestDeleteShippingAddress,
})(withQueries(ChangeShippingAddressFormContainer));