import { List, Map } from "immutable";
import { prismicTextIsEmpty } from "./PrismicHelper";

// File mostly shared by SHOP app and website INSTASHOP

export const MAX_NUMBER_WATER_ITEMS = 20;
export const MAX_NUMBER_NON_WATER_ITEMS = 3;
export function maxNumberOfProductsForOneOrder(sku) {
  return sku.startsWith("WAT-")
    ? MAX_NUMBER_WATER_ITEMS
    : MAX_NUMBER_NON_WATER_ITEMS;
}

export const findSurchageForCountry = (products, country, sku) => {
  const lowerCountryCode = country.code.toLowerCase();
  const lowerSKU = sku.toLowerCase();
  // Until we can support selecting states, we must ignore surcharges for specific states 
  const criteria = (s) => s.state === "" && s.country.toLowerCase() === lowerCountryCode && s.sku.toLowerCase() === lowerSKU;
  const surchargeData = products.surcharges && products.surcharges.find(criteria);
  const surcharge = surchargeData && surchargeData.surcharge;
  
  return surcharge;
}

export const getNotificationForJurisdiction = (cmsData, shopId) => {
  const notificationText = cmsData.getIn([shopId, "notification_text"]);

  const shouldDisplay = !prismicTextIsEmpty(notificationText);

  return shouldDisplay && Map({
    text: notificationText.toJS(),
    type: cmsData.getIn([shopId, "notification_type"]),
  });
}
export const getProductsForJurisdiction = (cmsData, shopId) => {
  return cmsData.getIn([shopId, "products"], List());
}
export const getProductForJurisdiction = (cmsData, shopId, productSku) => {
  const products = getProductsForJurisdiction(cmsData, shopId);
  return products.find((p) => getPrismicProductSku(p.get("product")) === productSku);
}

// Prismic specific data helpers
export const getPrismicProductId = (prismicProduct) => {
  return prismicProduct.getIn(["_meta", "uid"], "");
}
export const getPrismicProductSku = (prismicProduct) => {
  return prismicProduct.getIn(["sku"]);
}
const get = (obj, attr) => {
  // immutable
  if (obj.get) {
    return obj.get(attr);
  }
  // plain
  return obj[attr];
}
export const hasRelatedProducts = (prismicProduct) => {
  const rels = get(prismicProduct, "related_products");

  return rels.find((e) => !!get(e, "product"));
}
export const isProduct = (prismicProduct) => {
  return get(prismicProduct, "__typename") === "Product";
}
export const isSimpleCard = (prismicProduct) => {
  return get(prismicProduct, "__typename") === "Simple_card";
}
export const productHasTag = (prismicProduct, tagName) => {
  const tags = get(prismicProduct, "tags");
  return tags && !!tags.find((wrap) => get(wrap, "tag") === tagName);
}
