import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import {connect} from "react-redux";
import Form from "./ChangeCreditCardForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelChangeCreditCard, requestAddCreditCard} from "../../actions/AccountActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class ChangeCreditCardFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      selectedId: "current",
      errors: [] 
    }
  }

  componentDidMount() {
    this.subscribe();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.accountId && (prevProps.accountId !== this.props.accountId)) {
      this.subscribe();
    }
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    if (this.unsubscribeSubscriptionsChanges) {
      this.unsubscribeSubscriptionsChanges();
    }
    this.unsubscribeBillingChanges = this.props.subscribeToBillingChanges(this.props, {});
  }
  unsubscribe() {
    if (this.unsubscribeBillingChanges) {
      this.unsubscribeBillingChanges();
    }
  }

  handleSubmit(values) {
    if (this.state.selectedId === "current") {
      this.props.cancelChangeCreditCard();
    } else {
      return this.props.customerAssignDefaultPaymentMethod({accountId: this.props.accountId, paymentMethodId: this.state.selectedId})
        .then((response) => {
          if (response.data.customerAssignDefaultPaymentMethod.errors.length <= 0) {
            this.props.cancelChangeCreditCard();
          } else {
            let errors = createValidationErrors(response.data.customerAssignDefaultPaymentMethod.errors);
            this.setState({errors: errors});
          }
        })
        .catch((err) => {
          this.setState({errors: createErrors(err)});
        });
    }
  }

  handleCancel() {
    this.props.cancelChangeCreditCard();
  }

  handleAddCard() {
    this.props.cancelChangeCreditCard();
    this.props.requestAddCreditCard(this.props.accountId);
  }

  handleSelectCardId(id) {
    this.setState({
      selectedId: id
    })
  }

  handleDeleteCardId(id) {
    return this.props.customerRemovePaymentMethod({accountId: this.props.accountId, paymentMethodId: id})
      .then((response) => {
        if (response.data.customerRemovePaymentMethod.errors.length <= 0) {
          // this.props.cancelChangeCreditCard();
          // ^ we don't want to close the window after a card gets deleted..
        } else {
          let errors = createValidationErrors(response.data.customerRemovePaymentMethod.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    const cards = fromJS(data.customerPaymentMethods);
    let defaultCard = cards.find(x => x.get("default") === true);
    return (
      <Form
        errors={this.state.errors}
        selectedPaymentMethod={defaultCard}
        paymentMethods={cards}
        selectedId={this.state.selectedId}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onAddCard={this.handleAddCard.bind(this)}
        onSelect={this.handleSelectCardId.bind(this)}
        onDelete={this.handleDeleteCardId.bind(this)}
        />
    )
  }
}

const CUSTOMER_CREDIT_CARDS = gql`
  query customerPaymentMethods($accountId:ID!) {
    customerPaymentMethods(accountId:$accountId) {
      paymentMethodId
      type
      cardBrand
      cardExpYear
      cardExpMonth
      cardLast4
      default
      addedAt
    } 
  }
`

const ON_UPDATED_BILLING = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedBilling(accountId: $accountId) {
    accountId
  }`
);

const ASSIGN_DEFAULT_CARD = gql`
  mutation customerAssignDefaultPaymentMethod($data: CustomerAssignDefaultPaymentMethodInput!) {
    customerAssignDefaultPaymentMethod(input: $data) {
      errors { key message }
    }
  }
`

const REMOVE_CARD = gql`
  mutation customerRemovePaymentMethod($data: CustomerRemovePaymentMethodInput!) {
    customerRemovePaymentMethod(input: $data) {
      errors { key message }
    }
  }
`

const mapDispatchToProps = (dispatch) => ({
  subscribeToBillingChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_BILLING,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestAddCreditCard: (props) => dispatch(requestAddCreditCard(props)),
  cancelChangeCreditCard: (props) => dispatch(cancelChangeCreditCard(props)),
})

const withQueries = compose(
  graphql(CUSTOMER_CREDIT_CARDS, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId
      }
    })
  }),
  graphql(ASSIGN_DEFAULT_CARD, {
    props: ({ mutate }) => ({
      customerAssignDefaultPaymentMethod: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(REMOVE_CARD, {
    props: ({ mutate }) => ({
      customerRemovePaymentMethod: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

export default connect(null, mapDispatchToProps)(withQueries(ChangeCreditCardFormContainer));
