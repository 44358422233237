import { useTranslation } from "react-i18next";
import React from "react";
import Button from "./Button";
import PriceAndButtonForOneTimeProduct from "./PriceAndButtonForOneTimeProduct";
import PriceAndButtonForSubscribableProduct from "./PriceAndButtonForSubscribableProduct";

const ProductPriceAndButton = (props) => {
  const { t } = useTranslation("products");
  const theme = Button.THEMES.DARK_BLUE;

  return props.subscriptionPrice
    ? <PriceAndButtonForSubscribableProduct {...props} buttonTheme={theme} />
    : <PriceAndButtonForOneTimeProduct {...props} buttonTheme={theme} suffix={" + " + t("taxes")} />
}

ProductPriceAndButton.defaultProps = {
  inCart: false,
  active: true,
  currency: "USD",
  price: "",
  suffix: "",
  label: "Label",
  titleKey: "title"
}

export default ProductPriceAndButton;
