import React from "react";
import { Field } from "redux-form/immutable";
import { reduxForm } from "../../lib/ReduxFormWrapper";
import TextInputField from "../../shared/components/forms/TextInputField";
import Button from "../../shared/components/Button";
import { useTranslation } from 'react-i18next';
import styles from "./ForgotPassword.module.scss";

const successView = (succeededFor, t) => (
  <React.Fragment>
    <svg width="73px" height="63px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <path d="M22,14 L20,14 L20,7.238 L12.072,14.338 L4,7.216 L4,19 L14,19 L14,21 L3,21 C2.44771525,21 2,20.5522847 2,20 L2,4 C2,3.44771525 2.44771525,3 3,3 L21,3 C21.5522847,3 22,3.44771525 22,4 L22,14 Z M4.511,5 L12.061,11.662 L19.502,5 L4.511,5 Z M19,22 L15.464,18.464 L16.879,17.05 L19,19.172 L22.536,15.636 L23.95,17.05 L19,22 Z" id="Shape" fill="#fff"></path>
        </g>
      </g>
    </svg>

    <p className="sg-large">{t("forgot_password.check_emails")}</p>
    <p className="sg-small">{t("forgot_password.check_emails_info", {address: succeededFor})}</p>

    <Button
      label={t("forgot_password.go_back")}
      href="/signin" link={true}
      theme={Button.THEMES.WHITE}
      className="sg-font-bold sg-text-small"
    />
  </React.Fragment>
);

let ForgotPasswordForm = props => {
  const { t } = useTranslation("auth");
  const { handleSubmit, submit, succeededFor } = props;

  return (
    <div className={styles.module}>
      {/* Hallstein logo as header */}
      <img
        className={styles.logo}
        src="/assets/LogoSimple-White.svg"
        alt="Hallstein - Artesian Water"
        width={210}
        height={87}
      />

      {succeededFor ? successView(succeededFor, t) :
        <form onSubmit={handleSubmit}>
          <p className="sg-large">{t("forgot_password.title")}</p>
          <p className="sg-small">{t("forgot_password.info")}</p>
          <div className="fields sg-mb-normal">
            <Field
              label={t("forms:email")}
              name="email"
              placeholder={t("forms:email_placeholder")}
              component={TextInputField}
              autoFocus={true}
              noTable={true}
              darkBackground={true}
              serverError={props.errors.email}
            />
            <Button
              label={t("forgot_password.send_instructions")}
              primary="true"
              size={Button.SIZES.MEDIUM}
              className="expand sg-mb-tiny"
              onClick={submit}
            />
            <Button
              label={t("forgot_password.go_back")}
              href="/signin" link={true}
              theme={Button.THEMES.WHITE}
              className="sg-font-bold sg-text-small"
            />
          </div>
        </form>
      }
    </div>
  );
}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPassword'
})(ForgotPasswordForm);

export default ForgotPasswordForm;