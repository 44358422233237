import React from "react";
import Icon from "./Icon";

// Icons/Core/gift-2-line
function Gift(props) {
  const svgPathDParameter = "M14.5,2 C15.700526,1.99958943 16.8174916,2.61451164 17.4592128,3.62913313 C18.1009341,4.64375462 18.1778543,5.916478 17.663,7.001 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,12 C22,12.5522847 21.5522847,13 21,13 L20,13 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,13 L3,13 C2.44771525,13 2,12.5522847 2,12 L2,8 C2,7.44771525 2.44771525,7 3,7 L6.337,7.001 C5.60130584,5.45559928 6.09119569,3.60481631 7.49509866,2.62575261 C8.89900164,1.64668892 10.8050528,1.82657375 12.001,3.051 C12.6576828,2.37726534 13.5591774,1.99812536 14.5,2 Z M18,13 L6,13 L6,20 L18,20 L18,13 Z M20,9 L4,9 L4,11 L20,11 L20,9 Z M9.5,4 C8.69988381,4.00039617 8.04092672,4.62872296 8.00247579,5.42791481 C7.96402486,6.22710665 8.55961749,6.91579267 9.356,6.993 L9.5,7 L11,7 L11,5.5 C10.9999629,4.72737809 10.4130534,4.0811717 9.644,4.007 L9.5,4 Z M14.5,4 L14.356,4.007 C13.64108,4.07549475 13.0750245,4.64113094 13.006,5.356 L13,5.5 L13,7 L14.5,7 L14.644,6.993 C15.4127586,6.91846976 15.9992554,6.27236293 15.9992554,5.5 C15.9992554,4.72763707 15.4127586,4.08153024 14.644,4.007 L14.5,4 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default Gift;
