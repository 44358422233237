import React from "react";
import InfoIcon from "../../../shared/InfoIcon";
import Checked from "../../shared/icons/Checked";
import styles from "./CheckoutPanel.module.scss";

const Panel = (props) => {
  const { disabled, title, nbTasks, completed, instructions, help, children } = props;

  const classes = [
    styles.module,
    disabled && styles.disabled
  ];
  const helpElem = help && <p className="helptext">{help}</p>;
  const instructionsElem = instructions
    && <div className={styles.instructions}><InfoIcon />{ instructions }</div>;

  return (
    <div className={classes.join(" ")}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        { nbTasks && (
          <Checked side="32px"
            colorDisabled={completed ? "var(--accent-green-600)" : "var(--grey-200)"}
          />
        )}
      </div>

      <div className={styles.body}>
        { helpElem }
        { children }
        { instructionsElem }
      </div>
    </div>
  );
}


export default Panel;