import React from "react";

export function linkWithChangePage(changePage) {
  return (type, element, content, children, index) => {
    if (element.data.link_type === "Document") {
      return (
        // eslint-disable-next-line
        <a key={index} href="#" onClick={() => changePage(element.data.uid)}>{content}</a>
      )
    }
    return (
      <a key={index} href={element.data.url}>{content}</a>
    )
  }
}