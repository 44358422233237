//
// Cf inputs in design system here: https://app.zeplin.io/project/5fc893a7d73bf085db2cc3f4/screen/6000481929f74e1b01c36373
//
// This file should be shared between the website and the shop

import React from "react";
import { fromJS, List } from "immutable";
import Forbidden from "../../../shop/shared/icons/Forbidden";
import styles from "./FieldError.module.scss";

function addPeriod(v) {
  if (v.substr(-1, 1) === ".") return v;
  return v + ".";
}

function formatErrors(errors) {
  console.log(errors)
  // Make sure we have periods.
  let updated = errors.map((v, k) => addPeriod(v));
  return updated.join(" ");
}

const FieldError = (props) => {
  let errors = [];
  if (props.serverError) errors.push(props.serverError);
  if (props.showClientError && props.clientError) {
    if (List.isList(props.clientError)) {
      errors = errors.concat(props.clientError.toArray());
    } else {
      errors.push(props.clientError);
    }
  }

  if (errors.length === 0) return null;
  let formattedError = formatErrors(fromJS(errors));
  const classes = [styles.module, props.className];
  if (props.darkBackground) {
    classes.push(styles.darkBackground);
  }
  if (props.globalFormError) {
    classes.push(styles.globalFormError);
    formattedError = (
      <React.Fragment>
        <Forbidden />
        { formattedError }
      </React.Fragment>
    )
  }
  return <div htmlFor={props.fieldId} className={classes.join(" ")}>{formattedError}</div>;
};

FieldError.defaultProps = {
  serverError: "",
  clientError: "",
  showClientError: false,
};

export default FieldError;
