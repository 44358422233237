export const SHOW_SUPPORT = "SUPPORT:SHOW_SUPPORT";
export const CLOSE_SUPPORT = "SUPPORT:CLOSE_SUPPORT";
export const OPEN_PAGE = "SUPPORT:OPEN_PAGE";

export function showSupport(page="toc") {
  return {
    type: SHOW_SUPPORT,
    page
  }
}

export function closeSupport() {
  return {
    type: CLOSE_SUPPORT
  }
}

export function openSupportPage(page) {
  return {
    type: OPEN_PAGE,
    page
  }
}