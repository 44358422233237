import React from "react";
import Icon from "./Icon";

// Icons/Core/close-circle-line
function CloseXCircle(props) {
  const svgPathDParameter = "M12,22 C6.477,22 2,17.523 2,12 C2,6.477 6.477,2 12,2 C17.523,2 22,6.477 22,12 C22,17.523 17.523,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M12,10.586 L14.828,7.757 L16.243,9.172 L13.414,12 L16.243,14.828 L14.828,16.243 L12,13.414 L9.172,16.243 L7.757,14.828 L10.586,12 L7.757,9.172 L9.172,7.757 L12,10.586 Z";
  return (<Icon {...props} svgPathDParameter={svgPathDParameter} />);
}

export default CloseXCircle;
