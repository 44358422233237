import React, {Component} from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Navigation from "../../lib/Navigation";
import {recordStartedCheckout} from "../../lib/Analytics";
import {generateCheckoutSummary} from "../../lib/StatsSummary";
import Button from "../../shared/components/Button";
import {cancelCheckoutCart} from "../../actions/ShopActions";
import SmallMessage from "../shared/SmallMessage";
import styles from "./CheckoutCartFormContainer.module.scss";
import {fromJS} from "immutable";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import SmallErrorMessage from "../shared/SmallErrorMessage";
import { useTranslation } from 'react-i18next';
import withCmsProductTitles from "../../hocs/withCmsProductTitles";

class CheckoutFormContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: "waiting"
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let isLoading = this.props.data.loading || this.props.cmsDataProductTitlesLoading;
    // We don't care about ProductTitle errors, we then just keep the current titles.
    let hasError = this.props.data.error;
    if (!isLoading && !hasError && this.state.status === "waiting") {
      this.setState({status: "check_status"});
    }

    if (prevState.status === "waiting" && this.state.status === "check_status") {
      this.checkCheckoutStatus();
    }

    if (prevState.status !== this.state.status && this.state.status === "ready") {
      this.handleCheckoutReady();
    }
  }

  checkCheckoutStatus() {
    if (this.props.data.customerCart.nrItems > 0) {
      this.setState({status: "ready"});
    } else {
      this.setState({status: "no_items"});
    }
  }

  recordStartedCheckoutStats() {
    let customerCart = fromJS(this.props.data.customerCart);
    // We need to update the productTitles with the longTitle from the cmsDataProductTitles.
    let productTitles = this.props.cmsDataProductTitles;
    let updatedItems = customerCart.get("items").map((product) => {
      return product.set("productTitle", productTitles.getIn([product.get("productSku"), "longTitle"], product.get("productTitle")));
    });

    let summary = generateCheckoutSummary(customerCart.set("items", updatedItems));
    recordStartedCheckout(summary.toJS());
  }

  handleCheckoutReady() {
    this.props.checkoutCart(this.props.accountId)
      .then((response) => {
        if (response.data.customerCheckoutCart.errors.length <= 0) {
          this.recordStartedCheckoutStats();
          Navigation.gotoCheckout(this.props.accountId, response.data.customerCheckoutCart.orderId, "shop");
          this.props.cancelCheckoutCart();
        } else {
          this.setState({status: "error"});
        }
      })
      .catch((err) => {
        this.setState({status: "error"});
      })
  }

  handleCancel() {
    this.props.cancelCheckoutCart();
  }

  render() {
    return (
      <div className={styles.module}>
        {this.renderView(this.state.status)}
      </div>
    )
  }

  renderView(status) {
    return <Message status={status} onCancel={this.handleCancel.bind(this)} />
  }
}

function Message(props) {
  const { status, onCancel } = props;
  const { t } = useTranslation("checkout");

  const commonMsgProps = {
    titleAsGuideHeader: true,
  }
  const cancelButton = (
    <Button
      label={t("notifications.continue_button")}
      primary={true}
      onClick={onCancel}
    />
  );

  switch(status) {
    case "ready":
      return (
        <SmallMessage {...commonMsgProps}
          title={t("notifications.initiating_title")}
          icon={<LoadingCircle />}
          message={t("notifications.reserving_stock")}
        />
      );
    case "no_items":
      return (
        <SmallErrorMessage {...commonMsgProps}
          title={t("notifications.failed_title")}
          message={t("notifications.cart_empty")}
          button={cancelButton}
        />
      );
    case "error":
      return (
        <SmallErrorMessage {...commonMsgProps}
          title={t("notifications.failed_title")}
          message={t("notifications.unknown_error")}
          button={cancelButton}
        />
      );
    default:
      return (
        <SmallMessage {...commonMsgProps}
          title={t("notifications.initiating_title")}
          icon={<LoadingCircle />}
          message={t("notifications.preparing_order")}
        />
      );
  }
}


const CART_INFO = gql`
  query customerCart($accountId: ID!) {
    customerCart(accountId: $accountId) {
      accountId
      shopId
      nrItems
      currency
      subtotal
      items {
        productSku
        productTitle
        quantity
        subscription
        subtotal
        unitPrice
      }
    }
  }
`;

const CHECKOUT_CART = gql`
  mutation customerCheckoutCart($accountId: ID!) {
    customerCheckoutCart(accountId: $accountId) {
      orderId
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CART_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId
      }
    }) 
  }),
  graphql(CHECKOUT_CART, {
    props: ({mutate}) => ({
      checkoutCart: (accountId) => mutate({
        variables: {
          accountId: accountId
        }
      })
    })
  })
)

export default connect(null, {cancelCheckoutCart})(withQueries(withCmsProductTitles(CheckoutFormContainer)));