function recordEvent(event, data) {
  window.dataLayer = window.dataLayer || [];
  var attrs = data || {};
  attrs["event"] = event;
  window.dataLayer.push(attrs);

  // Hack to fix googletagmanager three dots on safari
  window.setTimeout(() => {
    document.body.childNodes.forEach((e) => e.nodeType === Node.TEXT_NODE && document.body.removeChild(e));
  }, 1)
}

function recordEcommerceEvent(event, data) {
  let ecommerceData = {
    event: event,
    ecommerce: data || {}
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push(ecommerceData);

  // Hack to fix googletagmanager three dots on safari
  window.setTimeout(() => {
    document.body.childNodes.forEach((e) => e.nodeType === Node.TEXT_NODE && document.body.removeChild(e));
  }, 1)
}

export function recordRegistrationCompleted(countryCode, shopOrigin="shop") {
  recordEvent("registrationCompleted", {
    "countryCode": countryCode, // Keeping it for legacy.
    "country_code": countryCode,
    "shop_origin": shopOrigin
  })
}

export function recordPurchaseCompleted(summary) {
  recordEcommerceEvent("purchase", summary)  
}

export function recordStartedCheckout(summary) {
  recordEcommerceEvent("begin_checkout", summary);
}

export function recordViewCart(summary) {
  recordEcommerceEvent("view_cart", summary);
}

export function recordViewItem(summary) {
  recordEcommerceEvent("view_item", summary);
}

export function recordAddToCart(summary) {
  recordEcommerceEvent("add_to_cart", summary);
}