import React from "react";
import { RaceBy } from '@uiball/loaders';
import styles from "./LoadingLine.module.scss";

// In round mode, displays a rotating SVG
const LoadingLine = (props) => {
  if (!props.show) return null;

  const classes = [
    props.className,
    "Loading", // backward compatible with previous loader
    styles.module,
  ]

  return (
    <span className={classes.join(" ")} style={{"--background-color": props.backgroundColor}}>
      <RaceBy color={props.color} />
    </span>
  );
}

LoadingLine.defaultProps = {
  show: true,
  color: "var(--primary-blue-700)",
  backgroundColor: "var(--primary-blue-200)",
}

export default LoadingLine;