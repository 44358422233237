import React from "react";
import SelectedAccount from "../accounts/SelectedAccount";
import Navigation from "../../lib/Navigation";
import CartSummary from "./CartSummary";
import HeaderDesktop from "../../shared/components/HeaderDesktop";
import { BOUNDARIES } from "../../routes";
import styles from "../../shared/components/HeaderDesktop.module.scss";
import HeaderMenu from "./HeaderMenu";

export const buttonClasses = (boundary, currentBoundary) => {
  const classes = [];
  
  if (boundary === currentBoundary) {
    classes.push(styles.currentPage);
  }

  return classes.join(" ");
}

const ShopHeader = (props) => {
  if (!props.show) return null;

  const cartSummary = (
    <CartSummary accountId={props.accountId}
      onViewCart={() => Navigation.gotoCart(props.accountId)}
      // don't show "view cart" button but we can click on the icon for that
      showViewCart={false}
      tightDisplay={true}
      basketClassName={buttonClasses(BOUNDARIES.CART, props.boundary)}
    />
  );
  const selectedAccount = (
    <SelectedAccount accountId={props.accountId} isMobile={false} />
  );

  return (
    <HeaderDesktop
      alwaysSticky={true}
      logoUrlDark="/assets/LogoSimple-Black.svg"
      logoUrlLight="/assets/LogoSimple-White.svg"
      darkBackground={false}
      menu={<HeaderMenu accountId={props.accountId}
        dividerClass={styles.divider}
        useDesktopMode={true}
        buttonClassCallback={(boundary) => buttonClasses(boundary, props.boundary)} />
      }
      cart={cartSummary}
      account={selectedAccount}
      submenu={props.submenu}
      banner={props.banner} />
  );
}

ShopHeader.defaultProps = {
  show: true,
  submenu: null,
}

export default ShopHeader;
