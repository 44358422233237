import React from "react";
import {useLocation} from "react-router-dom";
import {queryToMap} from "../lib/QueryParser";

export default function useQueryMap() {
  const { search } = useLocation();

  return React.useMemo(function() {
    return queryToMap(search);
  }, [search]);
}