import React, {Component} from "react";
import {connect} from "react-redux";
import {requestAddCreditCard, requestChangeCreditCard} from "../../../actions/AccountActions";
import CreditCardPaymentMethod from "../CreditCardPaymentMethod";
import { cx } from "../../../shared/lib/ClassSet";
import CheckoutPanel from "./CheckoutPanel";
import styles from "./SelectPaymentMethod.module.scss";
import Button from "../../../shared/components/Button";
import { withTranslation, Trans } from 'react-i18next';

class SelectPaymentMethod extends Component {

  handleAddCard() {
    this.props.requestAddCreditCard(this.props.accountId);
  }

  handleChangeCard() {
    this.props.requestChangeCreditCard(this.props.accountId);
  }

  handleDeleteCard(cardId) {
    this.props.handleDeleteCard(cardId);
  }

  render() {
    let classes = cx({
      [styles.module]: true,
      "section": true,
      "section-disabled": !this.props.active
    })
    return (
      <div className={classes}>
        {this.renderCreditCardMethod()}
        {this.renderPaymentTermsMethod()}
      </div>
    )
  }

  renderCreditCardMethod() {
    if (this.props.paymentMethod !== "credit_card") return null;

    return (
      <CheckoutPanel
        title={this.props.t("payment.title")}
        nbTasks={3}
        completed={this.props.defaultPaymentMethod}
        help={this.props.t("payment.info")}
      >
        <div className={styles.creditCard}>
          <CreditCardPaymentMethod
            accountId={this.props.accountId}
            defaultPaymentMethod={this.props.defaultPaymentMethod}
            clientSecret={this.props.clientSecret}
            onAddCard={this.handleAddCard.bind(this)}
            onChangeCard={this.handleChangeCard.bind(this)}
            // onDeleteCard={this.handleDeleteCard.bind(this)}
          >
            <div className={styles.refreshPaymentMethods}>
              <Trans
                i18nKey="checkout:payment.not_showing_up"
                components={{
                  a: <Button
                    link className={styles.refreshLink}
                    onClick={() => this.props.onForceRefreshPaymentMethods()} />
                }}
              />
            </div>
          </CreditCardPaymentMethod>
        </div>
        
        <p className={styles.stripeElement}>
          <span className={styles.text}>{this.props.t("accounts:payment_method.subtitle")}</span>
          <span className={styles.icon} />
        </p>
      </CheckoutPanel>
    )
  }

  renderPaymentTermsMethod() {
    if (this.props.paymentMethod !== "payment_terms") return null;
    return (
      <CheckoutPanel
        title={this.props.t("payment_terms.title")}
        nbTasks={3}
        completed={true}
        help={this.props.t("payment_terms.info", { count: this.props.paymentTermsDays })}
      >
      </CheckoutPanel>
    )
  }
}

SelectPaymentMethod.defaultProps = {
  active: true
}

export default withTranslation("checkout")(connect(null, {
  requestChangeCreditCard, 
  requestAddCreditCard,
})(SelectPaymentMethod));