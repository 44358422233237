import React, {useState} from "react";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Trans, useTranslation } from 'react-i18next';
import styles from "./StripeElements.module.scss";
import { handleStripeConfirmSetup } from "../../lib/StripeHelper";


export default function NewPaymentElementForm(props) {
  const { t } = useTranslation("common");

  const {
    submitting,
    clientSecret,
    textClass = "u-centered",
    buttonsClass = "buttons",
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  
  const [stripeErrorMessage, setStripeErrorMessage] = useState(null);
  const [submittingToken, setSubmittingToken] = useState(false);
  const [stripeFocused, setStripeFocused] = useState(false);
  const [stripeErrored, setStripeErrored] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    handleStripeConfirmSetup(clientSecret, {stripe, elements}, {t, setStripeErrorMessage, addPaymentMethod: props.onSubmit, setSubmittingToken})
  }

  const active = !(submitting || submittingToken);
  const submitLabel = active ? t("credit_card.add") : t("standby");

  const stripeElementClasses = [
    "StripeElement",
    styles.stripeElement,
  ];
  if (stripeFocused) {
    stripeElementClasses.push(styles.stripeFocused);
  }
  if (stripeErrored) {
    stripeElementClasses.push(styles.stripeErrored);
  }

  return (
    <form className={styles.module} onSubmit={handleSubmit}>
      <div className="fields">
        <p className={textClass}>
          <Trans
            i18nKey="common:payment_element.authorize_text"
            components={{
              b: <span className="sg-font-bold"/>
            }}
            values={{
              shopName: props.shopName,
            }}
          />
        </p>
        <PaymentElement
          options={{
            readOnly: !active,
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: true,
            },
          }}      
          onFocus={() => setStripeFocused(true)}
          onBlur={() => setStripeFocused(false)}
          onChange={(event) => {
            const inError = !!event.error;
            if (stripeErrored !== inError) {
              setStripeErrored(inError);
            }}
          }
          className={stripeElementClasses.join(" ")}
        />
      </div>
      <div className={buttonsClass}>
        <Button label={submitLabel} primary={true} onClick={handleSubmit} active={active} />
        { props.onCancel && (
            <Button label={t("cancel")} active={active} onClick={props.onCancel}
              theme={Button.THEMES.BLUE_OUTLINE}
              addCancelToTopRight={true} />)
        }
      </div>

      <FormError error={stripeErrorMessage} />
    </form>
  );
}
