import React, {Component} from "react";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {connect} from "react-redux";
import {fromJS} from "immutable";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import {requestDelayDelivery} from "../../actions/AccountActions";
import {isCancelled, isCurrent, isNext, isProcessed, isToBeIgnored} from "../../lib/SubscriptionHelper";
import Subpanel from "../shared/Subpanel";
import {useTranslation, withTranslation } from "react-i18next";
import styles from "./SubscriptionDeliveries.module.scss";
import Divider from "../shared/Divider";
import DeliveryItem from "../shared/DeliveryItem";
import { longDateFormat } from "../../lib/DateFormatter";
import StatusTag from "../../shared/components/StatusTag";


class SubscriptionDeliveries extends Component {

  componentDidMount() {
    this.subscribe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subscriptionId !== this.props.subscriptionId || prevProps.accountId !== this.props.accountId) {
      this.subscribe();
    }
  }

  subscribe() {
    if (this.unsubscribeChanges) {
      this.unsubscribeChanges();
    }
    this.unsubscribeChanges = this.props.subscribeToChanges(this.props, {});
  }

  render() {
    let {
      data,
      importedNotice,
      onlyDeliveryDate = false,
    } = this.props;
    if (data.loading) return <LoadingCircle />
    if (data.error) return <GraphQLErrors error={data.error} />

    const deliveries = fromJS(data.customerSubscriptionDeliveries);
    const currentDeliveries = [];
    const nextDeliveries = [];
    
    deliveries.forEach((delivery) => {
      if (isToBeIgnored(delivery)) {
        return;
      };

      if (isCurrent(delivery)) {
        currentDeliveries.push(delivery);
        return;
      }
      
      nextDeliveries.push(delivery);
    });

    return (
      <div className={styles.module}>
        <Deliveries
          deliveries={fromJS(currentDeliveries)}
          title={this.props.t("subscription.current_orders")}
          className={styles.currentOrders}
          requestDelayDelivery={this.props.requestDelayDelivery}
          onViewDeliveries={this.props.onViewDeliveries}
          importedNotice={importedNotice}
          onlyDeliveryDate={onlyDeliveryDate}
        />

        <Deliveries
          deliveries={fromJS(nextDeliveries)}
          title={this.props.t("subscription.next_orders")}
          className={styles.nextOrders}
          requestDelayDelivery={this.props.requestDelayDelivery}
          onViewDeliveries={this.props.onViewDeliveries}
          onlyDeliveryDate={onlyDeliveryDate}
        />
      </div>
    );
  }
}


function Deliveries(props) {
  const {
    deliveries,
    title,
    className,
    importedNotice,
    onlyDeliveryDate,
    requestDelayDelivery,
    onViewDeliveries,
  } = props;
  const { t } = useTranslation("accounts", { keyPrefix: "subscription" });

  if (deliveries.length === 0) return;
  
  if (onlyDeliveryDate) {
    return deliveries.map((delivery) => (
      <div key={delivery.get("deliveryId")} className="deliveryDateInfo">
        <span>{longDateFormat(delivery.get("deliveryDate"))}</span>
        <StatusTag visible={isNext(delivery)} color={StatusTag.COLORS.GREY} text={t("status.pending")} />
        <StatusTag visible={isCancelled(delivery)} color={StatusTag.COLORS.RED} text={t("status.cancelled")} />
        <StatusTag visible={isProcessed(delivery)} color={StatusTag.COLORS.GREEN} text={t("status.in_progress")} />
      </div>
    ));
  } else {
    return (
      <>
        <Divider spaceAround={Divider.SPACES.SMALLER} />
        <Subpanel title={title}
          content={importedNotice} />
        <div className={className}>
          {deliveries.map((delivery) => (
            <DeliveryItem
              key={delivery.get("deliveryId")}
              delivery={delivery} 
              onDelay={(deliveryId) => requestDelayDelivery(deliveryId)} 
              onViewDeliveries={onViewDeliveries} />
          ))}
        </div>
      </>
    );
  }
}


const DELIVERIES = gql`
  query customerSubscriptionDeliveries($accountId:ID!, $subscriptionId:ID!) {
    customerSubscriptionDeliveries(accountId: $accountId, subscriptionId: $subscriptionId) {
      deliveryId
      seqId
      products {
        productSku
        productTitle
        quantity
      }
      requestedDeliveryDate
      deliveryDate
      scheduleStatus
      stockStatus
      requestStatus
      stockStatus
      status
    }
  }
`

const ON_UPDATED_SUBSCRIPTION_DELIVERIES = SubscriptionQueryWrapper("$subscriptionId: ID!", `
  updatedSubscriptionDeliveries(subscriptionId: $subscriptionId) {
    subscriptionId
  }`
);

const withQueries = compose(
  graphql(DELIVERIES, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId,
        subscriptionId: props.subscriptionId
      }
    })
  })
)

const mapDispatchToProps = (dispatch, ownProps) => ({
  subscribeToChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_SUBSCRIPTION_DELIVERIES,
      variables: {
        subscriptionId: props.subscriptionId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestDelayDelivery: (deliveryId) => {
    dispatch(requestDelayDelivery(ownProps.accountId, ownProps.subscriptionId, deliveryId));
  }
})

export default withTranslation("accounts")(connect(null, mapDispatchToProps)(withQueries(SubscriptionDeliveries)));