import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "./ProductQuantityForm";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelEditProductInCart, requestAddProductToCart} from "../../actions/ShopActions";
import {Map} from "immutable";
import {validateGreaterThan, validateLowerThanOrEqual} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import { maxNumberOfProductsForOneOrder } from "../../lib/ProductsInformation";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { withTranslation } from "react-i18next";


function validateForm(values, max) {
  // Quantity can be 0 (which will make the submit fct call the remove fct).
  let errors = Map();
  errors = validateGreaterThan(errors, values, "quantity", -1);
  errors = validateLowerThanOrEqual(errors, values, "quantity", max);
  return errors.toJS();
}

function EditProductFormContainer(props) {
  const {accountId, productSku, productTitle, data} = props;

  const [errors, setErrors] = useState([]);

  const handleRemove = () => {
    let values = {
      accountId: accountId,
      productSku: productSku
    }
    
    return props.customerRemoveProductFromCart(values)
      .then((response) => {
        if (response.data.customerRemoveProductFromCart.errors.length <= 0) {
          props.cancelEditProductInCart();
        } else {
          let errors = createValidationErrors(response.data.customerRemoveProductFromCart.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });
  }

  const handleCancel = () => {
    props.cancelEditProductInCart();
  }

  const handleSubmit = (values) => {
    if (values.get("quantity") === 0) {
      // User can select the None option and submit
      return handleRemove();
    }

    let updatedValues = values.set("accountId", accountId)
                              .set("productSku", productSku);

    return props.customerChangeProductQuantityInCart(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerChangeProductQuantityInCart.errors.length <= 0) {
          // No notification when editing the quantity
          props.cancelEditProductInCart();
        } else {
          let errors = createValidationErrors(response.data.customerChangeProductQuantityInCart.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
      });
  }

  useEffect(() => {
    if (!data.loading && data.customerCartItem === null) {
      // The user removed this item and clicked again to edit the quantities
      // before the client side had time to get the result (=> the add product
      // form should have been opened instead)
      props.cancelEditProductInCart();
      props.requestAddProductToCart(accountId, productSku, productTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.customerCartItem]);

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  if (data.customerCartItem === null) return null; // cf useEffect/dispatch

  const initialValues = {
    quantity: data.customerCartItem.quantity
  }
    
  const maxNumberOfProducts = maxNumberOfProductsForOneOrder(data.customerCartItem.productSku);

  return (
    <Form
      initialValues={initialValues}
      productTitle={data.customerCartItem.productTitle}
      errors={errors}
      validate={(values) => validateForm(values, maxNumberOfProducts)}
      maxNumberOfProducts={maxNumberOfProducts}
      showRemove={!props.noDelete}
      onSubmit={handleSubmit.bind(this)}
      onCancel={handleCancel.bind(this)}
      onRemove={handleRemove.bind(this)}
      />
  )
}


const CART_ITEM = gql`
  query customerCartItem($accountId: ID!, $productSku: ID!, $subscription: Boolean!) {
    customerCartItem(accountId: $accountId, productSku: $productSku, subscription: $subscription) {
      productSku
      productTitle
      quantity
    }
  }
`;

const EDIT_PRODUCT_IN_CART = gql`
  mutation customerChangeProductQuantityInCart($data: AddToCartInput!) {
    customerChangeProductQuantityInCart(input: $data) {
      errors { key message }
    }
  }
`;

const REMOVE_PRODUCT_FORM_CART = gql`
  mutation customerRemoveProductFromCart($data: AccountProductInput!) {
    customerRemoveProductFromCart(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(CART_ITEM, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId,
        productSku: props.productSku,
        subscription: false
      }
    })
  }),
  graphql(EDIT_PRODUCT_IN_CART, {
    props: ({ mutate }) => ({
      customerChangeProductQuantityInCart: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(REMOVE_PRODUCT_FORM_CART, {
    props: ({ mutate }) => ({
      customerRemoveProductFromCart: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

const mapDispatchToProps = dispatch => ({
  cancelEditProductInCart: () => {
    dispatch(cancelEditProductInCart());
  },
  requestAddProductToCart: (accountId, productSku, productTitle) => {
    dispatch(requestAddProductToCart(accountId, productSku, productTitle))
  }
});

export default withTranslation("cart")(connect(null, mapDispatchToProps)(withQueries(EditProductFormContainer)));

