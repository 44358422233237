import React, {Component} from "react";
import {fromJS} from "immutable";
import { cx } from "../../../shared/lib/ClassSet";
import Table from "../../../shared/Table";
import ChargeItem from "./ChargeItem";
import Button from "../../../shared/components/Button";
import { withTranslation } from "react-i18next";
import Panel from "../../shared/Panel";
import "./ChargesPanel.css";
import LoadingLine from "../../../shared/components/forms/LoadingLine";

class ChargesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedId: ""
    }
  }

  render() {
    if (!this.props.show) return null;
    let classes = cx({
      "ChargesPanel": true,
      "u-active": true,
      "panel": true
    })

    let tableHeader = fromJS([
      {id: "result", label: this.props.t("payment_history.table.status")},
      {id: "charge", label: this.props.t("payment_history.table.charge")},
      {id: "currency", label: this.props.t("payment_history.table.currency")},
      {id: "description", label: this.props.t("payment_history.table.invoice_number")},
      {id: "date", label: this.props.t("payment_history.table.invoice_date")},
    ]);

    let subpanels = (<div className={classes}>
      { this.props.charges.size <= 0
        ? this.renderEmpty()
        : this.renderTable(tableHeader) }

      <LoadingLine show={this.props.loading} />
      <Button className="moreButton" label={this.props.t("common:show_all")} primary={false}
        link={true} visible={this.props.moreAvailable} onClick={this.props.onShowAll} />
    </div>);

    return (
      <Panel title={this.props.t("payment_history.name")}
        subtitle={this.props.t("payment_history.subtitle")}
        subpanels={subpanels}
        subpanelsCount={this.props.charges.size}
        loading={this.props.loading} />
    )
  }

  renderEmpty() {
    if (this.props.loading) return null;
    if (this.props.charges.size > 0) return null;
    return (
      <p className="helptext">{this.props.t("payment_history.no_recorded_payment")}</p>
    )
  }

  renderTable(tableHeader) {
    if (this.props.charges.size <= 0) return null;
    return (
      <Table
        data={this.props.charges}
        renderer={ChargeItem}
        mode="table"
        extraClassName="ChargesPanel"
        tableHeader={tableHeader}
        itemKey="salesOrderId"
        selected={this.state.selectedId}
        onItemClick={this.handleSelect.bind(this)}        
        />
    )    
  }

  handleSelect(orderId) {
    if (this.state.selectedId === orderId) {
      this.setState({selectedId: ""});
    } else {
      this.setState({selectedId: orderId});
    }
  }

}


ChargesPanel.defaultProps = {
  show: true,
  moreAvailable: false,
  loading: false
}

export default withTranslation("accounts")(ChargesPanel);