export function cx(obj) {
  // Returns keys for which values are truthy.
  // Use when setting classes for example.
  var result = [];
  for (var key in obj) {
    if (obj[key]) result.push(key);
  }
  return result.join(" ");
}

export function addClassName(yes, classes = "", newClassName = "") {
  if (!yes) return classes;
  return `${classes} ${newClassName}`;
}