import React from "react";
import styles from "./JurisdictionFlag.module.scss";

const SIZES = {
  SMALLER: styles.smaller,
  SMALL: styles.small,
  BIG: styles.big,
}

const JurisdictionFlag = ({jurisdictionId, size = SIZES.SMALL}) => {
  const classes = [styles.module, size, jurisdictionId];

  return (
    <div className={classes.join(" ")} />
  );
}

JurisdictionFlag.SIZES = SIZES;

export default JurisdictionFlag;