import React, {Component} from "react";
import {reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import CreditCardWithSelection from "../shared/CreditCardWithSelection";
import CreditCardList from "../views/CreditCardList";
import { withTranslation } from 'react-i18next';

class ChangeCreditCardForm extends Component {

  constructor(props) {
    super(props);
    this.handleCreditCardListChange = this.handleCreditCardListChange.bind(this);
  }

  render() {
    let {errors, handleSubmit, submitting} = this.props;
    let submitLabel = submitting ? this.props.t("standby") : this.props.t("credit_card.select_and_continue");

    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <p className="u-centered">{this.props.t("credit_card.select_or_create")}</p>
          <CreditCardWithSelection 
            id="current" 
            selected={this.props.selectedId === "current"}
            card={this.props.selectedPaymentMethod}
            onSelect={this.handleSelect.bind(this)}
            />
          {this.renderOtherCards()}
          
          <Button label={this.props.t("credit_card.add_new_button")} active={!submitting} onClick={this.props.onAddCard}
            theme={Button.THEMES.GREY_OUTLINE} className="expand" />
        </div>
        {/* Hack to remove when we will refactor the command window */}
        <div className="mobileOnly" style={{marginTop: "15px"}} />
        <div className="buttons">
          <Button onClick={handleSubmit(values => this.props.onSubmit(values))}
            label={submitLabel} active={!submitting} />
          <Button label={this.props.t("cancel")} active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
          
        <FormError error={errors._error} />
      </form>
    )
  }

  renderOtherCards() {
    let otherCards = this.props.paymentMethods.filter(x => x.get("default") === false);
    if (otherCards.size > 0) {
      return (
        <React.Fragment>
          <CreditCardList
            cards={otherCards}
            selectedId={this.props.selectedId}
            onChange={this.handleCreditCardListChange}
            onDelete={(id) => this.handleCreditCardListDelete(id)}
            />
        </React.Fragment>
      ) 
    } else {
      return null;
    }
  }

  handleSelect(id) {
    this.props.onSelect(id);
  }

  handleDelete(id) {
    this.props.onDelete(id);
  }

  handleCreditCardListChange(id) {
    this.props.onSelect(id);
  }
  handleCreditCardListDelete(id) {
    this.props.onDelete(id);
  }

}

const ReduxForm = reduxForm({
  form: "ChangeCreditCardForm"
})(ChangeCreditCardForm);

export default withTranslation("common")(ReduxForm);