import {fromJS} from "immutable";

import * as ShopActions from "../actions/ShopActions";

const initialState = fromJS({
  guideSubscriptionQuantity: 0
})

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case ShopActions.UPDATE_GUIDE_WATER_SUBSCRIPTION_QUANTITY:
      return state.set("guideSubscriptionQuantity", action.quantity);

    default:
      return state;
  }
}

export default reducer;