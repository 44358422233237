import React, { useRef } from "react";
import CloseX from "../shop/shared/icons/CloseX";
import { Trans } from 'react-i18next';
import styles from "./CreditCard.module.scss";
import useResize from "../hooks/useResize";
import { WALLET_CARD_TYPES } from "../lib/Money";

const ENOUGH_WIDTH_FOR_ONE_LINER = 420;

// We use the cardBrand to show specific images, as long as a class is
// defined with that name in the CSS (.visa, ...).
const CreditCard = ({card, onDelete}) => {
  const moduleRef = useRef();
  const { width } = useResize(moduleRef)

  const brand = card.get("cardBrand");
  const cardClasses = [styles.card, styles[brand]];

  let bottomRightElem = <CreditCardTypeIcon type={card.get("type")} />

  const baseClasses = [
    styles.module,
    card.get("type"),
    // Put expiration info to the right if there is enough space (no tag!)
    (width > ENOUGH_WIDTH_FOR_ONE_LINER) ? styles.expand : null,
  ];

  return (
    <div className={baseClasses.join(" ")} ref={moduleRef}>
      <div className={styles.main}>
        <span className={cardClasses.join(" ")} />
        **** **** **** {card.get("cardLast4")}
      </div>
      <div className={styles.additionalInfo}>
        <span>
          <Trans
            i18nKey="common:credit_card.expires"
            components={{
              span: <span className={styles.cardExpiry} />
            }}
            values={{
              month: card.get("cardExpMonth"),
              year: card.get("cardExpYear"),
            }}
          />
        </span>
        { bottomRightElem }
      </div>
      { onDelete &&
        <div className={styles.delete}
          onClick={() => onDelete(card.get("paymentMethodId"))}>
          <CloseX colorDisabled="var(--grey-400)" />
        </div>
      }
    </div>
  );
}

export default CreditCard;

// Optional display of type icon (wallets)
export function CreditCardTypeIcon({type}) {
  if (WALLET_CARD_TYPES.includes(type)) {
    return <img src={`/assets/payment/${type}.png`} width={56} height={24} alt={type} />;
  }

  return null;
}
