import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import ApolloClient from "apollo-client";
import { PrismicLink } from 'apollo-link-prismic';
import {PRISMIC_GRAPHQL_ENDPOINT} from "../../config";
import prismicFragmentTypesData from "../../prismicFragmentTypes.json";
import { prismicLangFromLocale } from "../PrismicHelper";

const prismicLink = new PrismicLink({
  uri: PRISMIC_GRAPHQL_ENDPOINT,
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: prismicFragmentTypesData
});

const client = new ApolloClient({
  link: prismicLink,
  cache: new InMemoryCache({fragmentMatcher})
});

export function loadProducts(locale, retry) {
  return client.query({query: getAllProducts(locale)})
  .then(response => {
    return {response: response.data.allShop_per_jurisdictions}
  })
  .catch(error => {
    return {error: error.message}
  });
}

export function loadProductTitles(locale) {
  return client.query({query: getAllProductTitles(locale)})
  .then(response => {
    return {response: response.data.allProducts}
  })
  .catch(error => {
    return {error: error.message}
  });
}


const simpleCardFields = `
  _meta {
    uid
  }
  text
  text_color
  text_position
  text_max_width
  background_color
  footer
  background_image
  min_height
  min_width
`;

function getAllProductTitles(locale) {
  return gql`
  query all_product_titles {
    allProducts(lang:"${prismicLangFromLocale(locale)}") {
      edges {
        node {
          ... on Product {
            sku
            title
            long_title
          }   
        }
      }
    }
  }`;
}

function getAllProducts(locale) {
  return gql`
query all_products {
  allShop_per_jurisdictions(lang:"${prismicLangFromLocale(locale)}") {
    edges {
      node {
        shop_code
        jurisdiction
        og_url
        og_title
        og_image
        og_description
        notification_type
        notification_text
        products {
          product {
            ... on Product {
              _meta {
                uid
              }
              sku
              tags {
                tag
              }
              listing_image
              title
              long_title
              subtitle
              description
              long_description
              keywords
              note
              images {
                image
              }
              og_url
              og_title
              og_image
              og_description
              related_products {
                product {
                  ... on Product {
                    _meta {
                      uid
                    }
                    sku
                    tags {
                      tag
                    }
                    listing_image
                    title
                    long_title
                    subtitle
                    description
                    long_description
                    keywords
                    note
                    images {
                      image
                    }
                    og_url
                    og_title
                    og_image
                    og_description
                  }
                  ... on Simple_card {
                    ${simpleCardFields}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;
}


export function loadAccountPageCards(locale) {
  return client.query({query: getAccountPageCards(locale)})
  .then(response => {
    return {response: response.data.allAccount_pages}
  })
  .catch(error => {
    return {error: error.message}
  });
}

function getAccountPageCards(locale) {
  return gql`
query all_products {
  allAccount_pages(lang:"${prismicLangFromLocale(locale)}") {
    edges {
      node {
        no_subscriptions_banner {
          ... on Simple_card {
            ${simpleCardFields}
          }
        }
        essential_cards {
          card {
            ... on Simple_card {
              ${simpleCardFields}
            }
          }
          priority
        }
        optional_cards {
          card {
            ... on Simple_card {
              ${simpleCardFields}
            }
          }
          priority
        }
      }
    }
  }
}`;
}
