import * as prismic from '@prismicio/client';
import {PRISMIC_REPOSITORY} from "./config";

export const apiEndpoint = PRISMIC_REPOSITORY;
export const accessToken = '';

// Client method to query documents from the Prismic repo
export const Client = (req = null) => (
  prismic.createClient(apiEndpoint, createClientOptions(req, accessToken))
);

const createClientOptions = (req = null, prismicAccessToken = null) => {
  // const reqOption = req ? { req } : {}
  const accessTokenOption = prismicAccessToken ? { accessToken: prismicAccessToken } : {}
  return {
    // ...reqOption,
    ...accessTokenOption,
  }
}