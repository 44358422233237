import {fromJS} from "immutable";

import * as SupportActions from "../actions/SupportActions";

const initialState = fromJS({
  active: false,
  page: "toc"
})

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case SupportActions.SHOW_SUPPORT:
      return state.set("page", action.page)
                  .set("active", true);

    case SupportActions.CLOSE_SUPPORT:
      return state.set("page", "toc")
                  .set("active", false);

    case SupportActions.OPEN_PAGE:
      return state.set("page", action.page)
                  .set("active", true);
                  
    default:
      return state;
  }
}

export default reducer;