import {createSubmitRequestTypes} from "./helpers";

export const YES_NOTIFICATION = "SHOP:YES_NOTIFICATION";
export const NO_NOTIFICATION = "SHOP:NO_NOTIFICATION";
export const yesNotification = () => ({ type: YES_NOTIFICATION});
export const noNotification = () => ({ type: NO_NOTIFICATION});

export const REQUEST_ADD_SHIPPING_ADDRESS = "SHOP:REQUEST_ADD_SHIPPING_ADDRESS";
export const ADD_SHIPPING_ADDRESS = createSubmitRequestTypes("SHOP:ADD_SHIPPING_ADDRESS");
export const requestAddShippingAddress = (accountId) => ({ type: REQUEST_ADD_SHIPPING_ADDRESS, accountId });
export const cancelAddShippingAddress = () => ({ type: ADD_SHIPPING_ADDRESS.CANCEL });

export const REQUEST_EDIT_SHIPPING_ADDRESS = "SHOP:REQUEST_EDIT_SHIPPING_ADDRESS";
export const EDIT_SHIPPING_ADDRESS = createSubmitRequestTypes("SHOP:EDIT_SHIPPING_ADDRESS");
export const requestEditShippingAddress = (accountId, addressId, address) => {
  // Keep the address ID in mind for when we show the address selection again
  EDIT_SHIPPING_ADDRESS.ADDRESS_ID = addressId;
  return ({ type: REQUEST_EDIT_SHIPPING_ADDRESS, accountId, addressId, address });
}
export const cancelEditShippingAddress = () => ({ type: EDIT_SHIPPING_ADDRESS.CANCEL });

export const REQUEST_DELETE_SHIPPING_ADDRESS = "SHOP:REQUEST_DELETE_SHIPPING_ADDRESS";
export const DELETE_SHIPPING_ADDRESS = createSubmitRequestTypes("SHOP:DELETE_SHIPPING_ADDRESS");
export const requestDeleteShippingAddress = (accountId, addressId, address) => ({ type: REQUEST_DELETE_SHIPPING_ADDRESS, accountId, addressId, address });
export const cancelDeleteShippingAddress = () => ({ type: DELETE_SHIPPING_ADDRESS.CANCEL });

export const REQUEST_CONFIRM_SHIPPING_ADDRESS = "SHOP:REQUEST_CONFIRM_SHIPPING_ADDRESS";
export const CONFIRM_SHIPPING_ADDRESS = createSubmitRequestTypes("SHOP:CONFIRM_SHIPPING_ADDRESS");
export const requestConfirmShippingAddress = (accountId, walletType, address) => ({ type: REQUEST_CONFIRM_SHIPPING_ADDRESS, accountId, walletType, address });
export const cancelConfirmShippingAddress = () => ({ type: CONFIRM_SHIPPING_ADDRESS.CANCEL });
