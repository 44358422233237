import React, { Component } from "react";
import FieldError from "./FieldError";
import Calendar from "./Calendar";
import "./CalendarField.scss";
import { parse } from "date-fns";

class CalendarField extends Component {

  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      focused: false,
      currentMonth: new Date()
    }
  }


  render() {
    const {serverError, label, input: {value}, meta: {touched, error}} = this.props;

    const notBeforeDate = parse(this.props.notBeforeDate, 'yyyy-MM-dd', new Date());
    const notAfterDate = parse(this.props.notAfterDate, 'yyyy-MM-dd', new Date());

    if (this.props.mode === "table") {
      return (
        <tr className="CalendarField field">
          <th>{label}</th>
          <td>
            <Calendar 
              selectedDate={value}
              currentMonth={this.state.currentMonth}
              disableWeekends={this.props.disableWeekends}
              notBeforeDate={notBeforeDate}
              notAfterDate={notAfterDate}
              unavailableDates={this.props.unavailableDates}
              onSetCurrentMonth={this.handleSetCurrentMonth.bind(this)}
              onSelect={this.handleSelect.bind(this)}
              showEvents={this.props.showEvents}
              />

            <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
          </td>
        </tr>
      )      
    } 

    return (
      <div className="CalendarField field">
        <div className="label">{label}</div>
        <Calendar 
          selectedDate={value}
          currentMonth={this.state.currentMonth}
          disableWeekends={this.props.disableWeekends}
          notBeforeDate={notBeforeDate}
          notAfterDate={notAfterDate}
          unavailableDates={this.props.unavailableDates}
          onSetCurrentMonth={this.handleSetCurrentMonth.bind(this)}
          onSelect={this.handleSelect.bind(this)}
          showEvents={this.props.showEvents}
          />
        <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
      </div>
    )      
  }

  handleFocus(e) {
    e.target.select();
    this.setState({focused: true});
  }

  handleBlur(e) {
    this.setState({focused: false});
  }

  handleSelect(val) {
    this.props.input.onChange(val);
  }

  handleSetCurrentMonth(date) {
    this.setState({currentMonth: date});
  }  
}

CalendarField.defaultProps = {
  active: true,
  mode: "table"
}

export default CalendarField;