import React, {Fragment, useState} from "react";
import gql from "graphql-tag";
import {Query} from "react-apollo";
import { useSubscription } from "@apollo/react-hooks";
import NarrowChargesPanel from "./Charges/NarrowChargesPanel";
import ChargesPanel from "./Charges/ChargesPanel";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import { SubscriptionQueryWrapper } from "../../lib/SubscriptionQueryWrapper";

function takeMaxDeliveries(deliveries, max, showAll) {
  if (showAll) return deliveries;
  return deliveries.take(max);
}

function areMoreDeliveriesAvailable(deliveries, max, showAll) {
  if (showAll) return false;
  return deliveries.size > max;
}

const ChargesController = (props) => {
  const [listAll, setListAll] = useState(false);

  function showAll(p, refetch) {
    refetch({
      accountId: p.accountId,
      chargesFilter: {
        limit: 100
      }      
    });
    setListAll(true);
  }

  let charges = fromJS([]);
  let moreAvailable = false;

  let fetchVariables = {
    accountId: props.accountId,
    chargesFilter: {
      limit: listAll ? 100 : 7
    }          
  }

  // Subscribe to updates in billing issues, so the query below can refetch
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const refetchOnce = () => {
    setShouldRefetch(true);
    window.setTimeout(() => setShouldRefetch(false), 1);
  }
  useSubscription(
    ON_UPDATED_BILLING_ISSUES,
    {
      variables: { accountId: props.accountId },
      onSubscriptionData: refetchOnce,
    }
  );

  return (
    <Query query={QUERY} variables={fetchVariables} fetchPolicy="cache-and-network">
      {({loading, error, data, refetch}) => {
        if (shouldRefetch) {
          refetch(fetchVariables);
        }

        if (error) {
          return <GraphQLErrors error={error} />
        }

        if (data && data.customerCharges) {
          let allCharges = fromJS(data.customerCharges);
          charges = takeMaxDeliveries(allCharges, 6, listAll);
          moreAvailable = areMoreDeliveriesAvailable(allCharges, 6, listAll);
        }
        
        return (
          <Fragment>
            <NarrowChargesPanel
              show={props.narrowSize}
              accountId={props.accountId}
              charges={charges}
              moreAvailable={moreAvailable}
              onShowAll={showAll.bind(this, props, refetch)}
              loading={loading}
              />
            <ChargesPanel
              show={!props.narrowSize}
              accountId={props.accountId}
              charges={charges}
              moreAvailable={moreAvailable}
              onShowAll={showAll.bind(this, props, refetch)}
              loading={loading}
              />
          </Fragment>
        )
      }}
    </Query>
  )
}

const QUERY = gql`
  query customerCharges($accountId:ID!, $chargesFilter:CustomerChargesFilter) {
    customerCharges(accountId:$accountId, filter:$chargesFilter) {
      accountId
      salesOrderId
      invoiceNr
      invoiceAvailable
      invoiceDate
      kind
      paymentTermsDays
      amount
      currency
      status
      lastErrorCode
      lastDeclineCode
      lastErrorMessage
      willRetry
      products {
        productTitle
        quantity
      }
      dueAt
      updatedAt
    }
  }
`;

const ON_UPDATED_BILLING_ISSUES = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedBillingIssues(accountId: $accountId) {
    accountId
  }`
);

ChargesController.defaultProps = {
  narrowSize: false
}

export default ChargesController;