import React, {useState, useEffect} from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import GraphQLErrors from "../../shared/GraphQLErrors";
import JurisdictionFlag from "../../shared/JurisdictionFlag";
import {fromJS} from "immutable";
import Accounts from "../views/Accounts";
import {requestAddAccount} from "../../actions/AccountActions";
import Button from "../../shared/components/Button";
import styles from "./SelectedAccount.module.scss";
import Navigation from "../../lib/Navigation";
import Divider from "../shared/Divider";
import {showSupport} from "../../actions/SupportActions";
import { connect } from "react-redux";
import ArrowButton from "../shared/icons/Arrow";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { useTranslation } from "react-i18next";


const SelectedAccount = (props) => {
  const { t } = useTranslation("accounts");
  let { data, accountId, isMobile, closeMenu } = props;
  const [expanded, setExpanded] = useState(false);

  // Subscribe to account changes whenever the accountId is updated
  useEffect(() => {
    const unsubscribe = props.subscribeToAccountChanges(props, {});
    return function() {
      unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (data.loading) return <LoadingLine />
  if (data.error) return <GraphQLErrors error={data.error} />

  let accounts = fromJS(data.customerAccounts);
  // Find the name of the account.
  let account = accounts && accounts.find(x => x.get("accountId") === accountId);
  if (!account) {
    // Wrong or expired account id: gotoRoot will handle the rest
    Navigation.gotoRoot();
    return null;
  }

  // NB: on mobile the signout button is at the bottom of the main menu
  const signOutButton = (!isMobile &&
    <React.Fragment>
      <Divider />
      <div className={styles.signOut}>
        <Button label={t("links:sign_out")} theme={Button.THEMES.BLUE_OUTLINE}
          onClick={() => Navigation.signOut()} />
      </div>
    </React.Fragment>
  );

  const onSelectAccount = (accountId) => {
    handleSelectAccount(data.customerAccounts, accountId, props.updateLastAccount);
    setExpanded(false);
    if (closeMenu) {
      closeMenu();
    }
  }

  const requestAddAccount = () => {
    props.requestAddAccount();
    // Close the menu while the user is creating his account
    setExpanded(false);
    if (closeMenu) {
      closeMenu();
    }
  }

  const menuClasses = [styles.menu];
  const menu = (
    <div className={menuClasses.join(" ")}>
      <div className={styles.accounts}>
        <Accounts
          context={Accounts.CONTEXTS.MENU}
          accounts={accounts}
          onSelectAccount={onSelectAccount} />
      </div>

      <div className={styles.buttons}>
        <div className={styles.newAccount}>
          <Button label={t("create_new_account")} onClick={requestAddAccount} />
          <Button label={t("why_create_multiple_accounts")} onClick={() => props.showSupport("creating-multiple-accounts")}
            className={styles.whyMultiple} link={true} />
        </div>
        { signOutButton }
      </div>
    </div>
  );

  const outsideClicksShallCloseTheMenu = expanded && <div className={styles.outside} />

  const baseClasses = [styles.module, isMobile ? styles.mobile : styles.desktop];

  return (
    <div className={baseClasses.join(" ")}>
      <div className={styles.main} onClick={() => setExpanded(!expanded)}>
        <div className={styles.flagWrapper}>
          <JurisdictionFlag jurisdictionId={account.get("jurisdictionId")} />
        </div>
        <div className={styles.title}>{account.get("name")}</div>
        <ArrowButton className={styles.arrow} onClick={() => {}}
          arrowStyle={ArrowButton.STYLES.DROP}
          direction={expanded ? ArrowButton.DIRECTIONS.UP : ArrowButton.DIRECTIONS.DOWN} />
        {outsideClicksShallCloseTheMenu}
      </div>
      {expanded && menu}
    </div>
  );
}

function isNewRegistration(query) {
  return (query && query.new && query.new === "1");
}
function isGoogleNewFlow(query) {
  return (query && query.flow && query.flow === "new");
}

function gotoAccount(account) {
  if (!account.accountId) {
    return; // We shouldn't redirect to a buggy address
  }

  let {query} = window.location;
  if (isNewRegistration(query) || isGoogleNewFlow(query)) {
    Navigation.gotoShop(account.accountId);
  } else {
    Navigation.gotoAccount(account.accountId);  
  }
}

function handleSelectAccount(customerAccounts, accountId, updateLastAccount) {
  // TODO: If we are in google flow we need to go to either setup new subscription, or to Account.
  // Find out which
  const accounts = fromJS(customerAccounts);
  const account = accounts.find((x) => x.get("accountId") === accountId).toJS();

  // Remember the client selection so we can log him in again later
  updateLastAccount(accountId);

  gotoAccount(account);
}

SelectedAccount.defaultProps = {
  accountId: "",
  isMobile: false,
}

// cartInitialized is used to know if an account is setup (and selectable)
const GET_ACCOUNTS = gql`
  query customerAccounts {
    customerAccounts {
      accountId
      name
      jurisdictionId
      cartInitialized
      allowedWaterOrders
      notifications
      subsActive
      subsPaused
      subsCancelled
    }
  }
`;

const ON_UPDATED_ACCOUNTS = SubscriptionQueryWrapper("", `
  customerUpdatedAccounts {
    customerId
  }
`);

const UPDATE_LAST_ACCOUNT = gql`
  mutation customerSelectAccount($accountId: ID!) {
    customerSelectAccount(accountId: $accountId) {
      errors { key message }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  subscribeToAccountChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ACCOUNTS,
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestAddAccount: () => dispatch(requestAddAccount()),
  showSupport: (topic) => dispatch(showSupport(topic)),
})

const withQuery = compose(
  graphql(GET_ACCOUNTS),
  graphql(UPDATE_LAST_ACCOUNT, {
    props: ({ mutate }) => ({
      updateLastAccount: (accountId) => mutate({
        variables: { accountId: accountId }
      })
    })
  }),
);

export default connect(null, mapDispatchToProps)(withQuery(SelectedAccount));
