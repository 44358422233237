import { useEffect } from "react";
import { useStripe } from '@stripe/react-stripe-js';


export default function AuthenticateStripePayment(props) {
  const {
    clientSecret,
    onError,
    onSuccess,
  } = props;

  const stripe = useStripe();

  useEffect(() => {
    function confirmPayment(clientSecret, intent) {
      // Pass the failed PaymentIntent to your client from your server
      stripe.confirmCardPayment(clientSecret, {
        payment_method: intent.last_payment_error.payment_method.id
      }).then(function(result) {
        if (result.error) {
          // Show error to your customer
          onError(result.error.message);
        } else if (result.paymentIntent.status === 'succeeded') {
          // The payment is complete!
          onSuccess();
        } else {
          onError("unknown error"); // TODO MESSAGE
        }
      });    
    }

    if (clientSecret) {
      stripe.retrievePaymentIntent(clientSecret)
        .then(function(result) {
          if (result.error) {
            onError("Payment information for this order could not be found or not retrieved at this moment in time.") // TODO MESSAGE
          } else {
            confirmPayment(clientSecret, result.paymentIntent);
          }
        });
    }
  }, [clientSecret]);

  return null;
}
