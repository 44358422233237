import React from "react";
import { useTranslation } from "react-i18next";
import StatusTag from "../../../shared/components/StatusTag";


export function DeliveryTag(props) {
  const { t } = useTranslation("accounts", { keyPrefix: "deliveries.tags" });
  const { data, className } = props;
  if (!data) return null;

  const commonProps = {
    className: className,
    alt: true,
    text: t(data.get("type")),
  }

  if (data.get("gift")) {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.BLUE_ACCENT} text={t("gift")} />;
  } else if (data.get("type") === "complimentary") {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.BEIGE} />;
  } else if (data.get("type") === "replacement") {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.GREY} />;
  } else if (data.get("type") === "subscription") {
    return <StatusTag {...commonProps} color={StatusTag.COLORS.GOLD} />;
  }
  
  return null;
}
