import { Client } from "../prismic-configuration";

const DEFAULT_PAGE_SIZE = 100;

export async function prismicGetByType(context, typename, options = {}) {
  const req = context.req;
  
  const upgradedOptions = {
    lang: prismicLangFromLocale(context.locale),
    pageSize: DEFAULT_PAGE_SIZE,
    ...options,
  }

  return Client(req).getByType(typename, upgradedOptions);
}

export async function prismicGetByID(context, id, options = {}) {
  const req = context.req;
  
  const upgradedOptions = {
    lang: "*", // The ID is different between each locale version of a prismic document
    pageSize: DEFAULT_PAGE_SIZE,
    ...options,
  }

  return Client(req).getByID(id, upgradedOptions);
}

export async function prismicGetByIDs(context, ids, options = {}) {
  const req = context.req;
  
  const upgradedOptions = {
    lang: "*", // The IDs are different between each locale version of a prismic document
    pageSize: DEFAULT_PAGE_SIZE,
    ...options,
  }

  return Client(req).getByIDs(ids, upgradedOptions);
}

export async function prismicGetByUID(context, typename, slug, options = {}) {
  const req = context.req;
  
  const upgradedOptions = {
    lang: prismicLangFromLocale(context.locale),
    pageSize: DEFAULT_PAGE_SIZE,
    ...options,
  }

  return Client(req).getByUID(typename, slug, upgradedOptions);
}

export async function prismicGetAllByType(context, typename, options = {}) {
  const req = context.req;
  
  const upgradedOptions = {
    lang: prismicLangFromLocale(context.locale),
    pageSize: DEFAULT_PAGE_SIZE,
    ...options,
  }

  return Client(req).getAllByType(typename, upgradedOptions);
}

export async function prismicGet(context, options = {}) {
  const req = context.req;

  const upgradedOptions = {
    lang: prismicLangFromLocale(context.locale),
    pageSize: DEFAULT_PAGE_SIZE,
    ...options,
  }

  return Client(req).get(upgradedOptions);
}


// Prismic have full locale defined (de-at, en-us, es-es and fr-fr)
// but for the website we only use the language part.
export function prismicLangFromLocale(locale) {
  let lang = null;

  switch (locale) {
    case "de":
      lang="de-at";
      break;
    case "en":
      lang="en-us";
      break;
    case "es":
      lang="es-es";
      break;
    case "fr":
      lang="fr-fr";
      break;
      
    default:
      lang="en-us";
      break;
  }

  return lang;
}

export function prismicTextIsEmpty(maybeRichText) {
  if (!maybeRichText) {
    return true;
  }

  const richText = maybeRichText.toJS ? maybeRichText.toJS() : maybeRichText;

  return !richText || richText.every((e) => e.text === "");
}