import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/TaxInfoForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelSetTaxInfo} from "../../actions/AccountActions";
import {fromJS} from "immutable";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {isEurope} from "../../lib/ShopTools";
import LoadingLine from "../../shared/components/forms/LoadingLine";

class SetTaxInfoFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = {
      accountId: this.props.accountId,
      taxId: values.get("taxId", ""),
      validateTaxId: values.get("validateTaxId")
    }

    return this.props.customerAssignTaxId(updatedValues)
      .then((response) => {
        if (response.data.customerAssignTaxId.errors.length <= 0) {
          this.props.cancelSetTaxInfo();
        } else {
          let errors = createValidationErrors(response.data.customerAssignTaxId.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelSetTaxInfo();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let account = fromJS(data.customerBillingAccount)
    let initialValues = {
      taxId: account.get("taxId", ""),
      validateTaxId: account.get("validTaxId")
    }
    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        showValidateTaxId={isEurope(account.get("shopId"))}
        />
    )
  }
}


const GET_BILLING_INFO = gql`
  query customerBillingAccount($accountId: ID!) {
    customerBillingAccount(accountId: $accountId) {
      shopId
      taxId
      validTaxId
    }
  }
`;

const ASSIGN_TAX_ID = gql`
  mutation customerAssignTaxId($data: BillingTaxInput!) {
    customerAssignTaxId(input: $data) {
      errors { key message }
    }
  }
`;


const withQueries = compose(
  graphql(GET_BILLING_INFO, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(ASSIGN_TAX_ID, {
    props: ({ mutate }) => ({
      customerAssignTaxId: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelSetTaxInfo})(withQueries(SetTaxInfoFormContainer));

