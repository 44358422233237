import React from "react";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import styles from "./Panel.module.scss";

// Panels are used in the account page to display various things, while always
// following the same structure:
// - a column on the left with a section title and below, a subtitle;
// - a second column with the data to display (could be a simple string, or a
// complex component).
function Panel(props) {
  const {
    title, subtitle,
    anchor,
    subpanels,
    loading,
    fullWidthSubpanels,
    subpanelsCount, // A special style is applied when there is only one element
    subtitleClasses // allows custom styling for subtitle
  } = props;

  const baseClasses = [styles.module];

  let subtitleElem = subtitle
    ? <div className={styles.subtitle + " " + subtitleClasses}>{subtitle}</div>
    : <div className="u-bottom-margin"></div>;

  // When there is only one element in the subpanel, we adjust the style so
  // it's aligned well with the substitle.
  const subpanelClasses = [styles.subpanels];
  if (subpanelsCount <= 1) {
    subpanelClasses.push(styles.unique);
  }
  if (fullWidthSubpanels) {
    baseClasses.push(styles.fullWidthSubpanels)
  }
  let subpanelsElem = null;
  if (subpanels) {
    subpanelsElem = (
      <div className={subpanelClasses.join(" ")}>
        {loading ? <LoadingCircle/> : subpanels}
      </div>);
  }

  return (
    <div className={baseClasses.join(" ")} id={anchor}>
      <div className={styles.labels}>
        <div className={styles.title}>{title}</div>
        {subtitleElem}
      </div>

      {subpanelsElem}
    </div>
  )
}

export default Panel;
