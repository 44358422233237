import countryData from 'country-telephone-data';

export default function normalize(str) {
  if (str === null || str === undefined || str === "") return "";
  let numbers = extractNumbers(str);

  // Index data.
  let indexedDialCodes = indexDialCodes();
  // find the longest possible dial code (max 4)
  let start = numbers.slice(0, 4);
  let len = findCountryCodeLength(indexedDialCodes, start);
  if (len === 0) return "";

  return `+${numbers.slice(0, len)} ${numbers.slice(len).replaceAll(" ", "")}`;
}

export function getCountryCallingCodeFromISO2(iso2Code) {
  const dialCode = iso2ToDialCode().get(iso2Code.toLowerCase()) || null;
  return dialCode;
}

export function getCompletePhoneNrOrNothing(str) {
  // We don't want just country codes.
  // Use this function when setting default country codes but don't
  // want to submit incomplete value.
  const normalizedNumber = normalize(str);

  // Normalized number will look like "+xxx yyy" (note the space).
  if (normalizedNumber.match(/^\+[0-9]+$/)) return "";
  return normalizedNumber;
}

export function autoFormat(str) {
  if (str === null || str === "") return "";
  let numbers = extractNumbers(str);
  // Index data.
  let indexedDialCodes = indexDialCodes();
  let start = numbers.slice(0, 4);
  let result = findBestMatch(indexedDialCodes, start);
  if (result == null) return str;

  return format(numbers, result.format);
}

export function format(nr, countryFormat) {
  return formatChar("", countryFormat, nr);
}

function formatChar(acc, formatChars, numbers) {
  if (numbers.length === 0) return acc;
  if (formatChars.length === 0) return acc.concat(numbers);
  if (formatChars[0] === ".") {
    return formatChar(acc.concat(numbers[0]), formatChars.slice(1), numbers.slice(1));
  }
  
  return formatChar(
    acc.concat(formatChars[0]),
    formatChars.slice(1),
    numbers
  ) 
}

// Precomputes the set of country dialCode => { name, iso2, dialCode, format, priority }
var indexDialCodes = (function() {
  var result = new Map();
  for (var country of countryData.allCountries) {
    result.set(country.dialCode, country);
  }

  return () => result;
})()
// Precomputes the set of country iso2 code => dialCode
var iso2ToDialCode = (function() {
  var result = new Map();
  for (var country of countryData.allCountries) {
    result.set(country.iso2, country.dialCode);
  }

  return () => result;
})()

function findBestMatch(codes, nr) {
  if (codes.has(nr) === true) return codes.get(nr);
  if (nr.length > 1) return findBestMatch(codes, nr.slice(0,-1));
  return null;
}

function findCountryCodeLength(codes, nr) {
  if (codes.has(nr) === true) return nr.length;
  if (nr.length > 1) return findCountryCodeLength(codes, nr.slice(0, -1));
  return 0;
}

function extractNumbers(str) {
  var result = [];
  for(var i=0; i<str.length; i++) {
    if (str[i].match(/[0-9]/)) {
      result.push(str[i]);
    }
  }
  return result.join("");
}