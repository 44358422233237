import React from "react";
import CloseXCircle from "./icons/CloseXCircle";
import SmallMessage from "./SmallMessage";

const SmallErrorMessage = (props) => {
  const {
    iconColor = "var(--accent-red-600)",
  } = props;

  return (
    <SmallMessage title="There was an error"
      icon={<CloseXCircle side="80px" colorDisabled={iconColor} />}
      {...props}
    />
  );
}

export default SmallErrorMessage;