import {Map, fromJS} from "immutable";
import {
  SHOW_NOTIFICATION, 
  SHOW_NOTIFICATION_WITH_PHOTO,
  HIDE_NOTIFICATION,
  } from "../actions/NotificationActions";

const initialState = Map({
  ids: Map(),
  elements: Map(),
  // ids: new Map({"ab43": "ab43"}),
  // elements: fromJS({
  //   "ab43": {
  //     id: "ab43",
  //     message: "Order total was updated",
  //     undo: false
  //   }
  // })
})

const reducer = function(state = initialState, action) {

  switch(action.type) {
    case SHOW_NOTIFICATION:
      return state.setIn(["ids", action.id], action.id)
                  .setIn(["elements", action.id], fromJS({
                    photo: false,
                    id: action.id,
                    message: action.message,
                    kind: action.kind
                  }));

    case SHOW_NOTIFICATION_WITH_PHOTO:
      return state.setIn(["ids", action.id], action.id)
                  .setIn(["elements", action.id], fromJS({
                    photo: true,
                    id: action.id,
                    photoUrl: action.photoUrl,
                    message: action.message,
                    undo: action.undo,
                    kind: action.kind
                  }));

    case HIDE_NOTIFICATION:
      return state.deleteIn(["ids", action.id])
                  .deleteIn(["elements", action.id]);
                  
    default:
      return state;
  }
}

export function getNotifications(state) {
  return state.getIn(["notifications","ids"]).map((id) => {
    return state.getIn(["notifications", "elements", id]);
  })
}

export default reducer;