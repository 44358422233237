import React, {Component} from "react";
import {reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import BackgroundImage from "../../shared/BackgroundImage";
import "./SuccessForm.css";

class SuccessForm extends Component {

  render() {
    let {label, title, message} = this.props;

    return (
      <form className="SuccessForm">
        <div className="fields">
          <div className="media">
            <BackgroundImage className="featureIcon" src="/assets/icon-signup-success.gif" width="140px" height="140px" />
          </div>
          <h5 className="title">{title}</h5>
          <p>{message}</p>
        </div>

        <div className="buttons">
          <Button label={label} active={true} onClick={this.props.onClose}
            addCancelToTopRight={true} />
        </div>
      </form>
    )
  }
}

SuccessForm.defaultProps = {
  label: "OK",
  title: "Success"
}

const ReduxForm = reduxForm({
  form: "SuccessForm"
})(SuccessForm);

export default ReduxForm;