import React, {Component} from "react";
import {connect} from "react-redux";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import {submit, isValid, isDirty, isSubmitting} from "redux-form/immutable";
import gql from "graphql-tag";
import GraphQLErrors from "../shared/GraphQLErrors";
import Button from "../shared/components/Button";
import WarningMessage from "../shared/WarningMessage";
import ShopHeader from "./shared/ShopHeader";
import ShopHeaderMobile from "./shared/HeaderMobile";
import {getMediaQuerySize} from "../reducers/SystemReducer";
import MultiSubscriptionForm from "./forms/MultiSubscriptionForm";
import {validateGreaterThan} from "../lib/Validators";
import {Map, fromJS} from "immutable";
import {requestQuantityAssistantForForm} from "../actions/ShopActions";
import {NOTIFICATION_KINDS, showNotification} from "../actions/NotificationActions";
import {createValidationErrors, createErrors} from "../lib/ErrorFormatter";
import MonitorEditSub from "./MonitorEditSub";
import Navigation from "../lib/Navigation";
import withCms from "../hocs/withCms";
import { getProductForJurisdiction } from "../lib/ProductsInformation";
import LoadingLine from "../shared/components/forms/LoadingLine";
import { withTranslation } from "react-i18next";
import "./SubPage.scss";


function validateForm(values, t) {
  let errors = Map();
  
  // Validate only when there are products as the CMS product data might
  // not be loaded right away
  if (values.get("products")) {
    errors = validateGreaterThan(errors, values, "frequencyWeeks", 0);
    // At least 1 product needs to have quantity above 0.
    // Products with 0 will be removed before sending.
    if (values.get("products").every((value, key) => value.get("quantity") === 0)) {
      errors = errors.set("products", Map({_error: t("subscription.edit.no_quantity") }));
    }
  }

  return errors.toJS();
}

class EditSubPage extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      submitAction: "add",
      submitting: false,
      errors: []
    }
  }

  handleRemoteSubmit() {
    this.props.submit("MultiSubscriptionForm");    
  }  

  handleSubmit(values) {
    let updatedProducts = 
      values.get("products")
      // Remove any item that has 0 quantity.
      .filter(x => x.get("quantity") > 0)
      // Just add sku and quantity.
      .map(x => {
        return fromJS({
          productSku: x.get("productSku"),
          quantity: x.get("quantity")
        })
      })
      
    let updatedValues = Map({
      accountId: this.props.accountId,
      subscriptionId: this.props.subscriptionId,
      frequencyWeeks: values.get("frequencyWeeks"),
      products: updatedProducts
    })

    return this.props.customerChangeSubscription(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerChangeSubscription.errors.length <= 0) {
          this.handleSuccess();
        } else {
          let errors = createValidationErrors(response.data.customerChangeSubscription.errors);
          this.props.showFailedNotification(this.props.t("subscription.edit.notifications.error"));
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
        window.alert(this.props.t("subscription.edit.notifications.generic_error"));
      });      
  }

  handleCancel() {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
    else {
      Navigation.gotoAccount(this.props.accountId);
    }
  }

  handleSuccess() {
    this.props.showSuccessNotification(this.props.t("subscription.edit.notifications.modified"));

    if (typeof this.props.onSuccess === "function") {
      this.props.onSuccess();
    }
    else {
      Navigation.gotoAccount(this.props.accountId);
    }
  }

  handleSuccessFrequency() {
    this.props.showSuccessNotification(this.props.t("subscription.edit.notifications.updating_success"));

    if (typeof this.props.onSuccess === "function") {
      this.props.onSuccess();
    }
    else {
      Navigation.gotoAccount(this.props.accountId);
    }
  }

  handleFailed() {
    this.props.showFailedNotification(this.props.t("subscription.edit.notifications.updating_error"));
  }

  render() {
    let {loading, error, customerSubscriptionProducts, subscriptionFrequencyOptions, customerSubscriptionInfo} = this.props.data;

    const showMobileHeader = this.props.isNarrowSize;
    const shopHeader = (
      <React.Fragment>
        <ShopHeader show={!showMobileHeader} accountId={this.props.accountId} />
        <ShopHeaderMobile show={showMobileHeader} accountId={this.props.accountId} />
      </React.Fragment>
    );

    if (loading) {
      if (this.props.formOnly) {
        return (<LoadingLine />);
      }

      return (
        <div className="SubPage">
          <div className="inner">
            <LoadingLine />
          </div>

          { shopHeader }
        </div>
      )
    }
    if (error) return <GraphQLErrors error={error} />    
    let availableProducts = fromJS(customerSubscriptionProducts);
    let frequencyOptions = fromJS(subscriptionFrequencyOptions);
    let info = fromJS(customerSubscriptionInfo);

    if (this.props.formOnly) {
      return (
        <React.Fragment>
          {this.renderProducts(availableProducts, frequencyOptions, info, true)}

          <div className="buttons">
            <Button label={this.props.t("subscription.edit.confirm")}
              active={this.props.valid && this.props.dirty && !this.props.submitting}
              theme={Button.THEMES.BLUE}
              onClick={this.handleRemoteSubmit.bind(this)} />
            <Button label={this.props.t("subscription.edit.cancel")} active={!this.props.submitting}
              theme={Button.THEMES.BLUE_OUTLINE}
              addCancelToTopRight={true}
              onClick={this.handleCancel.bind(this)} />
          </div>
        </React.Fragment>
      );
    }
    
    return (
      <div className="SubPage">
        <div className="inner">
          <h1>{this.props.t("subscription.edit.title")}</h1>
          <p className="lead">{this.props.t("subscription.edit.subtitle", { name: info.get("name") })}</p>
          <WarningMessage showIcon={false}
            title={this.props.t("subscription.edit.please_note")}
            message={this.props.t("subscription.edit.please_note_msg")}
          />
          {this.renderProducts(availableProducts, frequencyOptions, info)}
          <MonitorEditSub 
            subscriptionId={this.props.subscriptionId} 
            onSuccess={this.handleSuccess.bind(this)}
            onFailed={this.handleFailed.bind(this)}
            onFrequencyChanged={this.handleSuccessFrequency.bind(this)}
            />
        </div>

        <div className="SubscriptionFooter footer">
          <Button label={this.props.t("subscription.edit.confirm")}
            active={this.props.valid && this.props.dirty && !this.props.submitting}
            primary={true} onClick={this.handleRemoteSubmit.bind(this)} />
          <Button label={this.props.t("subscription.edit.cancel")} active={!this.props.submitting} link={true}
            onClick={this.handleCancel.bind(this)} />

          <LoadingLine show={this.props.submitting} />
        </div>

        { shopHeader }
      </div>
    ) 
  }

  renderProducts(availableProducts, frequencyOptions, info, basicForm) {
    if (!this.props.cmsLoaded) {
      return (<LoadingLine />);
    }

    let mappedQuantities = 
      info.get("products").reduce((acc, x) => {
                              return acc.set(x.get("productSku"), x.get("quantity"));
                            }, Map());

    let products = availableProducts.map(x => {
      // Get more details for fixtures.
      const item = getProductForJurisdiction(this.props.cmsData, info.get("shopId"), x.get("productSku"));
      if (item) {
        return x.set("quantity", mappedQuantities.get(x.get("productSku"), 0))
                .set("description", item.get("description", ""))
                .set("note", item.get("note", ""));
      } else {
        return x.set("quantity", mappedQuantities.get(x.get("productSku"), 0))
                .set("description", x.get("description", ""))
                .set("note", x.get("note", ""));
      }
    })

    let initialValues = Map({
      frequencyWeeks: info.get("frequencyWeeks"),
      products: products
    });

    return (
      <MultiSubscriptionForm
        showFrequency={true}
        initialValues={initialValues}
        surcharges={info.get("shippingAddressSurcharges")}
        disclaimer={this.props.t("subscription.edit.disclaimer")}
        frequency={frequencyOptions}
        errors={this.state.errors}
        validate={(val) => validateForm(val, this.props.t)}
        showSubmitButtons={false}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        onQuantityAssistant={this.handleQuantityAssistant.bind(this)}
        basicForm={basicForm}
        />
    )
  }

  handleQuantityAssistant(index) {
    this.props.requestQuantityAssistantForForm("MultiSubscriptionForm", `products[${index}].quantity`, "frequencyWeeks")
  }

}

const mapStateToProps = (state) => {
  return {
    isMediumSize: getMediaQuerySize(state.get("system")) === "mq-md",
    isNarrowSize: getMediaQuerySize(state.get("system")) === "mq-sm",
    dirty: isDirty("MultiSubscriptionForm")(state),
    valid: isValid("MultiSubscriptionForm")(state),
    submitting: isSubmitting("MultiSubscriptionForm")(state)    
  }
}

// Get shop subscription products

const SUBSCRIPTION_PRODUCTS = gql`
  query customerSubscriptionProducts($accountId:ID!, $subscriptionId:ID!) {
    customerSubscriptionProducts(accountId:$accountId) {
      shopId
      productSku
      productTitle
      pricing {
        value
        currency
      }
    }
    subscriptionFrequencyOptions {
      value
      title
    }
    customerSubscriptionInfo(accountId:$accountId, subscriptionId:$subscriptionId) {
      shopId
      subscriptionId
      frequencyWeeks
      name
      products {
        productSku
        quantity
      }
      shippingAddress {
        addressCountryCode
      }
      shippingAddressSurcharges {
        productSku
        surcharge {
          currency
          value
        }
      }      
    }    
  }
`;

const CHANGE_SUBSCRIPTION = gql`
  mutation customerChangeSubscription($data: CustomerChangeSubscriptionInput!) {
    customerChangeSubscription(input: $data) {
      errors { key message}
    }
  }
`

const withQuery = compose(
  graphql(SUBSCRIPTION_PRODUCTS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId,
        subscriptionId: props.subscriptionId
      }
    })
  }),
  graphql(CHANGE_SUBSCRIPTION, {
    props: ({mutate}) => ({
      customerChangeSubscription: (data) => mutate({
        variables: {data: data}
      })
    })
  })
);

const mapDispatchToProps = dispatch => ({
  requestQuantityAssistantForForm: (name, quantityField, frequencyWeeksField) => {
    dispatch(requestQuantityAssistantForForm(name, quantityField, frequencyWeeksField));
  },
  showSuccessNotification: (title) => {
    dispatch(showNotification("subscription-change", title, NOTIFICATION_KINDS.SUCCESS));
  },
  showFailedNotification: (title) => {
    dispatch(showNotification("subscription-change", title, NOTIFICATION_KINDS.ERROR));
  },
  submit: (form) => {
    dispatch(submit(form));
  }
})


export default withTranslation("accounts")(connect(mapStateToProps, mapDispatchToProps)(withQuery(withCms(EditSubPage))));