// Dates have to follow localised formats, but only a few functions take
// advantage of that ("format*"") : https://date-fns.org/v2.29.3/docs/I18n
import isDate from 'date-fns/isDate';
import format from 'date-fns/format'
import { enUS, de } from "date-fns/locale";
import i18n from "../i18n";
import { formatDistance } from 'date-fns';
const localesDateFns = { en: enUS, de } // keys must be the same as available locales

export function formatDate(dateStr, dateFormat, maybeLocale) {
  const date = new Date(dateStr);
  if (!dateStr || !isDate(date)) {
    return null;
  }

  const locale = maybeLocale || i18n.language;
  return format(date, dateFormat, { locale: localesDateFns[locale] })
}

// https://date-fns.org/v2.29.3/docs/format

export function longDateFormat(date) {
  return formatDate(date, "EEEE do MMM, yyyy");
}

export function dateWithTimeFormat(date) {
  return formatDate(date, "d MMM, yyyy HH:MM");
}

export function shortDate(date) {
  return formatDate(date, "MMM do");
}

export function invoiceDate(date) {
  return formatDate(date, "MMM do, yyyy");
}

export function distanceInDays(futureDate, previousDate = new Date(), locale = i18n.language) {
  return formatDistance(futureDate, previousDate, { locale: localesDateFns[locale] })
}