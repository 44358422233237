import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Form from "../forms/SubscriptionNameForm";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {cancelRenameSubscription} from "../../actions/AccountActions";
import {Map} from "immutable";
import {validatePresence} from "../../lib/Validators";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import LoadingLine from "../../shared/components/forms/LoadingLine";

function validateForm(values) {
  let errors = Map();
  errors = validatePresence(errors, values, "name");
  return errors.toJS();
}

class RenameSubscriptionFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: [] }
  }

  handleSubmit(values) {
    let updatedValues = values.set("accountId", this.props.accountId)
                              .set("subscriptionId", this.props.subscriptionId);

    return this.props.customerRenameSubscription(updatedValues.toJS())
      .then((response) => {
        if (response.data.customerRenameSubscription.errors.length <= 0) {
          this.props.cancelRenameSubscription();
        } else {
          let errors = createValidationErrors(response.data.customerRenameSubscription.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });      
  }

  handleCancel() {
    this.props.cancelRenameSubscription();
  }

  render() {
    let {data} = this.props;
    if (data.loading) return <LoadingLine />
    if (data.error) return <GraphQLErrors error={data.error} />
    let initialValues = {
      name: data.customerSubscriptionInfo.name
    }
    return (
      <Form
        initialValues={initialValues}
        errors={this.state.errors}
        validate={validateForm}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />
    )
  }
}


const GET_SUBSCRIPTION = gql`
  query customerSubscriptionInfo($accountId: ID!, $subscriptionId: ID!) {
    customerSubscriptionInfo(accountId: $accountId, subscriptionId: $subscriptionId) {
      name
    }
  }
`;

const RENAME_SUBSCRIPTION = gql`
  mutation customerRenameSubscription($data: CustomerRenameSubscriptionInput!) {
    customerRenameSubscription(input: $data) {
      errors { key message }
    }
  }
`;


const withQueries = compose(
  graphql(GET_SUBSCRIPTION, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
  graphql(RENAME_SUBSCRIPTION, {
    props: ({ mutate }) => ({
      customerRenameSubscription: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default connect(null, {cancelRenameSubscription})(withQueries(RenameSubscriptionFormContainer));

