import React from "react";
import {connect} from "react-redux";
import NumberStepper from "../../shared/components/forms/NumberStepper";
import Button from "../../shared/components/Button";
import {cancelQuantityAssistant, confirmQuantityAssistant} from "../../actions/ShopActions";
import {getEstimationDescription, estimateBottlesPerMonth} from "../../lib/BottleConsumptionEstimator";
import "./QuantityAssistantFormContainer.scss";

class QuantityAssistantFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      errors: [],
      nrAdults: 1,
      nrChildren: 0
    }
  }

  handleConfirm() {
    let bottles = estimateBottlesPerMonth(this.state.nrAdults, this.state.nrChildren);
    this.props.confirmQuantityAssistant(bottles, 4);
  }

  handleCancel() {
    this.props.cancelQuantityAssistant();
  }

  handleAdultsChange(adults) {
    this.setState({nrAdults: adults});
  }

  handleChildrenChange(children) {
    this.setState({nrChildren: children});
  }

  render() {
    return (
      <form className="QuantityAssistant">
        <div className="fields">
          <p className="u-centered sg-grey-900">{getEstimationDescription()} <strong>Please note that delivery schedule will change to every 4 weeks when using this assistant.</strong></p>
          <div className="steppers">
            <div className="stepper adults">
              <div className="label">Number of Adults</div>
              <NumberStepper value={this.state.nrAdults} onChange={this.handleAdultsChange.bind(this)} />
            </div>
            <div className="stepper children">
              <div className="label">Number of Children</div>
              <NumberStepper value={this.state.nrChildren} onChange={this.handleChildrenChange.bind(this)} />
            </div>
          </div>
        </div>
        <div className="buttons">
          <Button label="Submit Calculation" onClick={this.handleConfirm.bind(this)} />
          <Button label="Cancel" onClick={this.handleCancel.bind(this)}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
      </form>
    )
  }
}

export default connect(null, {cancelQuantityAssistant, confirmQuantityAssistant})(QuantityAssistantFormContainer);
