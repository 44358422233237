import React from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import { DeliveryPackage } from "./DeliveryPackage";
import {Map, List, fromJS} from "immutable";

export default function Packages(props) {
  if (!props.active) return null;
  let variables = {orderId: props.orderId, courierId: props.courierId}  
  let lineItems = formatContent(props.packages);

  return (
    <Query query={PACKAGE_TRACKING_QUERY} variables={variables} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        // Index packages if loaded.
        let packageTrackingMap = Map();
        if (!loading && data.customerPackageTracking) {
          let immutableData = fromJS(data.customerPackageTracking);
          packageTrackingMap = indexTrackingNrs(immutableData.get("packages"));
        }
        // Render.
        return lineItems.map((item, index) => {
          return <DeliveryPackage key={index} courierId={props.courierId} packageItem={item} trackingStatusLoading={loading} trackingUrl={packageTrackingMap.getIn([item.get("trackingNr"), "url"], "")} trackingStatus={packageTrackingMap.getIn([item.get("trackingNr"), "status"], "")} />
        });
      }}
    </Query>
  )
}

function formatContent(packages) {
  return packages.reduce((acc, val, key) => {
    let result = val.get("inventory").reduce((acc2, v, k) => {
      return acc2.push(v.get("title"));
    }, List())
    return acc.push(Map({title: result.join(", "), trackingNr: val.get("trackingNr"), packageId: val.get("packageId")}));
  }, List());
}

function indexTrackingNrs(packages = List()) {
  return packages.reduce((acc, pack) => {
    return acc.set(pack.get("trackingNr"), Map({
      status: pack.get("status"),
      url: pack.get("trackingUrl")
    }));
  }, Map())
}

const PACKAGE_TRACKING_QUERY = gql`
  query customerPackageTracking($orderId: ID!, $courierId: ID!) {
    customerPackageTracking(orderId: $orderId, courierId: $courierId) {
      orderId
      packages {
        trackingNr
        trackingUrl
        status
      }
    }
  }
`