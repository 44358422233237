import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {connect} from "react-redux";
import Button from "../../shared/components/Button";
import {cancelSetBillingAddress} from "../../actions/AccountActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import {applyStateProvinceRule} from "../../lib/AddressRules";
import Error from "../shared/icons/Error";
import SmallMessage from "../shared/SmallMessage";
import LoadingCircle from "../../shared/components/forms/LoadingCircle";
import { withTranslation } from "react-i18next";

class SetBillingAddressFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      errors: [],
      status: "busy"
    }
  }

  componentDidMount() {
    this.handleSubmit();
  }

  handleSubmit() {
    let values = applyStateProvinceRule({
      accountId: this.props.accountId,
      name: this.props.address.name,
      addressAddressLine1: this.props.address.addressAddressLine1,
      addressAddressLine2: this.props.address.addressAddressLine2,
      addressCity: this.props.address.addressCity,
      addressPostcode: this.props.address.addressPostcode,
      addressStateProvince: this.props.address.addressStateProvince,
      addressCountryCode: this.props.address.addressCountryCode
    });

    return this.props.customerChangeBillingAddress(values)
      .then((response) => {
        if (response.data.customerChangeBillingAddress.errors.length <= 0) {
          this.props.cancelSetBillingAddress();
        } else {
          let errors = createValidationErrors(response.data.customerChangeBillingAddress.errors);
          this.setState({errors: errors, status: "error"});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err), status: "error"});
      });      
  }

  handleCancel() {
    this.props.cancelSetBillingAddress();
  }

  render() {
    return (
      <div className="SetBillingAddressFormContainer status">        
        {this.renderView(this.state.status)}
      </div>
    )    
  }

  renderView(status) {
    switch(status) {
      case "error":
        return (
          <SmallMessage title={this.props.t("common:error")}
            icon={<Error side="60px" colorDisabled="var(--primary-blue-700)" />}
            message={this.props.t("billing.edit_address.error")}
            button={<Button label={this.props.t("common:cancel")} onClick={this.handleCancel.bind(this)} />} />
        );
      default: 
        return (
          <SmallMessage title={this.props.t("common:standby")}
            icon={<LoadingCircle />}
            message={this.props.t("billing.edit_address.updating")} />
        );
    }
  }


}

const CHANGE_BILLING_ADDRESS = gql`
  mutation customerChangeBillingAddress($data: BillingAddressInput!) {
    customerChangeBillingAddress(input: $data) {
      errors { key message }
    }
  }
`;


const withQueries = compose(
  graphql(CHANGE_BILLING_ADDRESS, {
    props: ({ mutate }) => ({
      customerChangeBillingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  })  
);

export default withTranslation("accounts")(connect(null, {cancelSetBillingAddress})(withQueries(SetBillingAddressFormContainer)));

