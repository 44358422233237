import React from "react";
import i18n from '../i18n';
import useCmsProductTitles from "../hooks/useCmsProductTitles";

export default function withCmsProductTitles(WrappedComponent) {
  return function(props) {
    const {data, loaded, loading, error} = useCmsProductTitles(i18n.language);

    return (
      <WrappedComponent 
        cmsDataProductTitles={data} 
        cmsDataProductTitlesLoading={loading}
        cmsDataProductTitlesLoaded={loaded}
        cmsDataProductTitlesError={error}
        {...props}
        />
    )
  }
}
