import React, {Component} from "react";
import {connect} from "react-redux";
import {fromJS} from "immutable";
import { cx } from "../../../shared/lib/ClassSet";
import Button from "../../../shared/components/Button";
import { withTranslation, useTranslation } from "react-i18next";
import PaymentError from "../../accounts/PaymentError";
import {invoiceDate} from "../../../lib/DateFormatter";
import {formatInvoiceNr} from "../../../lib/TextFormatter";
import {downloadInvoice} from "../../../actions/AccountActions";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import Panel from "../../shared/Panel";
import styles from "./NarrowChargesPanel.module.scss";
import { DeliveryTag } from "../Deliveries/DeliveryTag";
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import { formatStatus } from "./ChargeItem";

class NarrowChargesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedId: ""
    }
  }

  render() {
    if (!this.props.show) return null;
    let classes = [
      styles.active,
      styles.panel,
    ];

    let subpanels = (<React.Fragment>
      <div className={classes.join(" ")}>
        <div>
        { this.props.charges.size <= 0
          ? this.renderEmpty()
          : this.renderCharges() }
        </div>
        <LoadingLine show={this.props.loading} />
        <Button className={styles.moreButton} label={this.props.t("common:show_all")} primary={false}
          link={true} visible={this.props.moreAvailable} onClick={this.props.onShowAll} />
      </div>
    </React.Fragment>);

    return (
      <Panel title={this.props.t("payment_history.name")}
        subtitle={this.props.t("payment_history.subtitle")}
        subpanels={subpanels}
        subpanelsCount={this.props.charges.size}
        loading={this.props.loading} />
    )

  }

  renderEmpty() {
    if (this.props.loading) return null;
    if (this.props.charges.size > 0) return null;
    return (
      <p className="helptext">{this.props.t("payment_history.no_recorded_payment")}</p>
    )
  }

  renderCharges() {
    if (this.props.charges.size <= 0) return null;
    return this.props.charges.map((charge, index) => {
      return <Item 
              key={charge.get("salesOrderId")}
              charge={charge}
              index={index}
              selected={charge.get("salesOrderId") === this.state.selectedId}
              onClick={this.handleItemClick.bind(this)}
              />
    })
  }

  handleItemClick(id) {
    if (this.state.selectedId === id) {
      this.setState({selectedId: ""});
    } else {
      this.setState({selectedId: id});
    }
  }

}

NarrowChargesPanel.defaultProps = {
  show: true,
  loading: false,
  moreAvailable: false
}

const Item = (props) => {
  const { t: statusT } = useTranslation("accounts", { keyPrefix: "payment_history.status" });
  const { t } = useTranslation("products");
  let data = props.charge;

  let fn = (e) => props.onClick(data.get("salesOrderId"));

  let classes = cx({
    [styles.item]: true,
    [styles.selected]: props.selected,
    [styles.success]: data.get("status") === "paid",
    [styles.error]: data.get("status") === "failed",
    [styles.index0]: props.index === 0
  })

  let downloadInvoiceFn = (e) => props.downloadInvoice(data.get("accountId"), data.get("invoiceNr"));
  let errorRow = null;
  if (data.get("status") === "failed") {
    errorRow = (
      <div className={styles.errorRow}>
        <PaymentError
          accountId={data.get("accountId")}
          salesOrderId={data.get("salesOrderId")}
          willRetry={data.get("willRetry")}
          declineCode={data.get("lastDeclineCode")}          
          errorCode={data.get("lastErrorCode")} 
          errorMessage={data.get("lastErrorMessage")} 
          onClick={fn} />        
      </div>
    )
  }
  return (
    <div className={classes}>
      <div className={styles.header} onClick={fn}>
        <div className={styles.info}>
          <div className={styles.amount}>
            {data.get("currency")} {data.get("amount")}
          </div>
          <div className={styles.status}>
            {formatStatus(data.get("status"), statusT)}
          </div>
          <div className={styles.date}>
            {invoiceDate(data.get("invoiceDate"))}
          </div>
          <div className={styles.invoiceNr}>
            {formatInvoiceNr(data.get("invoiceNr", ""))}
          </div>
        </div>
        <div className={styles.expander}>
          <div className={styles.icon} />
        </div>
      </div>
      {errorRow}
      <div className={styles.body}>
        <Products accountId={data.get("accountId")} salesOrderId={data.get("salesOrderId")} active={props.selected} />
        <Button theme={Button.THEMES.BLUE} label={t("download_invoice")} visible={data.get("invoiceAvailable")} onClick={downloadInvoiceFn} />
      </div>
    </div>
  )

}

const INVOICE_QUERY = gql`
  query customerInvoice($accountId: ID!, $salesOrderId:ID!) {
    customerInvoice(accountId: $accountId, salesOrderId: $salesOrderId) {
      invoiceNr
      totalBeforeTax
      taxes
      currency
      total
      lineItems {
        productSku
        productTitle
        quantity
        unitPrice
        includedUnitSurcharge
        total
        subscription
      }
    }
  }
`

const Products = (props) => {
  const { t } = useTranslation("accounts", { keyPrefix: "payment_history.table" });
  if (!props.active) return null;
  let variables = {accountId: props.accountId, salesOrderId: props.salesOrderId}

  return (
    <Query query={INVOICE_QUERY} variables={variables} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingLine />
        }

        let invoice = fromJS(data.customerInvoice);

        let products = invoice.get("lineItems").map(x => {
          return (
            <tr key={x.get("productSku")}>
              <td>
                <span className={styles.qty}>{x.get("quantity")}x</span> {x.get("productTitle")} 
                <DeliveryTag data={x} className={styles.deliveryTag} />
              </td>
              <td>{x.get("total")} {invoice.get("currency")}</td>
            </tr>
          )
        });

        return (
          <table className={styles.invoiceTable}>
            <thead>
              <tr>
                <th>{t("product")}</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody className={styles.products}>
              {products}
            </tbody>
            <tbody className={styles.taxes}>
              <tr>
                <td>{t("subtotal")}</td>
                <td>{invoice.get("totalBeforeTax")} {invoice.get("currency")}</td>
              </tr>
              <tr>
                <td>{t("taxes")}</td>
                <td>{invoice.get("taxes")} {invoice.get("currency")}</td>
              </tr>
            </tbody>
            <tbody className={styles.total}>
              <tr>
                <td>{t("grand_total")}</td>
                <td className={styles.amount}>{invoice.get("total")} {invoice.get("currency")}</td>
              </tr>
            </tbody>
          </table>
        );

      }}
    </Query>
  )

}


export default withTranslation("accounts")(connect(null, {downloadInvoice})(NarrowChargesPanel));