import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reducer from "./reducer";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import {ApolloProvider} from "react-apollo";
import {Map} from "immutable";
import { createClient } from "./client";
import AuthSaga from "./sagas/AuthSaga";
import ShopSaga from "./sagas/ShopSaga";
import AccountSaga from "./sagas/AccountSaga";
import AddressBookSaga from "./sagas/AddressBookSaga";
import NotificationSaga from "./sagas/NotificationSaga";
import CmsSaga from "./sagas/CmsSaga";
import AnonymousCheckoutSaga from "./sagas/AnonymousCheckoutSaga";
import SocketSaga from "./sagas/SocketSaga";
import Auth from "./Auth";
import {setWindowSize} from "./actions/SystemActions";
import AppContainer from "./AppContainer";
import { BrowserRouter } from 'react-router-dom';
// import {withRouter, Route, Switch, Redirect} from "react-router";
import {Route, Switch} from "react-router-dom";
import LandingPage from './LandingPage';
// import * as serviceWorker from './serviceWorker';


const sagaMiddleware = createSagaMiddleware();
var middleware = [sagaMiddleware];
const store = createStore(reducer, Map(), composeWithDevTools(applyMiddleware(...middleware)));
sagaMiddleware.run(AuthSaga);
sagaMiddleware.run(ShopSaga);
sagaMiddleware.run(AccountSaga);
sagaMiddleware.run(AddressBookSaga);
sagaMiddleware.run(NotificationSaga);
sagaMiddleware.run(CmsSaga);
sagaMiddleware.run(AnonymousCheckoutSaga);
sagaMiddleware.run(SocketSaga);

const auth = new Auth();
const client = createClient(auth);


ReactDOM.render((
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route path="/:locale" render={(p) => <AppContainer auth={auth} />} />
          <LandingPage auth={auth} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>
  ),document.getElementById('root'));

window.addEventListener("resize", handleResize);

function handleResize(e) {
  store.dispatch(setWindowSize(window.innerWidth, window.innerHeight));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
