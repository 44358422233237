import React from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import FormError from "../../shared/components/forms/FormError";
import { useTranslation } from 'react-i18next';

function AccountNameForm(props) {
  const { t } = useTranslation("settings");

  let {errors, handleSubmit, submit, submitting} = props;
  const submitLabel = submitting ? t("common:standby") : t("accounts.rename_account.popup.ok_label");
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="fields">
        <Field 
          label={t("accounts.rename_account.popup.account_name")}
          name="name"
          component={TextInputField}
          mode=""
          serverError={props.errors.name}
          />
      </div>
      <div className="buttons">
        <Button label={submitLabel} primary={true} onClick={submit} active={!submitting} />
        <Button label={t("accounts.rename_account.popup.cancel_label")} active={!submitting} onClick={props.onCancel}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
      
      <FormError error={errors._error} />
    </form>
  );
}

const ReduxForm = reduxForm({
  form: "AccountNameForm"
})(AccountNameForm);

export default ReduxForm;