import React, {useState} from "react";

const Swipable = (props) => {
  const {
    sensitivity = 100, // Horizontal minimal distance to trigger swipe
    onLeftSwipe, onRightSwipe
  } = props;

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > sensitivity) {
      onLeftSwipe();
    }

    if (touchStart - touchEnd < -sensitivity) {
      onRightSwipe();
    }
  }

  return (
    <div className="parentSize"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      { props.children }
    </div>
  );
}

export default Swipable;