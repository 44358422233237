import React from "react";
import styles from "./NoSuchProduct.module.scss";
import Button from "./Button";

const NoSuchProduct = (props) => {
  return <div className={styles.module + " u-inner-padded"}>
    <p className="sg-text-large sg-primary-blue-700">This product is not available in this country</p>
    <Button link theme={Button.THEMES.BLACK}
      onClick={props.goToShop}>
      Visit the shop to see all available products
    </Button>
  </div>
}

export default NoSuchProduct;