import React, { Component } from "react";
import FieldError from "./FieldError";
import NumberStepper from "./NumberStepper";

const noOp = () => null;

class NumberStepperField extends Component {

  render() {
    const {serverError, label, active, input: {value, onChange}, meta: {touched, error}} = this.props;    

    let changeHandler = active ? onChange : noOp;

    if (this.props.mode === "table") {
      return (
        <tr className="NumberStepperField field">
          <th>{label}</th>
          <td>
            <NumberStepper 
              value={value} 
              min={this.props.min}
              max={this.props.max}
              onChange={changeHandler} 
              />
          </td>
        </tr>
      )      
    } 

    return (
      <div className="NumberStepperField field">
        <NumberStepper 
          value={value} 
          min={this.props.min}
          max={this.props.max}
          onChange={changeHandler} 
          />
        <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
      </div>
    )      
  }

}

NumberStepperField.defaultProps = {
  active: true,
  mode: "table",
  min: 0,
  max: 1000,
  showLabel: false
}

export default NumberStepperField;