import React from "react"
import styles from "./FiveStars.module.scss"

/**
 * Displays five stars
 *
 * 0 <= rating <= 5
 */
const FiveStars = ({ rating, cssColor }) => {
  const normalizedRating = normalizeRating(rating)
  const fullStars = Math.floor(normalizedRating)
  const addHalfStar = normalizedRating % 1 > 0

  return (
    <div>
      {Array.from({ length: fullStars }).map((_, index) => (
        <Star key={index} cssColor={cssColor} />
      ))}
      {addHalfStar && <HalfStar cssColor={cssColor} />}
    </div>
  )
}

function normalizeRating(rating) {
  const f = parseFloat(rating)
  if (isNaN(f)) return 0

  return isNaN(f) ? 0 : Math.min(Math.max(f, 0), 5)
}

const Star = ({ cssColor }) => {
  return (
    <span className={styles.star}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          fill={cssColor}
          d="M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z"
        />
      </svg>
    </span>
  )
}

const HalfStar = ({ cssColor }) => {
  return (
    <span className={styles.star}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          fill={cssColor}
          d="M12 15.968l4.247 2.377-.949-4.773 3.573-3.305-4.833-.573L12 5.275v10.693zm0 2.292l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26z"
        />
      </svg>
    </span>
  )
}

export default FiveStars
