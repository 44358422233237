//
// Cf inputs in design system here: https://app.zeplin.io/project/5fc893a7d73bf085db2cc3f4/screen/6000481929f74e1b01c36373
//
// This file should be shared between the website and the shop

import React, { Component } from "react";
import FieldError from "./FieldError";
import Eye from "../../../shop/shared/icons/Eye";
import styles from "./TextInputField.module.scss";

const noOp = () => null;

class TextInputField extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      focused: false,
      showPassword: false,
    };
  }

  render() {
    const {
      serverError,
      label,
      active,
      maxLength,
      minLength,
      type,
      obscured, // Like a password
      autoComplete,
      noTable, // Ask for this to be rendered without the table related tags
      darkBackground, // Having a dark background implies some css adaptation
      comment, // Optionnal comment (hidden when errors are displayed)
      input: { value, onChange }, // onBlur is also available, cf. handleBlur
      meta: { touched, error },
    } = this.props;

    let changeHandler = active ? onChange : noOp;
    let blurHandler = active ? this.handleBlur : noOp;
    let focusHandler = active ? this.handleFocus : noOp;

    const classes = ["field", styles.module];
    if (darkBackground) {
      classes.push(styles.darkBackground);
    }
    const inputClasses = [styles.input];
    if (!active) {
      inputClasses.push(styles.inactive);
    }
    const showClientError = error && touched;
    if (serverError || showClientError) {
      // For now edit the input style only for dark backgrounds
      inputClasses.push(styles.error);
    }

    const showComment = comment && !serverError && !showClientError;
    if (showComment) {
      classes.push(styles.hasComment);
    }

    const inputType = obscured && !this.state.showPassword ? "password" : type;

    const extras = {};
    if (autoComplete) {
      extras.autoComplete = autoComplete;
    }
    extras.spellCheck = this.props.spellCheck === undefined
      ? !obscured
      : this.props.spellCheck;

    const fieldId = this.props.fieldId || this.props.input.name || null;

    let innerIcon = null;
    if (obscured) {
      innerIcon = <div className={styles.rightIcon}>
        <Eye closed={this.state.showPassword}
          onClick={() => this.setState({ showPassword: !this.state.showPassword })} />
      </div>
    }

    return (
      <Base classes={classes} label={label} noTable={noTable} fieldId={fieldId}>
        <input
          id={fieldId}
          name={this.props.input.name}
          className={inputClasses.join(" ")}
          type={inputType}
          required={!!this.props.required}
          value={value}
          autoFocus={this.props.autoFocus}
          // https://developer.apple.com/password-rules/
          passwordrules={this.props.passwordrules}
          aria-label={label}
          onChange={changeHandler}
          onBlur={blurHandler}
          onFocus={focusHandler}
          placeholder={this.props.placeholder}
          maxLength={maxLength}
          minLength={minLength}
          {...extras}
        />
        { innerIcon }
        { showComment && <div className={styles.comment}>{comment}</div> }
        <FieldError
          fieldId={fieldId}
          darkBackground={darkBackground}
          serverError={serverError}
          clientError={error}
          showClientError={showClientError}
        />
      </Base>
    );
  }

  handleFocus(e) {
    e.target.select();
    this.setState({ focused: true });
  }

  handleBlur(e) {
    this.setState({ focused: false });

    if (this.props.input.onBlur) {
      this.props.input.onBlur(e);
    }
  }
}

const Base = ({ classes, label, children, noTable, fieldId }) => {
  return noTable ? (
    <div className={classes.join(" ")}>
      {label && <label className={styles.label} htmlFor={fieldId}>{label}</label>}
      {[children]}
    </div>
  ) : (
    <tr className={classes.join(" ")}>
      <th>{label}</th>
      <td>{children}</td>
    </tr>
  );
};

TextInputField.defaultProps = {
  active: true,
  placeholder: "",
  obscured: false,
  maxLength: "none",
  minLength: "none",
  type: "text",
  noTable: true, // New default with v2
  darkBackground: false,
};

export default TextInputField;
