import React from "react";
import {currencyFormat} from "../../lib/TextFormatter";

const Price = (props) => {
  if (!props.price) {
    return null;
  }

  const infoPrice = (
    <div className="info">
      <span className="price">{currencyFormat(props.price, props.currency)}</span>
      <span className="suffix">{props.suffix}</span>
    </div>
  );

  const withSurcharge = props.surcharge && (
    <div className="priceWithSurcharge">
      { infoPrice }
      <div className="surcharge">{ `+ ${currencyFormat(props.surcharge, props.currency)} Surcharge` }</div>
    </div>
  );

  return withSurcharge || infoPrice; 
}

Price.defaultProps = {
  currency: "USD",
  price: "",
  suffix: ""
}

export default Price;