
export function formatStatus(status, t) {
  return t(status);
}

export function isPositiveStatus(status) {
  return status === "delivered";
}

export function isNegativeStatus(status) {
  switch(status) {
    case "voided":
    case "failed":
    case "cancelled":
    case "exception":
      return true;

    default:
      return false;
  }
}