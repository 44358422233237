import {Map, List} from "immutable";

export function isEurope(shopId) {
  return shopId === "alpine-water-gmbh";
}

export function canOrderProduct(cart = Map(), productSku, kind="") {
  if (kind === "subscription") return true;
  if (productSku.substr(0,3) === "WAT") {
    return cart.get("allowedWaterOrders", false) === true;
  } else {
    return cart.get("allowedProductOrders", false) === true
  }
}

export function canOrderWaterProduct(cart = Map()) {
  return canOrderProduct(cart, "WAT-HAL-STL-05");
}

export function canOrderSubscriptions(cart = Map()) {
  return cart.get("allowedSubscriptions", false) === true;
}

export function isSubscriptionInCart(products = List(), productSku) {
  let result = products.find(x => x.get("productSku") === productSku && x.get("subscription") === true, null, false);
  if (result) return true;
  return false;  
}

export function isWaterSubscriptionInCart(products = List()) {
  let result = products.find(x => x.get("productSku") === "WAT-HAL-STL-05" && x.get("subscription") === true, null, false);
  if (result) return true;
  return false;  
}

export function getWaterSubscriptionInCart(products = List()) {
  return products.find(x => x.get("productSku") === "WAT-HAL-STL-05" && x.get("subscription") === true, null, false);
}

export function getProductIfInCart(products = List(), productSku) {
  let result = products.find(x => x.get("productSku") === productSku && x.get("subscription") === false, null, false);
  return result;
}

export function isProductInCart(products = List(), productSku) {
  let result = getProductIfInCart(products, productSku);
  return !!result;
}

export function hasProductInCartRequiringDispenser(products = List()) {
  let result = products.find(x => x.get("productSku") === "WAT-HAL-STL-05", null, false);
  if (result) return true;
  return false;
}

export function hasDispenserInCart(cart = Map()) {
  return cart.get("items").some(x => x.get("productSku").substr(0, 3) === "DPR");
}

export function isInCart(products = List(), productSku, kind) {
  if (kind === "subscription") return isSubscriptionInCart(products, productSku);
  return isProductInCart(products, productSku);
}

export function hasWaterSubscriptionInCart(cart = Map()) {
  return cart.get("subscription", false) === true;
}

