import {fromJS} from "immutable";

import {
  SET_WINDOW_SIZE
} from "../actions/SystemActions";

const initialState = fromJS({
  width: window.innerWidth,
  height: window.innerHeight
})

const reducer = function(state = initialState, action) {
  switch(action.type) {

    case SET_WINDOW_SIZE:
      if (state.width === action.width && state.height === action.height) return state;
      return state.set("width", action.width)
                  .set("height", action.height);

    default:
      return state;
  }
}

export function getMediaQueryClassName(state) {
  if (state.get("width") > 1400) return "mq-xxl mq-not-xl mq-not-l mq-not-md mq-not-sm";
  if (state.get("width") > 1000) return "mq-not-xxl mq-xl mq-not-l mq-not-md mq-not-sm";
  if (state.get("width") > 900) return "mq-not-xxl mq-not-xl mq-l mq-not-md mq-not-sm";
  if (state.get("width") > 700) return "mq-not-xxl mq-not-xl mq-not-l mq-md mq-not-sm";
  return "mq-not-xxl mq-not-xl mq-not-l mq-not-md mq-sm";
}

export function getMediaQuerySize(state) {
  if (state.get("width") > 1400) return "mq-xxl";
  if (state.get("width") > 1000) return "mq-xl";
  if (state.get("width") > 900) return "mq-l";
  if (state.get("width") > 700) return "mq-md";
  return "mq-sm";
}

export default reducer;