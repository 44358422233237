import {Component} from "react";
import {requestShowOrderStatus} from "../actions/ShopActions";
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../lib/SubscriptionQueryWrapper";

// This is just a wrapper to be able to send instructions to PlaceOrderForm

class OrderStatusMonitor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      invoiceNr: "",
      status: "",
      errorMsg: "",
      lastAttempt: 0
    }
  }

  componentDidMount() {
    this.props.subscribeToUpdates(this.props, {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      switch(this.state.status) {
        case "paid":
        case "billed":
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "success", "", this.state.invoiceNr);
          break;
        case "failed":
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "error", this.state.errorMsg);
          break;
        case "authenticate":
          this.props.requestShowOrderStatus(this.props.accountId, this.props.orderId, "authenticate");
          break;          
        default:
          // do nothing
      }
    }
    if (!this.props.data.loading && this.props.data && this.props.data.customerCheckoutInfo) {
      // Ok billing status changed.
      if (this.props.data.customerCheckoutInfo.billingStatus === "authentication_required" && this.state.status !== "authenticate") {
        this.setState({status: "authenticate"})
      } else if (this.state.lastAttempt !== this.props.data.customerCheckoutInfo.billingAttempt) {
        switch(this.props.data.customerCheckoutInfo.billingStatus) {
          case "paid":
          case "billed":
            this.setState({status: "paid", invoiceNr: this.props.data.customerCheckoutInfo.invoiceNr, lastAttempt: this.props.data.customerCheckoutInfo.billingAttempt});
            break;
          case "failed":
            this.setState({status: "failed", errorMsg: this.props.data.customerCheckoutInfo.billingError, lastAttempt: this.props.data.customerCheckoutInfo.billingAttempt});
            break;
          default:
            // do nothing.
        }
      }
    }
  }

  render() {
    return null;
  }

}

const CHECKOUT_INFO = gql`
  query customerCheckoutInfo($accountId: ID!, $orderId: ID!) {
    customerCheckoutInfo(accountId: $accountId, orderId: $orderId) {
      orderId
      accountId
      invoiceNr
      billingStatus
      billingError
      billingAttempt
      status
    }
  }
`;

const ON_UPDATED_ORDER = SubscriptionQueryWrapper("$orderId: ID!", `
  customerUpdatedOrderInfo(orderId: $orderId) {
    orderId
  }`
);

const withQueries = graphql(CHECKOUT_INFO, {
  options: (props) => ({
    fetchPolicy: "network-only",
    variables: {
      accountId: props.accountId,
      orderId: props.orderId
    }
  })
})

const mapDispatchToProps = dispatch => ({
  subscribeToUpdates: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ORDER,
      variables: {
        orderId: props.orderId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestShowOrderStatus: (accountId, orderId, status, errorMsg, invoiceNr) => {
    dispatch(requestShowOrderStatus(accountId, orderId, status, errorMsg, invoiceNr));
  }
})


export default connect(null, mapDispatchToProps)(withQueries(OrderStatusMonitor));