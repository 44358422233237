import {reducer as formReducer} from "redux-form/immutable";
import windowsReducer from "./reducers/WindowsReducer";
import systemReducer from "./reducers/SystemReducer";
import supportReducer from "./reducers/SupportReducer";
import shopReducer from "./reducers/ShopReducer";
import cmsReducer from "./reducers/CmsReducer";
import anonymousCheckoutReducer from "./reducers/AnonymousCheckoutReducer";
import notificationsReducer from "./reducers/NotificationsReducer";
import {combineReducers} from 'redux-immutable';

const reducers = {
  form: formReducer,
  windows: windowsReducer,
  system: systemReducer,
  support: supportReducer,
  shop: shopReducer,
  notifications: notificationsReducer,
  cms: cmsReducer,
  anonymousCheckout: anonymousCheckoutReducer
}

export default combineReducers(reducers);