import React, { useEffect, useRef, useState } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import GraphQLErrors from "../../shared/GraphQLErrors";
import useResizeListener from "../../hooks/useResizeListener";
import {requestAdjustTransitionPreference} from "../../actions/AccountActions";
import gql from "graphql-tag";
import styles from "../../shop/views/SubscriptionPanel.module.scss";
import { getSubscriptionTotals, indexSurchargeProducts } from "../../lib/SubscriptionHelper";
import { currencyFormat } from "../../lib/TextFormatter";
import { connect } from "react-redux";
import Button from "../../shared/components/Button";
import { SubscriptionQueryWrapper } from "../../lib/SubscriptionQueryWrapper";


// Hardcode the price as this is a temporary component
const TRANSITION_6_PACK_PRICE = 51;


function Subscription8PackEOL(props) {
  const {
    frequencyWeeks,
    products,
    data,
  } = props;
  
  const { t } = useTranslation("accounts");

  useEffect(() => props.subscribeToSubscriptionsChanges(props), [props])

  if (!products || products.every((product) => !willBeTransitionned(product))) {
    // No 8 packs in here so we can ignore anything else
    return null;
  }
  
  if (data.loading) return <LoadingLine />;
  if (data.error) return <GraphQLErrors error={data.error} />;

  if (data.customerPackTransitionPreference.matchBottles === null) {
    // This subscription doesn't have 8 packs or the job hasn't filled it into
    // the preference table yet => display nothing
    return null;
  }

  return (
    <div className={styles.eol}>
      <div className={styles.title}>{t("eol.title")}</div>
      <div className={styles.subscription}>
        <div className={styles.frequency}>{t("subscription.delivery_every", { count: frequencyWeeks })}</div>
        <Products {...props}
          matchBottles={data.customerPackTransitionPreference.matchBottles}
        />
      </div>
    </div>
  );
}

function willBeTransitionned(product) {
  return product.get("productSku") === "WAT-HAL-STL-8PC-750";
}

function Products(props) {
  const {
    accountId,
    subscriptionId,
    matchBottles,
    products,
    addressSurcharges,
  } = props;

  const [transitionnedProducts, setTransitionnedProducts] = useState(products)

  function transitionProduct(product) {
    if (!willBeTransitionned(product)) {
      return product;
    }
    
    const number8Cases = parseInt(product.get("quantity"), 10);
    const { quantity, price } = computeQuantityAndPrice6Cases(number8Cases, matchBottles);

    return product.set("quantity", quantity).set("originalQuantity", number8Cases).set("total", price);
  }
  useEffect(() => {
    setTransitionnedProducts(products.map((product) => transitionProduct(product)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchBottles]);

  const productQuantities = transitionnedProducts.map((product) => {
    const handleClick = () => props.requestAdjustTransitionPreference(accountId, subscriptionId, product.get("originalQuantity"));
    
    return willBeTransitionned(product)
      ? <ReplacedProduct key={product.get("productSku")} product={product} onClick={handleClick} />
      : <NormalProduct key={product.get("productSku")} product={product} />
  });

  const indexedSurcharges = indexSurchargeProducts(addressSurcharges);
  const newTotals = getSubscriptionTotals(transitionnedProducts, indexedSurcharges);

  return (
    <div className="products">
      {productQuantities}
      <div className={styles.pricing}>
        <SubscriptionTotal showNotification={() => {}} total={newTotals.get("total")} currency={newTotals.get("currency")} />
        <SubscriptionSurcharges total={newTotals.get("surcharges")} currency={newTotals.get("currency")} />
      </div>
    </div>
  )
}

export function computeQuantityAndPrice6Cases(number8Cases, matchBottles) {
  const currentBottleQuantity = number8Cases * 8;

  const newCaseQuantity = matchBottles
    ? Math.ceil(currentBottleQuantity / 6)
    : number8Cases;
  
  return {
    quantity: newCaseQuantity,
    price: newCaseQuantity * TRANSITION_6_PACK_PRICE,
  }
}

function NormalProduct({product}) {
  return (
    <div className={styles.product}>
      <div className={styles.description}>
        <span className={styles.quantity}>{product.get("quantity")}x</span> {product.get("productTitle")}
      </div>
    </div>
  );
}

function ReplacedProduct({product, onClick}) {
  const { t } = useTranslation("accounts");
  const baseRef = useRef();
  const {width} = useResizeListener(baseRef.current);

  const classes = [
    styles.productTrans,
    width < 520 ? styles.tight : styles.large,
  ]

  return (
    <div ref={baseRef} className={classes.join(" ")}>
      <div className={styles.description}>
        <div className={styles.info}>{t("eol.info")}</div>
        <div>
          <span className={styles.quantity}>{product.get("quantity")}x</span> {t("eol.6_case_label")}
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          label={t("eol.action")}
          theme={Button.THEMES.WHITE}
          onClick={onClick}
        />
      </div>
    </div>
  );
}



const SubscriptionTotal = (props) => {
  return (
    <div>
      <Trans i18nKey="accounts:subscription.price_per_delivery"
        components={{
          b: <span className="sg-font-bold" />
        }}
        values={{
          price: currencyFormat(props.total, props.currency, 2, false, false)
        }}
      />
    </div>
  );
}

function SubscriptionSurcharges({total, currency}) {
  const { t } = useTranslation("accounts", { keyPrefix: "subscription"});
  if (total === 0) return null;
  const surchargeAmount = currencyFormat(total, currency, 2, false, false);

  return (
    <div className={styles.surcharge}>
      {t("includes_surcharge", { surcharge: surchargeAmount })}
    </div>
  )
}

const CUSTOMER_PACK_TRANSITION_PREFERENCE = gql`
  query customerPackTransitionPreference($accountId: ID!, $subscriptionId: ID!) {
    customerPackTransitionPreference(accountId: $accountId, subscriptionId: $subscriptionId) {
      matchBottles
    }
  }
`;

const ON_UPDATED_ACCOUNT_SUBSCRIPTIONS = SubscriptionQueryWrapper("$accountId: ID!", `
  updatedAccountSubscriptions(accountId: $accountId) {
    accountId
  }`
);

const mapDispatchToProps = dispatch => ({
  subscribeToSubscriptionsChanges: (props) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_ACCOUNT_SUBSCRIPTIONS,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestAdjustTransitionPreference: (accountId, subscriptionId, originalQuantity) => {
    dispatch(requestAdjustTransitionPreference(accountId, subscriptionId, originalQuantity));
  },
});

const withQueries = compose(
  graphql(CUSTOMER_PACK_TRANSITION_PREFERENCE, {
    options: (props) => ({
      fetchPolicy: "network-only"
    })
  }),
);

export default connect(null, mapDispatchToProps)(withQueries(Subscription8PackEOL));

