import React from "react";
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import Form from "./ConfirmForm";
import {cancelRemoveSubscriptionFromCart} from "../../actions/ShopActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";

class RemoveSubscriptionFromCartFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      errors: []
    }
  }

  handleSubmit() {
    return this.props.customerRemoveSubscriptionFromCart({accountId: this.props.accountId})
      .then((response) => {
        if (response.data.customerRemoveSubscriptionFromCart.errors.length <= 0) {
          this.props.cancelRemoveSubscriptionFromCart();
        } else {
          let errors = createValidationErrors(response.data.customerRemoveSubscriptionFromCart.errors);
          this.setState({errors: errors});
        }
      })
      .catch((err) => {
        this.setState({errors: createErrors(err)});
      });          
  }

  handleCancel() {
    this.props.cancelRemoveSubscriptionFromCart();
  }


  render() {
    return (
      <Form
        errors={this.state.errors}
        message="Are you sure you want to remove the subscription from your cart? Any other products that require a subscription will also be removed."
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        />
    )
  }  

}


const REMOVE_SUBSCRIPTION_FROM_CART = gql`
  mutation customerRemoveSubscriptionFromCart($data: CustomerAccountInput!) {
    customerRemoveSubscriptionFromCart(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = graphql(REMOVE_SUBSCRIPTION_FROM_CART, {
    props: ({ mutate }) => ({
      customerRemoveSubscriptionFromCart: (data) => mutate({
        variables: { data: data }
      })
    })
  });



export default connect(null, {cancelRemoveSubscriptionFromCart})(withQueries(RemoveSubscriptionFromCartFormContainer));

