import React, {Fragment, useState} from "react";
import gql from "graphql-tag";
import {Query} from "react-apollo";
import NarrowDeliveriesPanel from "./Deliveries/NarrowDeliveriesPanel";
import DeliveriesPanel from "./Deliveries/DeliveriesPanel";
import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";

function takeMaxDeliveries(deliveries, max, showAll) {
  if (showAll) return deliveries;
  return deliveries.take(max);
}

function areMoreDeliveriesAvailable(deliveries, max, showAll) {
  if (showAll) return false;
  return deliveries.size > max;
}

const DeliveriesController = (props) => {
  const [listAll, setListAll] = useState(false);

  function showAll(p, refetch) {
    refetch({
      accountId: p.accountId,
      deliveriesFilter: {
        limit: 100
      }      
    });
    setListAll(true);
  }

  let deliveries = fromJS([]);
  let moreAvailable = false;

  let variables = {
    accountId: props.accountId,
    deliveriesFilter: {
      limit: listAll ? 100 : 7
    }          
  }

  return (
    <Query query={QUERY} variables={variables} fetchPolicy="cache-and-network">
      {({loading, error, data, refetch}) => {
        if (error) {
          return <GraphQLErrors error={error} />
        }

        if (data && data.customerDeliveries) {
          let allDeliveries = fromJS(data.customerDeliveries);
          deliveries = takeMaxDeliveries(allDeliveries, 6, listAll);
          moreAvailable = areMoreDeliveriesAvailable(allDeliveries, 6, listAll);
        }

        return (
          <Fragment>
            <NarrowDeliveriesPanel
              show={props.narrowSize}
              accountId={props.accountId}
              deliveries={deliveries}
              moreAvailable={moreAvailable}
              onShowAll={showAll.bind(this, props, refetch)}
              loading={loading}        
              />
            <DeliveriesPanel
              show={!props.narrowSize}
              accountId={props.accountId}
              deliveries={deliveries}
              moreAvailable={moreAvailable}
              onShowAll={showAll.bind(this, props, refetch)}
              loading={loading}
              />
          </Fragment>
        )
      }}
    </Query>
  )
}

const QUERY = gql`
  query customerDeliveries($accountId:ID!, $deliveriesFilter:CustomerDeliveriesFilter) {
    customerDeliveries(accountId:$accountId, filter:$deliveriesFilter) {
      orderId
      salesOrderId
      deliveryAddress {
        name
        addressAddressLine1
        addressPostcode
        addressCity
      }
      packages {
        packageId
        trackingNr
        inventory {
          title
        }
      }
      type
      gift
      nrPackages
      fulfillmentStatus
      courierStarted
      pickupDate
      courierId
      updatedAt
    }
  }
`

DeliveriesController.defaultProps = {
  narrowSize: false
}

export default DeliveriesController;