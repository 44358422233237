import {List} from "immutable";
import {isDate} from "date-fns";
import { isPossiblePhoneNumber } from 'react-phone-number-input/input';
import i18n from "../i18n";

export const PASSWORD_MIN_LENGTH = 6;

function requiredMessage() {
  return i18n.t("forms:validation.required");
}

export function validateIncludes(errors, values, key, options=List()) {
  if (options.includes(values.get(key, ""))) return errors;
  return errors.set(key, requiredMessage());
}

export function validatePresence(errors, values, key, message = requiredMessage()) {
  if (values.get(key, "").length > 0) return errors;
  return errors.set(key, message);
}

export function validateBy(errors, values, key, fn, message = requiredMessage()) {
  if (fn(values.get(key, null)) === true) return errors;
  return errors.set(key, message);
}

export function validatePhoneNumber(errors, values, key, message = i18n.t("forms:validation.phone_invalid")) {
  // No errors if not defined: use validatePresence on top of validating the
  // phone number if necessary.
  if (values.get(key, "") === "" || isPossiblePhoneNumber(values.get(key, ""))) return errors;
  return errors.set(key, message);
}

export function validateAcceptance(errors, values, key, equalsValue="yes") {
  let val = values.get(key, "");
  if (val === equalsValue) return errors;
  return errors.set(key, i18n.t("forms:validation.mandatory"));
}

export function validateMinLength(errors, values, key, minLength) {
  let val = values.get(key, "");
  if (String(val).length >= minLength) return errors;
  return errors.set(key, i18n.t("forms:validation.min_length", { count: minLength }));
}

export function validateGreaterThan(errors, values, key, minValue = 0) {
  let val = values.get(key, "");
  if (isNaN(val)) return errors.set(key, i18n.t("forms:validation.must_be_number"));
  if (parseInt(val, 10) > minValue) return errors;
  return errors.set(key, i18n.t("forms:validation.must_be_greater", { number: minValue }));
}

export function validateLowerThanOrEqual(errors, values, key, maxValue) {
  let val = values.get(key, "");
  if (isNaN(val)) return errors.set(key, "Must be a number");
  if (parseInt(val, 10) <= maxValue) return errors;
  return errors.set(key, i18n.t("forms:validation.must_be_lower", { number: maxValue }));
}

export function validateStartsWith(errors, value, key, str = "") {
  let val = value.get(key, "");
  if (val.substr(0, str.length) !== str) return errors.set(key, i18n.t("forms:validation.must_start_with", { string: str }));
  return errors;
}

export function validateFormat(errors, values, key, regexp, message = i18n.t("forms:validation.format_invalid")) {
  let val = values.get(key, "");
  if (regexp.test(val)) return errors;
  return errors.set(key, message);
}

export function validateEmail(errors, values, key, message = i18n.t("forms:validation.email_invalid")) {
  const regex = /.+@.+\..+/;
  return validateFormat(errors, values, key, regex, message);
}

// For now we only check if the password contains at least 6 characters
export function validatePassword(errors, values, key, message = i18n.t("forms:validation.password_invalid")) {
  return validateMinLength(errors, values, key, PASSWORD_MIN_LENGTH);
}

export function validateDate(errors, values, key, message = i18n.t("forms:validation.date_invalid")) {
  if (!isDate(values.get(key, ""))) return errors.set(key, message);
  return errors;
}