import React, { Component } from "react";
import FieldError from "./FieldError";
import styles from "./DropDownField.module.scss";
import Select from "./Select";
import { withTranslation } from "react-i18next";

const noOp = () => null;

class DropDownField extends Component {
  render() {
    const {
      serverError,
      label,
      active,
      showBlank,
      placeholder = this.props.t("select_placeholder"),
      autoComplete,
      input: { value, onChange },
      meta: { touched, error },
      darkBackground,
    } = this.props;

    let changeHandler = active ? onChange : noOp;

    let extras = {
      withSpritePath: this.props.withSpritePath,
    };
    if (autoComplete) {
      extras.autoComplete = autoComplete;
    }

    let baseClasses = styles.module + " field"
    if (this.props.darkBackground) {
      baseClasses += " " + styles.darkBackground;
    }

    if (this.props.mode === "table") {
      return (
        <tr className={baseClasses}>
          <th>{label}</th>
          <td>
            <Select
              data={this.props.options}
              value={value}
              preferredValues={this.props.preferredValues}
              showBlank={showBlank}
              valueKey={this.props.valueKey}
              titleKey={this.props.valueKey}
              placeholder={placeholder}
              onChange={changeHandler}
              darkBackground={darkBackground}
              {...extras}
              />
            <FieldError
              serverError={serverError}
              clientError={error}
              showClientError={touched && error}
            />
          </td>
        </tr>
      );
    }

    return (
      <div className={baseClasses}>
        <div className={styles.label}>{label}</div>
        <Select
          data={this.props.options}
          value={value}
          preferredValues={this.props.preferredValues}
          showBlank={showBlank}
          valueKey={this.props.valueKey}
          titleKey={this.props.titleKey}
          placeholder={placeholder}
          onChange={changeHandler}
          darkBackground={darkBackground}
          {...extras}
          />
        <FieldError
          darkBackground={darkBackground}
          serverError={serverError}
          clientError={error}
          showClientError={touched && error}
        />
      </div>
    );
  }

  renderErrors() {
    const {error, meta: {touched}} = this.props;
    if (touched && error) {
      return <div className="fieldError">{error}</div>
    }
    return null;
  }
}

DropDownField.defaultProps = {
  active: true,
  showBlank: true,
  mode: "table",
  valueKey: "value",
  titleKey: "title",
  darkBackground: false,
};

export default withTranslation("forms")(DropDownField);
