import React, {Component} from "react";
import {connect} from "react-redux";
import Button from "../../../shared/components/Button";
import SendAsGiftQuestion from "../../shared/SendAsGiftQuestion";
import {
  requestChangeShippingAddress,
  requestRemoveGiftingOption,
  } from "../../../actions/ShopActions";
import { requestAddShippingAddress } from "../../../actions/AddressBookActions";
import { showSupport } from "../../../actions/SupportActions";
import AddressList from "../AddressList";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import CheckoutPanel from "./CheckoutPanel";
import { isSameAddress } from "../../../lib/AddressRules";
import Conditional from "../../../shared/Conditional";
import GiftingMessageFormContainer from "../../forms/GiftingMessageFormContainer";
import {Map} from "immutable";
import styles from "./SelectShippingAddress.module.scss";
import Error from "../../shared/icons/Error";
import LoadingLine from "../../../shared/components/forms/LoadingLine";
import { withTranslation, Trans } from 'react-i18next';
import SetupShippingAddressFormContainer from "../../addressBook/SetupShippingAddressFormContainer";


class SelectShippingAddress extends Component {

  constructor(props) {
    super(props);
    this.handleAddShippingAddress = this.handleAddShippingAddress.bind(this);
    this.handleChangeShippingAddress = this.handleChangeShippingAddress.bind(this);
    this.handleAddressListChange = this.handleAddressListChange.bind(this);
    this.handleGiftClick = this.handleGiftClick.bind(this);
    this.state = {
      autoSelectShippingAddress: false, // auto select when only one address
      displayAllAddresses: false, // For the few users with many addresses
      selectedAddressId: "",
      submittingAddress: false,
      submittingGift: false,
      error: false // not used at the moment.
    }
  }

  autoHandleShippingAddressState() {
    const numberOfOldAddresses = this.props.usedShippingAddresses.size;

    const hasNotProvidedAnyAddresses = this.state.autoSelectShippingAddress === false && numberOfOldAddresses === 0 && this.props.status === "not_provided";

    if (hasNotProvidedAnyAddresses) {
      // If the user never provided an address we set autoSelectShippingAddress to true,
      // which will allow to auto select the first address he will add
      this.setState({ autoSelectShippingAddress: true });
    } else if (this.state.autoSelectShippingAddress && numberOfOldAddresses === 1) {
      // Try preselecting the address from the address book if there is only one
      // and no new address was provided yet.
      const loneId = this.props.usedShippingAddresses.get(0).get("addressId");
      if (loneId !== this.state.selectedAddressId && this.state.submittingAddress === false) {
        // test if we are submitting the address in order to not call
        // handleAddressListChange several times
        this.handleAddressListChange(loneId);
        this.setState({ autoSelectShippingAddress: false });
      }
    } else if (this.props.shippingAddress && numberOfOldAddresses > 0) {
      // Update the state if the shipping address is part of the address book
      const foundAddressInBook = this.props.usedShippingAddresses.find(
        (e) => isSameAddress(e.get("address"), this.props.shippingAddress)
      );

      if (foundAddressInBook) {
        const addressId = foundAddressInBook.get("addressId");
        if (addressId !== this.state.selectedAddressId) {
          this.setState({ selectedAddressId: addressId });
        }
      }
    }
  }

  componentDidMount() {
    this.autoHandleShippingAddressState();
  }
  componentDidUpdate(prevProps) {
    this.autoHandleShippingAddressState(prevProps);
  }

  render() {
    const wasAddressProvided = this.props.status === "provided";
    const isSelectedAddressInBook = this.props.usedShippingAddresses.find(
      (addr) => addr.get("addressId") === this.state.selectedAddressId
    );
    // Even if the address was provided, if the user deletes it from the
    // address book this should be marked as not completed.
    const isCompleted = wasAddressProvided && isSelectedAddressInBook;

    const helpText = (
      <>
        {this.props.t("shipping.info")}
        {!isCompleted && <span className={styles.infoNotCompleted}> {this.props.t("shipping.info_not_completed")}</span>}
      </>
    );

    return (
      <CheckoutPanel nbTasks={1} completed={isCompleted}
        title={this.props.t("shipping.title")}
        help={helpText}
        instructions={this.renderInstructions()}
      >
        {this.renderView(this.props.status)}
      </CheckoutPanel>
    )
  }

  renderInstructions() {
    if (this.props.status === "provided") return null;
    
    return this.props.usedShippingAddresses.size > 0
      ? this.props.t("shipping.select_address_to_proceed")
      : this.props.t("shipping.add_address_to_proceed");
  }

  renderAddressRequest() {
    return (
      <SetupShippingAddressFormContainer accountId={this.props.accountId}
        prefilledAddress={this.props.prefilledAddress}>
        <div className={styles.refreshAddresses}>
          <Trans
            i18nKey="checkout:shipping.not_showing_up"
            components={{
              a: <Button
                link className={styles.refreshLink}
                onClick={() => this.props.onForceRefreshAddresses()} />
            }}
          />
        </div>
      </SetupShippingAddressFormContainer>
    )
  }

  renderAddressList() {
    const buttonAction = this.handleChangeShippingAddress;

    const conditionalGiftElem = (
      <Conditional show={this.props.status === "provided"}>
        <div className={styles.giftBox}>
          <SendAsGiftQuestion
            onClick={this.handleGiftClick}
            checked={this.props.isGiftChecked}
            submitting={this.submittingGift}
            showSupport={this.props.showSupport}
          />
  
          <Conditional show={this.props.isGiftChecked}>
            <GiftingMessageFormContainer
              orderId={this.props.orderId}
              accountId={this.props.accountId}
              initialMessage={this.props.giftMessage}
              onMessageUpdate={this.props.setGiftMessage}
              onMarkedAsGift={this.props.setSavedGiftMessage}
            />
          </Conditional>
        </div>
      </Conditional>
    );

    const warningMessage = this.props.isGiftChecked && this.props.displaySameEmailWarning && (
      <div className={styles.giftWarning}>
        <Error /><div>{this.props.t("shipping.gift.to_yourself")}</div>
      </div>
    );
    
    const addressesToDisplay = this.state.displayAllAddresses
      ? this.props.usedShippingAddresses
      : this.props.usedShippingAddresses.take(this.props.defaultDisplayMaxAddresses);
    let buttonDisplayAll = null;
    if (this.props.usedShippingAddresses.size > this.props.defaultDisplayMaxAddresses) {
      const label = this.state.displayAllAddresses
        ? this.props.t("shipping.show_lasts")
        : this.props.t("shipping.show_all");
      buttonDisplayAll = (
        <Button label={label} link={true}
          onClick={() => this.setState({ displayAllAddresses: !this.state.displayAllAddresses })}
        />
      );
    }

    return (
      <React.Fragment>
        <AddressList
          addresses={addressesToDisplay}
          selectedId={this.state.selectedAddressId}
          onChange={this.handleAddressListChange}
          mode={AddressList.MODES.TIGHT}
          additionalElemForSelected={conditionalGiftElem}
          subMessageForSelected={warningMessage}
          errorForId={this.state.error}
          />
        { buttonDisplayAll }
        <div className="buttons">
          <Button label={this.props.t("shipping.add_address")}
            theme={!this.state.selectedAddressId ? Button.THEMES.BLUE : Button.THEMES.BLUE_OUTLINE}
            onClick={() => this.handleAddShippingAddress(false)}
            active={this.props.canChangeAddress && !this.state.submittingAddress} />
          <Button label={this.props.t("shipping.edit_address")}
            theme={Button.THEMES.BLUE_OUTLINE}
            size={Button.SIZES.SMALL}
            onClick={buttonAction}
            active={this.props.canChangeAddress && !this.state.submittingAddress} />
          <LoadingLine show={this.state.submittingAddress} />
          <div className={styles.refreshAddresses}>
            <Trans
              i18nKey="checkout:shipping.not_showing_up"
              components={{
                a: <Button
                  link className={styles.refreshLink}
                  onClick={() => this.props.onForceRefreshAddresses()} />
              }}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderView(shippingAddressStatus) {
    if (this.props.usedShippingAddresses.size > 0 || shippingAddressStatus === "provided") {
      // There are addresses in the book so we diplay them as a right list
      return this.renderAddressList();
    } else {
      // No address yet, we display a form inline to add and set an address
      return this.renderAddressRequest();
    }
  }

  handleAddShippingAddress(autoSelect) {
    if (autoSelect) {
      this.setState({ autoSelectShippingAddress: true });
    }
    this.props.requestAddShippingAddress(this.props.accountId);
  }

  handleChangeShippingAddress() {
    this.props.requestChangeShippingAddress(this.props.accountId, this.props.orderId, this.state.selectedAddressId);
  }

  unmarkAsGift(onSuccess, onFailure) {
    let values = Map({
      orderId: this.props.orderId,
      accountId: this.props.accountId
    });
    this.props.customerUnmarkAsGift(values.toJS())
      .then((response) => {
        onSuccess && onSuccess(response);
      })
      .catch((err) => {
        onFailure && onFailure(err);
      });
  }

  handleGiftClick(toggle) {
    if (toggle) {
      this.props.setIsGiftChecked(true);
    }
    else if (!toggle) {
      this.setState({ submittingGift: true });
      
      this.unmarkAsGift((response) => {
        if (response.data.customerUnmarkAsGift.errors.length <= 0) {
          this.props.setIsGiftChecked(false);
          this.setState({ submittingGift: false });
        }
      });
    }
  }

  handleAddressListChange(id) {
    if (!this.props.canChangeAddress) {
      return
    }
    
    this.setState({ submittingAddress: true, error: false });
    this.props.customerAssignExistingShippingAddress({orderId: this.props.orderId, accountId: this.props.accountId, addressId: id})
      .then((response) => {
        if (response.data.customerAssignExistingShippingAddress.errors.length <= 0) {
          this.setState({ submittingAddress: false, selectedAddressId: id, error: false });
        } else {
          this.setState({ submittingAddress: false, selectedAddressId: id, error: {
              id: id,
              message: response.data.customerAssignExistingShippingAddress.errors.flatMap(e => e.message).join(""),
            }
          });
        }
      })
      .catch((err) => {
        this.setState({ submittingAddress: false, error: true });
      })
  }
}

SelectShippingAddress.defaultProps = {
  canChangeAddress: true,
  defaultDisplayMaxAddresses: 4,
}

const ASSIGN_EXISTING_ADDRESS = gql`
  mutation customerAssignExistingShippingAddress($data: OrderExistingShippingAddressInput!) {
    customerAssignExistingShippingAddress(input: $data) {
      errors { key message }
    }
  }
`

const UNMARK_AS_GIFT = gql`
  mutation customerUnmarkAsGift($data: CustomerUnmarkAsGiftInput!) {
    customerUnmarkAsGift(input: $data) {
      errors { key message }
    }
  }
`;

const withQueries = compose(
  graphql(ASSIGN_EXISTING_ADDRESS, {
    props: ({ mutate }) => ({
      customerAssignExistingShippingAddress: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
  graphql(UNMARK_AS_GIFT, {
    props: ({ mutate }) => ({
      customerUnmarkAsGift: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

export default withTranslation("checkout")(connect(null, {
  requestAddShippingAddress,
  // requestEditShippingAddress,
  requestChangeShippingAddress,
  requestRemoveGiftingOption,
  showSupport,
})(withQueries(SelectShippingAddress)));