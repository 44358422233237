import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../shared/components/Button";
import styles from "./AdjustTransitionPreferenceForm.module.scss";
import RadioSelectButton from "../../shop/shared/RadioSelectButton";
import { computeQuantityAndPrice6Cases } from "./Subscription8PackEOL";
import { ChoiceBox } from "./Box";


function AdjustTransitionPreferenceForm(props) {
  const {
    number8Cases = 1,
    defaultMatchBottles,
    submitting,
  } = props;

  const { t } = useTranslation("accounts");
  const [matchBottles, setMatchBottles] = useState(defaultMatchBottles);

  const { quantity: quantityMatchBottles } = computeQuantityAndPrice6Cases(number8Cases, true);
  const { quantity: quantityMatchCases } = computeQuantityAndPrice6Cases(number8Cases, false);

  const cancelButton = props.onCancel && (
    <Button onClick={props.onCancel}
      theme={Button.THEMES.BLUE_OUTLINE}
      addCancelToTopRight={true}
      label={t("common:cancel")}
    />
  );

  return (
    <div className="form">
      <p className="u-centered">
        {t("eol.popup.intro")}
      </p>
      <div>
        <ChoiceBox
          numberLeft={number8Cases}
          numberRight={quantityMatchBottles}
          onMatchBottles={() => setMatchBottles(true)}>
          <div className={styles.button}>
            <RadioSelectButton selected={matchBottles === true}>
              {t("eol.popup.match_bottles")}<span>{t("eol.popup.match_bottles_more")}</span>
            </RadioSelectButton>
          </div>
        </ChoiceBox>
        <ChoiceBox
          numberLeft={number8Cases}
          numberRight={quantityMatchCases}
          onMatchBottles={() => setMatchBottles(false)}>
          <div className={styles.button}>
            <RadioSelectButton selected={matchBottles === false}>
            {t("eol.popup.match_cases")}<span>{t("eol.popup.match_cases_more")}</span>
            </RadioSelectButton>
          </div>
        </ChoiceBox>
      </div>
      <div>{props.errors}</div>
      <div className="buttons">
        <Button onClick={() => props.onSubmit(matchBottles)}
          label={t("eol.popup.confirm")} active={!submitting} />
        { cancelButton }
      </div>
    </div>
  );
}


export default AdjustTransitionPreferenceForm;

