import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {loadProductCmsData} from "../actions/CmsActions";
import SystemIsDownPage from "../SystemIsDownPage";
import i18n from '../i18n';

export default function withCms(WrappedComponent) {
  return function(props) {
    const dispatch = useDispatch();
    const status = useSelector(state => state.getIn(["cms", "status"]));
    const data = useSelector(state => state.getIn(["cms", "data"]));
    const [loadedLanguage, setLoadedLanguage] = useState();

    useEffect(() => {
      // Language needs to be defined in order to fetch the right data
      if (i18n.language !== loadedLanguage) {
        const shouldReload = loadedLanguage === undefined // First time
          || loadedLanguage !== i18n.language; // User switched language
  
        if (shouldReload) {
          dispatch(loadProductCmsData(i18n.language));
          setLoadedLanguage(i18n.language);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, i18n.language]);

    if (status === "error") {
      // Loading failed n times, we can't recover and show system KO page.
      return <SystemIsDownPage />
    }

    return (
      <WrappedComponent 
        cmsData={data} 
        cmsLoading={status === "loading" || status === "not_loaded"}
        cmsLoaded={status === "loaded"}
        cmsError={status === "error"}
        {...props}
        />
    )
  }
}
