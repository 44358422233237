import React from "react";
import SmallMessage from "./shop/shared/SmallMessage";
import Button from "./shared/components/Button";
import styles from "./ErrorPages.module.scss";
import Error from "./shop/shared/icons/Error";
import { useTranslation } from "react-i18next";

const SystemIsDownPage = (props) => {
  const { t } = useTranslation("common", { keyPrefix: "system_down" });
  const { debugInfo } = props;

  const commmonButtonProps = {
    size: Button.SIZES.MEDIUM,
  }
  const reloadButton = <Button label={t("reload_label")} onClick={() => window.location.reload()} {...commmonButtonProps} />

  const debugElem = process.env.NODE_ENV !== 'production' && debugInfo && (
    <p className={styles.debugInfo}>{ debugInfo}</p>
  );

  return (
    <div className={styles.module}>
      <SmallMessage
        icon={<Error side="56px" colorDisabled="var(--secondary-gold-600)" />}
        title={t("title")}
        button={reloadButton} >
        <p>{t("text")}&nbsp;
          <a href="mailto:contact@hallsteinwater.com?subject=Hallstein shop is down">contact@hallsteinwater.com</a>
        </p>
        { debugElem }
      </SmallMessage>
    </div>
  );
}

export default SystemIsDownPage;
