import React, {Component} from "react";
import {Field} from "redux-form/immutable";
import {reduxForm} from "../../lib/ReduxFormWrapper";
import DropDownField from "../../shared/components/forms/DropDownField";
import Button from "../../shared/components/Button";
import FormError from "../../shared/components/forms/FormError";
import { COUNTRY_CODE_KEY, COUNTRY_LABEL_KEY, preferredCountries } from "../../lib/CountriesAndStates";
import { withTranslation } from 'react-i18next';

class CountrySelectForm extends Component {
  // The "countries" prop is set as a result of a query, so we listen to the 
  // props updates to act on it.
  componentWillReceiveProps(nextProps) {
    if (this.props.countries.length === 0 && nextProps.countries) {
      // Init the field with the assumed country we got from the server,
      // but only if the value is valid for the shop list of countries.
      const assumedCountryCode = this.props.assumedCountryCode;

      if (nextProps.countries.map(e => e.get("code")).includes(assumedCountryCode)) {
        this.props.initialize({ countryCode: assumedCountryCode });
      }
    }
  }

  render() {
    const {errors, handleSubmit, submitting} = this.props;
    const submitLabel = submitting
      ? this.props.t("common:standby")
      : this.props.t("signup.country_select.continue");

    return (
      <form onSubmit={handleSubmit}>
        <div className="fields">
          <Field
            label={this.props.t("forms:country")}
            name="countryCode"
            preferredValues={preferredCountries}
            placeholder={this.props.t("forms:country_placeholder")}
            mode=""
            options={this.props.countries}
            valueKey={COUNTRY_CODE_KEY}
            titleKey={COUNTRY_LABEL_KEY}
            withSpritePath="/assets/flags/sprites-vertical.png"
            autoComplete="country"
            component={DropDownField}
            serverError={this.props.errors.countryCode}
            darkBackground={this.props.darkBackground}
            />
        </div>
        <div className="buttons">
          <Button
            label={submitLabel}
            size={Button.SIZES.MEDIUM}
            className="expand sg-mb-tiny"
            onClick={handleSubmit(values => this.props.onSubmit(values))}
            active={!submitting}/>
        </div>
        
        <FormError error={errors._error}
          darkBackground={this.props.darkBackground} />
      </form>
    )
  }
}

const ReduxForm = reduxForm({
  form: "CountrySelectForm",
  destroyOnUnmount: false
})(CountrySelectForm);

CountrySelectForm.defaultProps = {
  darkBackground: false,
}

export default withTranslation("auth")(ReduxForm);