import React from "react";
import { cx } from "./lib/ClassSet";
import {isUS} from "../lib/CountriesAndStates";
import Telephone from "./Telephone";
import { useTranslation } from "react-i18next";
import "./Address.scss";

const MODES = {
  NORMAL: "displayModeNormal",
  TIGHT: "displayModeTight",
}

const renderNameAndAttn = (props) => {
  const { address, mode } = props;
  const name = address.get("name", "");
  const attn = address.get("attName", "");

  // Name should always be available
  const nameElem = <div className="name">{ name }</div>;

  if (!attn) {
    return nameElem;
  }

  const attnElem = mode === MODES.TIGHT
    ? `Attn: ${attn}`
    : <><span className="label">Attn: </span>{attn}</>;

  return (
    <>
      {nameElem}
      <div className="attName">{ attnElem }</div>
    </>
  );
}

function renderAddress(address) {
  const fieldsArray = [
    address.get("name") && <span className="name">{address.get("name", "")}</span>,
    address.get("addressAddressLine1", ""),
    address.get("addressAddressLine2") && <span className="addressLine2">{address.get("addressAddressLine2", "")}</span>,
    address.get("addressCity", ""),
    address.get("addressStateProvince", ""),
    address.get("addressPostcode", ""),
    address.get("addressCountryName", ""),
  ].filter((e) => !!e);

  return fieldsArray.map((e, i) => <React.Fragment key={i}>{i !== 0 ? ", " : ""}{e}</React.Fragment>);
}

function renderPureAddress(address) {
  const line1 = address.get("addressAddressLine1", "");
  const line2 = address.get("addressAddressLine2", "");
  const stateProvince = address.get("addressStateProvince", "");
  
  if (isUS(address.get("addressCountryCode"))) {
    return (
      <React.Fragment>
        <div className="addressLine1">{line1}</div>
        { line2 && <div className="addressLine2">{line2}</div> }
        <div className="addressLine3">{address.get("addressCity", "")} {stateProvince} {address.get("addressPostcode", "")}</div>
        <div className="addressLine4">{address.get("addressCountryName", "")}</div>
      </React.Fragment>
    )
  } else {
    const stateProvinceWithSeparator = stateProvince && ", " + stateProvince;
    return (
      <React.Fragment>
        <div className="addressLine1">{line1}</div>
        { line2 && <div className="addressLine2">{line2}</div> }
        <div className="addressLine3">{address.get("addressPostcode", "")} {address.get("addressCity", "")}{stateProvinceWithSeparator}</div>
        <div className="addressLine4">{address.get("addressCountryName", "")}</div>
      </React.Fragment>
    )
  }
}

const AddressInline = (props) => {
  const { t } = useTranslation("common", { keyPrefix: "address_inline" });
  let address = props.address;
  if (!address.get("addressCountryName")) {
    return null;
  }
  
  let classes = cx({
    "Address": true,
    "Address-inline": props.inline,
    [props.mode]: true,
    "u-addressLine2": address.get("addressAddressLine2", "").length > 0,
    "u-email": address.get("email", "").length > 0,
    "u-attName": address.get("attName", "").length > 0,
    "u-phoneNr": address.get("phoneNr", "").length > 0,
  })

  if (props.inline) {
    return React.createElement("span", {className: classes}, [renderAddress(address)]);
  } else {
    let email = null;
    if (address.get("email", "").length > 0) {
      email = (
        <div className="email">
          <span className="label">{t("email")}: </span>
          <span className="sg-font-bold">{address.get("email")}</span>
        </div>);
    }
    let phoneNr = null;
    if (address.get("phoneNr", "").length > 0) {
      phoneNr = (
        <div className="phoneNr">
          <span className="label">{t("phone")}: </span>
          <Telephone nr={address.get("phoneNr")} className="phone sg-font-bold" />
        </div>);
    }

    const extras = phoneNr && email && (
      <div className="extras">
        {phoneNr}
        {email}
      </div>
    );

    return (
      <div className={classes + (extras ? "" : " noExtras")}>
        { props.mode === MODES.NORMAL && renderNameAndAttn(props) }
        <div className="address">
          { props.mode === MODES.TIGHT && renderNameAndAttn(props) }
          { renderPureAddress(address) }
        </div>
        { extras }
      </div>
    );
  }
}

AddressInline.defaultProps = {
  inline: false,
  showType: true,
  mode: MODES.NORMAL,
}

AddressInline.MODES = MODES;

export default AddressInline;
