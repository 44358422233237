import React, {Component} from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import TextInputField from "../../shared/components/forms/TextInputField";
import DropDownField from "../../shared/components/forms/DropDownField";
import FormError from "../../shared/components/forms/FormError";
import { COUNTRY_CODE_KEY, COUNTRY_LABEL_KEY, preferredCountries } from "../../lib/CountriesAndStates";

class AddAccountForm extends Component {

  render() {
    let {errors, handleSubmit, submit, submitting} = this.props;
    let submitLabel = submitting ? "Standby..." : "Create Account";
    return (
      <form onSubmit={handleSubmit}>
        <p className="sg-text-normal u-centered">Give your account a name and select shipping destination</p>
        <div className="fields">
          <Field 
            label="Account Name"
            name="name"
            placeholder="Personal"
            component={TextInputField}
            mode=""
            serverError={this.props.errors.name}
            />
          <Field
            label="Select Shipping Destination"
            name="countryCode"
            preferredValues={preferredCountries}
            mode=""
            options={this.props.countries}
            withSpritePath="/assets/flags/sprites-vertical.png"
            valueKey={COUNTRY_CODE_KEY}
            titleKey={COUNTRY_LABEL_KEY}
            component={DropDownField}
            serverError={this.props.errors.countryCode}
            />          
        </div>
        <div className="buttons">
          <Button label={submitLabel} onClick={submit} active={!submitting} />
          <Button label="Cancel" active={!submitting} onClick={this.props.onCancel}
            theme={Button.THEMES.BLUE_OUTLINE}
            addCancelToTopRight={true} />
        </div>
          
        <FormError error={errors._error} />
      </form>
    )
  }
}

const ReduxForm = reduxForm({
  form: "AddAccountForm"
})(AddAccountForm);

export default ReduxForm;