import React, {useEffect, useRef, useState} from "react";
import Bullets from "./Bullets";
import gsap from "gsap";
import Testimonial from "./Testimonial";
import Swipable from "./Swipable";
import styles from "./Testimonials.module.scss";

const THEMES = {
  WHITE: styles.themeWhite + " " + Testimonial.THEMES.WHITE,
  SLIDING_WHITE: styles.themeSlidingWhite + " " + Testimonial.THEMES.SLIDING_WHITE,
  BLUE: styles.themeBlue + " " + Testimonial.THEMES.BLUE,
  SLIDING_BLUE: styles.themeSlidingBlue + " " + Testimonial.THEMES.SLIDING_BLUE,
  BLACK: styles.themeBlack + " " + Testimonial.THEMES.BLACK,
}

const Testimonials = (props) => {
  const { delayInSeconds } = props;

  const innerRef = useRef();
  const timelineRef = useRef();
  
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      previousIndex = props.data.length - 1;
    }

    setCurrentIndex(previousIndex);
  }

  const handleNext = () => {
    let nextIndex = currentIndex+1;
    if (nextIndex >= props.data.length) nextIndex = 0;

    setCurrentIndex(nextIndex);
  }

  const handleBulletClick = (index) => {
    setCurrentIndex(index);
  }

  const handleTimer = () => {
    handleNext();
  }

  useEffect(() => {
    // On index and props update we animate towards the index and start the timer
    if (props.data.length <= currentIndex) {
      return setCurrentIndex(0);
    }
    animateTo(currentIndex);

    gsap.killTweensOf(timelineRef.current);
    gsap.fromTo(timelineRef.current, {width: "0%"}, {width: "100%", duration: delayInSeconds, ease: "none"});

    const timerId = window.setTimeout(handleTimer, delayInSeconds * 1000);

    return () => window.clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, props.data]);

  const animateTo = (index, animate = true) => {
    gsap.killTweensOf(innerRef.current);
    let pos = `-${index * props.width}px`;
    if (animate) {
      gsap.to(innerRef.current, {x: pos, duration: 1}); 
    } else {
      gsap.set(innerRef.current, {x: pos});    
    }
  }
  
  let items = props.data.map((item, i) =>
    <Item key={item.name} data={item} position={i} theme={props.theme} />
  );

  if (props.swipable) {
    items = (
      <Swipable onLeftSwipe={handleNext} onRightSwipe={handlePrevious}>
        {items}
      </Swipable>
    );
  }

  const classes = [styles.module, props.theme];

  const bulletsTheme = props.theme === THEMES.WHITE || props.theme === THEMES.SLIDING_WHITE
    ? Bullets.THEMES.WHITE
    : Bullets.THEMES.BLUE;
  
  return (
    <div className={classes.join(" ")} style={{width: props.width}}>
      <div className={styles.inner} ref={innerRef}>
        { items }
      </div>
      <div className={styles.timeline}>
        <div className={styles.timelineAnim} ref={timelineRef} />
      </div>
      <div className={styles.bullets}>
        <Bullets
          index={currentIndex}
          count={props.data.length}
          onClick={handleBulletClick}
          theme={bulletsTheme} />
      </div>
    </div>
  );
}

const Item = ({data, position, theme}) => {

  let outerStyles = {
    left: `${position*100}%`
  }
  return (
    <div className={styles.item} style={outerStyles}>
      <div className={styles.innerItem}>
        <Testimonial
          theme={theme}
          photo={data.photo}
          quote={data.quote}
          authorName={data.name}
          authorTitle={data.title}
        />
      </div>
    </div>
  );
}

Testimonials.defaultProps = {
  delayInSeconds: 8,
  theme: THEMES.BLACK,
}

Testimonials.THEMES = THEMES;

export default Testimonials;
