import {fromJS, Map} from "immutable";

const snakeToCamelCase = function(key) {
  // first_name -> firstName, isbn_10 -> isbn10, scores_a_1 -> scoresA1
  return key.replace(/(_+[a-z0-9])/g, function (snip) {
    return snip.toUpperCase().replace("_", "");
  });    
}


export function createValidationErrors(errors, message = "An error occured") {
  let data = fromJS(errors);
  let result = data.reduce((acc, x) => {
    return acc.set(snakeToCamelCase(x.get("key")), x.get("message"));
  }, Map());
  return result.set("_error", result.get("msg", message)).toJS();
}

export function createErrors(err) {
  var str = String(err).replace("GraphQL error: ", "");
  return {_error: str}
}
