import React from "react";
import {Field, reduxForm} from "redux-form/immutable";
import Button from "../../shared/components/Button";
import "./ProductQuantityForm.css";
import FieldError from "../../shared/components/forms/FieldError";
import DropDownField from "../../shared/components/forms/DropDownField";
import TextInputField from "../../shared/components/forms/TextInputField";
import { fromJS } from "immutable";
import { useTranslation, Trans } from 'react-i18next';


function numerical(value) {
  let parsed = parseInt(value, 10);
  if (isNaN(parsed)) return value;
  return parsed;
}

const ProductQuantityForm = (props) => {
  const { t } = useTranslation("products");

  const {
    errors,
    handleSubmit, submit, submitting,
    productTitle, isAddition = false,
  } = props;

  let submitLabel = isAddition ? t("add_to_cart_label") : t("adjust_cart_label");
  if (submitting) {
    submitLabel = t("common:standby");
  }

  const allowRemove = props.showRemove;
  const extras = {}

  const useDropDown = !isNaN(props.maxNumberOfProducts);
  if (useDropDown) {
    const dropdownOptions = Array.from(
      { length: props.maxNumberOfProducts },
      (_, i) => ({id: i+1, label: String(i+1)}));

    if (allowRemove) {
      dropdownOptions.unshift({id: 0, label: "None"});
    }

    extras.component = DropDownField;
    extras.options = fromJS(dropdownOptions);
    extras.valueKey="id";
    extras.titleKey="label";
  }
  else {
    extras.component = TextInputField;
    extras.type = "number";
  }

  return (
    <form className="ProductQuantityForm" onSubmit={handleSubmit}>
      <p>
        <Trans i18nKey="products:select_product_quantity"
          values={{
            product: productTitle,
          }}
          components={{
            b: <span className="productTitle" />
          }}
        />
      </p>
      <div className="fields">
        <Field 
          label={t("cart:quantity")}
          name="quantity"
          normalize={numerical}
          mode=""
          serverError={props.errors.quantity}
          {...extras}
          />
      </div>
      <div className="buttons">
        <Button label={submitLabel} onClick={submit} active={!submitting} />
        <Button label={t("common:cancel")} active={!submitting} onClick={props.onCancel}
          theme={Button.THEMES.BLUE_OUTLINE}
          addCancelToTopRight={true} />
      </div>
      
      <FieldError globalFormError={true}
        serverError={errors._error} />
    </form>
  )
}

const ReduxForm = reduxForm({
  form: "ProductQuantityForm"
})(ProductQuantityForm);

ProductQuantityForm.defaultProps = {
  showRemove: true,
}

export default ReduxForm;