import React from "react";
import {Subscription} from "react-apollo";
import {SubscriptionQueryWrapper} from "../lib/SubscriptionQueryWrapper";

const MonitorEditSub = (props) => {

  return (
  <Subscription
    subscription={SUBSCRIPTION_LOG}
    variables={{ subscriptionId: props.subscriptionId }}
  >
    {({ data, loading }) => {
      if (!loading && data && data.customerUpdatedSubscriptionLog) {
        if (data.customerUpdatedSubscriptionLog.eventType === "ChangedSubscription") {
          props.onSuccess();
        } else if (data.customerUpdatedSubscriptionLog.eventType === "FailedChangingSubscription") {
          props.onFailed();
        } else if (data.customerUpdatedSubscriptionLog.eventType === "ChangedSubscriptionFrequency") {
          props.onFrequencyChanged();
        }
      }
      return null;
    }}
  </Subscription>
  );
}

const SUBSCRIPTION_LOG = SubscriptionQueryWrapper("$subscriptionId: ID!", `
  customerUpdatedSubscriptionLog(subscriptionId: $subscriptionId) {
    subscriptionId
    eventType
  }`
);

export default MonitorEditSub;