import React, { Component } from "react";
import FieldError from "./FieldError";
import { cx } from "../../lib/ClassSet";
import "./TextAreaInputField.css";

const noOp = () => null;

class TextAreaInputField extends Component {

  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      focused: false
    }
  }

  render() {
    const {serverError, label, active, rows, input: {value, onChange}, meta: {touched, error}} = this.props;    

    let changeHandler = active ? onChange : noOp;
    let classes = cx({
      "TextAreaInput": true,
      "s-inactive": !active,
      "s-focused": this.state.focused,
      "s-obscured": this.props.obscured
    });

    if (this.props.mode === "table") {
      return (
        <tr className="TextAreaInputField field">
          <th>{label}</th>
          <td>
            <textarea className={classes} type="text" value={value} onChange={changeHandler} rows={rows} onFocus={this.handleFocus} onBlur={this.handleBlur} placeholder={this.props.placeholder}/>
            <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
          </td>
        </tr>
      )      
    } 

    return (
      <div className="TextAreaInputField field">
        <div className="label">{label}</div>
        <textarea className={classes} type="text" value={value} onChange={changeHandler} rows={rows} onFocus={this.handleFocus} onBlur={this.handleBlur} placeholder={this.props.placeholder} />
        <FieldError serverError={serverError} clientError={error} showClientError={touched && error} />
      </div>
    )      
  }

  handleFocus(e) {
    e.target.select();
    this.setState({focused: true});
  }

  handleBlur(e) {
    this.setState({focused: false});
  }
}

TextAreaInputField.defaultProps = {
  active: true,
  placeholder: "",
  mode: "table",
  rows: 8
}

export default TextAreaInputField;