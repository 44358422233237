import React, { useEffect, useState } from "react";
import { graphql, useSubscription } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";
import {connect} from "react-redux";
import {cancelRetryPayment, requestChangeCreditCard} from "../../actions/AccountActions";
import {createValidationErrors, createErrors} from "../../lib/ErrorFormatter";
import RetryPaymentForm from "./RetryPaymentForm";

const STATES = {
  LOADING: "loading",
  RETRYING: "retrying",
  WAITING_FOR_UPDATE: "waiting_for_update",
  REFETCHING: "refetching",
  TIMEOUT: "timeout",
  FAILED: "failed",
  SUCCESS: "success",
}
const RetryPaymentFormContainer = (props) => {
  const {
    accountId,
    salesOrderId,
    timeoutSeconds,
    data,
    customerRetryPaymentForAccount,
  } = props;
  
  const [timerId, setTimerId] = useState(null);
  const [status, setStatus] = useState(STATES.LOADING);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [errors, setErrors] = useState([]);
  
  function onBillingIssuesUpdate() {
    if (status === STATES.WAITING_FOR_UPDATE) {
      setStatus(STATES.REFETCHING);
      data.refetch();
    }
  }

  function retryPayment() {
    customerRetryPaymentForAccount({accountId: accountId, salesOrderId: salesOrderId})
      .then((response) => {
        if (response.data.customerRetryPaymentForAccount.errors.length <= 0) {
          // Retry payment successfully requested, but this doesn't mean the
          // stripe processor will succeed so we have to wait (cf. billing
          // issues subscription)
          setStatus(STATES.WAITING_FOR_UPDATE);
        } else {
          setStatus(STATES.FAILED);
          let errors = createValidationErrors(response.data.customerRetryPaymentForAccount.errors);
          setErrors(errors);
        }
      })
      .catch((err) => {
        setErrors(createErrors(err));
        setStatus(STATES.FAILED);
      });
  }

  function clearTimer() {
    clearTimeout(timerId);
  }
  function handleCancelRetryPayment() {
    clearTimer();
    props.cancelRetryPayment();
  }
  function handleChangeCreditCard() {
    clearTimer();
    props.cancelRetryPayment();
    props.requestChangeCreditCard(accountId);
  }
  function handleRetry() {
    clearTimer();
    setStatus(STATES.LOADING);
  }

  useSubscription(
    ON_UPDATED_BILLING_ISSUES,
    {
      variables: { accountId: accountId },
      onSubscriptionData: onBillingIssuesUpdate,
    }
  );

  const loading = [STATES.LOADING, STATES.RETRYING, STATES.REFETCHING, STATES.WAITING_FOR_UPDATE].includes(status);

  useEffect(() => {
    if (status === STATES.LOADING) {
      // When we start loading, we set up a new timer
      setTimerId(window.setTimeout(() => {
        setStatus(STATES.TIMEOUT);
      }, timeoutSeconds * 1000));
    }

    if (status === STATES.FAILED || status === STATES.SUCCESS) {
      // These states are final
      clearTimer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!data.loading && !data.error && data.customerBillingIssues) {
      // In any case, if we get no customer issues we set status to success
      if (data.customerBillingIssues.length === 0) {
        // No issues anymore
        setErrors([]);
        setStatus(STATES.SUCCESS);
      }

      if (status === STATES.LOADING) {
        // We make sure that the data is loaded first so that we can record a version
        // number of the billing issue, in case there is an error.
        if (data.customerBillingIssues.length > 0) {
          setCurrentVersion(data.customerBillingIssues[0].version);
          setStatus(STATES.RETRYING);
          retryPayment();
        }
      } else if (status === STATES.REFETCHING) {
        if (data.customerBillingIssues.length > 0) {
          // Ensure that the version received is greater than what we already had.
          // Otherwise, we leave in "waiting_for_update" state.
          if (data.customerBillingIssues[0].version > currentVersion) {
            setCurrentVersion(data.customerBillingIssues[0].version);
            setErrors(data.customerBillingIssues);
            setStatus(STATES.FAILED);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, currentVersion]);

  return (
    <RetryPaymentForm loading={loading} errors={errors} timeout={status === STATES.TIMEOUT}
      onCancel={handleCancelRetryPayment}
      onChangeCreditCard={handleChangeCreditCard}
      onRetry={handleRetry}
    />
  );
}


const RETRY_PAYMENT = gql`
mutation customerRetryPaymentForAccount($data: CustomerRetryPaymentForAccountInput!) {
  customerRetryPaymentForAccount(input: $data) {
    errors { key message }
  }
}
`;

const BILLING_ISSUES = gql`
  query customerBillingIssues($accountId:ID!) {
    customerBillingIssues(accountId:$accountId) {
      salesOrderId
      accountId
      errorCode
      declineCode
      description
      willRetry
      version
    }
  }
`;
const ON_UPDATED_BILLING_ISSUES = SubscriptionQueryWrapper("$accountId: ID!", `
  customerUpdatedBillingIssues(accountId: $accountId) {
    accountId
  }`
);

const mapDispatchToProps = (dispatch) => ({
  cancelRetryPayment: (props) => dispatch(cancelRetryPayment(props)),
  requestChangeCreditCard: (props) => dispatch(requestChangeCreditCard(props)),
})

const withQueries = compose(
  graphql(BILLING_ISSUES, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        accountId: props.accountId,
      }
    })
  }),
  graphql(RETRY_PAYMENT, {
    props: ({ mutate }) => ({
      customerRetryPaymentForAccount: (data) => mutate({
        variables: { data: data }
      })
    })
  }),
);

export default connect(null, mapDispatchToProps)(withQueries(RetryPaymentFormContainer));
