import React from "react";
import {autoFormat} from "../lib/TelephoneNormalizer";

const Telephone = (props) => {
  if (props.nr === null || props.nr === "" || props.nr[0] !== "+") return null;
  let formatted = autoFormat(props.nr);

  return (
    <div className={props.className}>{formatted}</div>
  )

}

Telephone.defaultProps = {
  className: "phone"
}

export default Telephone;