import React, {useEffect} from "react";
import {connect} from "react-redux";
import { cx } from "../../shared/lib/ClassSet";
import Button from "../../shared/components/Button";
import Telephone from "../../shared/Telephone";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import {SubscriptionQueryWrapper} from "../../lib/SubscriptionQueryWrapper";

import GraphQLErrors from "../../shared/GraphQLErrors";
import {fromJS} from "immutable";
import {
  requestChangePassword, 
  requestEditProfile
} from "../../actions/LoginActions";
import styles from "./ProfilePanel.module.scss";
import Subpanel from "../shared/Subpanel";
import LoadingLine from "../../shared/components/forms/LoadingLine";
import { useTranslation } from 'react-i18next';


const ProfilePanel = (props) => {
  const { t } = useTranslation("settings");
  let {data, accountId} = props;

  // Subscribe to account changes
  useEffect(() => {
    const unsubscribe = props.subscribeToProfileChanges(props, {});
    return function() {
      unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (data.loading) {
    return (
      <div className={styles.module}>
        <LoadingLine />
      </div>
    )
  }
  if (data.error) return <GraphQLErrors error={data.error} />

  let classes = cx({
    [styles.module]: true,
    [styles.uActive]: true,
  })

  const accountHolder = fromJS(data.customerAccountHolder);

  return (
    <Subpanel
      content={
        <div className={classes}>
          <div className={styles.section}>
            <div className={styles.name}><span>{t("forms:full_name")}:</span> {accountHolder.get("name")}</div>
            <div className={styles.email}><span>{t("forms:email")}:</span> {accountHolder.get("email")}</div>
            <div className={styles.phone}><span>{t("forms:phone")}:</span> <Telephone nr={accountHolder.get("phone")} /></div>
            <div className={styles.memberSince}>{t("profile.member_since", { date: accountHolder.get("memberSince") })}</div>
          </div>
        </div>}
      button={
        <React.Fragment>
          <Button label={t("profile.modify_profile.button_label")} onClick={props.requestEditProfile}
            theme={Button.THEMES.GREY_OUTLINE} className="u-bottom-margin expand" />
          <Button label={t("profile.modify_password.button_label")} onClick={props.requestChangePassword}
            theme={Button.THEMES.GREY_OUTLINE} className="expand" />
        </React.Fragment>}
    />);
}

const SUBSCRIPTIONS = gql`
  query customerSubscriptions($accountId:ID!) {
    customerAccountHolder(accountId:$accountId) {
      customerId
      name
      email
      phone
      memberSince
    }
  }
`;

const ON_UPDATED_PROFILE_NOTIFICATIONS = SubscriptionQueryWrapper("", `
  customerUpdatedProfile {
    customerId
  }`
);

const mapDispatchToProps = dispatch => ({
  subscribeToProfileChanges: (props, params) => {
    return props.data.subscribeToMore({
      document: ON_UPDATED_PROFILE_NOTIFICATIONS,
      variables: {
        accountId: props.accountId
      },
      updateQuery: (prev, { subscriptionData }) => {
        props.data.refetch();
        return prev;
      }
    })
  },
  requestChangePassword: () => dispatch(requestChangePassword()),
  requestEditProfile: () => dispatch(requestEditProfile()),
})

const withQuery = compose(
  graphql(SUBSCRIPTIONS, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        accountId: props.accountId,
        deliveriesFilter: {
          limit: 7
        }
      }
    })
  })
);

export default connect(null, mapDispatchToProps)(withQuery(ProfilePanel));
