import React, {Component} from "react";
import {Field} from "redux-form/immutable";
import {reduxForm} from "../../lib/ReduxFormWrapper";
import { PASSWORD_MIN_LENGTH } from "../../lib/Validators";
import TextInputField from "../../shared/components/forms/TextInputField";
import TelephoneInputField from "../../shared/components/forms/TelephoneInputField";
import Checkbox from "../../shared/components/forms/Checkbox";
import Button from "../../shared/components/Button";
import Conditional from "../../shared/Conditional";
import FormError from "../../shared/components/forms/FormError";
import normalizePhoneNr from "../../lib/TelephoneNormalizer";
import Navigation from "../../lib/Navigation";
import { withTranslation, Trans } from 'react-i18next';


class RegistrationForm extends Component {

  render() {
    let {errors, handleSubmit, submitting, darkBackground} = this.props;
    let submitLabel = submitting ? this.props.t("common:standby") : this.props.t("signup.registration.create_account"); 

    const privacyPolicy = (
      <span>
        <Trans i18nKey="auth:agree_terms"
          components={{
            a: <Button
              theme={darkBackground ? Button.THEMES.WHITE : Button.THEMES.BLACK}
              link={true} onClick={Navigation.gotoTermsAndConditions} />
          }}
        />
      </span>
    );
    
    return (
      <form onSubmit={handleSubmit} autoComplete="on">
        <div className="fields">
          <Field
            label={this.props.t("forms:full_name")}
            name="name"
            mode=""
            placeholder={this.props.t("forms:full_name_placeholder")}
            autoComplete="name"
            component={TextInputField}
            serverError={this.props.errors.name}
            noTable={true}
            darkBackground={darkBackground}
            />
          <Field
            label={this.props.t("forms:email")}
            name="email"
            mode=""
            placeholder={this.props.t("forms:email_placeholder")}
            autoComplete="email"
            component={TextInputField}
            serverError={this.props.errors.email}
            noTable={true}
            darkBackground={darkBackground}
            />
          <Field
            label={this.props.t("forms:password")}
            name="password"
            comment={this.props.t("forms:validation.password_comment")}
            mode=""
            placeholder="*******"
            minLength={PASSWORD_MIN_LENGTH}
            obscured
            component={TextInputField}
            serverError={this.props.errors.password}
            noTable={true}
            darkBackground={darkBackground}
            />
          <Field
            label={this.props.t("forms:phone")}
            name="phone"
            mode=""
            defaultCountryCode={this.props.countryCode}
            normalize={normalizePhoneNr}
            autoComplete="tel"
            component={TelephoneInputField}
            serverError={this.props.errors.phone}
            noTable={true}
            darkBackground={darkBackground}
            />
          <Conditional show={this.props.showReferralCode}>
            <Field
              label={this.props.t("forms:referral_code")}
              name="referralCode"
              placeholder={this.props.t("forms:referral_code_placeholder")}
              mode=""
              normalize={upcase}
              component={TextInputField}
              serverError={this.props.errors.referralCode}
              noTable={true}
              darkBackground={darkBackground}
              />
          </Conditional>
          <div className="u-bottom-margin-large sg-text-tiny">
            <Field
              label={privacyPolicy}
              name="tacAccepted"
              className="u-bottom-margin"
              component={Checkbox}
              serverError={this.props.errors.tacAccepted}
              noTable={true}
              darkBackground={darkBackground}
              />
            <Field
              label={<span>{this.props.t("receive_marketing")}</span>}
              name="marketingAccepted"
              component={Checkbox}
              serverError={this.props.errors.marketingAccepted}
              noTable={true}
              darkBackground={darkBackground}
              />
          </div>
        </div>
        <div className="u-bottom-margin">
          <Button onClick={handleSubmit(values => this.props.onSubmit(values))}
            label={submitLabel} active={!submitting}
            theme={Button.THEMES.BLUE}
            size={Button.SIZES.MEDIUM}
            className="expand"/>
        </div>
        
        <FormError error={errors._error} className="u-bottom-margin"
          darkBackground={darkBackground} />
        
        <div className="u-centered sg-text-small u-no-margin">
          <Trans i18nKey="auth:already_have_account"
            components={{
              a: <Button
                className="sg-font-bold"
                theme={darkBackground ? Button.THEMES.WHITE : Button.THEMES.BLACK}
                onClick={this.props.onSignIn} link/>
            }}
          />
        </div>
      </form>
    )    
  }
}

function upcase(val) {
  return String(val).toUpperCase();
}

RegistrationForm.defaultProps = {
  darkBackground: true,
  showReferralCode: false
}

const ReduxForm = reduxForm({
  form: "RegistrationForm",
  destroyOnUnmount: false,
})(RegistrationForm);

export default withTranslation("auth")(ReduxForm);